import * as React from "react";
import { mui } from "../../Helper";

export const MyCommandCell = (props: any) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
                onClick={() => (props.update(dataItem))}
            >
                {mui(userRights, "EditFormNFSettings", "lblUpdate", "Update")}
            </button>
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
                onClick={() => (props.cancel(dataItem))}
            >
                {mui(userRights, "EditFormNFSettings", "lblCancel", "Cancel")}
            </button>
        </td>
    ) : (
        <td className="k-command-cell">
                <button
                    disabled={dataItem.disableEdit}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                    onClick={() => props.edit(dataItem)}
            >
                    {mui(userRights, "EditFormNFSettings", "lblEdit", "Edit")}
            </button>
 
        </td>
    );
};