import {useEffect, useState } from "react";
import { TabStrip, TabStripSelectEventArguments, TabStripTab, Timeline, sortEventList } from "@progress/kendo-react-layout";
import { TabTitle, mui } from "../../Helper";
import { CMainTimeline, CUserRightsAppl} from "../../types";
import { v4 as uuidv4 } from 'uuid';

interface timelineProps {
    tlRequest: CMainTimeline;
    tlMaterial: CMainTimeline[];
    tlParam: CMainTimeline[];
};
const TimelineComp = (props: timelineProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'timeline' === ura.sFormName;
        }) : null;

    const [tabTLvalue, setTabTLValue] = useState(0);

    useEffect(() => {
        console.log('TimelineComp', props);
    }, [])

    const handleTLTabSelect = (e: TabStripSelectEventArguments) => {
        setTabTLValue(e.selected);
    };
    var formHeight: number = window.innerHeight - 350;
    return (
        <>
            <div style={{ height: "100%", width: "98%" }}>
                <TabStrip style={{ overflowX: "hidden", width: '100%', height: formHeight }} animation={false} scrollable={false} selected={tabTLvalue} onSelect={handleTLTabSelect}>
                    <TabStripTab title={<TabTitle title={mui(userRights, "timeline", "ReqTabTitle", "Request Timeline")} selected={tabTLvalue === 0 ? true : false} />}>
                        <div style={{ overflowY: "hidden", width: '100%', height: '300px' }} key={uuidv4()}>
                            <Timeline
                                className="tl-width"
                                events={sortEventList(props.tlRequest.tl)}
                                horizontal={true}
                                />                
                        </div>
                    </TabStripTab>
                    {props.tlMaterial.length > 0 && < TabStripTab title={<TabTitle title={mui(userRights, "timeline", "MatTabTitle", "Material Timeline")} selected={tabTLvalue === 1 ? true : false} />}>
                        {
                            props.tlMaterial.map((obj: CMainTimeline, index) => (
                                <div style={{ overflowY: "hidden", width: '100%', height: '300px' }} key={uuidv4()}>
                                    <label><u><b>{obj.title}</b></u></label>
                                    <Timeline
                                        className="tl-width"
                                        events={sortEventList(obj.tl)}
                                        horizontal={true}
                                    />
                                </div>
                            ))
                        }
                    </TabStripTab>}
                    {props.tlParam.length > 0 && <TabStripTab title={<TabTitle title={mui(userRights, "timeline", "ParamTabTitle", "Param Timeline")} selected={tabTLvalue === (props.tlMaterial.length > 0?2:1) ? true : false} />}>
                        {
                            props.tlParam.map((obj: CMainTimeline, index) => (
                                <div style={{ overflowY: "hidden", width: '100%', height: '300px' }} key={uuidv4()}>
                                    <label><u><b>{obj.title}</b></u></label>
                                    <Timeline
                                        className="tl-width"
                                        events={sortEventList(obj.tl)}
                                        horizontal={true}
                                    />
                                </div>
                            ))
                        }
                    </TabStripTab>}
                </TabStrip>
            </div>            
        </>
    );
}   
export default TimelineComp;