import React from 'react'
import { useRef, useEffect, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './Login.css'
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Typography } from "@progress/kendo-react-common";
import { CUserRightsAppl, SystemUser } from '../../types'
import { CacheOrderProvider, mui, performLogin, saveToStorage, UserLoggedIn } from '../../Helper';
import UserSession from '../UserSession';
import { Button } from "@progress/kendo-react-buttons";

import {
    StackLayout
} from "@progress/kendo-react-layout";

import {
    FloatingLabel,
        Label,
        Hint,
        Error,
} from "@progress/kendo-react-labels";

import {
    Form,
    Field,
    FormElement,
    FieldRenderProps,
    FormRenderProps,
} from "@progress/kendo-react-form";
import { CodeTableSel } from '../CodeTableSel/CodeTableSel';

const Login = () => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'login' === ura.sFormName;
        }) : null;


    const navigate = useNavigate();
    const userRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        username: "",
        password: "",
        errMsg: ""
    })

    const handleChange = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    useEffect(() => {
        if (UserLoggedIn()) {
            navigate('/Dashboard', { replace: true })
        }
        if (userRef.current)
            userRef.current.focus();
    }, [])

    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            errMsg: ""
        }))
    }, [data.username, data.password])


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (data.username === '' || data.password === '') {
            setData(prevData => ({
                ...prevData,
                errMsg: mui(userRights, 'login', 'msgEnterData', 'Enter username and password') 
            }));
            return;
        }
        console.log(UserSession.getSystemSettings().API_URL);
        setLoading(true);
        try {           
            const user: SystemUser = await performLogin(data.username, data.password,"");
            if (user.errinfo.success) {
                user.CurrentMandant = user.DefMandant;
                user.CurrentMandantDesc = user.DefMandantDesc;
                UserSession.setLoggedInUser(user);

                if (user.Language.toLowerCase() === "de") {
                    globalThis.locale = "de-DE";
                }
                else if (user.Language.toLowerCase() === "it") {
                    globalThis.locale = "it-IT";
                }
                else if (user.Language.toLowerCase() === "fr") {
                    globalThis.locale = "fr-FR";
                }
                else
                    globalThis.locale = "en-GB";

                await CacheOrderProvider();
                navigate('/dashboard', { replace: true })
            }
            else {
                await saveToStorage("orderProvider", "");
                setData(prevData => ({
                    ...prevData,
                    errMsg: mui(userRights, 'login', 'msgLoginFailed', 'Login Failed:') + (user.errinfo.errdesc !== '' ? (': ' + user.errinfo.errdesc) : '')
                }));
                if (userRef.current)
                    userRef.current.focus();
            }
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
                setData(prevData => ({
                    ...prevData,
                    errMsg: 'No Server response'
                }));
            } else if (err.response?.status === 401) {
                setData(prevData => ({
                    ...prevData,
                    errMsg: 'Unauthorized'
                }));
            } else {
                setData(prevData => ({
                    ...prevData,
                    errMsg: mui(userRights, 'login', 'msgLoginFailed', 'Login Failed:')
                }));
            }
            if (userRef.current)
                userRef.current.focus();
        }
        setLoading(false);
    }


    return (
        <>
            <div className='bgimage'>
                <GridLayout align={{ horizontal: 'center', vertical: 'middle' }}
                    rows={[{ height: '100%' }]} cols={[{ width: '100%' }]} gap={{ rows: 5, cols: 5 }}
                    style={{ minHeight: '100vh', height: '100%', display: 'flex' }}
                >
                    <GridLayoutItem className="content">
                        <form onSubmit={handleSubmit}>
                            <StackLayout orientation={"vertical"} gap={10}>
                                <Typography.h5 textAlign={"center"} fontWeight={"bold"}>{mui(userRights, 'login', 'header', 'Authentication')}</Typography.h5>
                                <Label editorId={'username'}>{mui(userRights, 'login', 'lblUsername', 'Username:')}</Label>
                                <Input ref={userRef} autoComplete="off" id="username" name="username" 
                                    type='text' value={data.username} onChange={handleChange} />
                                <Label editorId={'password'}>{mui(userRights, 'login', 'lblPassword', 'Password:')}</Label>
                                <Input id="password" name="password" autoComplete="off" 
                                        type='password' value={data.password} onChange={handleChange} />
                                <Button type={"submit"} themeColor={'primary'}>{mui(userRights, 'login', 'btn-login', 'Log in')}</Button>
                                <Typography.h6 themeColor={'warning'} textAlign={'center'} variant='subtitle1' className={data.errMsg ? "errmsg" : "offscreen"}>{data.errMsg}</Typography.h6>
                            </StackLayout>
                        </form>
                    </GridLayoutItem>
                </GridLayout>
            </div>
        </>
        );
}

export default Login