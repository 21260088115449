import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { StackLayout } from "@progress/kendo-react-layout";
import { ListBox, ListBoxDragEvent, ListBoxItemClickEvent, ListBoxToolbar, ListBoxToolbarClickEvent, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../../Helper";
import { ctDataType, ListItemData, MULTI_SELECT_SEP, MULTI_SELECT_TABLES } from "../../../types";
import axios from 'axios';
import UserSession from "../../UserSession";

export interface multiSelectProps {
    multiSelectTable: MULTI_SELECT_TABLES;
    selectedValues: string;
    showDialog: boolean;
    MultiSelectSaved(multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean): any;
}

const SELECTED_FIELD = "selected";

let source: any = null;

const MultiSelectList = (props: multiSelectProps) => {
    const [allFields, setAllFields] = useState<ListItemData[]>([]);
    const [selectedFields, setSelectedFields] = useState<ListItemData[]>([]);  

    const [visible, setVisible] = useState<boolean>(props.showDialog);

    const CancelClick = async () => {
        props.MultiSelectSaved(props.multiSelectTable, "", true);
        setVisible(false);
    };

    const SaveClick = async () => {
        let sSelectedValues = "";
        if (selectedFields.length > 0)
            sSelectedValues = Array.prototype.map.call(selectedFields, function (item: ListItemData) { return item.id; }).join(MULTI_SELECT_SEP);
        else
            sSelectedValues = "";
        props.MultiSelectSaved(props.multiSelectTable, sSelectedValues, false);
        setVisible(false);
    };

    useEffect(() => {
        source = axios.CancelToken.source();

        return () => {
            if (source) {
                source.cancel("Component got unmounted");
            }
        };

    }, [])

    function loadSourceData() {
        const fill_ReportPage = async () => {
            if (props.multiSelectTable === "") {
                setAllFields([]);
                setSelectedFields([]);
            }
            else {
                try {
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'address/getReportPageData',
                        JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, forSelection: true }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json',
                            },
                            withCredentials: false,
                            cancelToken: source.token
                        }
                    );
                    var binddata_all: ListItemData[] = [];
                    var binddata_sel: ListItemData[] = [];
                    if (response?.data !== null)
                        response?.data.forEach((o: any) => {
                            if (props.selectedValues.indexOf(o.sReportPageID) === -1)
                                binddata_all.push({ selected: false, id: o.sReportPageID, name: o.sReportName });
                            else
                                binddata_sel.push({ selected: false, id: o.sReportPageID, name: o.sReportName });
                            setAllFields(binddata_all);
                            setSelectedFields(binddata_sel);
                        });
                } catch (err: any) {
                    console.log(err);
                    if (!err?.response) {
                    } else if (err.response?.status === 401) {
                    } else {
                    }
                }
            }
        }
        const fill_DiagnoseCode = async () => {
            if (props.multiSelectTable === "") {
                setAllFields([]);
                setSelectedFields([]);
            }
            else {
                try {
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SelectCode/getDiagnoseCodeData',
                        JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, forSelection: true }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json',
                            },
                            withCredentials: false,
                            cancelToken: source.token
                        }
                    );
                    var binddata_all: ListItemData[] = [];
                    var binddata_sel: ListItemData[] = [];
                    if (response?.data !== null)
                        response?.data.forEach((o: any) => {
                            if (props.selectedValues.indexOf(o.sCode) === -1)
                                binddata_all.push({ selected: false, id: o.sCode, name: o.sName });
                            else
                                binddata_sel.push({ selected: false, id: o.sCode, name: o.sName });
                            setAllFields(binddata_all);
                            setSelectedFields(binddata_sel);
                        });
                } catch (err: any) {
                    console.log(err);
                    if (!err?.response) {
                    } else if (err.response?.status === 401) {
                    } else {
                    }
                }
            }
        }
        const fill_OrderProvider = async () => {
            if (props.multiSelectTable === "") {
                setAllFields([]);
                setSelectedFields([]);
            }
            else {
                try {
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SelectCode/getOrderProviderData',
                        JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, forSelection: true }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json',
                            },
                            withCredentials: false,
                            cancelToken: source.token
                        }
                    );
                    var binddata_all: ListItemData[] = [];
                    var binddata_sel: ListItemData[] = [];
                    if (response?.data !== null)
                        response?.data.forEach((o: any) => {
                            if (props.selectedValues.indexOf(o.sOrdProvCode) === -1)
                                binddata_all.push({ selected: false, id: o.sOrdProvCode, name: o.sName + ' ' + o.sFirstName });
                            else
                                binddata_sel.push({ selected: false, id: o.sOrdProvCode, name: o.sName + ' ' + o.sFirstName });
                            setAllFields(binddata_all);
                            setSelectedFields(binddata_sel);
                        });
                } catch (err: any) {
                    console.log(err);
                    if (!err?.response) {
                    } else if (err.response?.status === 401) {
                    } else {
                    }
                }
            }
        }
        const fill_Antibody = async () => {
            if (props.multiSelectTable === "") {
                setAllFields([]);
                setSelectedFields([]);
            }
            else {
                try {
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SelectCode/gettabledata',
                        JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "tableid": "Antibodies", "code": "AntibodyID", "desc": "AntibodyName", "supplParam1": "", "supplParam2": "", }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json',
                            },
                            withCredentials: false,
                            cancelToken: source.token
                        }
                    );
                    var binddata_all: ListItemData[] = [];
                    var binddata_sel: ListItemData[] = [];
                    if (response?.data !== null)
                        console.log(response?.data);
                    response?.data.forEach((o: any) => {
                        console.log(props.selectedValues.indexOf(o.code))
                        if (props.selectedValues.indexOf(o.code) === -1)
                            binddata_all.push({ selected: false, id: o.code, name: o.desc });
                        else
                            binddata_sel.push({ selected: false, id: o.code, name: o.desc });
                        setAllFields(binddata_all);
                        setSelectedFields(binddata_sel);
                    });
                } catch (err: any) {
                    console.log(err);
                    if (!err?.response) {
                    } else if (err.response?.status === 401) {
                    } else {
                    }
                }
            }
        }

        if (props.multiSelectTable === "ReportPage")
            fill_ReportPage();
        else if (props.multiSelectTable === "DiagnoseCode") {
            console.log("DiagnoseCode")
            fill_DiagnoseCode();
        }
        else if (props.multiSelectTable === "OrderProvider") {
            console.log("OrderProvider")
            fill_OrderProvider();
        }
        else if (props.multiSelectTable === "Antibody") {
            console.log("Antibody")
            fill_Antibody();
        }
            
        
    }

    useEffect(() => {
        if (props.showDialog === false) {
            setAllFields([]);
            setSelectedFields([]);
        } else {
            loadSourceData();
        }
        setVisible(props.showDialog);
    }, [props.showDialog])  

    const handleItemClick = (event: ListBoxItemClickEvent, allData: ListItemData[], selData: ListItemData[], allLocation: string) => {
        const newData: ListItemData[] = allData.map((item: ListItemData) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        const chosenData: ListItemData[] = selData.map((item: ListItemData) => {
            if (item.id === event.dataItem.id) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        if (allLocation === 'FirstAll') {
            setAllFields(newData);
            setSelectedFields(chosenData);
        }
        else {
            setAllFields(chosenData);
            setSelectedFields(newData);
        }

    };

   // user chose to transfer/remove one or more items from left or right list
    const handleToolBarClick = (toolName: string) => {
        let result: any = processListBoxData(
            allFields,
            selectedFields,
            toolName,
            SELECTED_FIELD
        );
        setAllFields(result.listBoxOneData.map((item: any) => {
            item.selected = false;
            return item;
        }));
        setSelectedFields(result.listBoxTwoData.map((item: any) => {
            item.selected = false;
            return item;
        }));
    };

    return (
        <>
            <div>
                {visible && (
                    <Dialog  title={<DialogTitleBar title={mui(userRights, "multiSelect", "header", "Multi Select")} />} onClose={CancelClick}
                        height={525} width={750} >
                        <div className="container" style={{ overflowX: 'hidden', width: '95%' }}>
                            <div className="row ">
                                <div className="col">
                                    <ListBox data-id="lstSource"
                                        style={{ height: 400, width: "100%" }}
                                        data={allFields}
                                        textField="name"
                                        
                                        selectedField={SELECTED_FIELD}
                                        onItemClick={(e: ListBoxItemClickEvent) =>
                                            handleItemClick(e, allFields, selectedFields, 'FirstAll')
                                        }
                                    />
                                </div>
                                <div className="col" style={{ width: '100', maxWidth: 100 }}>
                                    <StackLayout orientation={"vertical"}>
                                        <Button className="buttons-container-button" icon="arrow-60-right"
                                            onClick={() => {
                                                handleToolBarClick('transferTo');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-seek-right"
                                            onClick={() => {
                                                handleToolBarClick('transferAllTo');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-60-left"
                                            onClick={() => {
                                                handleToolBarClick('transferFrom');
                                            }}
                                        >
                                        </Button>
                                        <Button className="buttons-container-button" icon="arrow-seek-left"
                                            onClick={() => {
                                                handleToolBarClick('transferAllFrom');
                                            }}
                                        >
                                        </Button>
                                    </StackLayout>
                                </div>
                                <div className="col">
                                    <ListBox data-id="lstTarget"
                                        style={{ height: 400, width: "100%" }}
                                        data={selectedFields}
                                        textField="name"
                                        selectedField={SELECTED_FIELD}
                                        onItemClick={(e: ListBoxItemClickEvent) =>
                                            handleItemClick(e, selectedFields, allFields, 'SecondAll')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row"><div className="col">&nbsp;</div></div>
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>&nbsp;
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            SaveClick()
                                        }}>{mui(userRights, "common", "cmdSelect", "Select")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    );
}

export default MultiSelectList;