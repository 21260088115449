import { SocketLog } from "./Helper";
import UserSession from "./components/UserSession";

export type enumSocketCallback = "success" | "close" | "error" | "exception";

type doScanningSocketCallback = (source: string, status: enumSocketCallback, data: string) => any;
export function getWorkstationID(fnCallback: doScanningSocketCallback) {
    try {
        let socket = new WebSocket("ws://127.0.0.1:" + UserSession.getSystemSettings().socketPort + "/getworkstation");
        socket.onopen = function (e) {
            if (SocketLog()) console.log("[getWorkstationID.open] Connection established");
            socket.send("getworkstation");
        };
        socket.onmessage = function (event) {
            if (SocketLog()) console.log(`[getWorkstationID.message] Data received from server`); // : ${event.data}
            fnCallback("", "success", event.data);
        };
        socket.onclose = function (event) {
            if (event.wasClean) {
                if (SocketLog()) console.log(`[getWorkstationID.close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                // e.g. server process killed or network down event.code is usually 1006 in this case
                if (SocketLog()) console.log('[getWorkstationID.close] Connection died');
            }
            fnCallback("", "close", "");
        };
        socket.onerror = function (error) {
            console.log(`[getWorkstationID.error]`, error);
            fnCallback("", "close", "");
        };
    }
    catch (ex: any) {
        console.log('getWorkstationID.Exception: ', ex);
        fnCallback("", "exception", ex);
    }
}
export function fillDevicesSocket(fnCallback: doScanningSocketCallback) {
    try {
        let socket = new WebSocket("ws://127.0.0.1:" + UserSession.getSystemSettings().socketPort + "/getdevices");
        socket.onopen = function (e) {
            if (SocketLog()) console.log("[fillDevicesSocket.open] Connection established");
            socket.send("getdevices");
        };
        socket.onmessage = function (event) {
            if (SocketLog()) console.log(`[fillDevicesSocket.message] Data received from server`); // : ${event.data}
            fnCallback("", "success", event.data);
        };
        socket.onclose = function (event) {
            if (event.wasClean) {
                if (SocketLog()) console.log(`[fillDevicesSocket.close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                // e.g. server process killed or network down event.code is usually 1006 in this case
                if (SocketLog()) console.log('[fillDevicesSocket.close] Connection died');
            }
            fnCallback("", "close", "");
        };
        socket.onerror = function (error) {
            console.log(`[fillDevicesSocket.error]`, error);
            fnCallback("", "close", "");
        };
     }
    catch (ex: any) {
        console.log('fillDevicesSocket.Exception: ', ex);
        fnCallback("", "exception", ex);
    }
}

export const doScanningSocket = (source: string, scannerSelected: string, onePageScanned: doScanningSocketCallback) => {
    try {
        let socket = new WebSocket("ws://127.0.0.1:" + UserSession.getSystemSettings().socketPort + "/scan?scannername=" + scannerSelected);
        socket.onopen = function (e) {
            if (SocketLog()) console.log("[doScanningSocket.open] Connection established");
            socket.send("scan|" + scannerSelected);
        };
        socket.onmessage = function (event) {
            if (SocketLog()) console.log(`[doScanningSocket.message] Data received from server`); // : ${event.data}
            onePageScanned(source, "success", event.data);
        };
        socket.onclose = function (event) {
            if (event.wasClean) {
                if (SocketLog()) console.log(`[doScanningSocket.close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                // e.g. server process killed or network down event.code is usually 1006 in this case
                if (SocketLog()) console.log('[doScanningSocket.close] Connection died');
            }
            onePageScanned(source, "close", "");
        };
        socket.onerror = function (error) {
            console.log(`[doScanningSocket.error]`);
            onePageScanned(source, "error", String(error));
        };
    }
    catch (ex: any) {
        console.log('doScanningSocket.Exception: ', ex);
        onePageScanned(source, "exception", ex);
    }

}
