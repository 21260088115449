import React, { useEffect, useState } from "react";
import { CAddnReqParam, CAddnReqParamOutput, CUserRightsAppl, CGenType, CSaveStatus, requestDialogData, COneRequest, viewResultDialogCriteria, viewResultDialog } from "../../types";
import UserSession from "../UserSession";
import axios from 'axios';
import { Loader } from '@progress/kendo-react-indicators';
import { Label } from "@progress/kendo-react-labels";
import { mui} from "../../Helper";
import { Checkbox, CheckboxChangeEvent, RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { ComboBoxChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { getSelectedState, Grid, GridCellProps, GridColumn, GridRowProps, GridSelectionChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { getter, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import RequestDialog from "../RequestEntry/RequestDialog";
import ViewResultDialog from "../ViewResult/ViewResultDialog";
import ProgressBar from "../Misc/ProgressBar";

interface AdditionalRequestParamProps {
    loadfor: string;
    mandantChanged: number;
}

const initialSort: Array<SortDescriptor> = [
    /* { field: "sReqDate", dir: "asc" },*/
];

const emptyAddnReqParam: CAddnReqParamOutput = {
    listAddnReqParam:[],
    listParamGroup:[],
    listWorkGroup:[],
};

const AdditionalRequestParam = (props: AdditionalRequestParamProps) => {
    const columns = [
        { field: "sCode", header: "Code" },
        { field: "sDesc", header: "Description" },
    ];
    const [sort, setSort] = useState(initialSort);
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'AdditionalRequestParam' === ura.sFormName;
        }) : null;
    const [isLoading, setIsLoading] = useState(false);    
    const [selectedOption, setSelectedOption] = React.useState("RD");
    const [selParamGroup, setSelParamGroup] = useState<CGenType | null>(null);
    const [selWorkGroup, setSelWorkGroup] = useState<CGenType | null>(null);
    const [gridData, setGridData] = useState<CAddnReqParam[]>([]);
    const [paramGroups, setParamGroups] = useState<CGenType[]>([]);
    const [workGroups, setWorkGroups] = useState<CGenType[]>([]);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const idGetter = getter('sReqID');
    //const ref = useRef<HTMLDivElement>(null);
    //const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    //console.log(inViewport);
    // for request dialog
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({
        reqid: '', pid: '', fid: '',
        bloodProduct: false, showDialog: false, inViewport: false, extcall: false
    });
    // inViewport
    
    const emptyVRC: viewResultDialogCriteria = {
        mandantid: "",
        mbyn: false,
        pid: "",
        fid: "",
        gendercode: "",
        birthdate: "",
        pregnancyweeks: "",
        toage: "",
        reqid: "",
        reqdate: "",
        reqtime: "",
        medvalidation: "",
        valtype: ""
    };
    const dlgEmptyState: viewResultDialog = {
        objVRC: emptyVRC, showDialog: false, cancleClicked: () => { }
    };
    const [viewResultDialog, setViewResultDialog] = useState<viewResultDialog>(dlgEmptyState);

    const fillData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'additionalreqparam/search',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, paramgroup: selParamGroup != null ? selParamGroup.sCode : "",
                    workgroup: selWorkGroup != null ? selWorkGroup.sCode : "", borderbyparamgroup: selectedOption === 'PG' ? true : false, borderbyworkgroup: selectedOption === 'WG' ? true : false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            var binddata: CAddnReqParamOutput = emptyAddnReqParam;
            var data: CAddnReqParam[] = [];
            var cbdata: CGenType[] = [];
            if (response?.data !== null)
                binddata = response?.data;
            binddata.listAddnReqParam.forEach((o: CAddnReqParam) => {
                data.push({ ...o });
            });
            setGridData(data);
            if (paramGroups.length === 0) {
                if (binddata?.listParamGroup != null)
                    binddata.listParamGroup.forEach((o: CGenType) => {
                        cbdata.push({ ...o });
                    });
                setParamGroups(cbdata)
            };
            if (workGroups.length === 0) {
                cbdata = [];
                if (binddata?.listWorkGroup != null)
                    binddata.listWorkGroup.forEach((o: CGenType) => {
                        cbdata.push({ ...o });
                    });
                setWorkGroups(cbdata);
            };
        } catch (err: any) {
            console.log('AdditionalRequestParam.fillData ', err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fillData();
    }, [props.mandantChanged, selParamGroup, selWorkGroup, selectedOption]);

    const handleOBChange = React.useCallback(
        (e: RadioButtonChangeEvent) => {
            setSelectedOption(e.value);
        },
        [setSelectedOption]
    );

    const handlePGChange = (event: ComboBoxChangeEvent) => {
        var objPG: CGenType = { sCode: "", sDesc: "" };        
        if (event.value != null) objPG = event.value;                
        setSelParamGroup(objPG);        
    };

    const handleWGChange = (event: ComboBoxChangeEvent) => {   
        var objWG: CGenType = { sCode: "", sDesc: "" };        
        if (event.value != null) objWG = event.value;        
        setSelWorkGroup(objWG);        
    };

    const handleCBChange = (event: CheckboxChangeEvent,reqid:string ,paramid:string) => {
        const binddata: CAddnReqParam[] = [...gridData];
        var obj: CAddnReqParam;
        for (obj of binddata) {
            if (obj.sReqID === reqid && obj.sParamID === paramid) {
                obj.sAddnParamYN = event.value ? "1" : "0";
                break;
            }
        }
        setGridData(binddata);
    };

    const addnParamCustomCell = (props: GridCellProps) => { 
        const reqid = props.dataItem['sReqID']; 
        const paramid = props.dataItem['sParamID']; 
        const checkvalue = props.dataItem['sAddnParamYN'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handleCBChange(event, reqid, paramid)} checked={checkvalue === "1" ? true : false}></Checkbox>
            </td>
        );
    };

    const saveClicked = async () => {
        try {
            var obj: CSaveStatus;
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'additionalreqparam/save',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, paramgroup: selParamGroup != null ? selParamGroup.sCode : "",
                    workgroup: selWorkGroup != null ? selWorkGroup.sCode : "", userid: UserSession.getLoggedInUser().UserID, griddata: gridData
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            obj = response?.data;
            if (obj.bRecordSaved) {
                var cbdata: CGenType[] = [];
                setParamGroups(cbdata);
                setWorkGroups(cbdata);
                fillData();
            } else {
                if (obj.sMsg !== "") alert(obj.sMsg);
            };
        } catch (err: any) {
            console.log('AdditionalRequestParam.saveClick ', err);
        }
        setIsLoading(false);
    };    

    const arpRowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.sRowColor };
        const trProps: any = { style: rowColor };
        return React.cloneElement(
            trElement,
            { ...trProps }
        );
    };

    const arpGridSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'sReqID',
        });
        let selectedIds = []
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let product = gridData.find(item => item.sReqID === property.toString())
                selectedIds.push(product)
            }
        }       
        setSelectedState(newSelectedState);
    };

    const requestEdit = (ReqID: string, PID:string,FID:string) => {
        setRequestDialogData({
            reqid: ReqID, pid: PID, fid: FID, bloodProduct: false, showDialog: true,
            inViewport: false, extcall: false
        })
    };
    // inViewport

    // Called when the request add/edit screen is closed using the cancel or save button
    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        console.log('callbackRequestDialogClosed', objRequest);
        setRequestDialogData({
            reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false,
            inViewport: false, extcall: false
        })
    };
    // inViewport

    const requestEditCustomCell = (props: GridCellProps) => {
        const ReqID = props.dataItem.sReqID;
        const PID = props.dataItem.sPID;
        const FID = props.dataItem.sFID;
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" title={mui(userRights, "AdditionalRequestParam", "btnReqDetails", "Request Details")} icon="pencil"
                    onClick={() => {
                        requestEdit(ReqID,PID,FID);
                    }}
                >
                </Button>
            </td>
        );
    };

    const vrCustomCell = (props: GridCellProps) => {
        const ReqID = props.dataItem.sReqID;
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" title={mui(userRights, "AdditionalRequestParam", "btnViewResult", "View Result")} icon="eye"
                    onClick={() => {
                        //alert('edit clicked for ' + value);                        
                        showViewResultForm(ReqID, 'open');
                    }}
                >
                </Button>
            </td>
        );
    };

    const pbCustomCell = (props: GridCellProps) => {
        const ReqID = props.dataItem.sReqID;
        const PID = props.dataItem.sPID;
        const FID = props.dataItem.sFID;
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" title={mui(userRights, "AdditionalRequestParam", "btnPrintLabels", "Print Labels")} icon="printer"
                    onClick={() => {
                        printBarcodeAndLabels(ReqID, PID, FID);
                    }}
                >
                </Button>
            </td>
        );
    };

    const printBarcodeAndLabels = (ReqID: string, PID: string, FID: string) => {
        try {
            axios.post(UserSession.getSystemSettings().API_URL + 'request/printbarcodeandlabels',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: ReqID, pid: PID, fid: FID, bprintdirectly: false, userid: UserSession.getLoggedInUser().UserID
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
        } catch (err: any) {
            console.log('AdditionalRequestParam.printBarcodeAndLabels ', err);
        }
    };

    const showViewResultForm = (ReqID: string, action: string) => {
        let addnreqparamdata = gridData.find((i: any) => i.sReqID === ReqID);
        if (addnreqparamdata !== undefined) {
            var objVRC: viewResultDialogCriteria = emptyVRC;
            objVRC.mandantid = UserSession.getLoggedInUser().CurrentMandant
            objVRC.reqid = addnreqparamdata.sReqID
            objVRC.pid = addnreqparamdata.sPID
            objVRC.fid = addnreqparamdata.sFID
            objVRC.reqdate = addnreqparamdata.sReqDate.substring(0,9)
            objVRC.reqtime = addnreqparamdata.sReqDate.substring(10)
            objVRC.birthdate = addnreqparamdata.sBirthdate
            objVRC.valtype = ""
            objVRC.gendercode = ""
            objVRC.pregnancyweeks = ""
            setViewResultDialog({ objVRC: objVRC, showDialog: action === 'open' ? true : false, cancleClicked: cancleClicked })
        }
    };

    const cancleClicked = () => {
        setViewResultDialog(dlgEmptyState);
    };

    // Sizing of form contents
    const location: string = props.loadfor;
    var formHeight: number = location === "modal" ? 720 : window.innerHeight;
    const formWidth: number = location === "modal" ? 1400 : window.innerWidth;
    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        formHeight -= 110;
        gridHeight = formHeight - 100; // as dialog
        contentWidth = formWidth - 20;
    }
    else {
        formHeight -= 200;
        gridHeight = formHeight - 110; // as dashboard
        contentWidth = formWidth - 130;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: contentWidth, minWidth: contentWidth }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="col-12">&nbsp;</div>
                    <div className="col">
                            <div>
                                <div><Label editorId={'paramgroup'}>{mui(userRights, "AdditionalRequestParam", "lblParamGroup", "ParamGroup")}</Label></div>
                                <div>
                                    <MultiColumnComboBox
                                        columns={columns}
                                        id={"combobox"}
                                        style={{ width: "300px" }}
                                        data={paramGroups}
                                        textField="sDesc"
                                        dataItemKey="sCode"
                                        value={selParamGroup}
                                        onChange={handlePGChange}

                                    />
                                </div>
                            </div>
                        </div>
                    <div className="col">
                            <div>
                                <div><Label editorId={'workgroup'}>{mui(userRights, "AdditionalRequestParam", "lblWorkGroup", "WorkGroup")}</Label></div>
                                <div>
                                    <MultiColumnComboBox
                                        columns={columns}
                                        id={"combobox"}
                                        style={{ width: "300px" }}
                                        data={workGroups}
                                        textField="sDesc"
                                        dataItemKey="sCode"
                                        value={selWorkGroup}
                                        onChange={handleWGChange}

                                    />
                                </div>
                            </div>
                        </div>
                    <div className="col">
                            <div className="vertical-wrapper">
                                <div className="vertical-wrapper-child"><Label editorId={'orderBy'}>{mui(userRights, "AdditionalRequestParam", "lblOrderBy", "OrderBy")}</Label></div>
                                <div className="vertical-wrapper-child">
                                    <RadioButton
                                        width="300px"
                                        name="group1"                                    
                                        value="RD"
                                        checked={selectedOption === "RD"}
                                        label={mui(userRights, "AdditionalRequestParam", "optReqDate", "ReqDate")}
                                        onChange={handleOBChange}
                                    />&nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        width="300px"
                                        name="group1"
                                        value="PG"
                                        checked={selectedOption === "PG"}
                                        label={mui(userRights, "AdditionalRequestParam", "optParamGroup", "ParamGroup")}
                                        onChange={handleOBChange}
                                    />&nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        width="300px"
                                        name="group1"
                                        value="WG"
                                        checked={selectedOption === "WG"}
                                        label={mui(userRights, "AdditionalRequestParam", "optWorkGroup", "WorkGroup")}
                                        onChange={handleOBChange}
                                    />
                                </div>                            
                            </div>
                    </div>    
                    <div className="col">
                        <div className="button-right" style={{paddingTop:'10px', paddingRight: '35px' }}>
                            <Button style={{ width: '120px', display: 'inline-block' }}
                                themeColor={'primary'}
                                onClick={saveClicked}
                            >
                                {mui(userRights, "common", "cmdSave", "Save")}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                            <Grid
                                style={{
                                    height: gridHeight, width: '100%'
                                }}
                                data={orderBy(gridData, sort).map((item) => ({
                                    ...item,
                                    selected: selectedState[idGetter(item)],
                                }))}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort(e.sort);
                                }}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                onSelectionChange={arpGridSelectionChange}
                                selectedField={'selected'}
                                rowRender={arpRowRender}
                            >
                                <GridColumn field="" title="" width="50px" cell={requestEditCustomCell} />
                                <GridColumn field="" title="" width="50px" cell={vrCustomCell} />
                                <GridColumn field="" title="" width="50px" cell={pbCustomCell} />
                                <GridColumn field="sReqID" title={mui(userRights, "AdditionalRequestParam", "col1_ReqID", "ReqID")} width="90px" />
                                <GridColumn field="sReqNr" title={mui(userRights, "AdditionalRequestParam", "col2_ReqNr", "ReqNr")} width="70px" />
                                <GridColumn field="sReqDate" title={mui(userRights, "AdditionalRequestParam", "col3_ReqDate", "ReqDate")} width="150px" />
                                <GridColumn field="sPID" title={mui(userRights, "AdditionalRequestParam", "col4_PID", "PID")} width="90px" />
                                <GridColumn field="sPatName" title={mui(userRights, "AdditionalRequestParam", "col5_PatName", "PatName")} width="250px" />
                                <GridColumn field="sBirthdate" title={mui(userRights, "AdditionalRequestParam", "col6_Birthdate", "Birthdate")} width="90px" />
                                <GridColumn field="sGender" title={mui(userRights, "AdditionalRequestParam", "col7_Gender", "Gender")} width="90px" />
                                <GridColumn field="sOrdProv" title={mui(userRights, "AdditionalRequestParam", "col8_OrdProv", "OrdProv")} />
                                <GridColumn field="sSerothekDetails" title={mui(userRights, "AdditionalRequestParam", "col9_SerothekInfo", "SerothekInfo")} />
                                <GridColumn field="sParamID" title={mui(userRights, "AdditionalRequestParam", "col10_ParamID", "ParamID")} width="90px" />
                                <GridColumn field="sRecordedDate" title={mui(userRights, "AdditionalRequestParam", "col11_RecordedDate", "RecordedDate")} width="150px" />
                                <GridColumn field="sAddnParamYN" title={mui(userRights, "AdditionalRequestParam", "col12_AddnParamYN", "AddnParamYN")}
                                    width="120px" cell={addnParamCustomCell} />
                                <GridColumn field="sTransportMedium" title={mui(userRights, "AdditionalRequestParam", "col13_TransportMedium", "TranspMed")} />
                                );
                            </Grid>
                    }
                    
                    </div>
                </div>
            </div>
            <div>
                <RequestDialog data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
                <ViewResultDialog objVRC={viewResultDialog?.objVRC} showDialog={viewResultDialog?.showDialog} cancleClicked={cancleClicked}></ViewResultDialog>
            </div>            
        </>
    );
}
export default AdditionalRequestParam;


