import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect } from "react";
import { LabelBold } from "../../../Helper";
import { adrBdAKS, adrBdGrp, adrBdTrf, CAddressData } from "../../../types";

interface AdrBdTabProps {
    data: CAddressData;
    contentContainerWidth: number;
    windowHeight: number;
    adrBdGrpData: adrBdGrp[];
    adrBdAksData: adrBdAKS[];
    adrBdTrfData: adrBdTrf[]
}

const AdrBdTab = (props: AdrBdTabProps) => {
    useEffect(() => {
        

        return () => {
            
        };

    }, [])

    const genericFunction = () => {
        alert('todo');
    };

    var tabContentHeight: number = props.windowHeight - 200;
    var threeGridHeight: number = (tabContentHeight / 3) - 30;
 
    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth, width: props.contentContainerWidth - 50 }}>
                <div className="row pb5 pt5">
                    <div className="col form-section-header">
                        <LabelBold>Adr. Bd. Grp</LabelBold>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: threeGridHeight, border: '0px solid red' }}
                                sortable={true}
                                selectedField={'selected'}
                                data={props.adrBdGrpData}
                                dataItemKey={'ReqID'}
                            >
                                <GridColumn field="ReqID" title="ReqID" width="100px" />
                                <GridColumn field="ReqDateDisplay" title="ReqDate" width="180px" />
                                <GridColumn field="BldGrp" title="BloodGroup" width="100px" />
                                <GridColumn field="Rhesus" title="Rhesus" width="100px" />
                                <GridColumn field="PhaenTypeDesc" title="PhenoType" width="100px" />
                                <GridColumn field="CtrlNr" title="CtrlNr" width="100px" />
                                <GridColumn field="Comment" title="Comment" />
                                <GridColumn field="FullBGYN" title="FullBGYN" width="100px" />
                                <GridColumn field="KellDesc" title="Kell" width="100px" />
                                <GridColumn field="DATDesc" title="DAT" width="100px" />
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="row pb5 pt5">
                    <div className="col form-section-header">
                        <LabelBold>Adr. Bd. Aks</LabelBold>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: threeGridHeight, border: '0px solid red' }}
                                sortable={true}
                                selectedField={'selected'}
                                data={props.adrBdAksData}
                                dataItemKey={'ReqID'}
                            >
                                <GridColumn field="ReqID" title="ReqID" width="100px" />
                                <GridColumn field="ReqDateDisplay" title="ReqDate" width="180px" />
                                <GridColumn field="AksResult" title="AksResult" width="100px" />
                                <GridColumn field="AksComment" title="AksComment" width="100px" />
                                <GridColumn field="AntibodyID" title="AntibodyID" width="100px" />
                                <GridColumn field="AksEnzym" title="AksEnzym" width="100px" />
                                <GridColumn field="AKSVALIDDateDisplay" title="AKSValitTo" />
                                <GridColumn field="BGChangeSerID" title="BGChangeSerID" width="100px" />
                                <GridColumn field="AntibodyNotToPrint" title="AntibodyNotToPrint" width="100px" />
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="row pb5 pt5">
                    <div className="col form-section-header">
                        <LabelBold>Adr. Bd. Trf</LabelBold>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: threeGridHeight, border: '0px solid red' }}
                                sortable={true}
                                selectedField={'selected'}
                                data={props.adrBdTrfData}
                                dataItemKey={'ReqID' + 'BldBgNr'}
                            >
                                <GridColumn field="ReqID" title="ReqID" width="100px" />
                                <GridColumn field="ReqDateDisplay" title="ReqDate" width="180px" />
                                <GridColumn field="BldBgNr" title="BldBgNr" width="100px" />
                                <GridColumn field="CompType" title="CompType" width="100px" />
                                <GridColumn field="BldBgBGrpDesc" title="BldBgBGrpDesc" width="100px" />
                                <GridColumn field="BldBgRhsDesc" title="BldBgRhsDesc" width="100px" />
                                <GridColumn field="TransDateDisplay" title="TransDate" width="100px" />
                                <GridColumn field="Reaction" title="Reaction" width="100px" />
                                <GridColumn field="Switch" title="Switch" width="100px" />
                                <GridColumn field="TransChargeYN" title="TransChargeYN" width="100px" />
                                <GridColumn field="DeliveryDateDisplay" title="DeliveryDate" width="100px" />
                                <GridColumn field="BGChangeSerID" title="BGChangeSerID" width="100px" />
                                <GridColumn field="IncompatibleCompReason" title="IncompatibleCompReason" width="100px" />
                                <GridColumn field="BldBgType" title="BldBgType" width="100px" />
                                <GridColumn field="ReservedDateDisplay" title="ReservedDate" width="100px" />
                                <GridColumn field="Remark" title="Remark" width="100px" />
                                <GridColumn field="CompPhenoTypeDesc" title="CompPT" width="100px" />
                                <GridColumn field="CompKellDesc" title="CompKell" width="100px" />
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col pt5" style={{ border: '0px solid red' }}>
                        <div className="button-right">
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Scan AKS Result
                            </Button>
                            <Button style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                                themeColor={'primary'}
                                onClick={genericFunction}
                            >
                                Scan AKS Result PDF
                            </Button>
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Show AKS Result
                            </Button>
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Change BGRH
                            </Button>
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Conpatibler Phenotype
                            </Button>
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Find Bag
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdrBdTab;