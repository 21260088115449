import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getSelectedState, Grid, GridCellProps, GridColumn, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent, Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { dateInternalToSystem, DialogTitleBar, mui, useIntersection } from "../../Helper";
import { CPatientReports, CPatientReportsReportData, CUserRightsAppl, patientReportsDialog, addressDialogData } from "../../types";
import axios from 'axios';
import UserSession from "../UserSession";
import MyDatePicker from "../myDatePicker/myDatePicker";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import React from "react";
import { getter } from "@progress/kendo-react-common";
import ViewPDF from "../ModalForms/ViewPDF/ViewPDF";
import ProgressBar from "../Misc/ProgressBar";

const AddressDialog = React.lazy(() => import('../ModalForms/AddressDialog/AddressDialog'));

const emptyPRData: CPatientReports = {
    sMandantID: "",
    sMandantDesc: "",
    sAddressMandant: "",
    sPID: "",
    sCaseNr: "",
    sCheckDigit: "",
    sName: "",
    sFName: "",
    sBirthDate: "",
    sSelectedCase: "",
    listCaseData: [],
    listReportData: [],
    bIncludeInactiveCasesYN: false,
    bPhoneticYN: false,
    bIncludeInactivePatientsYN: false,
    bSetAllPagesPrintedYN: false,
    bShowAllFIDYN: false,
    bPrintCopyToRep: false,
    bMBYN: false,
    sPDFBase64:"",
    sMsg: "",
    sMsgTitle: "",
    sUserID:"",
};
var patientReportDataGC: CPatientReports = (emptyPRData);
const GridContext = React.createContext({});
const GridDataCellPageNr = (cellProps: any) => {
    const { patientReportData }: any = React.useContext(GridContext);    
    const { setPatientReportData }: any = React.useContext(GridContext);
    var maindata: CPatientReports = { ...patientReportData };
    var binddata: CPatientReportsReportData[] = [...maindata?.listReportData];
    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;                
        var obj: CPatientReportsReportData;
        for (obj of binddata) {
            if (obj.sReportPageID === dataItem.sReportPageID) {
                obj.sPageNr = e.value;
                break;
            };
        };
        setPatientReportData(maindata);
        //setPatientReportData((oldGridData: any) =>
        //    oldGridData.map((item: any) => {
        //        if (item.sReportPageID === dataItem.sReportPageID) {
        //            item[field || ''] = e.value;
        //        }
        //        return item;
        //    })
        //);
    };
    return (
        <td>
            <Input
                {...cellProps}
                onChange={GridDataCellChange}
                disabled={false}
                value={cellProps.dataItem.sPageNr}                
                width="100%"
            />
        </td>
    );
};

const PatientReports = (props: patientReportsDialog) => {    
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'PatientReports' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
    const pidRef = useRef<HTMLInputElement>(null);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false);
    const [patientReportData, setPatientReportData] = useState<CPatientReports>(emptyPRData);
    const [birthdate, setBirthdate] = useState<Date | null>(null);
    const [mandantDESC, setMandantDESC] = useState<string | "">("");
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const idGetter = getter('sCaseNr');
    const [showPDF, setShowPDF] = React.useState(false);
    const [pdfBase64, setPdfBase64] = useState("");    
    const [addressDialogData, setAddressDialogData] = useState<addressDialogData>({ loadPID: '', showDialog: false, inViewport: false });
    const [disableControls, setDisableControls] = useState<boolean | false>(true);

    const PrintReportsClick = async () => {
        setIsLoading(true);
        var binddata: CPatientReports = emptyPRData; 
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/printpatientreports',
            JSON.stringify({ "objPatientReports": patientReportData }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== undefined) {
            binddata = response?.data;
            if (binddata.sMsg !== "") {
                alert(binddata.sMsg);
            } else if (binddata.sPDFBase64 !== "") {
                setPdfBase64(binddata.sPDFBase64);
                setShowPDF(true);
            } else {
                alert(mui(userRights, "PatientReports", "msgCouldNotGenReport", "Could Not Generate Report!"));
            };
        };
        setIsLoading(false);
    };

    const PrintReportsInWardClick = async () => {
        setIsLoading(true);
        var binddata: CPatientReports = emptyPRData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/printpatientreportsinward',
            JSON.stringify({ "objPatientReports": patientReportData }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== undefined) {
            binddata = response?.data;
            if (binddata.sMsg !== "") {
                alert(binddata.sMsg);
            } else if (binddata.sPDFBase64 !== "") {
                setPdfBase64(binddata.sPDFBase64);
                setShowPDF(true);
            } else {
                alert(mui(userRights, "PatientReports", "msgCouldNotGenReport", "Could Not Generate Report!"));
            };
        };
        setIsLoading(false);
    };    

    const PrintWOPreviewClick = async () => {

    };
    
    const ResendPDFClick = async () => {
        setIsLoading(true);
        var binddata: CPatientReports = emptyPRData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/resendpdfs',
            JSON.stringify({ "objPatientReports": patientReportData }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== undefined) {
            binddata = response?.data;
            if (binddata.sMsg !== "") {
                alert(binddata.sMsg);
            };
        };
        setIsLoading(false);
    };
    
    const EmailClick = async () => {

    };
    
    const ResetPagesClick = async () => {
        setIsLoading(true);
        var binddata: CPatientReports = emptyPRData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/resetreportpages',
            JSON.stringify({ "objPatientReports": patientReportData }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== undefined) {
            binddata = response?.data;
            if (binddata.sMsg !== "") {
                alert(binddata.sMsg);
            };
        };
        setIsLoading(false);
    };
    
    const PatDetailsClick = async () => {
        if (patientReportData.sPID !== undefined && patientReportData.sPID !== "") {            
            setAddressDialogData({ loadPID: patientReportData.sPID, showDialog: true, inViewport: true })
        };        
    };
    
    const SearchPatClick = async () => {

    };

    const CancelClick = async () => {
        props.FormClosed(true);
        setVisible(false);
    };

    const ClearClick = () => {
        var obj: CPatientReports = emptyPRData;
        obj.sPID = "";
        obj.bShowAllFIDYN = false;
        setPatientReportData(obj);
        setBirthdate(null);
        setDisableControls(false);        
        setTimeout(() => { pidRef.current?.focus() }, 200);
    };

    const ShowPatientReportsData = async (sPID:string) => {
        var binddata: CPatientReports=emptyPRData;        
        setIsLoading(true);
        binddata.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
        binddata.sPID = sPID;
        binddata.bMBYN = UserSession.getLoggedInUser().MBYN;
        binddata.sUserID = UserSession.getLoggedInUser().UserID;
        binddata.bShowAllFIDYN = patientReportData.bShowAllFIDYN;
        binddata.bPrintCopyToRep = patientReportData.bPrintCopyToRep;
        binddata.bIncludeInactiveCasesYN = patientReportData.bIncludeInactiveCasesYN;
        binddata.bIncludeInactivePatientsYN = patientReportData.bIncludeInactivePatientsYN;
        binddata.bPhoneticYN = patientReportData.bPhoneticYN;
        binddata.bSetAllPagesPrintedYN = patientReportData.bSetAllPagesPrintedYN;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/getpatientreportsdata',
            JSON.stringify({ "objPatientReports": binddata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        binddata = response?.data;
        if (binddata.sMsg !== "") {
            alert(binddata.sMsgTitle + '\n' + binddata.sMsg);            
            setTimeout(() => { pidRef.current?.focus() }, 200);
        } else {
            setPatientReportData(binddata);
            binddata.sBirthDate !== undefined && binddata.sBirthDate !== "" ? setBirthdate(dateInternalToSystem(binddata.sBirthDate)) : setBirthdate(null);
            setMandantDESC(binddata.sMandantID + ' ' + binddata.sMandantDesc);
            if (binddata.sSelectedCase !== "") {
                let sel: any = {};
                sel[binddata.sSelectedCase] = true;
                setSelectedState(sel);
            };
            setDisableControls(true);
        };
        
        setIsLoading(false);
    };

    const GetReportPages = async (sCaseNr:string) => {
        var binddata: CPatientReports = patientReportData;
        setIsLoading(true);
        binddata.sSelectedCase = sCaseNr;        
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'patientreports/getreportpages',
            JSON.stringify({ "objPatientReports": binddata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        binddata = response?.data;
        setPatientReportData(binddata);
        //binddata.sBirthDate !== undefined ? setBirthdate(dateInternalToSystem(binddata.sBirthDate)) : setBirthdate(null);
        //setMandantDESC(binddata.sMandantID + ' ' + binddata.sMandantDesc);
        //if (binddata.sSelectedCase !== "") {
        //    let sel: any = {};
        //    sel[binddata.sSelectedCase] = true;
        //    setSelectedState(sel);
        //};
        setIsLoading(false);
    };

    useEffect(() => {
        setPatientReportData(emptyPRData);
        ShowPatientReportsData(props.pid);
        setVisible(props.showDialog);
    }, [props.showDialog])       

    useEffect(() => {
        ShowPatientReportsData(patientReportData.sPID);
        setVisible(props.showDialog);
    }, [patientReportData.bShowAllFIDYN])       

    const handlePrintYNCheck = (event: CheckboxChangeEvent, sReportPageID: string) => {
        if (patientReportData !== undefined) {
            var maindata: CPatientReports = { ...patientReportData };
            var binddata: CPatientReportsReportData[] = [...maindata?.listReportData];
            var obj: CPatientReportsReportData;
            for (obj of binddata) {
                if (obj.sReportPageID === sReportPageID) {
                    obj.sPrintYN = event.value ? "1" : "0";
                    break;
                };
            };
            setPatientReportData(maindata);
        };
    };

    const CustomCellPrintYN = (props: GridCellProps) => {    
        const reportpageid = props.dataItem['sReportPageID'];
        const checkvalue = props.dataItem['sPrintYN'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handlePrintYNCheck(event, reportpageid)} checked={checkvalue === "1" ? true : false} id='printyn'></Checkbox>
            </td>
        );
    };

    const handlePrintLastPageYNCheck = (event: CheckboxChangeEvent, sReportPageID: string) => {
        if (patientReportData !== undefined) {
            var maindata: CPatientReports = { ...patientReportData };
            var binddata: CPatientReportsReportData[] = [...maindata?.listReportData];
            var obj: CPatientReportsReportData;
            for (obj of binddata) {
                if (obj.sReportPageID === sReportPageID) {
                    obj.sPrintLastPageYN = event.value ? "1" : "0";
                    break;
                };
            };
            setPatientReportData(maindata);
        };
    };

    const CustomCellPrintLastPageYN = (props: GridCellProps) => {
        const reportpageid = props.dataItem['sReportPageID'];
        const checkvalue = props.dataItem['sPrintLastPageYN'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handlePrintLastPageYNCheck(event, reportpageid)} checked={checkvalue === "1" ? true : false} id='printlastpageyn'></Checkbox>
            </td>
        );
    };    

    const handleStatusYNCheck = (event: CheckboxChangeEvent, sReportPageID: string) => {
        if (patientReportData !== undefined) {
            var maindata: CPatientReports = { ...patientReportData };
            var binddata: CPatientReportsReportData[] = [...maindata?.listReportData];
            var obj: CPatientReportsReportData;
            for (obj of binddata) {
                if (obj.sReportPageID === sReportPageID) {
                    obj.sUpdatePageStatusYN = event.value ? "1" : "0";
                    break;
                };
            };
            setPatientReportData(maindata);
        };
    };

    const CustomCellStatusYN = (props: GridCellProps) => {
        const reportpageid = props.dataItem['sReportPageID'];
        const ShowPageStatusCheckBox = props.dataItem['bShowPageStatusCheckBox'];
        const checkvalue = props.dataItem['sUpdatePageStatusYN'];
        if (ShowPageStatusCheckBox === true) {
            return (
                <td style={{ textAlign: 'center', padding: '1px' }} >
                    <Checkbox onChange={(event: CheckboxChangeEvent) => handleStatusYNCheck(event, reportpageid)} checked={checkvalue === "1" ? true : false} id='statusyn'></Checkbox>
                </td>
            );
        } else {
            return (
                <td></td>
            );
        };
    };

    const handlePrintNewestAddnDocYNCheck = (event: CheckboxChangeEvent, sReportPageID: string) => {               
        if (patientReportData !== undefined) {
            var maindata: CPatientReports = { ...patientReportData };
            var binddata: CPatientReportsReportData[] = [...maindata?.listReportData];             
            var obj: CPatientReportsReportData;
            for (obj of binddata) {
                if (obj.sReportPageID === sReportPageID) {
                    obj.sPrintNewestAddnDocYN = event.value ? "1" : "0";                    
                    break;
                };
            };            
            setPatientReportData(maindata);
        };
    };

    const CustomCellPrintNewestAddnDocYN = (props: GridCellProps) => {        
        const reportpageid = props.dataItem['sReportPageID'];
        const checkvalue = props.dataItem['sPrintNewestAddnDocYN'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handlePrintNewestAddnDocYNCheck(event, reportpageid)} checked={checkvalue === "1" ? true : false} id='printnewestdocyn'></Checkbox>
            </td>
        );
    };

    const handleChangeDate = (value: Date | null, source: string) => {
        setBirthdate(value);
    };

    const handleChange = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value;
        setPatientReportData(prevData => ({
            ...prevData,
            [name]: value
        }))       
    };
    
    const PIDLostFocus = () => {
        if (patientReportData.sPID !== "") ShowPatientReportsData(patientReportData.sPID);
    };

    const onSelectionChange = (event: GridSelectionChangeEvent) => {        
        var sCaseNr: string = "";
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'sCaseNr',
        });
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                sCaseNr = property.toString();                
            };
        };
        setSelectedState(newSelectedState);  
        GetReportPages(sCaseNr);
    };

    const callbackPDFDialogClosed = () => {
        setShowPDF(false);
    };

    // Called when the address add/edit screen is closed using the cancel or save button
    const callbackAddressDialogClosed = () => {
        setAddressDialogData({ loadPID: '', showDialog: false, inViewport: false })        
    };

    return (
        <>
            <div>
                {visible && (
                    <Dialog  title={<DialogTitleBar title={mui(userRights, "PatientReports", "textTitle", "Patient Report")} />} onClose={CancelClick} height={940} width={1700} >
                        <div className="container-fluid" style={{ overflowX: "hidden", width: '98%' }}>
                            <div className="row" style={{ border: '2px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0, backgroundColor: "#e0f0ff" }}>                            
                                <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
                                    <div className="col">
                                        <Label editorId={'lblmandantid'}>{mui(userRights, "common", "lblMandantID", "Mandant")}</Label>
                                    </div>
                                    <div className="col">
                                        <Label className='k-input-header'>{mandantDESC}</Label>
                                    </div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                                <div className="row" style={{ marginBottom: 5 }}>
                                    <div className="col">
                                        <Label editorId={'lblfid'}>{mui(userRights, "common", "lblFID", "FID")}</Label>
                                    </div>
                                    <div className="col">
                                        <Input disabled={true} className='k-input-header' autoComplete="off" id="sCaseNr" name="sCaseNr" type='text' style={{ width: '100px' }} onChange={handleChange} value={patientReportData?.sCaseNr !== undefined ? patientReportData?.sCaseNr : ""} />&nbsp;
                                        <Input disabled={true} className='k-input-header' autoComplete="off" id="chkdigit" name="chkdigit" type='text' style={{ width: '50px' }} value={patientReportData?.sCheckDigit !== undefined ? patientReportData?.sCheckDigit : ""} />
                                    </div>
                                    <div className="col">
                                        <Label editorId={'lblinclinactivecasesyn'}>{mui(userRights, "PatientReports", "lblInclInactiveCases", "Incl Inactive Cases")}</Label>
                                    </div>
                                    <div className="col">
                                        <Checkbox onChange={handleChange} checked={patientReportData.bIncludeInactiveCasesYN} name='bIncludeInactiveCasesYN'></Checkbox>
                                    </div>
                                    <div className="col">
                                        <Label editorId={'lblphoneticyn'}>{mui(userRights, "PatientReports", "lblPhonetic", "Phonetic")}</Label>
                                    </div>
                                    <div className="col">
                                        <Checkbox onChange={handleChange} checked={patientReportData.bPhoneticYN} name='bPhoneticYN'></Checkbox>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: 5 }}>
                                    <div className="col">
                                        <Label editorId={'lblpid'}>{mui(userRights, "common", "lblPID", "PID")}</Label>
                                    </div>
                                    <div className="col">
                                        <Input ref={pidRef} disabled={disableControls} className='k-input-header' autoComplete="off" id="sPID" name="sPID" type='text' style={{ width: '100px' }} onBlur={PIDLostFocus} onChange={handleChange} value={patientReportData?.sPID !== undefined ? patientReportData?.sPID : ""} />                                        
                                    </div>
                                    <div className="col">
                                        <Label editorId={'lblinclinactivepatientsyn'}>{mui(userRights, "PatientReports", "lblInclInactivePatients", "Incl Inactive Patients")}</Label>
                                    </div>
                                    <div className="col">
                                        <Checkbox onChange={handleChange} checked={patientReportData.bIncludeInactivePatientsYN} name='bIncludeInactivePatientsYN'></Checkbox>
                                    </div>
                                    <div className="col">
                                        <Label editorId={'lblreppagesprintedyn'}>{mui(userRights, "PatientReports", "lblRepPagesPrinted", "Report Pages Printed")}</Label>
                                    </div>
                                    <div className="col">
                                        <Checkbox onChange={handleChange} checked={patientReportData.bSetAllPagesPrintedYN} name='bSetAllPagesPrintedYN'></Checkbox>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: 5 }}>
                                    <div className="col">
                                        <Label editorId={'lblname'}>{mui(userRights, "common", "lblLastname", "Name")}</Label>
                                    </div>
                                    <div className="col">
                                        <Input disabled={true} className='k-input-header' autoComplete="off" id="sName" name="sName" type='text' style={{ width: '150px' }} onChange={handleChange} value={patientReportData?.sName !== undefined ? patientReportData?.sName : ""} />                                        
                                    </div>                     
                                    <div className="col">
                                        <Label editorId={'lblname'}>{mui(userRights, "common", "lblFirstname", "FName")}</Label>
                                    </div>
                                    <div className="col">
                                        <Input disabled={true} className='k-input-header' autoComplete="off" id="sFName" name="sFName" type='text' style={{ width: '150px' }} onChange={handleChange} value={patientReportData?.sFName !== undefined ? patientReportData?.sFName : ""} />                                        
                                    </div>
                                    <div className="col">
                                        <Label editorId={'lblbirthdate'}>{mui(userRights, "common", "lblBirthDate", "BirthDate")}</Label>
                                    </div>
                                    <div className="col">
                                        <MyDatePicker disabled={true} label="" source={'birthdate'} parentOnChange={handleChangeDate}
                                            pickerValue={birthdate} required={false} setPickerValue={setBirthdate} id={'birthdate'}
                                            supplParam1="" supplParam2="" className="k-input-header" ></MyDatePicker>
                                    </div>
                                </div>                                
                            </div>
                            <div className="row" style={{ border: '2px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                               
                                <div className="row">
                                    <div className="col-6">
                                        <Button disabled={true} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'tertiary'}
                                            onClick={() => {
                                                SearchPatClick();
                                            }}
                                        >{mui(userRights, "common", "cmdSearchPat", "Search Pat")}</Button>
                                        <Button  type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'tertiary'}
                                            onClick={() => {
                                                ClearClick();
                                            }}
                                        >{mui(userRights, "common", "cmdClear", "Clear")}</Button>
                                        <Button disabled={!disableControls} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                PatDetailsClick();
                                            }}
                                        >{mui(userRights, "common", "cmdPatDetails", "Pat Details")}</Button>
                                        <Button disabled={!disableControls} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                ResetPagesClick();
                                            }}
                                        >{mui(userRights, "PatientReports", "cmdResetPages", "Reset Pages")}</Button>                                        
                                    </div>                                                         
                                    <div className="col" style={{ textAlign: "right" }}>
                                        <Button disabled={!disableControls} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                ResendPDFClick();
                                            }}
                                        >{mui(userRights, "PatientReports", "cmdResendPDF", "ResendPDF")}</Button>                                       
                                        <Button disabled={true} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                PrintWOPreviewClick();
                                            }}
                                        >{mui(userRights, "PatientReports", "cmdPrintWOPreview", "PrintWOPreview")}</Button>
                                        <Button disabled={!disableControls} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                PrintReportsInWardClick();
                                            }}
                                        >{mui(userRights, "PatientReports", "cmdPrintInWards", "PrintInWards")}</Button>
                                        <Button disabled={true} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                EmailClick();
                                            }}
                                        >{mui(userRights, "PatientReports", "cmdEmail", "Email")}</Button>
                                        {/*<Button type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}*/}
                                        {/*    onClick={() => {*/}
                                        {/*        ClearClick();*/}
                                        {/*    }}*/}
                                        {/*>{mui(userRights, "common", "cmdClear", "Clear")}</Button>*/}
                                        <Button disabled={!disableControls} type={"button"} style={{ width: '120px', marginTop: 5, marginRight: 10, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                PrintReportsClick();
                                            }}
                                        >{mui(userRights, "common", "cmdPrint", "Print")}</Button>
                                        <Button type={"button"} style={{ width: '120px', marginTop: 5, marginBottom: 5 }} themeColor={'primary'}
                                            onClick={() => {
                                                CancelClick();
                                            }}
                                        >{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom:5 }}>
                                    <div className="col">
                                        <Checkbox disabled={!disableControls} onChange={handleChange} checked={patientReportData.bShowAllFIDYN} name='bShowAllFIDYN' label={mui(userRights, "PatientReports", "chkShowAllFIDYN", "Show All FID")}></Checkbox>                                        
                                    </div>
                                    <div className="col">                                        
                                        <Checkbox onChange={handleChange} checked={patientReportData.bPrintCopyToRep} name='bPrintCopyToRep' label={mui(userRights, "PatientReports", "chkPrintCopyToRepYN", "Print Copy To Rep")}></Checkbox>
                                    </div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </div>
                            <div className="row" style={{ border: '2px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                                <div className="row">
                                    <div className="col" style={{ marginTop: 5, marginBottom: 5 }}>    
                                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                        <Grid
                                            data={patientReportData?.listCaseData.map((item) => ({
                                                ...item,
                                                selected: selectedState[idGetter(item)],
                                            }))}                                            
                                            style={{
                                                height: 600, width:508
                                            }}
                                            selectable={{
                                                enabled: true,
                                                drag: false,
                                                cell: false,
                                                mode: "single",
                                            }}
                                            onSelectionChange={onSelectionChange}
                                            selectedField={'selected'}
                                            dataItemKey={'sCaseNr'}
                                        >                                            
                                            <GridColumn field="sCaseNr" title={mui(userRights, "PatientReports", "col_CaseNr", "CaseNr")} width="150px" />
                                            <GridColumn field="sAdmissionDate" title={mui(userRights, "PatientReports", "col_AdmDate", "Admission Date")} />
                                            <GridColumn field="sDischargeDate" title={mui(userRights, "PatientReports", "col_DiscDate", "Discharge Date")} />
                                            <GridColumn field="sDeptCode" title={mui(userRights, "PatientReports", "col_DeptCode", "Dept Code")} />
                                            <GridColumn field="sStation" title={mui(userRights, "PatientReports", "col_Station", "Station")} />                                            
                                            </Grid>
                                        }
                                    </div>
                                    <div className="col" style={{ marginTop: 5, marginBottom: 5 }}>
                                        {isLoading ? <ProgressBar message=""></ProgressBar> :
                                            <GridContext.Provider
                                                value={{ setPatientReportData, patientReportData }}
                                            >
                                                <Grid
                                                    data={patientReportData?.listReportData}
                                                    style={{
                                                        height: 600, width: 1070
                                                    }}
                                                >
                                                    <GridColumn field="sReportPageID" title={mui(userRights, "PatientReports", "col_ReportPage", "Report Page")} width="150px" />
                                                    <GridColumn field="sPrintYN" title={mui(userRights, "PatientReports", "col_PrintYN", "PrintYN")} cell={CustomCellPrintYN} />
                                                    <GridColumn field="sPrintLastPageYN" title={mui(userRights, "PatientReports", "col_PrintLastPageYN", "PrintLastPageYN")} cell={CustomCellPrintLastPageYN} />
                                                    <GridColumn field="sPageNr" title={mui(userRights, "PatientReports", "col_PageNr", "PageNr")} cell={GridDataCellPageNr} />
                                                    <GridColumn field="sUpdatePageStatusYN" title={mui(userRights, "PatientReports", "col_StatusYN", "StatusYN")} cell={CustomCellStatusYN} />
                                                    <GridColumn field="sNrOfCopies" title={mui(userRights, "PatientReports", "col_NrOfCopies", "NrOfCopies")} />
                                                    <GridColumn field="sPrintNewestAddnDocYN" title={mui(userRights, "PatientReports", "col_NewestAddnDocYN", "NewestAddnDocYN")} cell={CustomCellPrintNewestAddnDocYN} />
                                                </Grid>
                                            </GridContext.Provider>
                                        }
                                    </div>                                        
                                </div>
                            </div>                           
                            <ViewPDF callbackPDFDialogClosed={callbackPDFDialogClosed} url={""} base64PDF={pdfBase64} showDialog={showPDF}></ViewPDF>
                            <AddressDialog key={'A'} data={addressDialogData} callbackAddressDialogClosed={callbackAddressDialogClosed}></AddressDialog>
                        </div>                                          
                    </Dialog>
                )}
            </div>          
        </>
    );
};

export default PatientReports;