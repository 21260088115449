export interface TimelineEventProps {
    description: string;
    date: Date;
    title: string;      
}

export const events: TimelineEventProps[] = [
    {
        description: "Request Entered",
        date: new Date(2008, 4, 25),
        title: "User XYZ, 10:10:23",
              
    },
    {
        description: "Mat Accepted",
        date: new Date(2008, 4, 26),
        title: "User XYZ, 13:23:51",
    },
    {
        description: "Result Entered",
        date: new Date(2008, 4, 27),
        title: "User XYZ, 14:20:20",
    },
    {
        description: "Result Validated",
        date: new Date(2008, 4, 28),
        title: "User XYZ, 16:33:40",
    }
]