import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { componentOrderListData, CUserRightsAppl} from "../../types";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Loader } from '@progress/kendo-react-indicators';
import axios from 'axios';
import UserSession from "../UserSession";
import { mui } from "../../Helper";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { IntlService } from "@progress/kendo-react-intl";
import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import ProgressBar from "../Misc/ProgressBar";

const initialSort: Array<SortDescriptor> = [
    
];

interface ComponentOrderListProps {
    loadfor: string;
    mandantChanged: number;
}

const ComponentOrderList = (props: ComponentOrderListProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'comporderlist' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [sort, setSort] = useState(initialSort);

    const [isLoading, setIsLoading] = useState(false);
    const [checkedShowOnlyAntibodies, setCheckedShowOnlyAntibodies] = useState(false);
    const [checkedShowOnlyOpen, setCheckedShowOnlyOpen] = useState(false);
    const [dateFrom, setDateFrom] = React.useState<string | "">("");
    const [dateTo, setDateTo] = React.useState<string | "">("");
    const [timeFrom, setTimeFrom] = React.useState<string | "">("");
    const [timeTo, setTimeTo] = React.useState<string | "">("");
    const dlgEmptyState: componentOrderListData = {
        pid: 0, fid: 0, lastname: "", firstname: "", birthdate: "", gender: "", reqid: 0, reqdatetime: "", bg: "", rh: "", ph: "", kell: "", antibodies: "", nrofcomps: "", comptype: ""
    }
    const [compOrderListData, setCompOrderListData] = useState<componentOrderListData[]>([dlgEmptyState]);

    const handleChangeDate = (value: string | "", source: string) => {
        if (source === 'datefrom')
            setDateFrom(value);
        else if (source === 'dateto')
            setDateTo(value);

        setCompOrderListData([]);
    };

    const handleChangeTime = (event: string, source: string) => {
        if (source === 'timefrom')
            setTimeFrom(event);
        else if (source === 'timeto')
            setTimeTo(event);
        setCompOrderListData([]);
    };

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        const source = e.target.name
        //const value = e.target.value === true ? '1' : '0'
        if (source === 'ShowOnlyAntibodies')
            setCheckedShowOnlyAntibodies(!checkedShowOnlyAntibodies);
        else if (source === 'ShowOnlyOpen')
            setCheckedShowOnlyOpen(!checkedShowOnlyOpen);
        setCompOrderListData([]);
    };

    function CheckMandatoryData() {
        var bok: boolean = true;
        var sMsg: string = ""
        if (dateFrom == null) {
            sMsg = mui(userRights, "common", "msgEnterReqDateFrom", "Enter Req Date From")
            alert(sMsg);
            bok = false
        }
        else if (timeFrom == null) {
            sMsg = mui(userRights, "common", "msgEnterReqTimeFrom", "Enter Req Time From")
            alert(sMsg);
            bok = false
        }
        else if (dateTo == null) {
            sMsg = mui(userRights, "common", "msgEnterReqDateTo", "Enter Req Date To")
            alert(sMsg);
            bok = false
        }
        else if (timeTo == null) {
            sMsg =  mui(userRights, "common", "msgEnterReqTimeTo", "Enter Req Time To")
            alert(sMsg);
            bok = false
        }
        return bok;
    };

    const performSearch = async () => {
        if (CheckMandatoryData()) {

            setIsLoading(true)
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'Component/getcomponentorderlist',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "datefrom": dateFrom, "dateto": dateTo, "timefrom": timeFrom, "timeto": timeTo, "showonlyantibodiesyn": checkedShowOnlyAntibodies, "showonlyopenyn": checkedShowOnlyOpen }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            // console.log(response?.data);

            const DATE_FORMAT = "dd.MM.yyyy hh:mm:ss";
            const intl = new IntlService("en");

            var binddata: componentOrderListData[] = [];
            if (response?.data !== null)
                response?.data.forEach((o: componentOrderListData) => {
                    o.reqdatetime = intl.parseDate(
                        o.reqdatetime ? o.reqdatetime : "20.20.2020",
                        DATE_FORMAT
                    );

                    binddata.push({ ...o });
                });

            setCompOrderListData(binddata);
            setIsLoading(false)
        }
       
    };

    async function clearForm(): Promise<void> {
        setCompOrderListData([]);
        setDateFrom("")
        setTimeFrom("")
        setDateTo("")
        setTimeTo("")
    }

    const location: string = props.loadfor;
    const formWidth: number = location === "modal" ? 1400 : window.innerWidth;
    var formHeight: number = location === "modal" ? 720 : window.innerHeight;
    formHeight -= 120;
    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 100; // as dialog
        contentWidth = formWidth - 50;
    }
    else {
        formHeight -= 50;
        gridHeight -= 170; // as dashboard
        contentWidth = formWidth - 120;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: contentWidth, minWidth: contentWidth }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="col-3">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'reqdateto'}>{mui(userRights, "comporderlist", "lblReqDateTimeFrom", "Req. date/time from")}</Label></div>
                            <div className="vertical-wrapper-child">
                                <CustomDate defaulValue={dateFrom} id={'txtdatefrom'} source={'datefrom'} label=""
                                    required={false} disabled={false} width={'150px'} supplParam1="" supplParam2="" className="k-input-header"
                                    dateChanged={handleChangeDate}></CustomDate>
                               
                                &nbsp;
                                <CustomTime allowSecond={true} timeChanged={handleChangeTime} width='100px' className='k-input-header' supplParam1=""
                                    supplParam2="" defaulValue={timeFrom} id={'txttimefrom'} source={'timefrom'} label=""
                                    required={false} disabled={false} secondField={false}></CustomTime>
                               
                            </div>
                        </div>

                    </div>
                    <div className="col-3">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'reqdateto'}>{mui(userRights, "comporderlist", "lblReqDateTimeTo", "Req. date/time to")}</Label></div>
                            <div className="vertical-wrapper-child">
                                <CustomDate defaulValue={dateTo} id={'txtdateto'} source={'dateto'} label=""
                                    required={false} disabled={false} width={'150px'} supplParam1="" supplParam2="" className="k-input-header"
                                    dateChanged={handleChangeDate}></CustomDate>

                                &nbsp;
                                <CustomTime allowSecond={true} timeChanged={handleChangeTime} width='100px' className='k-input-header' supplParam1=""
                                    supplParam2="" defaulValue={timeTo} id={'txttimeto'} source={'timeto'} label=""
                                    required={false} disabled={false} secondField={false}></CustomTime>

                            </div>
                        </div>

                    </div>
                    <div className="col-2">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={handleCheckboxChange} name="ShowOnlyAntibodies" label={mui(userRights, "comporderlist", "chkShowOnlyAntibodies", "Show Only Antibodies")} />
                            </div>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={handleCheckboxChange } name="ShowOnlyOpen" label={mui(userRights, "comporderlist", "chkShowOnlyOpen", "Show Only Open")} />
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="button-right">
                          
                            <Button type={"button"} style={{ width: '120px', marginTop: 20, marginRight: 10 }} themeColor={'secondary'}
                                onClick={() => {
                                    clearForm();
                                }}
                            >{mui(userRights, "common", "cmdClear", "Clear")}</Button>
                            <Button type={"button"} style={{ width: '120px', marginTop: 20 }} themeColor={'primary'}
                                onClick={() => {
                                    performSearch();
                                }}
                            >{mui(userRights, "common", "cmdSearch", "Search")}
                            </Button>
                        </div>
                    </div>                    
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                        <Grid
                            style={{
                                    height: gridHeight, width: '100%'
                            }}
                            data={orderBy(compOrderListData, sort)}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "single",
                            }}
                            selectedField={'selected'}
                            dataItemKey={'ReqID'}
                        >
                            <GridColumn field="pid" title={mui(userRights, "comporderlist", "col1_PID", "PID")} width="110px" filter={"numeric"} sortable={ true} />
                            <GridColumn field="fid" title={mui(userRights, "comporderlist", "col2_FID", "FID")} width="110px" filter={"numeric"} />
                            <GridColumn field="lastname" title={mui(userRights, "comporderlist", "col3_Lastname", "Lastname")} width="180px" />
                            <GridColumn field="firstname" title={mui(userRights, "comporderlist", "col4_Firstname", "Firstname")} width="180px" />
                            <GridColumn field="birthdate" title={mui(userRights, "comporderlist", "col5_Birthdate", "Birthdate")} width="90px" />
                            <GridColumn field="gender" title={mui(userRights, "comporderlist", "col6_Gender", "Gender")} width="80px" />
                            <GridColumn field="reqid" title={mui(userRights, "comporderlist", "col7_ReqID", "ReqID")} width="90px" filter={"numeric"} />
                            <GridColumn field="reqdatetime" title={mui(userRights, "comporderlist", "col8_ReqDateTime", "Request date/time")} filter={"date"} sortable={true} width="150px" format="{0:dd.MM.yyyy hh:mm:ss}" />
                            <GridColumn field="bg" title={mui(userRights, "comporderlist", "col9_BG", "bg")} width="80px" />
                            <GridColumn field="rh" title={mui(userRights, "comporderlist", "col10_RH", "rh")} width="100px" />
                            <GridColumn field="ph" title={mui(userRights, "comporderlist", "col11_PH", "ph")} width="80px" />
                            <GridColumn field="kell" title={mui(userRights, "comporderlist", "col12_Kell", "kell")} width="80px" />
                            <GridColumn field="antibodies" title={mui(userRights, "comporderlist", "col13_Antibodies", "Antibodies")} width="200px" />
                            <GridColumn field="nrofcomps" title={mui(userRights, "comporderlist", "col14_NrOfComps", "NrOfComps")} width="80px" />
                            <GridColumn field="comptype" title={mui(userRights, "comporderlist", "col15_CompType", "comptype")} width="80" />
                            );
                            </Grid>
                        }
                    </div>
                </div>
            </div>
  
        </>
    );
}
export default ComponentOrderList;