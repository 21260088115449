import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { Suspense, useEffect, useState } from "react";
import { mui } from "../../Helper";
import { qcResultEntryDialog, CQCResultEntry, CQCResultEntryOutput, resultSelDialog, emptyREData, CResultEntry, CQCResult, inputBoxDialog, dlgEmptyInputBoxState, enmInputBoxType } from "../../types";
import UserSession from "../UserSession";
import axios from 'axios';
import ResultSelDialog from "../ResultAndValidation/ResultSelDialog";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import React from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { InputBoxDialog } from "../InputBoxDialog/InputBoxDialog";
import ProgressBar from "../Misc/ProgressBar";

//var mainResultData: CResultEntry[] = ([]);

const GridContext = React.createContext({});
//const allInEdit = mainResultData.map((item) =>
//    Object.assign({ inEdit: true }, item)
//);
const GridDataCellResult = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CQCResult>();
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const color = cellProps.dataItem['sResultColColor'];

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sRefParamID === dataItem.sRefParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        if (e.target.index === 0) {
            console.log('event called', e);
            var obj: CQCResult = e.value
            setComboResultValue(e.value);
            setResultData((oldGridData: any) =>
                oldGridData.map((item: any) => {
                    if (item.sRefParamID === obj.sParamId) {
                        item.sSerIDToValidate = obj.sSerID;
                        item.sComment = obj.sComment;
                        item.sResultDate = obj.sResultDate;
                        item.sResultTime = obj.sResultTime;
                        item.sResultRules = obj.sResultRules;
                        item.sResultFlag = obj.sResultFlag;
                        item.sWorkPlaceFlag = obj.sWorkPlaceFlag;
                    }
                    return item;
                })
            );
        } else {

        };
    };

    if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sResultFlag === 'V' || cellProps.dataItem.sResultFlag === 'R' || cellProps.dataItem.sResultFlag === 'A')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult}
                    textField="sResult"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    defaultValue={cellProps.dataItem.ExistingResult[0]}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sResultFlag === '') {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={false}
                    value={cellProps.dataItem.sResult}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateResult(cellProps.dataItem, resultData, setResultData, resultMainData, '')}
                    style={{ textAlign: 'right', backgroundColor: color }}
                    width="100%"
                />
            </td>
        );
    } else {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sResult}
                    style={{ textAlign: 'right', backgroundColor: color }}
                    width="100%"
                />
            </td>
        );
    };
};

const ValidateResult = async (cellProps: any, mainResultData: CQCResultEntry[], setResultData: any, resultMainData: any, resultType: string) => {
    const obj: CQCResultEntry = cellProps;
    const binddata: CQCResultEntry[] = [...mainResultData];
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultEntry/checkresult',
        JSON.stringify({
            sMode: 'R', sResultType: resultType, obj: obj
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objR = response?.data
        if (objR.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sRefParamID === objR.sRefParamID) {
                binddata[i] = JSON.parse(JSON.stringify(objR));
                break;
            };
        };
        setResultData(binddata);
    };
};

const GridDataCellResultDate = (cellProps: any) => {
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const { formState }: any = React.useContext(GridContext);

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sRefParamID === dataItem.sRefParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };
    return (
        <td>
            <Input
                {...cellProps}
                disabled={formState === 1 ? true : false}
                value={cellProps.dataItem.sResultDate}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateResult(cellProps.dataItem, resultData, setResultData, resultMainData, 'D')}
                style={{ textAlign: 'right' }}
                width="100%"
            />
        </td>
    );
};

const GridDataCellResultTime = (cellProps: any) => {
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const { formState }: any = React.useContext(GridContext);

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sRefParamID === dataItem.sRefParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };
    return (
        <td>
            <Input
                {...cellProps}
                disabled={formState === 1 ? true : false}
                value={cellProps.dataItem.sResultTime}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateResult(cellProps.dataItem, resultData, setResultData, resultMainData, 'T')}
                style={{ textAlign: 'right' }}
                width="100%"
            />
        </td>
    );
};

const QCResultEntryComponent = (props: qcResultEntryDialog) => {
    const [formState, setFormState] = useState<number>(props.formstate);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false);
    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);
    const [inputBoxDialogData, setInputBoxDialogData] = useState<inputBoxDialog>(dlgEmptyInputBoxState);
    const [resultMainData, setResultMainData] = useState<CQCResultEntryOutput>();
    const [resultData, setResultData] = useState<CQCResultEntry[]>([]);
    const [resultSavedOrValidated, setResultSavedOrValidated] = useState<boolean | false>(false);

    const saveDialog = async () => {
        processForSaveOrValidation("S");
    };

    const validateDialog = async () => {
        processForSaveOrValidation("V");
    };

    const processForSaveOrValidation = async (action: string) => {
        if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultEntry/saveData',
            JSON.stringify({
                objREO: resultMainData, sMode: formState, sMsg: '',
                bFillFromArray: props.obj.sFromWorklist !== "1" ? true : false, sUserID: UserSession.getLoggedInUser().UserID
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        var maindata: CQCResultEntryOutput | undefined;
        //var binddata: CQCResultEntry[] = [];
        if (response?.data !== null) maindata = response?.data;
        if (maindata !== undefined) {
            if (maindata.sErrorMsg === undefined) maindata.sErrorMsg = "";
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                if (action === "S") setFormState(1);
                setResultSavedOrValidated(true);
                GetQCResultData(1);
            };
        };
        setIsLoading(false);
    };

    const cancelDialog = () => {
        props.ResultSaved(resultSavedOrValidated);
        setVisible(false);
    };

    const closeDialog = () => {
        props.ResultSaved(true);
        setVisible(false);
    };

    const customCellParamDesc = (props: GridCellProps) => {
        const paramid = props.dataItem['sRefParamID'];
        const paramdesc = props.dataItem['sParamDesc'];
        const tooltip = props.dataItem['sParamTooltip'];
        const bcolor = props.dataItem['sParamDescColBackColor'];
        const fcolor = props.dataItem['sParamDescColForeColor'];
        return (
            <td style={{ backgroundColor: bcolor, color: fcolor }} title={tooltip !== "" ? tooltip : paramid}>
                {paramdesc}
            </td>
        );
    };

    const CustomCellEditResultSel = (propsG: GridCellProps) => {
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open', '') }}
                    disabled={false}
                >
                    ...
                </Button>

            </td>
        );
    };

    const CustomCellEditResultDateSel = (propsG: GridCellProps) => {

        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open', 'D') }}
                    disabled={false}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onResSelClick = (objRE: CQCResultEntry, action: string, resultType: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                if (resultType === 'D') {
                    objRE.sResultDate = Code;
                } else {
                    objRE.sResult = Code;
                };
                ValidateResult(objRE, resultType);
            }
            setResultSelDialogData(dlgEmptyState);
        }
        var obj: CResultEntry = emptyREData;
        if (resultType === 'D') {
            obj.sRawResult = objRE.sResultDate;
            obj.sResSelTableName = "Date";
            obj.sResSelCodeFieldName = "";
            obj.sResSelDescrFieldName = "";
        } else {
            obj.sRawResult = objRE.sResult;
            obj.sResSelTableName = "ResultCode";
            obj.sResSelCodeFieldName = "Code";
            obj.sResSelDescrFieldName = "Descr";
        };
        setResultSelDialogData({ selType: "Result", objRE: obj, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const ValidateResult = async (objRE: CQCResultEntry, resultType: string) => {
        const binddata: CQCResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultEntry/checkresult',
            JSON.stringify({
                sMode: "R", sResultType: resultType, obj: objRE
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objR = response?.data
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sRefParamID === objR.sRefParamID) {
                    binddata[i] = JSON.parse(JSON.stringify(objR));
                    break;
                };
            };
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const CustomCellAccept = (props: GridCellProps) => {
        const value = props.dataItem['sRefParamID'];
        const checkvalue = props.dataItem['sAccept'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        const bLockAcceptReject = props.dataItem['bLockAcceptReject'];

        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td style={{ textAlign: 'center', padding: '1px', verticalAlign: 'middle' }} >
                <Checkbox disabled={bLockAcceptReject} onChange={(event: CheckboxChangeEvent) => handleAcceptCheck(event, value)} checked={checkvalue === "1" ? true : false} id='sAccept'></Checkbox>
            </td>
        );
    };

    const CustomCellReject = (props: GridCellProps) => {
        const value = props.dataItem['sRefParamID'];
        const checkvalue = props.dataItem['sReject'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        const bLockAcceptReject = props.dataItem['bLockAcceptReject'];

        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td style={{ textAlign: 'center', padding: '1px', verticalAlign: 'middle' }} >
                <Checkbox disabled={bLockAcceptReject} onChange={(event: CheckboxChangeEvent) => handlRejectCheck(event, value)} checked={checkvalue === "1" ? true : false} id='sReject'></Checkbox>
            </td>
        );
    };

    const handleAcceptCheck = (event: CheckboxChangeEvent, paramid: string) => {
        const binddata: CQCResultEntry[] = [...resultData];
        var obj: CQCResultEntry;
        for (obj of binddata) {
            if (obj.sRefParamID === paramid) {
                obj.sAccept = event.value ? "1" : "0";
                processClick(obj, "A");
                break;
            };
        };
        setResultData(binddata);
    };

    const handlRejectCheck = (event: CheckboxChangeEvent, paramid: string) => {
        const binddata: CQCResultEntry[] = [...resultData];
        var obj: CQCResultEntry;
        for (obj of binddata) {
            if (obj.sRefParamID === paramid) {
                obj.sReject = event.value ? "1" : "0";
                processClick(obj, "R");
                break;
            }
        }
        setResultData(binddata);
    };

    const processClick = async (obj: CQCResultEntry, sCheckBoxCol: string) => {
        const setText = async (inputText: string) => {
            setInputBoxDialogData(dlgEmptyInputBoxState);
            objX.sRejectionReason = inputText;
            objX.sRefRejectionReason = inputText;
            const binddata: CQCResultEntry[] = [...resultData];
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sRefParamID === objX.sRefParamID) {
                    binddata[i] = JSON.parse(JSON.stringify(objX));
                    break;
                };
            };
            setResultData(binddata);
        };

        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultEntry/checkForResultFlag',
            JSON.stringify({
                sCheckBoxCol: sCheckBoxCol, obj: obj, sMode: formState, bEnterRejectionReason: false
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        if (response?.data !== null) {
            var resObj = response?.data;
            var objX: CQCResultEntry = resObj.obj;
            if (resObj.bEnterRejectionReason === true) {
                setInputBoxDialogData({ promptText: "Enter Rejection Reason", title: "Rejection Reason", defText: obj.sRejectionReason, maxLength: 255, enm: enmInputBoxType.RejectionReason, setText: setText, showDialog: true })
            };
        };
    };

    const CustomCellRejReason = (props: GridCellProps) => {
        const value = props.dataItem['sRejectionReason'];
        return (
            <td title={value}>
                {value}
            </td>
        );
    };

    const CustomCellResultRules = (props: GridCellProps) => {
        const value = props.dataItem['sResultRules'];
        return (
            <td title={value}>
                {value}
            </td>
        );
    };

    const GetQCResultData = async (formState: number) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultEntry/getQCDataForResultEntry',
            JSON.stringify({
                sMandantID: props.mandantid, sWorkPlaceID: props.obj.sWorkplaceID, sWorkPlaceDesc: props.obj.sWorkplaceDesc,
                sQCMaterialID: props.obj.sQCMaterialID, sBarCode: props.obj.sBarcode, sValidFrom: props.obj.sValidFrom,
                sValidTo: props.obj.sValidTo, sLotID: props.obj.sLotID, enmQcFormState: formState,
                bRefreshGrid: false, bFillFromArray: props.obj.sFromWorklist !== "1" ? true : false, bSaveValidation: formState === 0 ? false : true
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: CQCResultEntry[] = [];
        if (response?.data !== null) {
            setResultMainData(response?.data);
            response?.data.listResEntry.forEach((o: CQCResultEntry) => {
                binddata.push({ ...o });
            });
            setResultData(binddata);
            if (binddata.length === 0) closeDialog();
        };

        setIsLoading(false);
    };

    useEffect(() => {
        GetQCResultData(props.formstate);
        setFormState(props.formstate);
        setResultSavedOrValidated(false);
        setVisible(props.showDialog);
    }, [props.showDialog])


    var formWidth: number = 1400 - 40;
    var windowHeight: number = 720 - 250;
    var gridHeight = windowHeight - 10
    
    return (
        <>
            {visible &&
                
                <div className="container-fluid" style={{ overflowX: "hidden", width: formWidth, padding: 5, paddingBottom: 5 }}>
                    <div className="row" style={{ border: '1px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0, backgroundColor: "#e0f0ff" }}>
                        <div className="col" style={{ minHeight: '150px', height: '150px', maxHeight: '150px', overflowY: 'hidden', marginBottom: 5 }}>
                            <div className="row" style={{ marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 5, backgroundColor: "white" }}>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lblmandantid'}>{mui(userRights, "common", "lblMandantID", "Mandant")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lblmandantinfo'}>{UserSession.getLoggedInUser().CurrentMandant + ' : ' + UserSession.getLoggedInUser().CurrentMandantDesc}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lblqcmat'}>{mui(userRights, "QCResultEntry", "lblQCMat", "QCMaterial")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lblqcmatinfo'}>{props.obj.sQCMaterialID + ' : ' + props.obj.sQCMatDescr}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lbllotid'}>{mui(userRights, "QCResultEntry", "lblLotID", "LotID")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lbllotidinfo'}>{props.obj.sLotID}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lblbarcode'}>{mui(userRights, "QCResultEntry", "lblBarcode", "Barcode")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lblbarcodeinfo'}>{props.obj.sBarcode}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lblvalidfrom'}>{mui(userRights, "QCResultEntry", "lblValidFrom", "ValidFrom")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lblvalidfrominfo'}>{props.obj.sValidFrom}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingBottom: 5 }}>
                                        <div className="col-1">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lblworkplace'}>{mui(userRights, "QCResultEntry", "lblWorkplace", "WorkPlace")}</Label></div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">
                                                    <Label editorId={'lblworkplaceinfo'}>{props.obj.sWorkplaceID + ' : ' + props.obj.sWorkplaceDesc}</Label></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="button-right">
                                            <Button style={{ width: '120px', marginRight: 10, display: 'inline-block' }}
                                                themeColor={'primary'}
                                                onClick={saveDialog}
                                                hidden={formState === 1 ? true : false}
                                            >
                                                {mui(userRights, "common", "cmdSave", "Save")}
                                            </Button>
                                            <Button style={{ width: '120px', marginRight: 10, display: 'inline-block' }}
                                                themeColor={'primary'}
                                                onClick={validateDialog}
                                                hidden={formState === 0 ? true : false}
                                            >
                                                {mui(userRights, "common", "cmdValidate", "Validate")}
                                            </Button>
                                            <Button style={{ width: '120px', marginRight: 10, display: 'inline-block' }}
                                                themeColor={'secondary'}
                                                onClick={cancelDialog}
                                            >
                                                {mui(userRights, "common", "cmdCancel", "Cancel")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="row" style={{ border: '1px solid lightblue', height: windowHeight, marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                        <div className="col">
                            <div className="row">
                                <div className="col" style={{ marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1 }}>
                                {isLoading ? <ProgressBar message=""></ProgressBar> :
                                        <GridContext.Provider
                                            value={{ setResultData, resultData, resultMainData, formState }}
                                        >
                                            <Grid
                                                style={{
                                                    height: gridHeight, width: '100%'
                                                }}
                                                data={resultData}
                                                editField="inEdit"
                                            >
                                            <GridColumn field="sParamDesc" title={mui(userRights, "QCResultEntry", "col_ParamID", "ParamID")} cell={customCellParamDesc} width="100px" />
                                                <GridColumn field="sResult" title={mui(userRights, "QCResultEntry", "col_Result", "Result")} width="125px" cell={GridDataCellResult} />
                                                {formState === 1 && < GridColumn field="sAccept" title={mui(userRights, "QCResultEntry", "col_Accept", "Accept")} width="100px" cell={CustomCellAccept} />}
                                                {formState === 1 && < GridColumn field="sReject" title={mui(userRights, "QCResultEntry", "col_Reject", "Reject")} width="100px" cell={CustomCellReject} />}
                                                {formState === 1 && < GridColumn field="sRejectionReason" title={mui(userRights, "QCResultEntry", "col_RejectionReason", "Rejection Reason")} width="250px" cell={CustomCellRejReason} />}
                                                {formState === 0 && < GridColumn field="" title={mui(userRights, "QCResultEntry", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResultSel} />}
                                                <GridColumn field="sResultDate" title={mui(userRights, "QCResultEntry", "col_ResultDate", "ResultDate")} width="125px" cell={GridDataCellResultDate} />
                                                {formState === 0 && <GridColumn field="" title={mui(userRights, "QCResultEntry", "col_ResultDateSel", " ")} width="50px" cell={CustomCellEditResultDateSel} />}
                                                <GridColumn field="sResultTime" title={mui(userRights, "QCResultEntry", "col_ResultTime", "ResultTime")} width="125px" cell={GridDataCellResultTime} />
                                                <GridColumn field="sStdDeviation" title={mui(userRights, "QCResultList", "col_3sRange", "3sRange")} width="100px" />
                                                {formState === 1 && < GridColumn field="sResultRules" title={mui(userRights, "QCResultEntry", "col_ResultRules", "Result Rules")} width="250px" cell={CustomCellResultRules} />}
                                                <GridColumn field="sUnit" title={mui(userRights, "QCResultList", "col_sUnit", "Unit")} width="100px" />
                                                );
                                            </Grid>
                                        </GridContext.Provider>}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>                
            }
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>
                <InputBoxDialog promptText={inputBoxDialogData.promptText} title={inputBoxDialogData.title} defText={inputBoxDialogData.defText} maxLength={inputBoxDialogData.maxLength} enm={inputBoxDialogData.enm} setText={inputBoxDialogData.setText} showDialog={inputBoxDialogData.showDialog} ></InputBoxDialog>
            </Suspense>
        </>
    );
}
export default QCResultEntryComponent;