import React, { Suspense,  useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn, GridRowProps,  GridSortChangeEvent} from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { ctDataType, CUserRightsAppl, resultCriteriaListEntry, resultEntryDialog } from "../../types";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Loader } from '@progress/kendo-react-indicators';
import axios from 'axios';
import UserSession from "../UserSession";
import { mui } from "../../Helper";
import ChemieResultEntryDialog from "../ResultAndValidation/ChemieResultEntryDialog";
import {
    IntlProvider,
    load,
    loadMessages,
    LocalizationProvider,
    IntlService
} from "@progress/kendo-react-intl";
import { getter } from "@progress/kendo-react-common";
import UrineSticksResultAndValidation from "../ResultAndValidation/UrineSticksResultAndValidation";
import SerologyResultAndValidation from "../ResultAndValidation/SerologyResultAndValidation";
import HamatResultAndValidation from "../ResultAndValidation/HamatResultAndValidation";
import ProgressBar from "../Misc/ProgressBar";

const DATE_FORMAT = "dd.mm.yyyy";
const DATE_TIME_FORMAT = "dd.mm.yyyy hh:mm:ss";
const intl = new IntlService("en");

const initialSort: Array<SortDescriptor> = [
    
];

interface ResultCriteriaProps {
    loadfor: string;
    mandantChanged: number;
}

const ResultCriteria = (props: ResultCriteriaProps) => {   

    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resultcriteria' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [sort, setSort] = useState(initialSort);
    const [selectedCode, setSelectedCode] = useState("");
    const [CodeTableData, setCodeTableData] = useState<ctDataType | null>(null);
    const [checkedExtMandant, setCheckedExtMandant] = useState(false);
    const [resultData, setResultData] = useState<resultCriteriaListEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const dlgEmptyState: resultEntryDialog = {
        mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode: "", dayWorkSpace: false
    }
    const [resultEntryDialogData, setResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [urinsticksDialogData, setUrinSticksDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [seroresultEntryDialogData, setSeroResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [hamatDialogData, setHamatDialogData] = useState<resultEntryDialog>(dlgEmptyState);

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setSelectedCode(objCT.sCode);
        setCodeTableData(objCT);
        setResultData([]);
    };

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.emergencyreqcolor };
        const trProps: any = { style: rowColor };
        return React.cloneElement(
            trElement,
            { ...trProps }
        );
    };

    const ResultSaved = async (save: boolean, reqID: string, valType: string) => {
        setResultEntryDialogData(dlgEmptyState);
        setUrinSticksDialogData(dlgEmptyState);
        setSeroResultEntryDialogData(dlgEmptyState);
        setHamatDialogData(dlgEmptyState);

        if (save === true) {
            if (await ResultsCanBeEntered(reqID,valType) === false) {
                var binddata: resultCriteriaListEntry[] = [];
                resultData.forEach((o: resultCriteriaListEntry) => {
                    if (o.reqid !== reqID) {
                        binddata.push({ ...o });
                    };
                });
                setResultData(binddata);
            };
        };
    };

    const handleResultEditClick = (RCLE: resultCriteriaListEntry, action: string) => {             
        //console.log("valtype", RCLE.valtypeSuppl1.trim())
        if (RCLE.valtypeSuppl1.trim() === 'C') {
            setResultEntryDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: RCLE.reqid.toString(), pid: RCLE.pid.toString(), fid: RCLE.fid.toString(),
                paramid: "", reqdatetime: RCLE.reqdatetime, reqprovider: RCLE.reqprovider, valtype: RCLE.valtype, addedparams: "", pregnancyweek: RCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            })
        } else if (RCLE.valtypeSuppl1.trim() === 'US' || RCLE.valtypeSuppl1.trim() === 'GF' || RCLE.valtypeSuppl1.trim() === 'PU' || RCLE.valtypeSuppl1.trim() === 'LI') {
            setUrinSticksDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: RCLE.reqid.toString(), pid: RCLE.pid.toString(), fid: RCLE.fid.toString(),
                paramid: "", reqdatetime: RCLE.reqdatetime, reqprovider: RCLE.reqprovider, valtype: RCLE.valtype, addedparams: "", pregnancyweek: RCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        } else if (RCLE.valtypeSuppl1.trim() === 'S') {
            setSeroResultEntryDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: RCLE.reqid.toString(), pid: RCLE.pid.toString(), fid: RCLE.fid.toString(),
                paramid: "", reqdatetime: RCLE.reqdatetime, reqprovider: RCLE.reqprovider, valtype: RCLE.valtype, addedparams: "", pregnancyweek: RCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        } else if (RCLE.valtypeSuppl1.trim() === 'HM' || RCLE.valtypeSuppl1.trim() === 'MD') {
            setHamatDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: RCLE.reqid.toString(), pid: RCLE.pid.toString(), fid: RCLE.fid.toString(),
                paramid: "", reqdatetime: RCLE.reqdatetime, reqprovider: RCLE.reqprovider, valtype: RCLE.valtype, addedparams: "", pregnancyweek: RCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        };
        
    };       
        
    async function ResultsCanBeEntered(reqID:string, valType:string) {
        var bok: boolean = true;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'resultcriteria/resultscanbeentered',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: reqID, valtype: valType, mbyn: false }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        bok = response?.data
        return bok;
    };

    const resultEditButton = (props: GridCellProps) => {        
        const reqid = props.dataItem['reqid'];
        const pid = props.dataItem['pid'];
        const fid = props.dataItem['fid'];
        const reqdatetime = props.dataItem['reqdatetime'];
        const valtype = props.dataItem['valtype'];
        const pregnancyweek = props.dataItem['pregnancyweek'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="zoom-in"
                    onClick={() => {
                        handleResultEditClick(props.dataItem, 'open');                        
                    }}
                >
                </Button>
            </td>
        );
    };
    const ReqDateTimeCell = (props: GridCellProps) => {
        const value = props.dataItem['reqdatetime'];
        if (value === '' || value === null)
            return (
                <td>
                </td>
            )
        else {
            return (
                <td>
                    {intl.formatDate(value, 'dd.mm.yyyy hh:mm:ss')}
                </td>
            )
        }
    };

    const BirthDateCell = (props: GridCellProps) => {
        const value = props.dataItem['birthdate'];
        if (value === '' || value === null)
            return (
                <td>
                </td>
            )
        else {
            return (
                <td>
                    {intl.formatDate(value, 'dd.mm.yyyy')}
                </td>
            )
        }
    }


    useEffect(() => {
        SearchClick();
    }, [CodeTableData, checkedExtMandant]);

    useEffect(() => {
        setCodeTableData(null);
        setResultData([]);
    }, [props.mandantChanged]);     

    const SearchClick = async () => {
        if (CodeTableData === null) return;
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'resultcriteria/getresultcriteriadata',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "valtype": CodeTableData != null ? CodeTableData.sCode : '', "showdataforexternmandant": checkedExtMandant === true ? '1' : '0' }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        // console.log(response?.data);
        var binddata: resultCriteriaListEntry[] = [];
        if (response?.data !== null)
            response?.data.forEach((o: resultCriteriaListEntry) => {                
                o.birthdate = intl.formatDate(
                    o.birthdate ? o.birthdate : "20.20.2020",
                    DATE_FORMAT
                );                
                o.reqdatetime = intl.formatDate(
                    o.reqdatetime ? o.reqdatetime : "20.20.2020",
                    DATE_TIME_FORMAT
                );                
                binddata.push({ ...o });
            });
        setResultData(binddata);
        setIsLoading(false);
    };

    const idGetter = getter('reqid');

    const location: string = props.loadfor;

    var formHeight: number = location === "modal" ? 720 : window.innerHeight;
    formHeight -= 120;
    const formWidth: number = location === "modal" ? 1400 : window.innerWidth;

    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 100; // as dialog
        contentWidth = formWidth - 30;
    }
    else {
        formHeight -= 50;
        gridHeight -= 170; // as dashboard
        contentWidth = formWidth - 100;
    }
   
    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: contentWidth, minWidth: contentWidth }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="col-12">&nbsp;</div>
                    <div className="col-1">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'valCriteria'}>{mui(userRights, "resultcriteria", "lblValType", "Val Type")}</Label></div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={props.mandantChanged} id="valType"
                                    codeTableID='202' selCode={selectedCode} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{ display: 'inline-block' }}>
                            <Checkbox onChange={() => setCheckedExtMandant(!checkedExtMandant)} name="showdataforexternmandant" label={mui(userRights, "resultcriteria", "chkShowDataForExtnMandant", "Show Data For Extern Mandant")} />
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ textAlign: "right", display: 'inline-block' }}>
                            <Button hidden={ true} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                SearchClick()
                            }}>{mui(userRights, "common", "cmdSearch", "Search")}</Button>
                            </div>
                      </div>
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> :
                            <Grid
                                style={{
                                    height: gridHeight, width: '100%'
                                }}
                                data={orderBy(resultData, sort).map((item) => ({
                                    ...item,
                                    selected: selectedState[idGetter(item)],
                                }))}
                                sortable={{
                                    allowUnsort: false,
                                    mode: "single",
                                }}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort(e.sort);
                                }}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                selectedField={'selected'}
                                dataItemKey={'reqid'}
                                rowRender={rowRender}
                            >
                                <GridColumn field="" title={mui(userRights, "resultcriteria", "col1_cmdResultEntry", "")} width="50px" cell={resultEditButton} />
                                <GridColumn field="reqid" title={mui(userRights, "resultcriteria", "col2_ReqID", "ReqID")} width="100px" />
                                <GridColumn cell={ReqDateTimeCell} field="reqdatetime" filter={"date"} title={mui(userRights, "resultcriteria", "col3_ReqDateTime", "Request date/time")} width="150px" />
                                <GridColumn field="fid" title={mui(userRights, "resultcriteria", "col4_FID", "FID")} width="125px" />
                                <GridColumn field="pid" filter={"numeric"} title={mui(userRights, "resultcriteria", "col5_PID", "PID")} width="100px" />
                                <GridColumn field="name" title={mui(userRights, "resultcriteria", "col6_Name", "Name")} width="250px" />
                                <GridColumn field="reqprovider" title={mui(userRights, "resultcriteria", "col7_ReqProv", "Req Prov")} width="125px" />
                                <GridColumn cell={BirthDateCell} field="birthdate" filter={"date"} title={mui(userRights, "resultcriteria", "col8_Birthdate", "Birthdate")} width="100px" />
                                <GridColumn field="valtype" title={mui(userRights, "resultcriteria", "col9_ValType", "Val Type")} width="100px" />
                                <GridColumn field="reqnr" title={mui(userRights, "resultcriteria", "col10_ReqNr", "Req Nr")} width="100px" />
                                {/*<GridColumn field="reqdatesort" title="Request Date Sort" width="150px" />*/}
                                <GridColumn field="externmandantid" title={mui(userRights, "resultcriteria", "col11_ExtMandant", "ExtMandant")} />
                                );
                            </Grid>}
                    </div>
                </div>
            </div>
            <Suspense fallback={<Loader />}>
                <ChemieResultEntryDialog mandantid={resultEntryDialogData.mandantid} reqid={resultEntryDialogData.reqid} pid={resultEntryDialogData.pid} fid={resultEntryDialogData.fid}
                    paramid={resultEntryDialogData.paramid} reqdatetime={resultEntryDialogData.reqdatetime} reqprovider={resultEntryDialogData.reqprovider} valtype={resultEntryDialogData.valtype} addedparams={resultEntryDialogData.addedparams}
                    pregnancyweek={resultEntryDialogData.pregnancyweek} mbyn={resultEntryDialogData.mbyn} userid={resultEntryDialogData.userid}
                    showDialog={resultEntryDialogData.showDialog} ResultSaved={resultEntryDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></ChemieResultEntryDialog>
                <UrineSticksResultAndValidation mandantid={urinsticksDialogData.mandantid} reqid={urinsticksDialogData.reqid} pid={urinsticksDialogData.pid} fid={urinsticksDialogData.fid}
                    paramid={urinsticksDialogData.paramid} reqdatetime={urinsticksDialogData.reqdatetime} reqprovider={urinsticksDialogData.reqprovider} valtype={urinsticksDialogData.valtype} addedparams={urinsticksDialogData.addedparams}
                    pregnancyweek={urinsticksDialogData.pregnancyweek} mbyn={urinsticksDialogData.mbyn} userid={urinsticksDialogData.userid}
                    showDialog={urinsticksDialogData.showDialog} ResultSaved={urinsticksDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></UrineSticksResultAndValidation>
                <SerologyResultAndValidation mandantid={seroresultEntryDialogData.mandantid} reqid={seroresultEntryDialogData.reqid} pid={seroresultEntryDialogData.pid} fid={seroresultEntryDialogData.fid}
                    paramid={seroresultEntryDialogData.paramid} reqdatetime={seroresultEntryDialogData.reqdatetime} reqprovider={seroresultEntryDialogData.reqprovider} valtype={seroresultEntryDialogData.valtype} addedparams={seroresultEntryDialogData.addedparams}
                    pregnancyweek={seroresultEntryDialogData.pregnancyweek} mbyn={seroresultEntryDialogData.mbyn} userid={seroresultEntryDialogData.userid}
                    showDialog={seroresultEntryDialogData.showDialog} ResultSaved={seroresultEntryDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></SerologyResultAndValidation>
                <HamatResultAndValidation mandantid={hamatDialogData.mandantid} reqid={hamatDialogData.reqid} pid={hamatDialogData.pid} fid={hamatDialogData.fid}
                    paramid={hamatDialogData.paramid} reqdatetime={hamatDialogData.reqdatetime} reqprovider={hamatDialogData.reqprovider} valtype={hamatDialogData.valtype} addedparams={hamatDialogData.addedparams}
                    pregnancyweek={hamatDialogData.pregnancyweek} mbyn={hamatDialogData.mbyn} userid={hamatDialogData.userid}
                    showDialog={hamatDialogData.showDialog} ResultSaved={hamatDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></HamatResultAndValidation>
            </Suspense>
        </>
    );
}
export default ResultCriteria;