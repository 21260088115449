import { useParams } from 'react-router';
import CobraMonitor from '../CobraMonitor/CobraMonitor';
import MatInLab from '../Dashboard/MatInLab';
import ReservedComponents from '../Dashboard/ReservedComponents';
import ResultCriteria from '../Dashboard/ResultCriteria';
import ValCriteria from '../Dashboard/ValCriteria';
import WorkList from '../Dashboard/WorkList';
import NavBar from "../NavBar/NavBar";
import AdditionalRequestParam from '../Dashboard/AdditionalRequestParam';

const SystemFunction = (props: any) => {
    const { id } = useParams();

    const callbackMandantChanged = async () => {
    }

    return (
        <NavBar callbackMandantChanged={callbackMandantChanged}>
            {id === 'cobramonitor' && <CobraMonitor loadfor='' mandantChanged={0}></CobraMonitor>}
            {id === 'resultcriteria' && <ResultCriteria loadfor={''} mandantChanged={0}></ResultCriteria>}
            {id === 'technicalvalidation' && <ValCriteria loadfor={''} mandantChanged={0}></ValCriteria>}
            {id === 'worklist' && <WorkList loadfor='' mandantChanged={0}></WorkList>}
            {id === 'reservedcomponents' && <ReservedComponents loadfor='' mandantChanged={0}></ReservedComponents>}
            {id === 'matinlab' && <MatInLab></MatInLab>}
            {id === 'additionalrequestparam' && <AdditionalRequestParam loadfor={''} mandantChanged={0}></AdditionalRequestParam>}
        </NavBar>
    )
}

export default SystemFunction;
