import { Loader } from "@progress/kendo-react-indicators";
import { useEffect } from "react";
import logo from '../../assets/cobra.png';

interface SplashParams {
    message: string;
}

const Splash = (props: SplashParams) => {
    useEffect(() => {

        return () => {
        };

    }, [])

    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{
                position: 'absolute', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, textAlign: 'center', width: 900, height: 100
            }}>
                <div><Loader type="converging-spinner" size='large'></Loader></div>
                <div>
                    <h1 style={{ color: '#223D8D' }}>{props.message !== "" ? "cobra software ag" : ""}</h1>
                </div>
                <div>
                    <h3 style={{ color: 'black' }}>{props.message}</h3>
                </div>
            </div>
        </div>
    );
}

export default Splash;