import { SystemUser, SystemSettings } from '../types';

var UserSession = (function () {
    var getSystemSettings = function (): SystemSettings {
        var systemSettings: SystemSettings = { API_URL: '', CMMode: 0, websiteLanguage: "en", hideInternalMenus: "0", socketPort: "8001" };
        var temp = sessionStorage.getItem("systemSettings")
        if (temp !== null) {
            systemSettings = JSON.parse(temp);
        }
        return systemSettings;
    }

    var setSystemSettings = function (systemsettings: SystemSettings) {
        if (systemsettings.socketPort === undefined || systemsettings.socketPort === null || systemsettings.socketPort === "")
            systemsettings.socketPort = "8001";
        sessionStorage.setItem("systemSettings", JSON.stringify(systemsettings));
    };

    function getLanguage(): string {
        var temp = getSystemSettings().websiteLanguage;
        return temp;
    };
    
    var setLanguage = function (language: string) {
        var systemsettings: SystemSettings = getSystemSettings();
        systemsettings.websiteLanguage = language;
        setSystemSettings(systemsettings);
    };


    var getLoggedInUser = function (): SystemUser {
        var loggedInUser = null;
        var temp = sessionStorage.getItem("sessionUser")
        if (temp !== null) {
            loggedInUser = JSON.parse(temp);
        }
        return loggedInUser;
    };

    var setLoggedInUser = function (user: SystemUser) {
        user.MBYN = false;
        var i: number;
        for (i = 0; i < user.MandantCat.length; i++) {
            if (user.CurrentMandant === user.MandantCat[i].Code)
                if (user.MandantCat[i].MBYN === "1")
                    user.MBYN = true;
        }
        sessionStorage.setItem("sessionUser", JSON.stringify(user));
        //console.log('setting language: ', user.Language.toLowerCase());
        setLanguage(user.Language.toLowerCase());
    };

    return {
        getLoggedInUser: getLoggedInUser,
        setLoggedInUser: setLoggedInUser,
        getSystemSettings: getSystemSettings,
        setSystemSettings: setSystemSettings,
        getLanguage: getLanguage
    }

})();

export default UserSession;
