import { getter, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { getSelectedState, Grid, GridCellProps, GridColumn, GridSelectionChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { useCallback, useEffect, useState } from "react";
import { dateInternalToSystem, formatDate, mui } from "../../../Helper";
import { CAddressData, CAdrPatBld, CHANGED, ctDataType, DELETED, SAVED, selCodeDataType, UNSAVED } from "../../../types";
import { v4 as uuidv4 } from 'uuid';
import UserSession from "../../UserSession";
import Confirmation, { IConfirmation } from "../../Misc/Confirmation";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { CodeTableSel } from "../../CodeTableSel/CodeTableSel";
import MyDatePicker from "../../myDatePicker/myDatePicker";
import { Label } from "@progress/kendo-react-labels";
import { SelectCode } from "../../SelectCode/SelectCode";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import CustomDate from "../../Misc/CustomDate";
interface AdrPatBldProps {
    data: CAddressData;
    AdrPatBldData: CAdrPatBld[] | undefined;
    contentContainerWidth: number;
    callbackHandleAdrPatBldDataChange(value: CAdrPatBld[]): any;
}

const AdrPatBldTab = (props: AdrPatBldProps) => {
    // For confirmation dialog
    const emptyConfirmation: IConfirmation = {
        title: "",
        message: "",
        button_yes: "",
        button_no: "",
        show: false,
        userdata: "",
        buttonClicked: function (yes: boolean): void {

        }
    }
    const [confirmDialog, setConfirmDialog] = useState<IConfirmation>(emptyConfirmation);

    const [issueDate, setIssueDate] = useState<string>("");
    const emptyAdrPatBld: CAdrPatBld = {
        key: "",
        sMandantID: "",
        sPID: "",
        sOldPID: "",
        sIDFrgCard: "",
        sIssueBy: "",
        sIssueDate: "",
        sRemark: "",
        sBloodGroup: "",
        sRhesus: "",
        sPhenoType: "",
        sBGChangeSerID: "",
        sCWYN: "",
        sInactiveYN: "",
        objRecUpd: {
            sModifiedBy: "",
            sModifiedDate: "",
            sModifiedTime: "",
            sRecordedBy: "",
            sRecordedDate: "",
            sRecordedTime: "",
            sDelete: "",
            sInActive: ""
        },
        sBGDesc: "",
        sRHDesc: "",
        sPTDesc: "",
        sIssueByDesc: "",
        CWYN: false,
        sRecStatus:""
    };
    const idGetter_AdrPatBld = getter('sIDFrgCard');
    const [adrPatBld, setAdrPatBld] = useState<CAdrPatBld[]>([]);
    const [adrPatKey, setAdrPatKey] = useState<string>("");
    const [selectedAdrPatBld, setSelectedAdrPatBld] = useState<CAdrPatBld>(emptyAdrPatBld);
    const [disabled, setDisabled] = useState(false);

    const handleChangeDate = (value: string, source: string) => {
        if (source === 'issuedate') {
            setIssueDate(value);
            setSelectedAdrPatBld(prevData => ({
                ...prevData,
                ['sIssueDate']: value
            }))            
        };    
    };

    // All code tables related to adr pat bld tab
    const callbackCTSelected_CAdrPatBld = async (ctid: string, controlID: string, objCT: ctDataType) => {
        var prevState: CAdrPatBld = { ...selectedAdrPatBld, [controlID]: objCT.sCode };
        if (controlID === 'sBloodGroup')
            prevState.sBGDesc = objCT.sDescDevelopment;
        else if (controlID === 'sRhesus')
            prevState.sRHDesc = objCT.sDescDevelopment;
        else if (controlID === 'sPhenoType')
            prevState.sPTDesc = objCT.sDescDevelopment;
        else if (controlID === 'sIssueBy')
            prevState.sIssueByDesc = objCT.sDescDevelopment;
        setSelectedAdrPatBld(prevState);
    };
    // All textbox and checkboxes related to adr pat bld tab
    const handleChange_CAdrPatBld = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        // console.log('name, type', name, type);
        setSelectedAdrPatBld(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    // Select code component callback for all selections on adr pat bld
    const callbackCodeSelected_CAdrPatBld = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        var prevState: CAdrPatBld = { ...selectedAdrPatBld, [controlID]: obj.code };
        if (controlID === 'sPhenoType')
            prevState.sPTDesc = obj.desc;
        setSelectedAdrPatBld(prevState);
    };
    const initialSort_AdrPatBld: Array<SortDescriptor> = [
        { field: "sIDFrgCard", dir: "asc" },
    ];
    const [selectedState_AdrPatBld, setSelectedState_AdrPatBld] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const [sort_AdrPatBld, setSort_AdrPatBld] = useState(initialSort_AdrPatBld);
    const onSelectionChangeAdrPatBld = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState_AdrPatBld,
            dataItemKey: 'sIDFrgCard',
        });
        // get the selected row from the grid and set state variable so that data is populated in the form
        let selectedIds: CAdrPatBld[] = [];
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let adrpatbld: CAdrPatBld | undefined = adrPatBld.find((item: CAdrPatBld) => item.sIDFrgCard === property.toString())
                if (adrpatbld !== undefined)
                    selectedIds.push(adrpatbld)
            }
        }
        if (selectedIds.length > 0) {
            console.log('selected adr pat bld: ', selectedIds[0]);
            selectedIds[0].sIssueDate !== "" ? setIssueDate(selectedIds[0].sIssueDate) : setIssueDate("");
            selectedIds[0].CWYN = selectedIds[0].sCWYN === "1" ? true : false;
            setSelectedAdrPatBld(selectedIds[0]);
            setAdrPatKey(selectedIds[0].key)
            selectedIds[0].sRecStatus === "S" ? setDisabled(true) : setDisabled(false);
        }
        else
            setSelectedAdrPatBld(emptyAdrPatBld);
        setSelectedState_AdrPatBld(newSelectedState);
    };
    const newAdrPatBld = () => {
        var obj: CAdrPatBld = emptyAdrPatBld;
        obj.sPhenoType = "";
        obj.sPTDesc = "";
        let sel: any = {};
        setSelectedState_AdrPatBld(sel);
        setSelectedAdrPatBld(obj);
        setAdrPatKey("");
        setIssueDate("");
        setDisabled(false);
        setTimeout(() => { document.getElementById('sIDFrgCard')?.focus() }, 200);
    }
    function CheckMandatoryData() {
        var bok: boolean = true;
        if (selectedAdrPatBld.sIDFrgCard === "") {
            alert("Enter IDFrgCard");
            setTimeout(() => { document.getElementById('sIDFrgCard')?.focus() }, 200);
            bok = false;
        } else if (selectedAdrPatBld.sBloodGroup !== "" && selectedAdrPatBld.sRhesus === "") {
            alert("Enter Rhesus");
            setTimeout(() => { document.getElementById('sRhesus')?.focus() }, 200);
            bok = false;
        } else if (selectedAdrPatBld.sRhesus !== "" && selectedAdrPatBld.sBloodGroup === "") {
            alert("Enter Blood Group");
            setTimeout(() => { document.getElementById('sBloodGroup')?.focus() }, 200);
            bok = false;       
        };
        return bok;
    };
    function VerifyData() {
        var bok: boolean = true;
        if (props.data.sBloodGroup !== "" && props.data.sBloodGroup !== selectedAdrPatBld.sBloodGroup) {
            alert("Invalid Blood Group");
            setTimeout(() => { document.getElementById('sBloodGroup')?.focus() }, 200);
            bok = false;
        } else if (props.data.sRhesus !== "" && props.data.sRhesus !== selectedAdrPatBld.sRhesus) {
            alert("Invalid Rhesus");
            setTimeout(() => { document.getElementById('sRhesus')?.focus() }, 200);
            bok = false;
        } else if (props.data.sPhenoType !== "" && props.data.sPhenoType !== selectedAdrPatBld.sPhenoType) {
            alert("Invalid Pheno Type");
            setTimeout(() => { document.getElementById('sPhenoType')?.focus() }, 200);
            bok = false;
        } else { 
            adrPatBld.forEach((o: CAdrPatBld) => {
                if (o.sIDFrgCard === selectedAdrPatBld.sIDFrgCard) {
                    alert("IDFrgCard Already Exists.");
                    bok = false;
                    setTimeout(() => { document.getElementById('sIDFrgCard')?.focus() }, 200);
                };
            });
        };
        return bok;
    };
    const updateListAdrPatBld = () => {
        if (Object.keys(selectedState_AdrPatBld).length === 0) {
            if (CheckMandatoryData()) {
                if (VerifyData()) {
                    let clonedArray: CAdrPatBld[] = JSON.parse(JSON.stringify(adrPatBld));
                    var newkey: string = uuidv4();
                    clonedArray.unshift({
                        key: newkey,
                        sMandantID: UserSession.getLoggedInUser().CurrentMandant,
                        sPID: props.data.sPID,
                        sInactiveYN: "",
                        objRecUpd: {
                            sModifiedBy: "",
                            sModifiedDate: "",
                            sModifiedTime: "",
                            sRecordedBy: "",
                            sRecordedDate: "",
                            sRecordedTime: "",
                            sDelete: "",
                            sInActive: ""
                        },
                        sOldPID: "",
                        sIDFrgCard: selectedAdrPatBld.sIDFrgCard,
                        sIssueBy: selectedAdrPatBld.sIssueBy,
                        sIssueDate: selectedAdrPatBld.sIssueDate,
                        sRemark: selectedAdrPatBld.sRemark,
                        sBloodGroup: selectedAdrPatBld.sBloodGroup,
                        sRhesus: selectedAdrPatBld.sRhesus,
                        sPhenoType: selectedAdrPatBld.sPhenoType,
                        sBGChangeSerID: "",
                        sCWYN: selectedAdrPatBld.CWYN ? "1" : "0",
                        sBGDesc: selectedAdrPatBld.sBGDesc,
                        sRHDesc: selectedAdrPatBld.sRHDesc,
                        sPTDesc: selectedAdrPatBld.sPTDesc,
                        sIssueByDesc: selectedAdrPatBld.sIssueByDesc,
                        CWYN: false,
                        sRecStatus: UNSAVED
                    });
                    setAdrPatBld(clonedArray);
                    props.callbackHandleAdrPatBldDataChange(clonedArray);
                    let sel: any = {};
                    sel[clonedArray[0].key] = true;
                    setSelectedState_AdrPatBld(sel);
                    setAdrPatKey(newkey);
                };                
            };            
        }
        else if (selectedAdrPatBld !== null) {
            var binddata: CAdrPatBld[] = [];
            adrPatBld.forEach((o: CAdrPatBld) => {
                if (adrPatKey === o.key) {
                    binddata.push({
                        ...o, sIDFrgCard: selectedAdrPatBld.sIDFrgCard,
                        sIssueBy: selectedAdrPatBld.sIssueBy,
                        sIssueDate: issueDate, sRemark: selectedAdrPatBld.sRemark,
                        sBloodGroup: selectedAdrPatBld.sBloodGroup,
                        sRhesus: selectedAdrPatBld.sRhesus,
                        sPhenoType: selectedAdrPatBld.sPhenoType, sCWYN: selectedAdrPatBld.CWYN ? "1" : "0", sBGDesc: selectedAdrPatBld.sBGDesc,
                        sRHDesc: selectedAdrPatBld.sRHDesc,
                        sPTDesc: selectedAdrPatBld.sPTDesc,
                        sIssueByDesc: selectedAdrPatBld.sIssueByDesc,
                        sRecStatus: selectedAdrPatBld.sRecStatus === SAVED ? CHANGED : selectedAdrPatBld.sRecStatus
                    });
                }
                else
                    binddata.push({ ...o });
            });
            setAdrPatBld(binddata);
            props.callbackHandleAdrPatBldDataChange(binddata);
        }
    }
    const deleteAdrPat = useCallback((key: string, value: string, recstatus: string) => {
        if (recstatus === DELETED) return;
        const buttonClickedConfAdrPat = (yes: boolean, userdata: string): void => {
            if (yes) {
                console.log('adrPatKey to delete', userdata, adrPatKey);
                if (recstatus === UNSAVED) {
                    let newList: CAdrPatBld[] = adrPatBld.filter(ura => {
                        return userdata !== ura.key;

                    })
                    console.log(newList);
                    setAdrPatBld(newList);
                    props.callbackHandleAdrPatBldDataChange(newList);
                } else {
                    var binddata: CAdrPatBld[] = adrPatBld;
                    binddata.forEach((o: CAdrPatBld) => {
                        if (o.sIDFrgCard === value) {
                            o.sRecStatus = DELETED;                            
                        };
                    });
                    setAdrPatBld(binddata);
                    props.callbackHandleAdrPatBldDataChange(binddata);
                };                
            };
            setConfirmDialog(emptyConfirmation);
        }

        const deleteConfirmation: IConfirmation = {
            title: mui(userRights, "common", "deleteconfirm", "Delete Confirm"),
            message: "Delete ext lab " + value + '?',
            button_yes: mui(userRights, "common", "yes", "Yes"),
            button_no: mui(userRights, "common", "no", "No"),
            show: true,
            buttonClicked: buttonClickedConfAdrPat,
            userdata: key
        }
        setConfirmDialog(deleteConfirmation);
    }, [adrPatBld]);
    const CustomCellEdit_AdrPatBld = (props: GridCellProps) => {
        const key = props.dataItem['key'];
        const value = props.dataItem['sIDFrgCard'];
        const recstatus = props.dataItem['sRecStatus'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="delete"
                    onClick={() => {
                        deleteAdrPat(key, value, recstatus);
                    }}
                >
                </Button>
            </td>
        );
    };

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        if (props.data.hasOwnProperty('state') && props.data.state === "EMPTY") {
            setAdrPatBld([]);
            setSelectedAdrPatBld(emptyAdrPatBld);
            setIssueDate("");
        }
        else {
            var binddata_adrpatbld: CAdrPatBld[] = [];
            if (props.AdrPatBldData !== null && props.AdrPatBldData !== undefined)
                for (const o of props.AdrPatBldData) {
                    binddata_adrpatbld.push({ ...o, CWYN: o.sCWYN === "1" ? true : false, key: uuidv4() });
                }
            setAdrPatBld(binddata_adrpatbld);
        }
    }, [props.AdrPatBldData])

    function checkDeleted(obj: CAdrPatBld) {
        return obj.sRecStatus !== DELETED;
    };

    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth, width: props.contentContainerWidth - 50 }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'idfrgcard'}>ID Frg Card</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={disabled} autoComplete="off" id="sIDFrgCard" name="sIDFrgCard" type='text' onChange={handleChange_CAdrPatBld}
                                    value={selectedAdrPatBld.sIDFrgCard} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Blood group</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CAdrPatBld} mandantChanged={0} id="sBloodGroup"
                                    codeTableID='602' selCode={selectedAdrPatBld.sBloodGroup} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rhesus</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CAdrPatBld} mandantChanged={0} id="sRhesus"
                                    codeTableID='603' selCode={selectedAdrPatBld.sRhesus} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Pheno type</Label></div>
                            <div className="vertical-wrapper-child">
                                <SelectCode autocomplete={false} disabled={disabled} setSelectedTable={callbackCodeSelected_CAdrPatBld} id="sPhenoType"
                                    tablename="PhenoType" code="PhenoID" desc="Description" selCode={selectedAdrPatBld.sPhenoType}
                                    mandantChanged={0} width={"100%"} supplParam1="" supplParam2=""></SelectCode>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Issued By</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CAdrPatBld} mandantChanged={0} id="sIssueBy"
                                    codeTableID='320' selCode={selectedAdrPatBld.sIssueBy} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/*<MyDatePicker label="Issue date" source={'issuedate'}*/}
                        {/*    parentOnChange={handleChangeDate}*/}
                        {/*    required={false} pickerValue={issueDate} setPickerValue={setIssueDate} id={'sIssueDate'} supplParam1="" supplParam2=""></MyDatePicker>*/}
                        <CustomDate disabled={disabled} dateChanged={handleChangeDate} width='150px' className='' defaulValue={issueDate} id={'sIssueDate'} source={'issuedate'} label={'Issue date'} required={false} ></CustomDate>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox disabled={disabled} onChange={handleChange_CAdrPatBld} id="CWYN" name="CWYN"
                                    value={selectedAdrPatBld.CWYN}
                                    label="CW YN" />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sRemark'}>Remarks</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={disabled} width='100%' autoComplete="off" id="sRemark" name="sRemark" type='text'
                                    onChange={handleChange_CAdrPatBld} value={selectedAdrPatBld.sRemark} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper">&nbsp;</div>
                        <div className="vertical-wrapper-child">
                            <div className="button-right">
                                <Button style={{ width: '120px' }}
                                    themeColor={'success'}
                                    onClick={updateListAdrPatBld}
                                    disabled={disabled}
                                >
                                    Update List
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col" style={{ height: 390, maxHeight: 390, overflowY: 'hidden', marginTop: 10, border: '0px solid green' }}>
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: 390 }}
                                sortable={true}
                                sort={sort_AdrPatBld}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort_AdrPatBld(e.sort);
                                }}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                onSelectionChange={onSelectionChangeAdrPatBld}
                                selectedField={'selected'}
                                data={orderBy(adrPatBld.filter(checkDeleted), sort_AdrPatBld).map((item) => ({
                                    ...item,
                                    selected: selectedState_AdrPatBld[idGetter_AdrPatBld(item)],
                                }))}
                                dataItemKey={'sIDFrgCard'}
                            >
                                <GridToolbar>
                                    <button
                                        title="Add new"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={newAdrPatBld}
                                    >
                                        Add new
                                    </button>
                                </GridToolbar>
                                <GridColumn field="" title="" width="50px" cell={CustomCellEdit_AdrPatBld} />
                                <GridColumn field="sIDFrgCard" title="ID Frg Card" />
                                <GridColumn field="sBGDesc" title="BloodGroup" width="100px" />
                                <GridColumn field="sRHDesc" title="Rhesus" width="100px" />
                                <GridColumn field="sIssueByDesc" title="Issue By" />
                                <GridColumn field="sIssueDate" title="Issue Date" width="100px" />
                                <GridColumn field="sRemark" title="Remarks" />
                                <GridColumn field="sPTDesc" title="PhenoType" width="100px" />
                                <GridColumn field="objRecUpd.sRecordedBy" title="Entered By" width="100px" />
                                <GridColumn field="objRecUpd.sRecordedDate" title="Entry Date" width="100px" />
                                <GridColumn field="sCWYN" title="CW YN" width="100px" />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            <Confirmation title={confirmDialog.title} message={confirmDialog.message} button_yes={confirmDialog.button_yes} button_no={confirmDialog.button_no}
                show={confirmDialog.show} buttonClicked={confirmDialog.buttonClicked} userdata={confirmDialog.userdata}></Confirmation>
        </>
    );
}

export default AdrPatBldTab;