import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { dateInternalToSystem, mui } from "../../../Helper";
import { CAddressData, ctDataType, selCodeDataType } from "../../../types";
import { CodeTableSel } from "../../CodeTableSel/CodeTableSel";
import CustomDate from "../../Misc/CustomDate";
import MyDatePicker from "../../myDatePicker/myDatePicker";
import { SelectCode } from "../../SelectCode/SelectCode";

interface PatientTabProps {
    data: CAddressData;
    callbackCTSelected(ctid: string, controlID: string, objCT: ctDataType): any;
    callbackControlChange(e: any): any;
    callbackHandleChangeDate(value: string, source: string, supplParam1?: string, supplParam2?: string): any;
    callbackCodeSelected(tableid: string, controlID: string, obj: selCodeDataType): any;
    contentContainerWidth: number
}

const PatientTab = (props: PatientTabProps) => {
    const [statusdate, setStatusDate] = useState<string>(props.data.sStatusDate);

    useEffect(() => {
        return () => {
            
        };

    }, [])

    useEffect(() => {
        //if (props.data.hasOwnProperty('state') && props.data.state === "EMPTY") {
        //    setStatusDate("");
        //}
        //else {
            
        //};

    }, [props.data])

    const HandleChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        if (source === 'statusdate') {
            setStatusDate(value);
            props.callbackHandleChangeDate(value, source, supplParam1, supplParam2);       
        };
    };

    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth, width: props.contentContainerWidth - 50 }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Status</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sStatus"
                                    codeTableID='306' selCode={props.data.sStatus} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">
                                <CustomDate dateChanged={HandleChangeDate} width='150px' className='' defaulValue={statusdate} id={'statusdate'} source={'statusdate'} label={mui(userRights, "address", "lblStatusDate", "Status Date")} required={false} disabled={false}></CustomDate>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Language</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sLanguage"
                                    codeTableID='307' selCode={props.data.sLanguage} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Blood Group</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sBloodGroup"
                                    codeTableID='602' selCode={props.data.sBloodGroup} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rhesus</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sRhesus"
                                    codeTableID='603' selCode={props.data.sRhesus} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Pheno type</Label></div>
                            <div className="vertical-wrapper-child">
                                <SelectCode autocomplete={false} disabled={false} setSelectedTable={props.callbackCodeSelected} id="sPhenoType"
                                    tablename="PhenoType" code="PhenoID" desc="Description" selCode={props.data.sPhenoType} mandantChanged={0} width={"100%"}
                                    supplParam1="" supplParam2=""></SelectCode>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Kell</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sKell"
                                    codeTableID='627' selCode={props.data.sKell} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Height</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input inputMode='numeric' autoComplete="off" id="sHeight" name="sHeight" type='text'
                                    onChange={props.callbackControlChange}
                                    value={props.data.sHeight} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Weight</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input inputMode='numeric' autoComplete="off" id="sWeight" step=".001" name="sWeight" type='text'
                                    onChange={props.callbackControlChange}
                                    value={props.data.sWeight} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={props.callbackControlChange} id="ExtBloodGrpYN" name="ExtBloodGrpYN"
                                    value={props.data.ExtBloodGrpYN}
                                    label="Ext. blood group YN" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    </div>
                </div>

            </div>
        </>
    );
}

export default PatientTab;