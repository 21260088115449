import { Mandant, SystemUser } from '../../types'
import React, { useEffect, useState } from 'react'
import UserSession from '../../components/UserSession';
import { CacheOrderProvider } from '../../Helper';
import { ComboBox, ComboBoxChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";

interface CurrentMandantProps {
    callbackMandantChanged(): any;
}
// ***SD: Mandant component to that takes care of the dropdown in the navbar for mandant selection
export const CurrentMandant = (props: CurrentMandantProps) => {
    const loggedInUser: SystemUser = UserSession.getLoggedInUser();
    const [selectedmandant, setSelectedmandant] = useState<Mandant | null>(null);
    const [mandantList, setMandantList] = useState<Mandant[]>([]);

    const onChange = (event: ComboBoxChangeEvent) => {
        setSelectedmandant(event.value);
        performInitOnMandantChange(event.value.Code, event.value.Description);
    };

    // Change event that handles change of mandant combobox. It fires the callback passed by the parent after mandant change
    const performInitOnMandantChange = async (mandantID: string, desc:string) => {
        const user: SystemUser = UserSession.getLoggedInUser();
        user.CurrentMandant = mandantID;
        user.CurrentMandantDesc = desc;
        UserSession.setLoggedInUser(user);
        await CacheOrderProvider();
        props.callbackMandantChanged();
    }

    useEffect(() => {
        if (loggedInUser !== undefined && loggedInUser.MandantCat !== undefined) {
            var binddata: Mandant[] = [];
            loggedInUser.MandantCat.forEach((o: Mandant) => {
                binddata.push({ ...o, DisplayString: o.Code + ' ' + o.Description });
            });
            setMandantList(binddata);
        }
    }, []);

    useEffect(() => {
        mandantList.forEach((o: Mandant) => {
            if (o.Code === loggedInUser.CurrentMandant) {
                setSelectedmandant(o);
            }
        });
    }, [mandantList]);

    const columns = [
        { field: "Code", header: "ID", width: "100px" },
        { field: "Description", header: "Name", width: "200px" },
    ];


    if (loggedInUser === undefined || loggedInUser.MandantCat === undefined)
        return <div></div>
    else
        return (
            <MultiColumnComboBox
                columns={columns}
                style={{ width: "300px" }}
                data={mandantList}
                textField="DisplayString"
                dataItemKey="Code"
                value={selectedmandant}
                onChange={onChange}
                clearButton={false}
                  />
        )
}

