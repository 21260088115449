import { Dialog } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CUserRightsAppl, qcResultEntryDialog } from "../../types";
import QCResultEntryComponent from "./QCResultEntryComponent";

const QCResultEntryDialog = (props: qcResultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'QCResultEntry' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
        
    const [showQCResultEntryComponent, setShowQCResultEntryComponent] = useState<boolean>(props.showDialog);
    
    const CancelClick = async () => {
        setShowQCResultEntryComponent(false);
        props.ResultSaved(false);
    };

    useEffect(() => {
        setShowQCResultEntryComponent(props.showDialog);
    }, [props.showDialog])

    var formWidth: number = 1400;
    var windowHeight: number = 720;

    return (
        <>
            {showQCResultEntryComponent &&
                <Dialog title={<DialogTitleBar title={mui(userRights, "QCResultEntry", "textTitle", "QC Result")} />} onClose={CancelClick} height={windowHeight} closeIcon={false} width={formWidth} >
                    <QCResultEntryComponent mandantid={props.mandantid} userid={props.userid} formstate={props.formstate}
                        showDialog={props.showDialog} obj={props.obj} ResultSaved={props.ResultSaved}></QCResultEntryComponent>
                </Dialog>
            }
        </>
    );       
}
export default QCResultEntryDialog;