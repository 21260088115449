import React, { useEffect, useState } from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { ctDataType } from '../../types';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';

interface CodeTableSelProps {
    codeTableID: string;
    selCode: string;
    mandantChanged: number;
    setSelectedCT(ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled?: boolean;
}

export const CodeTableSelList = (props: CodeTableSelProps) => {    
    // Default empty selected CT state
    var ctData = {
        sCodeTableID: "",
        sMandantID: "",
        sCode: "",
        sAbbrDevelopment: "",
        sDescDevelopment: "",
        sMemoDevelopment: "",
        sLanguage: "",
        sSuppl1: "",
        sSuppl2: "",
        sSuppl3: "",
        sUserGroup: ""
    };

    const [selected, setSelected] = useState(ctData);

    // Array of CT data for the specified CT
    const [CodeTableData, setCodeTableData] = useState<ctDataType[]>([]);

    async function GetCodeTableData() {

        const GETREQUESTINFOBAR_URL = UserSession.getSystemSettings().API_URL + 'codetablesel/getcodetabledata';

        const response = await axios.post(GETREQUESTINFOBAR_URL,
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "codeTableID": props.codeTableID }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: ctDataType[] = [];
        binddata.push({ DisplayString: "", sCodeTableID: "", sMandantID: "", sCode: "", sAbbrDevelopment: "", sDescDevelopment: "", sMemoDevelopment: "", sLanguage: "", sSuppl1: "", sSuppl2: "", sSuppl3: "", sUserGroup: "", selected: false });
        if (response?.data !== null)
            response?.data.forEach((o: ctDataType) => {
                binddata.push({
                    DisplayString: o.sCode + ' ' + o.sDescDevelopment, sCodeTableID: o.sCodeTableID,
                    sMandantID: o.sMandantID, sCode: o.sCode, sAbbrDevelopment: o.sAbbrDevelopment,
                    sDescDevelopment: o.sDescDevelopment === "" ? o.sAbbrDevelopment : o.sDescDevelopment,
                    sMemoDevelopment: o.sMemoDevelopment, sLanguage: o.sLanguage, sSuppl1: o.sSuppl1, sSuppl2: o.sSuppl2, sSuppl3: o.sSuppl3, sUserGroup: o.sUserGroup, selected: o.selected
                });
            });
        setCodeTableData(binddata);
        binddata.forEach((o: ctDataType) => {
            if (o.sCode === props.selCode) {
                setSelected(o)
            }
        });
    }

    useEffect(() => {
        // console.log('CodeTableSel UE: props.mandantChanged');
        GetCodeTableData();
    }, [props.mandantChanged])

    useEffect(() => {
        if (CodeTableData === null || CodeTableData === undefined) return;
        let selCode: ctDataType | undefined = CodeTableData.find((item: ctDataType) => item.sCode === props.selCode)
        if (selCode !== undefined) {
            setSelected(selCode);
        }
    }, [props.selCode])

    const SELECTED_FIELD = "selected";
    const lastSelectedIndex = React.useRef(0);
    const [filter, setFilter] = React.useState<FilterDescriptor>();
    const [selectedFields, setSelectedFields] = useState<ctDataType[]>([]);  

    const handleItemClick = (event: ListBoxItemClickEvent, allData: ctDataType[], selData: ctDataType[]) => {
        const newData: ctDataType[] = allData.map((item: ctDataType) => {
            if (item.sCode === event.dataItem.sCode) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        const chosenData: ctDataType[] = selData.map((item: ctDataType) => {
            if (item.sCode === event.dataItem.sCode) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });

        const current = newData.findIndex(
            (dataItem) => dataItem === event.dataItem
        );
        lastSelectedIndex.current = current;


        chosenData.forEach((item) => {
            if (item.sCode === event.dataItem.sCode) {

            } else {
                item.selected = false
            }
            return item;
        });

        setCodeTableData(chosenData);

        //console.log(event.dataItem, chosenData, newData)
        props.setSelectedCT(props.codeTableID, props.id, event.dataItem, props.supplParam1, props.supplParam2);
        setSelected(event.dataItem);

    };

    const MyCustomItem = (props: any) => {
        let { dataItem, selected, ...others } = props;
        return (
            <li {...others} style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                    <div className="col-2">{props.dataItem.sCode}</div>
                    <div className="col-10">{props.dataItem.sDescDevelopment}</div>
                </div>

            </li>
        );
    };

    return (
        <>
            <ListBox
                style={{ height: 350, width: props.width }}
                data={filter ? filterBy(CodeTableData, filter) : CodeTableData}
                textField="DisplayString"
                selectedField={SELECTED_FIELD}
                key="sCode"
                onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, selectedFields, CodeTableData)}
                item={MyCustomItem}
            /></>
    )

}