import React, { useEffect, useState } from 'react'
import axios from 'axios';
import UserSession from '../UserSession';
import { Loader, LoaderType } from "@progress/kendo-react-indicators";
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Checkbox, CheckboxChangeEvent, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useLocation } from 'react-router-dom';
import { CRequest, ItemsToPrintDialogData, PrintData, PrintItemsData, ReqInfoBar } from '../../types';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DialogTitleBar, mui } from '../../Helper';
import RequestInfoBar from '../ReqInfoBar/RequestInfoBar';
import ProgressBar from '../Misc/ProgressBar';

export type ItemsToPrintList = {
    product: string
    bg: string
    rh: string
    entered: string
    checked: string
    reserved: string
    tested: string
    total: string
    expirydays: string
};

interface ItemsToPrintProps {

    itemstoprintdata: ItemsToPrintDialogData
    callbackDialogClosed(): any;
}

const ItemsToPrint = (props: ItemsToPrintProps) => {
    const [visible, setVisible] = useState<boolean>(props.itemstoprintdata.showDialog);
    const [printdata, setPrintData] = useState<PrintData[]>([]);
    const [printdatalabel, setPrintDataLabel] = useState<PrintData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);    

    useEffect(() => {
        setIsLoading(false)
        //console.log("useEffect ItemsToPrint", props)
        setVisible(props.itemstoprintdata.showDialog);     
        if (props.itemstoprintdata.showDialog === true) {
            getitemstoprint();
              
        }

    }, [props.itemstoprintdata.reqData.sReqID, props.itemstoprintdata.showDialog])

    const getitemstoprint = async () => {
        console.log('getitemstoprint: ');
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'Printing/getPrintItemsListData',
                JSON.stringify({
                    "mandantid": UserSession.getLoggedInUser().CurrentMandant, "reqid": props.itemstoprintdata.reqData.sReqID, "pid": props.itemstoprintdata.reqData.sPID,
                    "fid": props.itemstoprintdata.reqData.sFID, "matid": props.itemstoprintdata.matid, "sformnr": props.itemstoprintdata.sformnr,
                    "enmwhattoprint": props.itemstoprintdata.enmwhattoprint, "bprintdirectly": props.itemstoprintdata.bprintdirectly, "bprintwhenrequestplan": props.itemstoprintdata.bprintwhenrequestplan,
                    "bprintreqlabel": props.itemstoprintdata.bprintreqlabel, "bprintmedienlabelsonly": props.itemstoprintdata.bprintmedienlabelsonly,
                    "bprintomrbarcodenndlabel": props.itemstoprintdata.bprintomrbarcodenndlabel, "bfrommatinlab": props.itemstoprintdata.bfrommatinlab, "userid": UserSession.getLoggedInUser().UserID}),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            console.log('getitemstoprint: ', response?.data.collPrintData, response?.data.collPrintDataLabel);
            setPrintData(response?.data.collPrintData)
            setPrintDataLabel(response?.data.collPrintDataLabel)
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    }

    const CancelClick = async () => {
        props.callbackDialogClosed();
        setVisible(false);
    };

    const CustomCellYNFieldPD = (props: GridCellProps) => {
        //console.log("CustomCellYNFieldPD", props)
        const chkprintyn = props.dataItem['sPrint'];
        //console.log("CustomCellYNField 2", props)
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >

                <Checkbox
                    size="large"
                    key={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "sPrint"}
                    id={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "sPrint"}
                    name={"bprint"}
                    onChange={(event: CheckboxChangeEvent) => handleYNCheckPD(event, props.dataItem.sPrintLabelType, props.dataItem.sMatCode)}
                    checked={chkprintyn === "1" ? true : false} />

            </td>
        );

    };

    const handleYNCheckPD = (event: CheckboxChangeEvent, sPrintLabelType: string, smatdesc: string) => {
        const name = event.target.name
        const value = event.target.value === true ? '1' : '0'
        //console.log("handleValYNCheck", event.target, event.target.value)

        const binddata: PrintData[] = [...printdata];
        var tabElement: PrintData;
        for (tabElement of binddata) {
            if (tabElement.sPrintLabelType === sPrintLabelType && tabElement.sMatCode === smatdesc) {
                tabElement.sPrint = value;

                break;
            }
        }
        setPrintData(binddata);

    };

    const CustomCellYNField = (props: GridCellProps) => {
        //console.log("CustomCellYNField", props)
        const chkprintyn = props.dataItem['sPrint'];
        //console.log("CustomCellYNField 2", props)
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >

                <Checkbox
                    size="large"
                    key={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "sPrint"}
                    id={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "sPrint"}
                    name={"bprint"}
                    onChange={(event: CheckboxChangeEvent) => handleYNCheck(event, props.dataItem.sPrintLabelType, props.dataItem.sMatCode)}
                    checked={chkprintyn === "1" ? true : false} />

            </td>
        );

    };

    const CustomCellNumericField = (props: GridCellProps) => {
        //console.log("CustomCellNumericField", props)
        //console.log("CustomCellNumericField 2", props)
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <TextBox style={{ border: '1px solid darkgrey', width: '100px' }}
                    key={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "NumOfLabels"}
                    id={props.dataItem.sPrintLabelType + props.dataItem.sMatCode + "NumOfLabels"}
                    onChange={(event: TextBoxChangeEvent) => handleNumericFieldChange(event, props.dataItem.sPrintLabelType, props.dataItem.sMatCode)}
                    value={props.dataItem.sNumOfLabels}  />
               
            </td>
        );

    };

    const handleYNCheck = (event: CheckboxChangeEvent, sPrintLabelType :string, smatdesc: string) => {
        const name = event.target.name
        const value = event.target.value === true ? '1' : '0'
        //console.log("handleValYNCheck", event.target, event.target.value)

        const binddata: PrintData[] = [...printdatalabel];
        var tabElement: PrintData;
        for (tabElement of binddata) {
            if (tabElement.sPrintLabelType === sPrintLabelType && tabElement.sMatCode === smatdesc) {
                tabElement.sPrint = value;

                break;
            }
        }
        setPrintDataLabel(binddata);

    };

    const handleNumericFieldChange = (event: TextBoxChangeEvent, sPrintLabelType: string, smatdesc: string) => {
        //console.log(event)
        const value: number =Number (event.target.value)

        const binddata: PrintData[] = [...printdatalabel];
        var tabElement: PrintData;
        for (tabElement of binddata) {
            if (tabElement.sPrintLabelType === sPrintLabelType && tabElement.sMatCode === smatdesc) {
                tabElement.sNumOfLabels = value;

                break;
            }
        }
        setPrintDataLabel(binddata);
    };

    const PrintBarcodeAndLabels = () => {
      
        const response = axios.post(UserSession.getSystemSettings().API_URL + 'Printing/printFromItemsToPrint',
            JSON.stringify({
                "mandantid": UserSession.getLoggedInUser().CurrentMandant, "reqid": props.itemstoprintdata.reqData.sReqID, "pid": props.itemstoprintdata.reqData.sPID,
                "fid": props.itemstoprintdata.reqData.sFID, "matid": props.itemstoprintdata.matid, "sformnr": props.itemstoprintdata.sformnr,
                "enmwhattoprint": props.itemstoprintdata.enmwhattoprint, "bprintdirectly": props.itemstoprintdata.bprintdirectly, "bprintwhenrequestplan": props.itemstoprintdata.bprintwhenrequestplan,
                "bprintreqlabel": props.itemstoprintdata.bprintreqlabel, "bprintmedienlabelsonly": props.itemstoprintdata.bprintmedienlabelsonly,
                "bprintomrbarcodenndlabel": props.itemstoprintdata.bprintomrbarcodenndlabel, "bfrommatinlab": props.itemstoprintdata.bfrommatinlab, "userid": UserSession.getLoggedInUser().UserID,
                "collPrintData": printdata, "collPrintDataLabel": printdatalabel
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
    };

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };

    var patRequestAreaWidth: number = window.innerWidth - 200;
    var gridHeight: number = window.innerHeight - 100;

    var dlgHeight: number = window.innerHeight - 100;
    var dlgWidth: number = window.innerWidth - 200;

    return (
        <>
            <div>
                {visible && (
                    <Dialog style={{ overflow: 'hidden' }} title={<DialogTitleBar title={mui(userRights, "ItemsToPrintList", "textTitle", "Items To Print")} />} onClose={CancelClick} height={dlgHeight} width={dlgWidth} >
                        <div className="container-fluid" style={{ overflowX: "hidden", width: dlgWidth -40 }}>    
                            <div className="row">
                                <div className="col"></div>
                                <div className="col"></div>
                                <div className="col" style={{ textAlign: "right" }}>
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                        CancelClick()
                                    }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>&nbsp;
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        PrintBarcodeAndLabels();
                                    }}>{mui(userRights, "ItemsToPrintList", "btnPrint", "Print")} </Button>

                                </div>
                            </div>
                            <div className="row" style={{ height: 1 }}></div>
                            <div className="row" >
                                <RequestInfoBar reqid={props.itemstoprintdata.reqData.sReqID} valtype={''} showmatdata={false} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>
                            </div>        

                            {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                <>
                                    <div className="row" style={{ height: 1 }}></div>
                                    <div className="row" style={{ minHeight: '250px', height: '250px' }}>
                                        <div className="col">
                                            <Grid
                                                style={{
                                                    width: '100%', height: '250px'
                                                }}
                                                data={printdata}
                                            >
                                                <GridColumn field="sWorkGroupName" title={mui(userRights, "ItemsToPrintList", "colWorkGroupName", "WorkGroupName")} />
                                                <GridColumn field="sBarCodeID" title={mui(userRights, "ItemsToPrintList", "colBarCodeID", "BarCodeID")} />
                                                <GridColumn field="sBarcode" title={mui(userRights, "ItemsToPrintList", "colBarcode", "Barcode")} />
                                                <GridColumn field="bPrint" title={mui(userRights, "ItemsToPrintList", "colPrint", "Print")} width="70px" editable={true} cell={CustomCellYNFieldPD} />
                                                <GridColumn field="sWorkPlaceID" title={mui(userRights, "ItemsToPrintList", "colWorkPlaceID", "WorkPlaceID")} />
                                                <GridColumn field="sWorkPlaces" title={mui(userRights, "ItemsToPrintList", "colWorkPlaces", "WorkPlaces")} />
                                                );
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <div className="row" style={{ minHeight: '250px', height: '250px' }}>
                                        <div className="col">
                                            <Grid
                                                style={{
                                                    width: '100%', height: '250px'
                                                }}
                                                data={printdatalabel}
                                                editField="inEdit"
                                            >
                                                <GridColumn field="sLBLDesc" title={mui(userRights, "ItemsToPrintList", "colLabelDesc", "LabelDesc")} />
                                                <GridColumn field="sParamDesc" title={mui(userRights, "ItemsToPrintList", "colParamDesc", "ParamDesc")} />
                                                <GridColumn field="sMatDesc" title={mui(userRights, "ItemsToPrintList", "colMatDesc", "MatDesc")} />
                                                <GridColumn field="bPrint" title={mui(userRights, "ItemsToPrintList", "colPrint", "Print")} width="70px" editable={true} cell={CustomCellYNField} />
                                                <GridColumn field="sNumOfLabels" title={mui(userRights, "ItemsToPrintList", "colNumOfLabels", "NumOfLabels")} editable={true} cell={CustomCellNumericField} />
                                                <GridColumn field="sBarcode" title={mui(userRights, "ItemsToPrintList", "colBarcode", "Barcode")} />
                                                );
                                            </Grid>
                                        </div>
                                    </div>
                                </>
                        }

                           
                        </div>
                    </Dialog>
                    )}
            </div>
      
        </>
    );
}

export default ItemsToPrint;