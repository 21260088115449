import React, {  useEffect, useState } from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { masterTableDataType } from '../../types';
import { ComboBox, ComboBoxChangeEvent, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';

interface MasterTableSelProps {
    tableName: string;    
    selCode: string;  
    setSelectedValue(tableName: string, objMT: masterTableDataType): any;
    width: string;
    mandantChanged: number;
    dependentCode: string | null;
    disabled: boolean|false;
}


export const MasterTableSel = (props: MasterTableSelProps) => {
    const columns = [
        { field: "sCode", header: "Code", width: "100px" },
        { field: "sDesc", header: "Description" },
    ];

    // Default empty selected Master Table state
    var ctMasterData = {        
        sCode: "",        
        sDesc: ""        
    };
    const [selected, setSelected] = useState(ctMasterData);
    const [disabled, setDisabled] = React.useState(false);

    // Array of Master Table data for the specified Matser Table
    const [MasterTableData, setMasterTableData] = useState<masterTableDataType[]>([]);

    async function GetMasterTableData() {

        const GETREQUESTINFOBAR_URL = UserSession.getSystemSettings().API_URL + 'mastertablesel/getmastertabledata';

        const response = await axios.post(GETREQUESTINFOBAR_URL,
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "tablename": props.tableName, dependentCode: props.dependentCode }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: masterTableDataType[] = [];
        binddata.push({ DisplayString: "", sCode: "", sDesc: ""});
        if (response?.data !== null)
            response?.data.forEach((o: masterTableDataType) => {
                binddata.push({ DisplayString: o.sCode + ' : ' + o.sDesc, sCode: o.sCode, sDesc: o.sDesc});
            });
        setMasterTableData(binddata);
        binddata.forEach((o: masterTableDataType) => {
            if (o.sCode === props.selCode) {
                setSelected(o)
            }
        });
    }

    useEffect(() => {        
        setSelected(ctMasterData);
        console.log('selected - ' + selected.sCode);
        GetMasterTableData();
        setDisabled(props.disabled)
    }, [props.mandantChanged, props.dependentCode, props.selCode])      

    const handleChangeAutoComplete = (value: masterTableDataType) => {
        props.setSelectedValue(props.tableName, value);
        setSelected(value);
        console.log('HCAC-setSelected - ' + value);
    }

    const onChange = (event: ComboBoxChangeEvent) => {
        props.setSelectedValue(props.tableName, event.value);
        setSelected(event.value);
        console.log('setSelected - ' + event.value);
    };

    return (
        <MultiColumnComboBox
            columns={columns}
            id={"combobox"}
            style={{ width: props.width }}
            data={MasterTableData}
            textField="DisplayString"
            dataItemKey="sCode"
            value={selected}
            onChange={onChange}
            clearButton={false}
            disabled={disabled}
        />
    )

}