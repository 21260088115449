import React, { useEffect, useState } from 'react'
import axios from 'axios';
import UserSession from '../UserSession';
import { Grid, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import ProgressBar from '../Misc/ProgressBar';

export type reservedComponentEntry = {
    product: string
    bg: string
    rh: string
    entered: string
    checked: string
    reserved: string
    tested: string
    total: string
    expirydays: string
};

const initialSort: Array<SortDescriptor> = [
    { field: "worklistnr", dir: "asc" },
];

interface ReservedComponentsProps {
    loadfor: string;
    mandantChanged: number;
}

const ReservedComponents = (props: ReservedComponentsProps) => {
    const [sort, setSort] = React.useState(initialSort);
    const [rescomp, setRescomp] = useState<reservedComponentEntry[]>([]);  
    const [isLoading, setIsLoading] = useState(false);

    const fillrescomp = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'ComponentsStockOverview/getcompstockoverview',
                { mandantid: UserSession.getLoggedInUser().CurrentMandant },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            setRescomp(response?.data);
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    }        


    useEffect(() => {
        fillrescomp();
    }, [props.mandantChanged]);

    useEffect(() => {
        fillrescomp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
        };
    }, [])


    const location: string = props.loadfor;
    var formHeight: number = location === "modal" ? 720 : window.innerHeight;
    formHeight -= 120;
    const formWidth: number = location === "modal" ? 1400 : window.innerWidth;
    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 10; // as dialog
        contentWidth = formWidth - 30;
    }
    else {
        formHeight -= 50;
        gridHeight -= 90; // as dashboard
        contentWidth = formWidth - 100;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: contentWidth, minWidth: contentWidth }}>
                <div className="row" style={{ marginTop: 10 }}>
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                        <Grid
                style={{
                                height: gridHeight, width: '100%'
                }}
                data={orderBy(rescomp, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
            >
                <GridColumn field="product" title="product" />
                <GridColumn field="bg" title="bg" />
                <GridColumn field="rh" title="rh" />
                <GridColumn field="entered" title="entered" />
                <GridColumn field="checked" title="checked"/>
                <GridColumn field="reserved" title="reserved" />
                <GridColumn field="tested" title="tested" />
                <GridColumn field="total" title="total" />
                <GridColumn field="expirydays" title="expirydays" />
                );
                        </Grid>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReservedComponents;