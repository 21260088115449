import { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import UserSession from "../UserSession";
import axios from 'axios';
import { CUserRightsAppl, ReqInfoBar, viewResult, viewResultDialog } from "../../types";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { DialogTitleBar, mui } from "../../Helper";
import ProgressBar from "../Misc/ProgressBar";

//const emptyViewResult: viewResult = {
//    mandantid: "",
//    paramid: "",
//    paramname: "",
//    orderedyn: "",
//    valtype: "",
//    paramtypeflag: "",
//    prevaluedays: "",
//    paramgroup: "",
//    recstatus: "",
//    paramnamecolor: "",
//    paramstatus: "",
//    chkrefvalyn: "",
//    resulttype: "",
//    workgroup: "",
//    showQualitativeyn: "",
//    unit: "",
//    reaction: "",
//    belongstoprofiles: "",
//    preval1: "",
//    preval2: "",
//    preval3: "",
//    prevaldate1: "",
//    prevaldate2: "",
//    prevaldate3: "",
//    prevaldesc1: "",
//    prevaldesc2: "",
//    prevaldesc3: "",
//    preval1tooltip: "",
//    preval2tooltip: "",
//    preval3tooltip: "",
//    resultcode: "",
//    interpretation: "",
//    interpretationcolor: "",
//    rawresult: "",
//    resultflag: "",
//    resulttext: "",
//    resultchangedmanuallyyn: "",
//    dilution: "",
//    serid: "",
//    refvalue: "",
//    refflag: "",
//    comment: "",
//    commentfreetext: "",
//    comptype: "",
//    objParamRef: {
//        sMandantID: "",
//        sParamId: "",
//        sGender: "",
//        sToAge: "",
//        sFromTime: "",
//        sToTime: "",
//        sFromDate: "",
//        sToDate: "",
//        sLowFlagYN: "",
//        sHighFlagYN: "",
//        sEquation: "",
//        sRefValCode: "",
//        sLowRefVal1: "",
//        sHighRefVal1: "",
//        sLowRefVal2: "",
//        sHighRefVal2: "",
//        sLowRefVal3: "",
//        sHighRefVal3: "",
//        sLowRefVal4: "",
//        sHighRefVal4: "",
//        sLowRefVal5: "",
//        sHighRefVal5: "",
//        sLLimTest: "",
//        sHLimTest: "",
//        sWarnCall1YN: "",
//        sWarnCall2YN: "",
//        sWarnCall3YN: "",
//        sWarnCall4YN: "",
//        sWarnCall5YN: "",
//        sValLevel: "",
//        sLowExtTest: "",
//        sHighExtTest: "",
//        sLowCheckYN: "",
//        sHighCheckYN: "",
//        sThRange1: "",
//        sThRange2: "",
//        sThRange3: "",
//        sThRange4: "",
//        sParamAdd1: "",
//        sResultCode1: "",
//        sParamAdd2: "",
//        sResultCode2: "",
//        sComment: "",
//        sPathologyResult: "",
//        sInactiveYN: "",
//        sRefValueString: "",
//        sRefFlag: "",   
//        objRecUpd: {
//            sModifiedBy: "",
//            sModifiedDate: "",
//            sModifiedTime: "",
//            sRecordedBy: "",
//            sRecordedDate: "",
//            sRecordedTime: "",
//            sDelete: "",
//            sInActive: ""
//        }    
//    }
//};

const ViewResultDialog = (props: viewResultDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'viewresult' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [data, setData] = useState<viewResult[]>([]);
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };


    const CancelClick = async () => {        
        setVisible(false);
        props.cancleClicked();
    };
        
    const CustomCellParam = (props: GridCellProps) => {
        const value = props.dataItem['paramname'];
        const bgcolor = props.dataItem['paramnamecolor'];
        const tttext = props.dataItem['paramid'];
        console.log(bgcolor);
        return (
            <td style={{ backgroundColor: bgcolor }} title={tttext}>
                {value}
            </td>
        );
    };
    const CustomCellInterpretation = (props: GridCellProps) => {
        const value = props.dataItem['interpretation'];
        const bgcolor = props.dataItem['interpretationcolor'];
        return (
            <td style={{ backgroundColor: bgcolor }} title={value}>
                {value}
            </td>
        );
    };
    const CustomCellPreVal1 = (props: GridCellProps) => {
        const value = props.dataItem['preval1'];
        const tttext = props.dataItem['preval1tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };
    const CustomCellPreVal2 = (props: GridCellProps) => {
        const value = props.dataItem['preval2'];
        const tttext = props.dataItem['preval2tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };
    const CustomCellPreVal3 = (props: GridCellProps) => {
        const value = props.dataItem['preval3'];
        const tttext = props.dataItem['preval3tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };
    const GetViewResultData = async () => {   
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'viewresult/getviewresultdata',
            JSON.stringify({ mandantid: props.objVRC.mandantid, mbyn: props.objVRC.mbyn, pid: props.objVRC.pid, fid: props.objVRC.fid, gendercode: props.objVRC.gendercode, birthdate: props.objVRC.birthdate, pregnancyweeks: props.objVRC.pregnancyweeks, toage: props.objVRC.toage, reqid: props.objVRC.reqid, reqdate: props.objVRC.reqdate, reqtime: props.objVRC.reqtime, medvalidation: props.objVRC.medvalidation, valtype: props.objVRC.valtype }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );        
        setData(response?.data);  
        setIsLoading(false);
    };

    useEffect(() => {
        GetViewResultData();
        setVisible(props.showDialog);        
    }, [props.showDialog])    

    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                {visible && (
                    <Dialog  title={<DialogTitleBar title={mui(userRights, "viewresult", "header", "View Result")} />} onClose={CancelClick}
                        height={750} width="calc(100vw - 150px)">
                        <div style={{ overflow: 'hidden' }}>
                            <RequestInfoBar reqid={props.objVRC.reqid} valtype="" showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>  
                            {isLoading ? <ProgressBar message=""></ProgressBar> :   
                            <Grid
                                style={{
                                    height: 450
                                }}
                                data={data}
                                sortable={true}                                                                
                            >
                                <GridColumn field="paramname" title={mui(userRights, "viewresult", "col1_ParamName", "Param Name")} cell={CustomCellParam} />
                                <GridColumn field="valtype" title={mui(userRights, "viewresult", "col2_ValType", "Val Type")} />
                                <GridColumn field="preval1" title={mui(userRights, "viewresult", "col3_PreVal1", "Pre Val 1")} cell={CustomCellPreVal1} />
                                <GridColumn field="preval2" title={mui(userRights, "viewresult", "col4_PreVal2", "Pre Val 2")} cell={CustomCellPreVal2} />
                                <GridColumn field="preval3" title={mui(userRights, "viewresult", "col5_PreVal3", "Pre Val 3")} cell={CustomCellPreVal3} />
                                <GridColumn field="interpretation" title={mui(userRights, "viewresult", "col6_Interp", "Interpretation")} cell={CustomCellInterpretation} />
                                <GridColumn field="comptype" title={mui(userRights, "viewresult", "col7_CompType", "Comp Type")} />
                                <GridColumn field="comment" title={mui(userRights, "viewresult", "col8_Comment", "Comment")} />
                                <GridColumn field="refvalue" title={mui(userRights, "viewresult", "col9_RefValue", "Ref Value")} />
                                <GridColumn field="unit" title={mui(userRights, "viewresult", "col10_Unit", "Unit")} />                                
                                );
                            </Grid>        
                            }
                            <div className="grid-item" >
                                <div className="button-right"><Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                    CancelClick()
                                }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button></div>
                            </div>
                        </div>

                    </Dialog>
                )}
            </div>
        </>
    );

}
export default ViewResultDialog;
