import * as React from "react";
import { mui } from "../../Helper";

export const MyEditCell = (props: any) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];

    return (
        <td className="k-command-cell">
            <button
                disabled={dataItem.disableEdit}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                onClick={() => props.enterEdit(props.dataItem)}
            >
                {mui(userRights, "EditFormNFSettings", "lblEdit", "Edit")}
            </button>

        </td>
    );
};