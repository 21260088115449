import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, DialogCloseEvent } from "@progress/kendo-react-dialogs";

export interface IConfirmation {
    title: string;
    message: string;
    button_yes: string;
    button_no: string;
    show: boolean;
    userdata: string;
    buttonClicked: (yes: boolean, userdata: string) => void;
}
const Confirmation = (props: IConfirmation) => {
    const closeClicked = (event: DialogCloseEvent) => {
        
    };

    const noClicked = () => {
        props.buttonClicked(false, props.userdata);
    };

    const yesClicked = () => {
        props.buttonClicked(true, props.userdata);
    };

    return (
        <>
            {
                props.show && (
                    <Dialog  title={props.title} onClose={closeClicked}>
                        <div className="container" style={{ overflowX: 'hidden', width: 500 }}>
                            <div className="row">
                                <div className="col">
                                    {props.message}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'error'} onClick={() => {
                                            noClicked()
                                        }}>{props.button_no}</Button>&nbsp;
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'success'} onClick={() => {
                                            yesClicked()
                                        }}>{props.button_yes}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
            </Dialog>
            )
            }
        </>
    );
}

export default Confirmation;