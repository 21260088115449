import React, { useEffect, useState} from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { CResultEntry, CResultEntryOutput, CUserRightsAppl, emptyREData, emptyREO, selCodeDataType } from '../../types';
import { AutoComplete, AutoCompleteBlurEvent, AutoCompleteChangeEvent, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { mui } from '../../Helper';

interface SelectCodeProps {
    tablename: string;
    code: string;
    desc: string;
    selCode: string;
    mandantChanged: number;
    setSelectedTable(tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled: boolean;
    autocomplete: boolean;
    objRE?: CResultEntry;
}

export const SelectCode = (props: SelectCodeProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'serologyResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const columns = [
        { field: "code", header: "Code" },
        { field: "desc", header: "Description" },        
    ];

    // Default empty selected CT state
    var selCodeData: selCodeDataType = {
        mandantid: "",
        code: "",
        desc: "",   
        DisplayString: "",
        addnfield: "",
        selected: false
    };
    const [selAuto, setSelAuto] = useState<string>("");
    const [selected, setSelected] = useState<selCodeDataType>(selCodeData);
    // Array of CT data for the specified CT
    const [selCode, setSelCode] = useState<selCodeDataType[]>([]);

    if (props.tablename === "Room") {
        columns.push({ field: "addnfield", header: "Station" });
    };

    async function GetTableData() {        
        const GetTableData_URL = UserSession.getSystemSettings().API_URL + 'SelectCode/gettabledata';

        const response = await axios.post(GetTableData_URL,
            JSON.stringify({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, "tableid": props.tablename,
                "code": props.code, "desc": props.desc, "supplParam1": props.supplParam1, "supplParam2": props.supplParam2,
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: selCodeDataType[] = [];
        binddata.push({ DisplayString: "", mandantid: "", code: "", desc: "", addnfield: "", selected: false });
        if (response?.data !== null)
            response?.data.forEach((o: selCodeDataType) => {
                binddata.push({
                    DisplayString: o.code + ' - ' + o.desc ,
                    mandantid: o.mandantid, code: o.code, desc: o.desc, addnfield: o.addnfield, selected: o.selected
                });
            });
        setSelCode(binddata);
        // console.log('columns', columns);
        //console.log(binddata)
        binddata.forEach((o: selCodeDataType) => {
            if (o.code === props.selCode) {
                setSelected(o)
                setSelAuto(o.code)
            }
        });
    }
   
    useEffect(() => {
        //console.log('SelectCode UE:', props);
        GetTableData();
    }, [props.mandantChanged, props.supplParam1])

    useEffect(() => {
        
        setSelAuto(props.selCode)
        if (selCode === null || selCode === undefined) return;
        let selcCode: selCodeDataType | undefined = selCode.find((item: selCodeDataType) => item.code === props.selCode)
        if (selcCode !== undefined) {
            setSelected(selcCode);
            setSelAuto(selcCode.code)
        }
    }, [props.selCode])

    const onChange = (event: ComboBoxChangeEvent) => {
        if (event.value === null) {
            return;
        }
        props.setSelectedTable(props.tablename, props.id, event.value, props.supplParam1, props.supplParam2);
        setSelected(event.value);

        //props.code = event.value
        // console.log(event)
    };

    const onBlurAuto = async (event: AutoCompleteBlurEvent) => {

        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var newselCodeObj: selCodeDataType = selCodeData;
        var Msg: string = "";

        if (props.id === "ResultCode") {

            //var resultMainData: CResultEntryOutput = emptyREO;
            //var obj: CResultEntry = emptyREData;
            //var newselCodeObj: selCodeDataType = selCodeData;

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sRawResult = event.target.value;
                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }

            console.log("onBlurAuto ResultCode SC - ", event, obj)

            if (event.target.value === '') {
                setSelected(newselCodeObj);
                setSelAuto("")

            }
            else {
                const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                    JSON.stringify({
                        objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                    }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: false
                    }
                );
                if (response?.data !== null) {
                    console.log("onBlurAuto SC response - ", response?.data)
                    var objRO = response?.data

                    Msg = objRO.objREO.sErrorMsg;
                    if (Msg === undefined) Msg = '';
                    if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                    if (objRO.objResultEntry.bInvalidCode === true) {
                        alert(Msg);
                        setSelected(selCodeData);
                        setSelAuto('')
                    }
                    else {
                        let selcCode: selCodeDataType | undefined = selCode.find((item: selCodeDataType) => item.code === objRO.objResultEntry.sRawResult)

                        if (selcCode !== undefined) {
                            //console.log("enter")
                            props.setSelectedTable(props.tablename, props.id, selcCode, props.supplParam1, props.supplParam2);
                            setSelected(selcCode);
                            setSelAuto(selcCode.code)
                        }
                        else {
                            setSelected(newselCodeObj);
                            setSelAuto(objRO.objResultEntry.sRawResult)
                        }
                    }

                };
            }

        }
        else {

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sResultComm = event.target.value;
                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
                
            }

            console.log("onBlurAuto ResultComm SC - ", event, obj)

            if (event.target.value === '') {
                setSelected(newselCodeObj);
                setSelAuto("")

            }
            else {
                const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
                    JSON.stringify({
                        mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
                    }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: false
                    }
                );
                if (response?.data !== null) {
                    console.log("onBlurAuto SC response - ", response?.data)
                    var objRCO = response?.data

                   if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                    if (objRCO.binvalidcode === true) {
                        alert(Msg);
                        setSelected(selCodeData);
                        setSelAuto('')
                    }
                    else {
                        let selcCode: selCodeDataType | undefined = selCode.find((item: selCodeDataType) => item.code === objRCO.resultcomm)

                        if (selcCode !== undefined) {
                            //console.log("enter")
                            props.setSelectedTable(props.tablename, props.id, selcCode, props.supplParam1, props.supplParam2);
                            setSelected(selcCode);
                            setSelAuto(selcCode.code)
                        }
                        else {
                            setSelected(newselCodeObj);
                            setSelAuto(objRCO.resultcomm)
                        }
                    }

                };
            }


        }
      

    
    };

    const onChangeAuto = (event: AutoCompleteChangeEvent) => {
        if (event.value === null) {
            return;
        }
        
        setSelAuto(event.value);
        console.log(event, selCode)
        let selcCode: selCodeDataType | undefined = selCode.find((item: selCodeDataType) => item.DisplayString === event.value)
                
        if (selcCode !== undefined) {
            //console.log("enter")
            props.setSelectedTable(props.tablename, props.id, selcCode, props.supplParam1, props.supplParam2);
            setSelected(selcCode);
            setSelAuto(selcCode.code)
        }
        console.log("onChangeAuto SC - ", event, selcCode, selected, selAuto)
    };
    const [filter, setFilter] = React.useState<FilterDescriptor>();

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (event) {
            setFilter(event.filter);
        }
    };

    return (

        <>
        
            {
                props.autocomplete === true ?
                    <AutoComplete
                        style={{ width: '100%' }}
                        data={filter ? filterBy(selCode, filter) : selCode}
                        textField="DisplayString"
                        dataItemKey="code"
                        defaultValue={props.code}
                        value={selAuto}
                        onChange={onChangeAuto}
                        onBlur={onBlurAuto}
                    />
                :
                    <MultiColumnComboBox
                        disabled={props.disabled}
                        columns={columns}
                        style={{ width: props.width }}
                        id={"combobox"}
                        data={filter ? filterBy(selCode, filter) : selCode}
                        textField="DisplayString"
                        dataItemKey="code"
                        value={selected}
                        onChange={onChange}
                        clearButton={false}
                        suggest={true}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                    />

            }
        </>

    )

}