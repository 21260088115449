import { Suspense, useEffect, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import axios from 'axios'
import React from "react";
import { requestDialog, CRequestFormCodeData, RequestData, hintDialogData, CRequestMaterial, CRequest, OrderProvider, SystemUser, requestDialogData, matDialogData, ctDataType, MULTI_SELECT_TABLES, reqProvDataType, COneRequest, emptyRequest } from "../../types";
import UserSession from "../UserSession";
import { distinct } from "@progress/kendo-data-query";
import { Checkbox, Input, NumericTextBox, TextBox } from "@progress/kendo-react-inputs";
import { Splitter, SplitterOnChangeEvent, Stepper, StepperChangeEvent, TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { DialogTitleBar, formatDate, formatTime, LabelBold, LabelBoldBigText, loadFromStorage, mui, TabTitle } from "../../Helper";
import { Loader } from "@progress/kendo-react-indicators";
import Splash from "../Misc/Splash";

import { ComboBoxChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import MultiSelectList, { multiSelectProps } from "../ModalForms/MultiSelect/MultiSelectList";
import { Tooltip } from "@progress/kendo-react-tooltip";

import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";

const HintDialog = React.lazy(() => import('../RequestEntry/HintDialog'));
const MaterialDialog = React.lazy(() => import('../RequestEntry/MaterialDialog'));

const RequestDialog = (props: requestDialog) => {
    const anchor = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const filterElements = (element: any) => {
        if (element.tagName === "STRONG") {
            return true;
        }
        return false;
    };
    const onClick = React.useCallback(() => {
        setShow(!show);
    }, [setShow, show]);

    const thStyle = {
        color: "#528aae",
    };

    const [panes, setPanes] = React.useState<Array<any>>([
        { size: "400", min: "400", collapsible: false, resizable: false },
        { collapsible: false, resizable: false },
    ]);

    const columnsOrderProvider = [
        { field: "OrdProvCode", header: mui(userRights, "common", "lblCode", "Code"), width: "100px" },
        { field: "DisplayName", header: mui(userRights, "common", "lblDescription", "Description"), width: "200px" },
    ];

    const itemsStepper = [
        { label: mui(userRights, "requestentry", "lblParamSel", "Param List"), icon: "k-i-list-unordered" },
        { label: mui(userRights, "requestentry", "lblRequestData", "Request Data"), icon: "k-i-track-changes" }
    ];

    const CT_COMPONENT_GROUP = "609"
    const CT_NOTIFY_QUESTION = "229"
    const CT_YN_CODE = "101"

    const emptyMatDialogData: matDialogData = {
        showDialog: false,
        objMat: {
            sMandantID: "",
            sReqID: "",
            sMaterialID: "",
            sInLaborYN: "",
            sExtractionDate: "",
            sExtractionTime: "",
            sExtractionType: "",
            sCollectedDuration: "",
            sCollectedAmount: "",
            sMaterialQuality: "",
            sExtractionPlace: "",
            sInactiveYN: "",
            sExtractionPlaceText: "",
            sTransportMedium: "",
            objRecUpd: {
                sModifiedBy: "",
                sModifiedDate: "",
                sModifiedTime: "",
                sRecordedBy: "",
                sRecordedDate: "",
                sRecordedTime: "",
                sDelete: "",
                sInActive: ""
            },

            sEditReason: "",
            sExtTimeEmptyYN: "",
            sMatInLabDate: "",
            sMatInLabTime: "",
            sCollectorID: "",
            sMatAcceptedBy: "",
            sMatPicture: "",

            //new?
            sSerothekPos: "",
            sBloodTakenInLabYN: "",
            sMatScannedOn: "",

            //these fields are not present in table.
            sMaterialDesc: "",
            sReqDate: "",
            sReqTime: "",
            sReqProvider: "",
            sReqComment: "",
            sMacOrHand: "",
            sPregWeek: "",
            sCopyToAddr: "",
            sCopyToOrdProv: "",
            sReqProviderAddn: "",
            sReqExtAddress: "",
            sHandProfCount: "",
            sShortName: "",
            sShortNameMedVal: "",
            sFID: "",
            bSetDirtyFlag: false,
            sReqNr: "",
            sAdrRemark2: "",
            sAnticoagYN: "",
            sHeparin: "",
            sDiagnose: "",
            sReqExtCaseNr: "",
            sDiagnoseCode: "",
            sReqMandantID: "",
            sDoctorName: "",
            sCollectedDurationDialysat: "",
            sCollectedAmountDialysat: "",

            bUpdate: false,
        }
    }

    const emptyRequestMaterial: CRequestMaterial = {
        sMandantID: "",
        sReqID: "",
        sMaterialID: "",
        sInLaborYN: "",
        sExtractionDate: "",
        sExtractionTime: "",
        sExtractionType: "",
        sCollectedDuration: "",
        sCollectedAmount: "",
        sMaterialQuality: "",
        sExtractionPlace: "",
        sInactiveYN: "",
        sExtractionPlaceText: "",
        sTransportMedium: "",
        objRecUpd: {
            sModifiedBy: "",
            sModifiedDate: "",
            sModifiedTime: "",
            sRecordedBy: "",
            sRecordedDate: "",
            sRecordedTime: "",
            sDelete: "",
            sInActive: ""
        },

        sEditReason: "",
        sExtTimeEmptyYN: "",
        sMatInLabDate: "",
        sMatInLabTime: "",
        sCollectorID: "",
        sMatAcceptedBy: "",
        sMatPicture: "",

        //new?
        sSerothekPos: "",
        sBloodTakenInLabYN: "",
        sMatScannedOn: "",

        //these fields are not present in table.
        sMaterialDesc: "",
        sReqDate: "",
        sReqTime: "",
        sReqProvider: "",
        sReqComment: "",
        sMacOrHand: "",
        sPregWeek: "",
        sCopyToAddr: "",
        sCopyToOrdProv: "",
        sReqProviderAddn: "",
        sReqExtAddress: "",
        sHandProfCount: "",
        sShortName: "",
        sShortNameMedVal: "",
        sFID: "",
        bSetDirtyFlag: false,
        sReqNr: "",
        sAdrRemark2: "",
        sAnticoagYN: "",
        sHeparin: "",
        sDiagnose: "",
        sReqExtCaseNr: "",
        sDiagnoseCode: "",
        sReqMandantID: "",
        sDoctorName: "",
        sCollectedDurationDialysat: "",
        sCollectedAmountDialysat: "",

        bUpdate: false,
    }

    const [selectedRequest, setSelectedRequest] = useState<COneRequest | null>(null);

    /* Interfaces */
    interface IMatDescTT {
        reqMat: CRequestMaterial,
        colIndex: number
    };

    interface IParamTitle {
        reqData: RequestData,
        colIndex: number
    };

    interface IParamDisplayName {
        reqData: RequestData,
        colIndex: number,
        sKey: string
    };

    interface IParamCheckBox {
        reqData: RequestData,
        colIndex: number,
        sKey: string
    };

    interface IReqEntryTab {
        children: never[],
        content: CRequestFormCodeData | never,
        colIndex: number
    };

    interface IReqEntryTabContent {
        children: never[],
        content: RequestData[] | never[],
        colIndex: number
    };

    /* Interfaces */

    /* States objects */

    const [stepperValue, setStepperValue] = React.useState<number>(0);
    const [visible, setVisible] = useState<boolean>(props.data.showDialog);
    const [isLoading, setIsLoading] = useState({ loading: false, message: "" });
    const [opOptions, setOpOptions] = useState<OrderProvider[]>([]);

    var patinfo = {
        mandantid: "",
        pid: "",
        fid: "",
        reqid: "",
        name: "",
        firstname: "",
        birthdate: "",
        gender: "",
        genderdesc: "",
        deptcode: "",
        deptcodedesc: "",
        bloodbroup: "",
        rhesus: "",
        phenotype: "",
        antibody: "",
        dat: "",
        edtaphenomenayn: "",
    };

    const [patdata, setPatData] = useState(patinfo);
    const [reqdata, setReqData] = useState<CRequest>(emptyRequest);
    const [selParamMatData, setselParamMatData] = useState<CRequestMaterial[]>([]);
    const [SelParamData, setSelParamData] = useState<RequestData[]>([]);
    const [ReqEntryData, setReqEntryData] = useState<CRequestFormCodeData[]>([]);
    const [selectedtab, setSelectedtab] = useState<number>(0);
    const [reqDate, setReqDate] = useState(formatDate(new Date().toDateString()));
    const [reqTime, setReqTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [RhesusProphylaxeDate, setRhesusProphylaxeDate] = React.useState<string | "">("");
    const [ExternTransfusionDate, setExternTransfusionDate] = React.useState<string | "">("");
    const [reqProvData, setReqProvData] = useState<reqProvDataType>({ MandantID: '', OrdProvCode: '', Name: '', FirstName: '' });
    // for hint dialog
    const [hintDialogData, setHintDialogData] = useState<hintDialogData>({ NameOnRep: '', HintDocument: '', HintText: '', HintWebsite: '', showDialog: false });
    const [matData, setMatDialogData] = useState<matDialogData>(emptyMatDialogData);

    const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }

    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);

    /* States objects */

    /* UI */

    const ParamTitle = (props: IParamTitle) => {
        useEffect(() => {

        })

        return (
            <div>

                {
                    //props.reqData.sTitleYN === '1' && props.reqData.sElementCat != '7'   {' ' + (props.reqData.sTitleName === '' ? props.reqData.sNameOnRep : props.reqData.sTitleName)}
                    props.reqData.sTitleYN === '1' && props.reqData.sElementCat === '7' ? <ParamCheckbox reqData={props.reqData} colIndex={props.colIndex} sKey={'tchk' + props.reqData.sCheckBoxID}></ParamCheckbox> :
                        props.reqData.sTitleYN === '1' && props.reqData.sGroupHeaderYN === '1' ? <><div style={{ backgroundColor: props.reqData.sColor }} ><Button id={'btn' + props.reqData.sCheckBoxID} icon={props.reqData.sIcon} style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, width: '20px', height: '20px' }}
                            onClick={(event: any) => handleClickGroupButton(props.reqData, props.colIndex)} ></Button>&nbsp;<Label style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, fontWeight: 'bold' }}> {' ' + (props.reqData.sTitleName === '' ? props.reqData.sNameOnRep : props.reqData.sTitleName)}</Label></div> </> :
                            <div style={{ backgroundColor: props.reqData.sColor }} ><Label style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, fontWeight: 'bold', fontFamily: 'Arial', fontSize: '13px' }}> {' ' + (props.reqData.sTitleName === '' ? props.reqData.sNameOnRep : props.reqData.sTitleName)}</Label> </div>
                }

                <div id={'dv' + props.reqData.sCheckBoxID} className={props.reqData.sIcon === 'minus' ? "" : "div-hidden"}>
                    {props.reqData.collGroupMembers.map((grpMembers: RequestData, index: number) => (
                        <React.Fragment key={grpMembers.nTabID + index + grpMembers.sCheckBoxID}>
                            {
                                <>
                                    <ParamCheckbox reqData={grpMembers} colIndex={props.colIndex} sKey={'chk' + grpMembers.sCheckBoxID}></ParamCheckbox>
                                    {/*<ParamDisplayName reqData={grpMembers} colIndex={props.colIndex} ></ParamDisplayName>*/}
                                </>
                            }
                        </React.Fragment>
                    ))}
                </div>
            </div>
        )
    }

    const ParamDisplayName = (props: IParamDisplayName) => {

        //console.log(props)
        if (props.reqData.sLTIURL !== '') {
            //console.log("sLTIURL")
            return (<a target="_blank" href={props.reqData.sLTIURL} style={{ color: "black", backgroundColor: props.reqData.sColor, fontFamily: 'Arial', fontSize: '13px', height: '20px' }}>{' ' + props.reqData.sNameOnRep}</a>)
        }
        else if (props.reqData.sEnterResultYN === '1' && props.reqData.sNumericResultYN !== '1') {
            //console.log("props.reqData.sEnterResultYN === '1' && props.reqData.sNumericResultYN !== '1'")
            return (<><Label style={{ fontFamily: 'Arial', fontSize: '13px', height: '20px' }} editorId={'lbl' + props.sKey}> {' ' + props.reqData.sNameOnRep}</Label><br /><TextBox style={{ border: '1px solid darkgrey', width: '200px' }} id={'txt' + props.sKey} disabled={props.reqData.bDisabled} /></>)
        }
        else if (props.reqData.sEnterResultYN === '1' && props.reqData.sNumericResultYN === '1') {
            //console.log("props.reqData.sEnterResultYN === '1' && props.reqData.sNumericResultYN === '1'")
            return (<><Label style={{ fontFamily: 'Arial', fontSize: '13px', height: '20px' }} editorId={'lbl' + props.sKey}> {' ' + props.reqData.sNameOnRep}</Label><br />
                <NumericTextBox style={{ border: '1px solid darkgrey', width: '200px' }} width={300} id={'txt' + props.sKey} disabled={props.reqData.bDisabled} format="n2" step={props.reqData.nIncrementBy} min={props.reqData.nStartValue} max={props.reqData.nNrOfOccurences} defaultValue={props.reqData.nStartValue} /></>)
        }
        else if (props.reqData.sTitleYN === '1') {
            //console.log("props.reqData.sTitleYN === '1'")
            return (<Label style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, fontWeight: 'bold', fontFamily: 'Arial', fontSize: '13px', height: '20px' }} editorId={'lbl' + props.sKey}>{' ' + (props.reqData.sTitleName === '' ? props.reqData.sNameOnRep : props.reqData.sTitleName)}</Label>)
        }
        else {
            //console.log("else")
            return (<Label style={{ fontFamily: 'Arial', fontSize: '13px', height: '20px' }} editorId={'lbl' + props.sKey}>{' ' + (props.reqData.sTitleName === '' ? props.reqData.sNameOnRep : props.reqData.sTitleName)}</Label>)
        }
    }

    const ParamCheckbox = (props: IParamCheckBox) => {

        return (
            <div style={{ backgroundColor: props.reqData.sColor }} >

                <>
                    {props.reqData.sHintText !== '' || props.reqData.sHintWebsite !== '' || props.reqData.sHintDocument !== '' ?
                        <Button id={'btnhint' + props.sKey} key={'btnhint' + props.sKey}
                            style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, width: '20px', height: '20px', borderColor: props.reqData.sColor }}
                            onClick={(event: any) => handleClickHintButton(event, props.reqData, props.colIndex)}
                            icon="information" ></Button>
                        : props.reqData.sElementCat === '7' && (props.reqData.sGroupHeaderYN === '1' || props.reqData.sTitleYN === '1') ? '' : <Button id={'btnhint' + props.sKey} key={'btnhint' + props.sKey}
                            style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, width: '20px', height: '20px', borderColor: props.reqData.sColor }}
                            disabled={true}
                        ></Button>}
                    {props.reqData.sElementCat === '7' && (props.reqData.sGroupHeaderYN === '1') ? <Button id={'btn' + props.reqData.sCheckBoxID} icon={props.reqData.sIcon} style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, width: '20px', height: '20px' }} onClick={(event: any) => handleClickGroupButton(props.reqData, props.colIndex)} ></Button> : ''}
                    {(props.reqData.sNameOnRep !== '' || props.reqData.sTitleName !== '') && props.reqData.sElementCat !== '6' ?
                        <Button id={props.sKey} disabled={props.reqData.bDisabled} key={'bchk' + props.sKey}
                            style={{ marginTop: 5, marginBottom: 5, backgroundColor: props.reqData.sColor, width: '35px', height: '20px', borderColor: props.reqData.sColor }}
                            onClick={(event: any) => handleCheckboxButton(event, props.reqData, props.colIndex)}
                            icon={SelParamData.some((el: RequestData) => el.sCheckBoxID === props.reqData.sCheckBoxID) || props.reqData.bCheckBoxValue === true ? "checkbox-checked" : "checkbox"} ></Button>
                        //<Checkbox
                        //    disabled={props.reqData.bDisabled}
                        //    size="large"
                        //    key={props.sKey}
                        //    id={props.sKey}
                        //    onChange={(event: CheckboxChangeEvent) => handleGroupCheckbox(event, props.reqData, props.colIndex)}
                        //    checked={SelParamData.some((el: RequestData) => el.sCheckBoxID === props.reqData.sCheckBoxID) || props.reqData.bCheckBoxValue === true} />
                        : ''}
                    <ParamDisplayName reqData={props.reqData} colIndex={props.colIndex} sKey={props.sKey}></ParamDisplayName>
                </>
            </div>
        )
    }

    const ReqEntryTabContent = (props: IReqEntryTabContent) => {
        useEffect(() => {
            // console.log('ReqEntryTabContent: refresh');
        })

        return (
            <React.Fragment>
                {props.content.map((value: RequestData, index: number) => (
                    <React.Fragment key={'tab' + index}>
                        {
                            value.sGroupHeaderYN === '1' || value.sTitleYN === '1' ? <ParamTitle reqData={value} colIndex={props.colIndex}></ParamTitle> :
                                <ParamCheckbox reqData={value} colIndex={props.colIndex} sKey={'pchk' + value.sParamID}></ParamCheckbox>
                        }
                    </React.Fragment>
                ))}
            </React.Fragment>
        )
    }

    const ReqEntryTab = (props: IReqEntryTab) => {
        useEffect(() => {
            // console.log('ReqEntryTabContent: refresh');
        })

        var dlgHeight: number = window.innerHeight - 100;
        var paneHeight: number = dlgHeight - 260;
        var tabStripHeight: number = paneHeight - 200;
        var criteriaDivWidth: number = dlgWidth - 30;
        var rightArea: number = (20 / 100) * criteriaDivWidth;
        var leftArea: number = criteriaDivWidth - rightArea;

        var tab: CRequestFormCodeData = props.content
        if (props.colIndex === selectedtab) {
            return (
                <React.Fragment key={'maintab' + props.colIndex + tab.sFormCode} >
                    <div style={{ height: tabStripHeight, width: leftArea-30, overflow: 'auto', padding: 0, border: '0px solid red' }} className="grid-container-5-req-entry">
                        <div style={{ display: tab.col1.length > 0 ? 'block' : 'none', width: tab.col1.length > 0 ? (tabStripItemWidth / tab.nNoCols) : 0, padding: 0 }} className="grid-item">
                            <ReqEntryTabContent content={tab.col1} colIndex={1}>
                            </ReqEntryTabContent>
                        </div>
                        <div style={{ display: tab.col2.length > 0 ? 'block' : 'none', width: tab.col2.length > 0 ? (tabStripItemWidth / tab.nNoCols) : 0, padding: 0 }} className="grid-item">
                            <ReqEntryTabContent content={tab.col2} colIndex={2}>
                            </ReqEntryTabContent>
                        </div>
                        <div style={{ display: tab.col3.length > 0 ? 'block' : 'none', width: tab.col3.length > 0 ? (tabStripItemWidth / tab.nNoCols) : 0, padding: 0 }} className="grid-item">
                            <ReqEntryTabContent content={tab.col3} colIndex={3}>
                            </ReqEntryTabContent>
                        </div>
                        <div style={{ display: tab.col4.length > 0 ? 'block' : 'none', width: tab.col4.length > 0 ? (tabStripItemWidth / tab.nNoCols) : 0, padding: 0 }} className="grid-item">
                            <ReqEntryTabContent content={tab.col4} colIndex={4}>
                            </ReqEntryTabContent>
                        </div>
                        <div style={{ display: tab.col5.length > 0 ? 'block' : 'none', width: tab.col5.length > 0 ? (tabStripItemWidth / tab.nNoCols) : 0, padding: 0 }} className="grid-item">
                            <ReqEntryTabContent content={tab.col5} colIndex={5}>
                            </ReqEntryTabContent>
                        </div>
                    </div>
                </React.Fragment>


            );
        }
        else {
            return (
                <React.Fragment key={'maintab' + props.colIndex} >
                    <div style={{ height: tabStripHeight, width: 'calc(100vw - 425px)', overflowY: 'scroll', padding: 0, border: '0px solid red' }} className="grid-container-5-req-entry"></div>
                </React.Fragment>
            );
        }

    }
    /* UI */

    /* Functions */
    function CheckMandatoryData() {
        var bok: boolean = true;
        var sMsg: string = ""
        if (reqDate == null) {

            sMsg: mui(userRights, "common", "msgEnterReqDate", "Enter Req Date")
            alert(sMsg);
            //mandantRef.current?.focus();
            bok = false
        }
        else if (reqTime == null) {
            sMsg: mui(userRights, "common", "msgEnterReqTime", "Enter Req Time")
            alert(sMsg);
            //mandantRef.current?.focus();
            bok = false
        }
        else if (reqdata.reqprov.OrdProvCode == '') {
            sMsg: mui(userRights, "common", "msgEnterReqProv", "Enter Req Prov")
            alert(sMsg);
            bok = false
        }
        return bok;
    };

    const TooltipContentTemplate = (props: any) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: props.title }} />
        );
    }

    const CustomCellMatForTT = (props: IMatDescTT) => {

        const value = props.reqMat.sMaterialDesc;
        var tttext = '<b>' + props.reqMat.sMaterialID + ' ' + props.reqMat.sMaterialDesc + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblExtrDate", "Extr. date") + ': <b>' + props.reqMat.sExtractionDate + ' ' + props.reqMat.sExtractionTime + '</b><br />' + mui(userRights, "dlg-mat", "lblCollectorID", "Collector ID") + ': <b>' + props.reqMat.sCollectorID + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblBloodTakenInLabYN", "BloodTakenInLabYN") + ': <b>' + props.reqMat.sBloodTakenInLabYN + '</b><br />' + mui(userRights, "dlg-mat", "lblInLaborYN", "InLaborYN") + ': <b>' + props.reqMat.sInLaborYN + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblMaterialQuality", "MaterialQuality") + ': <b>' + props.reqMat.sMaterialQuality + '</b><br />' + mui(userRights, "dlg-mat", "lblMatScannedOn", "MatScannedOn") + ': <b>' + props.reqMat.sMatScannedOn + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblMatAcceptedBy", "MatAcceptedBy") + ': <b>' + props.reqMat.sMatAcceptedBy + '</b><br />' + mui(userRights, "dlg-mat", "lblExtractionType", "ExtractionType") + ': <b>' + props.reqMat.sExtractionType + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblExtractionPlace", "ExtractionPlace") + ': <b>' + props.reqMat.sExtractionPlace + '</b><br />' + mui(userRights, "dlg-mat", "lblExtractionPlaceText", "ExtractionPlaceText") + ': <b>' + props.reqMat.sExtractionPlaceText + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblCollectedDuration", "CollectedDuration") + ': <b>' + props.reqMat.sCollectedDuration + '</b><br />' + mui(userRights, "dlg-mat", "lblCollectedAmount", "CollectedAmount") + ': <b>' + props.reqMat.sCollectedAmount + '</b><br />';
        tttext = tttext + mui(userRights, "dlg-mat", "lblSerothekPos", "SerothekPos") + ': <b>' + props.reqMat.sSerothekPos + '</b><br />' + mui(userRights, "dlg-mat", "lblTransportMedium", "TransportMedium") + ': <b>' + props.reqMat.sTransportMedium + '</b><br />';
        //const bgcolor = props.dataItem['emergencycolor'];
        return (
            <div title={tttext}>{value}</div>
            //<td style={{ backgroundColor: "white" }} title={tttext}>
            //    {value}
            //</td>
        );
    };

    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {
        if (cancel !== true) {

            if (multiSelectTable === "DiagnoseCode")
                setReqData(prevData => ({
                    ...prevData,
                    sDiagnoseCode: savedCodes
                }))
            else if (multiSelectTable === "OrderProvider")
                setReqData(prevData => ({
                    ...prevData,
                    sCopyToOrdProv: savedCodes
                }))

        }

        setMultiSelectData(dlgMSEmptyState);
    }

    const openMultiSelForm = (multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        if (multiSelectTable === "DiagnoseCode")
            selectedValues = reqdata.sDiagnoseCode;
        else if (multiSelectTable === "OrderProvider")
            selectedValues = reqdata.sCopyToOrdProv;

        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved
        });
    };

    // refresh the order provider dropdown if mandant changes or on initial load
    function refreshOrderProviders() {
        var opOptions = JSON.parse(loadFromStorage("orderProvider")!);
        var binddata: OrderProvider[] = [];
        opOptions.forEach((o: OrderProvider) => {
            binddata.push({ ...o, DisplayName: o.Name + (o.FirstName !== '' ? '(' + o.FirstName + ')' : '') });
        });
        setOpOptions(binddata);
    }

    const PopulateSelParamList = (binddata: RequestData[], props: RequestData, bChecked: boolean, nCollIndex: number, nTab: number, nSubCollIndex: number) => {

        //console.log(props, bChecked)
        //const newData = updateItem(props, sCollIndex, props.sIcon);   
        //return;
        var tabElement: RequestData;

        if (bChecked === true) { // something checked so add it to the list
            if (nSubCollIndex === -1) {
                if (nCollIndex === 1) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col1) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 2) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col2) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 3) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col3) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 4) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col4) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 5) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col5) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }

            }
            else {
                if (nCollIndex === 1) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col1[nSubCollIndex].collGroupMembers) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 2) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col2[nSubCollIndex].collGroupMembers) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 3) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col3[nSubCollIndex].collGroupMembers) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 4) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col4[nSubCollIndex].collGroupMembers) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }
                else if (nCollIndex === 5) {
                    for (tabElement of ReqEntryData.slice(nTab, nTab + 1)[0].col5[nSubCollIndex].collGroupMembers) {
                        if (tabElement.sCheckBoxID === props.sCheckBoxID) {
                            binddata.push({ ...tabElement });
                            break;
                        }
                    }
                }

            }

        }
        else {

            binddata.map((item, index) => {
                if (props.sCheckBoxID === item.sCheckBoxID) {

                    binddata.splice(index, 1);
                }
            });
        }
        return binddata
    }

    const ProcessParamForInBlockLinkedAllowOnlyOne = (binddata: RequestData[], props: RequestData, bChecked: boolean) => {
        //var binddata: RequestData[] = [...SelParamData];
        var arrParamsInBlock = props.sParamsInBlock.split("#");
        var arrLinkedParams = props.sLinkedParams.split("#");
        var arrAllowOnlyOneParamFromGroup = props.sAllowOnlyOneParamFromGroup.split("#");
        //console.log("process", props, binddata);
        //console.log("pb", props.sParamsInBlock);
        //console.log("lp", props.sLinkedParams);
        //console.log("aop", props.sAllowOnlyOneParamFromGroup);
        var sChecked: string
        var bDisabled: boolean

        if (bChecked === true) {
            bDisabled = false;
            sChecked = "1";
        }
        else {
            bDisabled = true;
            sChecked = "0";
        }

        //'method 1'
        if (ReqEntryData.length >= 1) {
            for (let nTab = 0; nTab < ReqEntryData.length; nTab++) {
                const obj: CRequestFormCodeData[] = ReqEntryData.slice(nTab, nTab + 1)
                //console.log(obj)
                //block params
                if (props.sParamsInBlock !== '') {

                    for (let i = 0; i < arrParamsInBlock.length; i++) {

                        var arrParamsInBlockDetail = arrParamsInBlock[i].split("$");

                        if (arrParamsInBlockDetail[0] !== '') {
                            //console.log("length", arrParamsInBlockDetail.length)
                            if (obj === undefined) return;

                            var selObj = null;

                            if (obj.length > 0)
                                selObj = obj[0];

                            if (selObj === null) return;

                            let index1 = selObj.col1.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2]);
                            if (index1 !== -1) {
                                //console.log("1", selObj)
                                UpdateForInBlockParams(index1, obj, binddata, bChecked, 1, nTab, -1)

                            }
                            else {
                                for (let ncollGrpMem = 0; ncollGrpMem < selObj.col1.length; ncollGrpMem++) {
                                    index1 = selObj.col1[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2])
                                    if (index1 !== -1) {
                                        //console.log("1.0")
                                        UpdateForInBlockParams(index1, obj, binddata, bChecked, 1, nTab, ncollGrpMem)
                                        //console.log("1.1", selObj)
                                        break;
                                    }
                                }
                            }
                            let index2 = selObj.col2.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2]);
                            if (index2 !== -1) {
                                UpdateForInBlockParams(index2, obj, binddata, bChecked, 2, nTab, -1)
                                //console.log("2", selObj)
                            }
                            else {
                                for (let ncollGrpMem = 0; ncollGrpMem < selObj.col2.length; ncollGrpMem++) {
                                    index2 = selObj.col2[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2])
                                    if (index2 !== -1) {
                                        //console.log("2.0")
                                        UpdateForInBlockParams(index2, obj, binddata, bChecked, 2, nTab, ncollGrpMem)
                                        //console.log("2.1", selObj)
                                        break;
                                    }
                                }
                            }
                            let index3 = selObj.col3.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2]);
                            if (index3 !== -1) {
                                //console.log("3.0", obj, selObj, "all")
                                UpdateForInBlockParams(index3, obj, binddata, bChecked, 3, nTab, -1)
                                //console.log("3", selObj)
                            }
                            else {
                                for (let ncollGrpMem = 0; ncollGrpMem < selObj.col3.length; ncollGrpMem++) {
                                    index3 = selObj.col3[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2])
                                    if (index3 !== -1) {
                                        //console.log("3.0", obj, selObj, "all")
                                        UpdateForInBlockParams(index3, obj, binddata, bChecked, 3, nTab, ncollGrpMem)
                                        //console.log("3.1", selObj)
                                        break;
                                    }
                                }
                            }
                            let index4 = selObj.col4.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2]);
                            if (index4 !== -1) {
                                UpdateForInBlockParams(index4, obj, binddata, bChecked, 4, nTab, -1)
                                ////console.log("4", selObj)
                            }
                            else {
                                for (let ncollGrpMem = 0; ncollGrpMem < selObj.col4.length; ncollGrpMem++) {
                                    index4 = selObj.col4[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2])
                                    if (index4 !== -1) {
                                        /*console.log("1")*/
                                        UpdateForInBlockParams(index4, obj, binddata, bChecked, 4, nTab, ncollGrpMem)
                                        //console.log("1.1", selObj)
                                        break;
                                    }
                                }
                            }
                            let index5 = selObj.col5.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2]);
                            if (index5 !== -1) {
                                UpdateForInBlockParams(index5, obj, binddata, bChecked, 5, nTab, -1)
                                ////console.log("5", selObj)
                            }
                            else {
                                for (let ncollGrpMem = 0; ncollGrpMem < selObj.col5.length; ncollGrpMem++) {
                                    index5 = selObj.col5[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrParamsInBlockDetail[0] && record.sFormCode === arrParamsInBlockDetail[2])
                                    if (index5 !== -1) {
                                        /*console.log("1")*/
                                        UpdateForInBlockParams(index5, obj, binddata, bChecked, 5, nTab, ncollGrpMem)
                                        //console.log("1.1", selObj)
                                        break;
                                    }
                                }
                            }
                        } //if paraminblockdetail[0], i.e paramid <>''

                    } //for arrParamsInBlock
                } //if block param <> ''
                //block params

                //linked params
                if (props.sLinkedParams !== '') {
                    for (let i = 0; i < arrLinkedParams.length; i++) {

                        var arrLinkedParamsDetail = arrLinkedParams[i].split("$");

                        if (obj === undefined) return;

                        var tempObj = null;

                        if (obj.length > 0)
                            tempObj = obj[0];

                        if (tempObj === null) return;

                        //console.log("linkpar", arrLinkedParamsDetail, tempObj)

                        let index1 = tempObj.col1.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1]);
                        if (index1 !== -1) {
                            UpdateForLinkedParams(tempObj.col1[index1].sAllowOnlyOneParamFromGroup, index1, obj, binddata, bChecked, bDisabled, 1, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col1.length; ncollGrpMem++) {
                                index1 = tempObj.col1[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1])
                                if (index1 !== -1) {
                                    /*console.log("1")*/
                                    UpdateForLinkedParams(tempObj.col1[ncollGrpMem].sAllowOnlyOneParamFromGroup, index1, obj, binddata, bChecked, bDisabled, 1, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index2 = tempObj.col2.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1]);
                        if (index2 !== -1) {
                            ////console.log("2")
                            UpdateForLinkedParams(tempObj.col2[index2].sAllowOnlyOneParamFromGroup, index2, obj, binddata, bChecked, bDisabled, 2, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col2.length; ncollGrpMem++) {
                                index2 = tempObj.col2[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1])
                                if (index2 !== -1) {
                                    ////console.log("1")
                                    UpdateForLinkedParams(tempObj.col2[ncollGrpMem].sAllowOnlyOneParamFromGroup, index2, obj, binddata, bChecked, bDisabled, 2, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index3 = tempObj.col3.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1]);
                        if (index3 !== -1) {
                            ////console.log("3")
                            UpdateForLinkedParams(tempObj.col3[index3].sAllowOnlyOneParamFromGroup, index3, obj, binddata, bChecked, bDisabled, 3, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col3.length; ncollGrpMem++) {
                                index3 = tempObj.col3[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1])
                                if (index3 !== -1) {
                                    ////console.log("1")
                                    UpdateForLinkedParams(tempObj.col3[ncollGrpMem].sAllowOnlyOneParamFromGroup, index3, obj, binddata, bChecked, bDisabled, 3, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index4 = tempObj.col4.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1]);
                        if (index4 !== -1) {
                            ////console.log("4")
                            UpdateForLinkedParams(tempObj.col4[index4].sAllowOnlyOneParamFromGroup, index4, obj, binddata, bChecked, bDisabled, 4, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col4.length; ncollGrpMem++) {
                                index4 = tempObj.col4[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1])
                                if (index4 !== -1) {
                                    ////console.log("1")
                                    UpdateForLinkedParams(tempObj.col4[ncollGrpMem].sAllowOnlyOneParamFromGroup, index4, obj, binddata, bChecked, bDisabled, 4, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index5 = tempObj.col5.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1]);
                        if (index5 !== -1) {
                            ////console.log("5")
                            UpdateForLinkedParams(tempObj.col5[index5].sAllowOnlyOneParamFromGroup, index5, obj, binddata, bChecked, bDisabled, 5, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col5.length; ncollGrpMem++) {
                                index5 = tempObj.col5[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrLinkedParamsDetail[0] && record.sResult === arrLinkedParamsDetail[1])
                                if (index5 !== -1) {
                                    ////console.log("1")
                                    UpdateForLinkedParams(tempObj.col5[ncollGrpMem].sAllowOnlyOneParamFromGroup, index5, obj, binddata, bChecked, bDisabled, 5, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }
                    }//for arrLinkedParams
                }//if linked param <> ''
                //linked params

                //allow only one params
                if (props.sAllowOnlyOneParamFromGroup !== '') {
                    for (let i = 0; i < arrAllowOnlyOneParamFromGroup.length; i++) {

                        var arrAllowOnlyOneParamFromGroupDetail = arrAllowOnlyOneParamFromGroup[i].split("$");
                        var bOneSelected: boolean

                        bOneSelected = IsAnyParamFromAllowOnlyOneListSelected(props.sAllowOnlyOneParamFromGroup, binddata)

                        if (obj === undefined) return;

                        var tempObj = null;

                        if (obj.length > 0)
                            tempObj = obj[0];

                        if (tempObj === null) return;

                        //console.log("linkpar", arrAllowOnlyOneParamFromGroupDetail, tempObj)

                        let index1 = tempObj.col1.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1]);
                        if (index1 !== -1) {
                            UpdateForAllowOnlyOneParam(index1, obj, bOneSelected, bChecked, bDisabled, 1, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col1.length; ncollGrpMem++) {
                                index1 = tempObj.col1[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1])
                                if (index1 !== -1) {
                                    /*console.log("1")*/
                                    UpdateForAllowOnlyOneParam(index1, obj, bOneSelected, bChecked, bDisabled, 1, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index2 = tempObj.col2.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1]);
                        if (index2 !== -1) {
                            ////console.log("2")
                            UpdateForAllowOnlyOneParam(index2, obj, bOneSelected, bChecked, bDisabled, 2, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col2.length; ncollGrpMem++) {
                                index2 = tempObj.col2[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1])
                                if (index2 !== -1) {
                                    ////console.log("1")
                                    UpdateForAllowOnlyOneParam(index2, obj, bOneSelected, bChecked, bDisabled, 2, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index3 = tempObj.col3.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1]);
                        if (index3 !== -1) {
                            ////console.log("3")
                            UpdateForAllowOnlyOneParam(index3, obj, bOneSelected, bChecked, bDisabled, 3, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col3.length; ncollGrpMem++) {
                                index3 = tempObj.col3[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1])
                                if (index3 !== -1) {
                                    ////console.log("1")
                                    UpdateForAllowOnlyOneParam(index3, obj, bOneSelected, bChecked, bDisabled, 3, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index4 = tempObj.col4.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1]);
                        if (index4 !== -1) {
                            ////console.log("4")
                            UpdateForAllowOnlyOneParam(index4, obj, bOneSelected, bChecked, bDisabled, 4, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col4.length; ncollGrpMem++) {
                                index4 = tempObj.col4[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1])
                                if (index4 !== -1) {
                                    ////console.log("1")
                                    UpdateForAllowOnlyOneParam(index4, obj, bOneSelected, bChecked, bDisabled, 4, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }

                        let index5 = tempObj.col5.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1]);
                        if (index5 !== -1) {
                            ////console.log("5")
                            UpdateForAllowOnlyOneParam(index5, obj, bOneSelected, bChecked, bDisabled, 5, nTab, -1)
                        }
                        else {
                            for (let ncollGrpMem = 0; ncollGrpMem < tempObj.col5.length; ncollGrpMem++) {
                                index5 = tempObj.col5[ncollGrpMem].collGroupMembers.findIndex((record: RequestData) => record.sParamID === arrAllowOnlyOneParamFromGroupDetail[0] && record.sResult === arrAllowOnlyOneParamFromGroupDetail[1])
                                if (index5 !== -1) {
                                    ////console.log("1")
                                    UpdateForAllowOnlyOneParam(index5, obj, bOneSelected, bChecked, bDisabled, 5, nTab, ncollGrpMem)
                                    break;
                                }
                            }
                        }
                    }//for arrLinkedParams
                }//if AllowOnlyOneParamFromGroup param <> ''
                //allow only one params
            }

            let arrayCopy = JSON.parse(JSON.stringify(ReqEntryData));
            setReqEntryData(arrayCopy)
            //if (props.sParamsInBlock !== '' || props.sLinkedParams !== '' || props.sAllowOnlyOneParamFromGroup !== '') {
            //    setSelParamData(binddata);
            //}
        }
        //'method 1'

    }

    const IsAnyParamFromAllowOnlyOneListSelected = (sAllowOnlyOneParamFromGroup: string, binddata: RequestData[]) => {

        var arrAllowOnlyOneParamFromGroup = sAllowOnlyOneParamFromGroup.split("#");

        if (arrAllowOnlyOneParamFromGroup.length > 1) {

            binddata.map((item, index) => {
                for (let i = 0; i < arrAllowOnlyOneParamFromGroup.length; i++) {
                    var arrAllowOnlyOneParamFromGroupDetail = arrAllowOnlyOneParamFromGroup[i].split("$");
                    if (arrAllowOnlyOneParamFromGroupDetail[0] !== '') {
                        if (arrAllowOnlyOneParamFromGroupDetail[0] === item.sParamID) {
                            //console.log("true")
                            return true;
                        }
                    }

                }
            });

        }
        //console.log("false")
        return false;
    }

    const UpdateForAllowOnlyOneParam = (index: number, obj: CRequestFormCodeData[], bOneSelected: boolean, bChecked: boolean, bDisabled: boolean, nCollIndex: number, nTab: number, nSubCollIndex: number) => {
        var bDisabled: boolean = bChecked === true ? false : true;

        if (nSubCollIndex === -1) {
            //console.log(nSubCollIndex)
            if (nCollIndex === 1) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col1[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col1[index].bDisabled = obj[0].col1[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 2) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col2[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col2[index].bDisabled = obj[0].col2[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 3) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col3[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col3[index].bDisabled = obj[0].col3[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 4) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col4[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col4[index].bDisabled = obj[0].col4[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 5) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col5[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col5[index].bDisabled = obj[0].col5[index].bNotSelectable === true ? true : false;
                }
            }
        }
        else {
            //console.log(nSubCollIndex, bDisabled, index)
            if (nCollIndex === 1) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col1[nSubCollIndex].collGroupMembers[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col1[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col1[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 2) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col2[nSubCollIndex].collGroupMembers[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col2[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col2[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 3) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col3[nSubCollIndex].collGroupMembers[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col3[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col3[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 4) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col4[nSubCollIndex].collGroupMembers[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col4[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col4[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : false;
                }
            }
            else if (nCollIndex === 5) {
                if (bOneSelected === true || bChecked === true) {
                    obj[0].col5[nSubCollIndex].collGroupMembers[index].bDisabled = true;
                }
                else {
                    //check later if disbaled for edit call
                    obj[0].col5[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col5[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : false;
                }
            }
        }

        ReqEntryData[nTab] = obj[0];
    }

    const UpdateForLinkedParams = (sAllowOnlyOneParamFromGroup: string, index: number, obj: CRequestFormCodeData[], binddata: RequestData[], bChecked: boolean, bDisabled: boolean, nCollIndex: number, nTab: number, nSubCollIndex: number) => {
        var sChecked: string
        var bDisabled: boolean

        if (bChecked === true) {
            bDisabled = false;
            sChecked = "1";
        }
        else {
            bDisabled = true;
            sChecked = "0";
        }
        if (nSubCollIndex === -1) {
            //console.log(nSubCollIndex)
            if (nCollIndex === 1) {
                obj[0].col1[index].bDisabled = obj[0].col1[index].bNotSelectable === true ? true : bDisabled;

                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col1[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }

            }
            else if (nCollIndex === 2) {
                obj[0].col2[index].bDisabled = obj[0].col2[index].bNotSelectable === true ? true : bDisabled;

                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col2[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }

            }
            else if (nCollIndex === 3) {
                obj[0].col3[index].bDisabled = obj[0].col3[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col3[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 4) {
                obj[0].col4[index].bDisabled = obj[0].col4[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col4[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 5) {
                obj[0].col5[index].bDisabled = obj[0].col5[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col5[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
        }
        else {
            //console.log(nSubCollIndex, bDisabled, index)
            if (nCollIndex === 1) {
                obj[0].col1[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col1[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : bDisabled;
                //console.log(obj[0].col1[nSubCollIndex].collGroupMembers[index].bDisabled)
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col1[nSubCollIndex].collGroupMembers[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 2) {
                obj[0].col2[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col2[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col2[nSubCollIndex].collGroupMembers[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 3) {
                obj[0].col3[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col3[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col3[nSubCollIndex].collGroupMembers[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 4) {
                obj[0].col4[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col4[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col4[nSubCollIndex].collGroupMembers[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
            else if (nCollIndex === 5) {
                obj[0].col5[nSubCollIndex].collGroupMembers[index].bDisabled = obj[0].col5[nSubCollIndex].collGroupMembers[index].bNotSelectable === true ? true : bDisabled;
                if (bDisabled === false) {
                    if (sAllowOnlyOneParamFromGroup !== '') {
                        //check if any of the param defined in sAllowOnlyOneParamFromGroup is aready selected.
                        //if already selected do not enable this param
                        obj[0].col5[nSubCollIndex].collGroupMembers[index].bDisabled = IsAnyParamFromAllowOnlyOneListSelected(sAllowOnlyOneParamFromGroup, binddata);
                    }
                }
            }
        }

        ReqEntryData[nTab] = obj[0];
    }

    const UpdateForInBlockParams = (index: number, obj: CRequestFormCodeData[], binddata: RequestData[], bCheckBoxValue: boolean, nCollIndex: number, nTab: number, nSubCollIndex: number) => {
        var sChecked: string
        var bDisabled: boolean

        if (bCheckBoxValue === true) {
            bDisabled = false;
            sChecked = "1";
        }
        else {
            bDisabled = true;
            sChecked = "0";
        }
        if (nSubCollIndex === -1) {
            if (nCollIndex === 1) {
                obj[0].col1[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col1[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 2) {
                obj[0].col2[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col2[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 3) {
                obj[0].col3[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col3[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 4) {
                obj[0].col4[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col4[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 5) {
                obj[0].col5[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col5[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
        }
        else {
            if (nCollIndex === 1) {
                //console.log("check 1", obj[0], nSubCollIndex, index)
                obj[0].col1[nSubCollIndex].collGroupMembers[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col1[nSubCollIndex].collGroupMembers[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 2) {
                obj[0].col2[nSubCollIndex].collGroupMembers[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col2[nSubCollIndex].collGroupMembers[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 3) {
                obj[0].col3[nSubCollIndex].collGroupMembers[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col3[nSubCollIndex].collGroupMembers[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 4) {
                obj[0].col4[nSubCollIndex].collGroupMembers[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col4[nSubCollIndex].collGroupMembers[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
            else if (nCollIndex === 5) {
                obj[0].col5[nSubCollIndex].collGroupMembers[index].bCheckBoxValue = bCheckBoxValue;
                PopulateSelParamList(binddata, obj[0].col5[nSubCollIndex].collGroupMembers[index], bCheckBoxValue, nCollIndex, nTab, nSubCollIndex)
            }
        }

        ReqEntryData[nTab] = obj[0];
    }

    const updateItem = (item: RequestData, columnIndex: number, newIcon: string) => {
        if (item === undefined) return;
        const obj: CRequestFormCodeData[] = ReqEntryData.slice(item.nTabID, item.nTabID + 1)

        //console.log(item, columnIndex, item.nTabID, ReqEntryData, ReqEntryData.slice(item.nTabID, item.nTabID+1))

        if (obj === undefined) return;
        var selObj = null;
        if (obj.length > 0)
            selObj = obj[0];
        if (selObj === null) return;
        item.sIcon = newIcon;
        if (columnIndex === 1) {
            let index = selObj.col1.findIndex((record: any) => record.sCheckBoxID === item.sCheckBoxID);
            selObj.col1[index] = item;
        }
        if (columnIndex === 2) {
            let index = selObj.col2.findIndex((record: any) => record.sCheckBoxID === item.sCheckBoxID);
            selObj.col2[index] = item;
        }
        if (columnIndex === 3) {
            let index = selObj.col3.findIndex((record: any) => record.sCheckBoxID === item.sCheckBoxID);
            selObj.col3[index] = item;
        }
        if (columnIndex === 4) {
            let index = selObj.col4.findIndex((record: any) => record.sCheckBoxID === item.sCheckBoxID);
            selObj.col4[index] = item;
        }
        if (columnIndex === 5) {
            let index = selObj.col5.findIndex((record: any) => record.sCheckBoxID === item.sCheckBoxID);
            selObj.col5[index] = item;
        }
        ReqEntryData[item.nTabID] = selObj;
        // deep clone of array is needed, just changing the contents of a location does not change any references. If array
        // maintains same reference value, it doesnt trigger a state change

        let arrayCopy = JSON.parse(JSON.stringify(ReqEntryData));
        setReqEntryData(arrayCopy)
        //setReqEntryDataRef(arrayCopy)
    };

    async function GetReqEntryData(mbyn: any, PassedSelParamData: RequestData[]) {
        try {
            //console.log("GetReqEntryData S", Date.toString)
            const GetReqEntryData_URL = UserSession.getSystemSettings().API_URL + 'RequestEntry/getRequestEntryData';
            const response = await axios.post(GetReqEntryData_URL,
                JSON.stringify({ "mbyn": mbyn, "pid": props.data.pid, "fid": props.data.fid, "reqid": props.data.reqid, "bloodproduct": props.data.bloodProduct, "mandantid": UserSession.getLoggedInUser().CurrentMandant, "paramlist": PassedSelParamData }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            setReqEntryData(response?.data);
            //setReqEntryDataRef(response?.data)
            //console.log("GetReqEntryData E", Date.toString)
        }
        catch (ex: any) {

        }
        setIsLoading({ loading: false, message: "" });

    }

    async function GetRequestData(mbyn: any) {
        try {
            //console.log("GetRequestData S", Date.toString)
            const GetRequestData_URL = UserSession.getSystemSettings().API_URL + 'RequestEntry/getRequestData';
            const response = await axios.post(GetRequestData_URL,
                JSON.stringify({ "mbyn": mbyn, "pid": props.data.pid, "fid": props.data.fid, "reqid": props.data.reqid, "mandantid": UserSession.getLoggedInUser().CurrentMandant, "paramlist": SelParamData }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            setReqData(response?.data);
            //console.log("GetRequestData E", Date.toString)
        }
        catch (ex: any) {

        }
    }

    async function GetRequestMaterialData(mbyn: any) {
        try {
            //console.log("GetRequestMaterialData S", Date.toString)
            const GetRequestMaterialData_URL = UserSession.getSystemSettings().API_URL + 'RequestEntry/getRequestMaterialData';
            const response = await axios.post(GetRequestMaterialData_URL,
                JSON.stringify({ "mbyn": mbyn, "pid": props.data.pid, "fid": props.data.fid, "reqid": props.data.reqid, "mandantid": UserSession.getLoggedInUser().CurrentMandant, "paramlist": SelParamData }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            //console.log(response?.data)
            setselParamMatData(response?.data);
            //console.log("GetRequestMaterialData E", Date.toString)
        }
        catch (ex: any) {

        }
    }

    async function GetRequestParamData(mbyn: any) {
        try {
            //console.log("GetRequestParamData S", Date.toString)
            const GetRequestParamData_URL = UserSession.getSystemSettings().API_URL + 'RequestEntry/getRequestParamData';
            const response = await axios.post(GetRequestParamData_URL,
                JSON.stringify({ "mbyn": mbyn, "pid": props.data.pid, "fid": props.data.fid, "reqid": props.data.reqid, "mandantid": UserSession.getLoggedInUser().CurrentMandant, "paramlist": SelParamData }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            setSelParamData(response?.data);
            GetReqEntryData(mbyn, response?.data);
            //console.log("GetRequestParamData E", Date.toString)
        }
        catch (ex: any) {

        }
    }

    async function FillData(props: requestDialogData) {
        if (props.pid === '' || props.fid === '')
            return;
        //console.log('FillData : PID = ' + props.pid + ' FID = ' + props.fid + ' ' + UserSession.getLoggedInUser().CurrentMandant)
        //console.log("FillData S", Date.toString)
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'RequestEntry/getinfo',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "pid": props.pid, "fid": props.fid }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false,
            }
        );
        //console.log(response?.data);
        setPatData(response?.data);
        //console.log("FillData E", Date.toString)
    };

    async function SaveRequestData() {
        if (CheckMandatoryData()) {
            var temp = { ...reqdata };
            temp.sReqDate = reqDate
            temp.sReqTime = reqTime
            temp.sRhesusProphylaxeDate = RhesusProphylaxeDate
            temp.sExternTransfusionDate = ExternTransfusionDate
            temp.sReqProvider = reqdata.reqprov.OrdProvCode
            if (props.data.reqid === '') {
                temp.objRecUpd.sRecordedBy = UserSession.getLoggedInUser().UserID;
                temp.objRecUpd.sModifiedBy = UserSession.getLoggedInUser().UserID;
            }
            else {
                temp.objRecUpd.sModifiedBy = UserSession.getLoggedInUser().UserID;
            }

            setReqData(temp);

            //console.log("reqdata", temp)
            const SaveRequesta_URL = UserSession.getSystemSettings().API_URL + 'RequestEntry/addeditRequest';
            const response = await axios.post(SaveRequesta_URL,
                JSON.stringify({ "paramlist": SelParamData, "matlist": selParamMatData, "objReq": temp, "extcall": props.data.extcall }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            var binddata: COneRequest[] = [];
            if (response?.data !== null)
                response?.data.forEach((o: COneRequest) => {
                    //console.log(formatDate(o.objRequest.sReqDate.toString()), o.objRequest.sReqDate.toString())
                    binddata.push({
                        ...o, sEmergency: o.objRequest.sEmergencyYN === "1" ? "NOTFALL" : "",
                        sdisplayReqDate: o.objRequest.sReqDate.toString()  //DisplayDateInternalFormat(formatDate(o.objRequest.sReqDate.toString()))
                    });
                });
            setSelectedRequest(binddata[0]);
            props.callbackRequestDialogClosed(binddata[0]);
            setVisible(false);

        }

    }

    /* Functions */

    /* Events */

    const cancelDialog = () => {
        //alert('cancelDialog');

        props.callbackRequestDialogClosed(selectedRequest);
        setVisible(false);
    }

    const saveDialog = () => {
        SaveRequestData()
    }

    const handleChangeStepper = (e: StepperChangeEvent) => {
        setStepperValue(e.value);
    };

    const handleCheckboxButton = (event: any, props: RequestData, sCollIndex: number) => {
        const binddata: RequestData[] = [...SelParamData];
        var bChecked: boolean;
        var sIcon: string;

        //console.log("handleCheckboxButton", props.bCheckBoxValue, event)
        if (props.bCheckBoxValue === true) {
            sIcon = "checkbox"
            bChecked = false

        }
        else {
            sIcon = "checkbox-checked"
            bChecked = true
        }
        //console.log("handleCheckboxButton", bChecked, sIcon, props)
        props.bCheckBoxValue = bChecked;

        if (bChecked === true) { // something checked so add it to the list
            binddata.push({ ...props });
            //console.log("handleCheckboxButton","true")
        }
        else {
            //console.log("handleCheckboxButton", "false", binddata)
            binddata.map((item, index) => {
                //if (props.sCheckBoxID === item.sCheckBoxID) {
                //    console.log("handleCheckboxButton", "remove")
                //    binddata.splice(index, 1);
                //}
                if (props.sFormCode !== "" && item.sFormCode !== "") {
                    if (props.sParamID === item.sParamID && props.sFormCode === item.sFormCode) {
                        //console.log("handleCheckboxButton", "remove")
                        binddata.splice(index, 1);
                    }
                }
                else {
                    if (props.sParamID === item.sParamID) {
                        //console.log("handleCheckboxButton", "remove")
                        binddata.splice(index, 1);
                    }
                }

            });
            // remove in profile/ratio parameters if profile/ratiois remvoed from request
            for (let index = binddata.length - 1; index >= 0; index--) {
                const item: RequestData[] = binddata.slice(index, index + 1)
                if (props.bProfileOrRatio === true) {
                    if (props.sFormCode !== "" && item[0].sFormCode !== "") {

                        if (props.sParamID === item[0].sRP_BelongsToProfiles && props.sFormCode === item[0].sFormCode) {
                            //console.log("inprof b", item[0].sRP_BelongsToProfiles, item[0].sParamID)
                            //console.log("handleCheckboxButton", "remove")
                            binddata.splice(index, 1);
                            //console.log("inprof a", item[0].sRP_BelongsToProfiles, item[0].sParamID, binddata)
                        }
                        else if (props.sParamID === item[0].sRP_BelongsToRatios && props.sFormCode === item[0].sFormCode) {
                            //console.log("inr b", item[0].sRP_BelongsToRatios, item[0].sParamID, binddata)
                            //console.log("handleCheckboxButton", "remove")
                            binddata.splice(index, 1);
                            //console.log("inr a", item[0].sRP_BelongsToRatios, item[0].sParamID, binddata)
                        }
                    }
                    else {
                        if (props.sParamID === item[0].sRP_BelongsToProfiles) {
                            //console.log("inprof b", item[0].sRP_BelongsToProfiles, item[0].sParamID, binddata)
                            //console.log("handleCheckboxButton", "remove")
                            binddata.splice(index, 1);
                            //console.log("inprof a", item[0].sRP_BelongsToProfiles, item[0].sParamID, binddata)
                        }
                        else if (props.sParamID === item[0].sRP_BelongsToRatios) {
                            //console.log("inr b", item[0].sRP_BelongsToRatios, item[0].sParamID, binddata)
                            //console.log("handleCheckboxButton", "remove")
                            binddata.splice(index, 1);
                            //console.log("inr a", item[0].sRP_BelongsToRatios, item[0].sParamID, binddata)
                        }
                    }
                }
            }

        }
        const newData = updateItem(props, sCollIndex, props.sIcon);
        //console.log("event", sCollIndex)

        if (props.sParamsInBlock != '' || props.sLinkedParams != '' || props.sAllowOnlyOneParamFromGroup != '') {
            ProcessParamForInBlockLinkedAllowOnlyOne(binddata, props, bChecked)
        }
        //else {
        //    setSelParamData(binddata);
        //}

        setSelParamData(binddata);

    };

    const handleClickHintButton = (event: any, props: RequestData, sCollIndex: number) => {
        setHintDialogData({ NameOnRep: props.sNameOnRep, HintDocument: props.sHintDocument, HintText: props.sHintText, HintWebsite: props.sHintWebsite, showDialog: true });
    };

    const handleClickMatButton = (event: any, props: CRequestMaterial) => {
        var temp = { ...matData };
        temp.objMat = props
        temp.showDialog = true;
        setMatDialogData(temp);
    };

    const handleClickGroupButton = (event: RequestData, colIndex: number) => {
        let div = document.getElementById('dv' + event.sCheckBoxID);
        let button = document.getElementById('btn' + event.sCheckBoxID);
        var newIcon: string = "";
        if (event.sIcon === 'minus')
            newIcon = "plus";
        else
            newIcon = "minus";
        //console.log(event, colIndex)
        const newData = updateItem(event, colIndex, newIcon);
    };

    const handleSelect = (e: TabStripSelectEventArguments) => {
        //console.log(e)
        setSelectedtab(e.selected);
    };

    const onPaneChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    const handleChangeDate = (value: string | "", source: string) => {
        if (source === 'sReqDate') {
            setReqDate(value);
        }
        else if (source === 'sRhesusProphylaxeDate') {
            setRhesusProphylaxeDate(value)
        }
        else if (source === 'sExternTransfusionDate') {
            setExternTransfusionDate(value)
        }

    };

    const handleChangeTime = (value: string, source: string) => {
        console.log('event.value', value);
        setReqTime(value);
        //console.log(event.value)
    };

    const handleCheckboxChange = (e: any) => {
        const name = e.target.name
        const value = e.target.value === true ? '1' : '0'
        setReqData(prevData => ({
            ...prevData,
            [name]: value
        }))
    };

    const handleChange = (e: any) => {
        const name = e.target.name
        const value = e.target.value

        //console.log (type,name,value)
        setReqData(prevData => ({
            ...prevData,
            [name]: value
        }))
    };

    const onChange = (event: ComboBoxChangeEvent) => {

        var temp: reqProvDataType = (event.value);
        //console.log (event)
        setReqData(prevData => ({
            ...prevData,
            reqprov: event.value,
            sReqProvider: temp.OrdProvCode
        }))
        setReqProvData(event.value);

        //console.log(reqdata)
    };

    /* Events */

    /* call back functions*/

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {

        setReqData(prevData => ({
            ...prevData,
            [controlID]: objCT.sCode
        }))

    };

    // Called when the hintscreen is closed using the cancel 
    const callbackhintDialogClosed = () => {
        setHintDialogData({ NameOnRep: '', HintDocument: '', HintText: '', HintWebsite: '', showDialog: false })
    }

    // Called when the hintscreen is closed using the cancel 
    const callbackmatDialogClosed = () => {
        setMatDialogData(emptyMatDialogData)
    }

    const callbackmatDialogSubmit = (event: CRequestMaterial) => {
        //console.log("callbackmatDialogSubmit", event);
        var user: SystemUser = UserSession.getLoggedInUser();
        var sUsername = '';
        if (user) {
            sUsername = user.UserFirstName + ' ' + user.UserLastName
        }
        sUsername = user.UserID;
        //console.log(selParamMatData);
        let newItem = true;
        let newData = selParamMatData.map((item, index) => {
            if (event.sMaterialID === item.sMaterialID) {

                newItem = false;
                item = { ...event };

                /*item.objRecUpd.sModifiedBy = sUsername;*/
                item.bUpdate = true;
            }
            return item;
        });
        if (newItem) {
            newData.push(event);
        }
        //console.log("updated mat", newData);
        setselParamMatData(newData);
        setMatDialogData(emptyMatDialogData)
    }
    /* call back functions*/

    // update selected material
    useEffect(() => {
        //console.log("useEffect SelParamData S", Date.toString)
        const binddata: CRequestMaterial[] = [...selParamMatData];
        var objM: CRequestMaterial = emptyRequestMaterial;
        var bAdd: boolean;
        var bRemove: boolean;
        var sURL: string = UserSession.getSystemSettings().API_URL;

        sURL = sURL.replace("api/cobra/", "");

        SelParamData !== null && distinct(SelParamData, "sParamMaterial").map((itemP, indexP) => {
            //console.log("P", indexP, itemP.sParamMaterial)
            bAdd = true;

            if (itemP.sParamMaterial !== '' && itemP.sParamMaterialDesc !== '') {
                binddata.map((itemM, indexM) => {
                    //console.log("M", indexM)
                    if (itemP.sParamMaterial === itemM.sMaterialID + '$' + itemM.sMandantID) {
                        //console.log("exists")
                        bAdd = false;
                    }
                });

                if (bAdd === true) {

                    var arrMat = itemP.sParamMaterial.split("$");
                    //console.log("add", arrMat)
                    //objM.sMaterialID = arrMat[0]
                    //objM.sMandantID = arrMat[1]
                    binddata.push({ ...objM, sMandantID: arrMat[1], sMaterialID: arrMat[0], sMaterialDesc: itemP.sParamMaterialDesc, sMatPicture: sURL + 'Images/' + itemP.sMatPicture });
                }
            }

        });

        binddata !== null && binddata.map((itemM, indexM) => {
            //console.log("P", indexP, itemP.sParamMaterial)
            bRemove = true;

            if (itemM.sMaterialID !== '' && itemM.sMaterialDesc !== '') {
                distinct(SelParamData, "sParamMaterial").map((itemP) => {
                    //console.log("M", indexM)
                    if (itemP.sParamMaterial === itemM.sMaterialID + '$' + itemM.sMandantID) {
                        //console.log("exists")
                        bRemove = false;
                    }
                });

                if (bRemove === true) {
                    binddata.splice(indexM, 1);
                }
            }

        });

        setselParamMatData(binddata)
        //console.log("useEffect SelParamData ", ReqEntryData)
        if (isLoading.loading === true) {
            {
                //console.log("useEffect SelParamData isLoading.loading === true", Date.toString)
                // to correct
                //GetReqEntryData('');
                //console.log("useEffect SelParamData isLoading.loading === true", Date.toString)

            }
        }

    }, [SelParamData])

    useEffect(() => {

        if (isLoading.loading === true) {
            //console.log("useEffect reqdata S", Date.toString)
            opOptions.forEach((o: reqProvDataType) => {
                if (o.OrdProvCode === reqdata.sReqProvider) {
                    setReqProvData(o);
                    reqdata.reqprov = o
                }
            });

            reqdata.sReqDate !== "" ? setReqDate(reqdata.sReqDate) : setReqDate("");
            reqdata.sReqTime !== "" ? setReqTime(reqdata.sReqTime) : setReqTime("");
            //console.log("reqord", reqdata.reqprov, reqProvData, reqdata)

            reqdata.sRhesusProphylaxeDate !== "" ? setRhesusProphylaxeDate(reqdata.sRhesusProphylaxeDate) : setRhesusProphylaxeDate("");
            reqdata.sExternTransfusionDate !== "" ? setExternTransfusionDate(reqdata.sExternTransfusionDate) : setExternTransfusionDate("");
            if (ReqEntryData.length !== 0) {

            }
        }
    }, [reqdata])

    useEffect(() => {
        setReqData(emptyRequest)
        setSelParamData([]);
        setselParamMatData([]);
        setReqProvData({ MandantID: '', OrdProvCode: '', Name: '', FirstName: '' });
        setHintDialogData(({ NameOnRep: '', HintDocument: '', HintText: '', HintWebsite: '', showDialog: false }));
        setMatDialogData(emptyMatDialogData);
        setVisible(props.data.showDialog);
        if (props.data.showDialog === true) {
            setIsLoading({ loading: true, message: "" });
            setStepperValue(0);
            setSelectedtab(0);
            FillData(props.data);
            refreshOrderProviders();
            if (props.data.reqid === '') {

                var temp = { ...emptyRequest };
                temp.sPID = props.data.pid;
                temp.sFID = props.data.fid;
                temp.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
                setReqData(temp);
                GetReqEntryData('', SelParamData);
            }
            else {

                GetRequestData('')
                GetRequestMaterialData('')
                GetRequestParamData('')
            }
            setTimeout(() => { document.getElementById('txtReqDate')?.focus() }, 1000);
        }

        // console.log('Mount: RequestDialog', props.data.showDialog);
        //console.log("useEffect props.data.pid, props.data.showDialog S", Date.toString)
        return () => {
            // console.log('Unmount: RequestDialog');
        };


    }, [props.data.pid, props.data.showDialog])

    var dlgHeight: number = window.innerHeight - 100;
    var dlgWidth: number = window.innerWidth - 150;
    var criteriaDivWidth: number = dlgWidth - 20;
    var paneHeight: number = dlgHeight - 260;


    // If there is more content above the splitter, change this calculation
    // tabstrip sits inside the pane
    var tabStripItemWidth: number = (dlgWidth - 620)
    var divWidth: number = (dlgWidth / 6) - (6 * 5);
    var rightArea: number = (20 / 100) * criteriaDivWidth;
    var leftArea: number = criteriaDivWidth - rightArea;
    
    return (
        <>
            <div>
                {visible && (
                    <Dialog contentStyle={{ overflow: 'hidden', padding: 0, overflowY: 'hidden' }} 
                        title={<DialogTitleBar
                            title={patdata.reqid !== '' ? mui(userRights, "reqentry", "headeredit", "Request - ") + ' ' + props.data.reqid : mui(userRights, "reqentry", "headernew", "New Request") + ' ' + props.data.reqid} >

                        </DialogTitleBar>}
                        onClose={cancelDialog} height={dlgHeight}
                        width={dlgWidth} >
                        {isLoading.loading && <Splash message={isLoading.message}></Splash>}
                        {!isLoading.loading &&
                            <>
                            <div className="container-fluid" style={{ margin: 0, padding: 0, maxWidth: criteriaDivWidth, width: criteriaDivWidth }}>
                            <div className="row" style={{ margin: 0, padding: 0}}>
                                    <div className="col" style={{ margin: 0, padding: 0, marginLeft: 5}}>
                                    <div style={{
                                        maxWidth: criteriaDivWidth, border: '1px solid lightblue', borderRadius: 10, backgroundColor: "#e0f0ff",
                                        width: criteriaDivWidth, marginTop: 5
                                        }}>
                                            <div className="row" style={{ paddingRight: 5, paddingLeft: 5 }}>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'pid'}>{mui(userRights, "common", "lblPID", "PID")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="pid" className='input-large'
                                                        type='text' value={patdata.pid} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'lastname'}>{mui(userRights, "common", "lblLastname", "Lastname") + '/' + mui(userRights, "common", "lblFirstname", "Firstname")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="lastname" className='input-large'
                                                        type='text' value={patdata.name + ' ' + patdata.firstname} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'casenr'}>{mui(userRights, "common", "lblFID", "FID")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="casenr" className='input-large'
                                                        type='text' value={patdata.fid} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper-child"><Label editorId={'birthdate'}>{mui(userRights, "common", "lblBirthdate", "Birthdate") + '/' + mui(userRights, "common", "lblGender", "Gender")}</Label></div>
                                            <div className="vertical-wrapper-child">
                                                <Input autoComplete="off" id="birthdate" className='input-large'
                                                    type='text' value={patdata.birthdate + '/' + patdata.genderdesc} />
                                            </div>

                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'deptcode'}>{mui(userRights, "common", "lblKlink", "Klink")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="deptcode" name="deptcode" className='input-large'
                                                        type='text' value={patdata.deptcodedesc} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">
                                                    <div style={{ display: 'inline-block' }}>
                                                        <Checkbox onChange={handleCheckboxChange} id="sEmergencyYN" name="sEmergencyYN" value={reqdata.sEmergencyYN === "1" ? true : false} label={mui(userRights, "common", "lblEmergencyYN", "EmergencyYN")} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>                                 
                                    </div>
                                            <div className="row" style={{ paddingRight: 5, paddingLeft: 5 }}>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label className="mandatory" editorId={'reqid'}>{mui(userRights, "common", "lblReqID", "Request ID")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="reqid" name="reqid" className='input-large'
                                                        type='text' value={reqdata.sReqID} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            {/*<div className="vertical-wrapper-child"><Label className="mandatory" editorId={'ReqDate'}>{mui(userRights, "common", "lblReqDate", "Req. date")}</Label></div>*/}
                                            <div className="vertical-wrapper-child">
                                                <CustomDate dateChanged={handleChangeDate} width='125px' className='input-large' defaulValue={reqDate} id={'txtReqDate'} source={'sReqDate'} label={mui(userRights, "common", "lblReqDate", "Req. date")} required={true} disabled={false}></CustomDate>
                                                &nbsp;
                                                        <CustomTime allowSecond={true} timeChanged={handleChangeTime} width='100px' className='input-large' defaulValue={reqTime} id={'txtReqTime'} source={'sReqTime'} label={mui(userRights, "common", "lblReqTime", "time")} required={true} disabled={false} secondField={false}></CustomTime>

                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label className="mandatory" editorId={'sReqProvider'}>{mui(userRights, "common", "lblOrderProvider", "Order provider")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <MultiColumnComboBox
                                                        required={true}
                                                        columns={columnsOrderProvider}
                                                        id='sReqProvider'
                                                        style={{ width: divWidth }}
                                                        data={opOptions}
                                                        textField="DisplayName"
                                                        dataItemKey="OrdProvCode"
                                                        value={reqdata.reqprov}
                                                        clearButton={false}
                                                        onChange={onChange}
                                                        className='input-large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper-child"><Label editorId={'sReqProviderAddn'}>{mui(userRights, "common", "lblReqProviderAddn", "ReqProviderAddn")}</Label></div>
                                            <div className="vertical-wrapper-child"></div>
                                            <Input autoComplete="off" id="sReqProviderAddn" name="sReqProviderAddn" onChange={handleChange} className='input-large'
                                                type='text' value={reqdata.sReqProviderAddn} />

                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'sDoctorNum'}>{mui(userRights, "common", "lblDoctorNum", "DoctorNum")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sDoctorNum" name="sDoctorNum" onChange={handleChange} className='input-large'
                                                        type='text' value={reqdata.sDoctorNum} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'sDoctorName'}>{mui(userRights, "common", "lblDoctorName", "DoctorName")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sDoctorName" name="sDoctorName" onChange={handleChange} className='input-large'
                                                        type='text' value={reqdata.sDoctorName} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'BloodGroup'}>{mui(userRights, "common", "lblBloodGroup", "BloodGroup")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="BloodGroup" className='input-large'
                                                        type='text' value={patdata.bloodbroup} readOnly />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'Rhesus'}>{mui(userRights, "common", "lblRhesus", "Rhesus")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="Rhesus" className='input-large'
                                                        type='text' value={patdata.rhesus} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'PhenoType'}>{mui(userRights, "common", "lblPhenoType", "PhenoType")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="PhenoType" className='input-large'
                                                        type='text' value={patdata.phenotype} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper-child"><Label editorId={'Antibody'}>{mui(userRights, "common", "lblAntibody", "Antibody")}</Label></div>
                                            <div className="vertical-wrapper-child">
                                                <Input autoComplete="off" id="Antibody" className='input-large'
                                                    type='text' value={patdata.antibody} readOnly />
                                            </div>

                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'DAT'}>{mui(userRights, "common", "lblDAT", "DAT")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="DAT" name="DAT" className='input-large'
                                                        type='text' value={patdata.dat} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"><Label editorId={'EDTAPhenomenaYN'}>{mui(userRights, "common", "lblEDTAPhenomenaYN", "EDTAPhenomenaYN")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="EDTAPhenomenaYN" name="EDTAPhenomenaYN" className='input-large'
                                                        type='text' value={patdata.edtaphenomenayn} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            <div className="row" style={{ paddingRight: 5, paddingLeft: 5 }}>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label editorId={'sWeight'}>{mui(userRights, "common", "lblWeight", "Weight")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sWeight" name="sWeight" onChange={handleChange}
                                                        type='text' value={reqdata.sWeight} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label editorId={'sHeight'}>{mui(userRights, "common", "lblHeight", "Height")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sHeight" name="sHeight" onChange={handleChange}
                                                        type='text' value={reqdata.sHeight} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label editorId={'sSymptomatic'}>{mui(userRights, "common", "lblSymptomatic", "Symptomatic")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} id="sSymptomatic"
                                                        codeTableID={CT_NOTIFY_QUESTION} selCode={reqdata.sSymptomatic} mandantChanged={0} supplParam1="" supplParam2="" width="220px"></CodeTableSel>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label editorId={'sPregnancyWeek'}>{mui(userRights, "common", "lblPregWeeks", "PregnancyWeek")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sPregnancyWeek" name="sPregnancyWeek" onChange={handleChange}
                                                        type='text' value={reqdata.sPregnancyWeek} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">
                                                    <Checkbox onChange={handleCheckboxChange} id="sPregnantYN" name="sPregnantYN" value={reqdata.sPregnantYN === "1" ? true : false}
                                                        label={mui(userRights, "common", "lblPregnantYN", "PregnantYN")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"> <Label editorId={'sHeparin'}>{mui(userRights, "common", "lblHeparin", "Heparin")}</Label></div>
                                                <div className="vertical-wrapper-child">
                                                    <Input autoComplete="off" id="sHeparin" name="sHeparin" onChange={handleChange}
                                                        type='text' value={reqdata.sHeparin} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            <div className="row" style={{ marginBottom: 5, paddingRight: 5, paddingLeft: 5 }}>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">
                                                    <div style={{ display: 'inline-block' }}>
                                                        <Checkbox onChange={handleCheckboxChange} id="sTelReportYN" name="sTelReportYN" value={reqdata.sTelReportYN === "1" ? true : false}
                                                            label={mui(userRights, "common", "lblTelReportYN", "TelReportYN")} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>                                       
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">
                                                    <div style={{ display: 'inline-block' }}>
                                                        <Checkbox onChange={handleCheckboxChange} id="sExtractionYN" name="sExtractionYN" value={reqdata.sExtractionYN === "1" ? true : false}
                                                            label={mui(userRights, "common", "lblExtractionYN", "ExtractionYN")} />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child"></div>
                                                <div className="vertical-wrapper-child">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="vertical-wrapper">
                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                <div className="vertical-wrapper-child">
                                                    <div className="button-right">
                                                        <Button
                                                            themeColor={'secondary'}
                                                            onClick={cancelDialog}
                                                            style={{ width: '120px' }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                                        &nbsp;
                                                        <Button
                                                            themeColor={'primary'}
                                                            onClick={saveDialog}
                                                            style={{ width: '120px' }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row" style={{ margin: 0, padding: 0 }}>
                                <div className="col" style={{
                                    width: leftArea, maxWidth: leftArea,
                                    overflow: 'hidden', height: paneHeight-60, padding: 5, margin: 5, border: '1px solid lightblue'
                                }}>
                                        <div className="row">
                                            <div className="col">
                                                <Stepper value={stepperValue} onChange={handleChangeStepper} items={itemsStepper} style={{ fontSize: '12px' }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                        <div className="col" style={{ width: leftArea, maxWidth: leftArea, display: stepperValue === 0 ? 'block' : 'none', padding: 5, margin: 5, border: '0px solid blue' }}>
                                                <TabStrip scrollable={true} selected={selectedtab} onSelect={handleSelect} style={{
                                                                border: '0px solid green', padding: 0, overflow: 'auto', userSelect: "none"
                                                            }}
                                                                keepTabsMounted={true}>
                                                                {
                                                                    ReqEntryData.map((tab: CRequestFormCodeData, index) => (
                                                                        <TabStripTab key={index} title={<TabTitle title={tab.sFormCode + ' ' + tab.sFormName} selected={index === selectedtab? true : false} />}>
                                                                            <>
                                                                                <ReqEntryTab content={tab} colIndex={index}>
                                                                                </ReqEntryTab>
                                                                            </>
                                                                        </TabStripTab>

                                                                    ))
                                                                }
                                                            </TabStrip>
                                            </div>
                                        <div className="col" style={{
                                            width: leftArea - 30, maxWidth: leftArea - 30, display: stepperValue === 1 ? 'block' : 'none',
                                            padding: 5, margin: 5, border: '0px solid blue', overflowX: 'auto', height: paneHeight - 130
                                        }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child"><Label editorId={'ReqOrdDate'}>{mui(userRights, "common", "lblReqOrdDate", "ReqOrdDate")}</Label></div>
                                                                    <div className="vertical-wrapper-child">
                                                                        <Input autoComplete="off" id="ReqOrdDate" name="ReqOrdDate"
                                                                            type='text' value={reqdata.sReqOrdDate + ' ' + reqdata.sReqOrdTime} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child"> <Label editorId={'ReqNr'}>{mui(userRights, "common", "lblReqNr", "ReqNr")}</Label></div>
                                                                    <div className="vertical-wrapper-child">
                                                                        <Input autoComplete="off" id="ReqNr" name="ReqNr"
                                                                            type='text' value={reqdata.sReqNr} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sTransferCode'}>{mui(userRights, "common", "lblTransferCode", "TransferCode")}</Label></div>
                                                                    <div className="vertical-wrapper-child">
                                                                        <Input autoComplete="off" id="sTransferCode" name="sTransferCode" onChange={handleChange}
                                                                            type='text' value={reqdata.sTransferCode} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="vertical-wrapper">
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row pt5 pb5">
                                                            <div className="col">
                                                                <div className="col form-section-header">
                                                                    <LabelBold>{mui(userRights, "common", "headerArtzRef", "Artz/Referenzen")}</LabelBold>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="container" style={{
                                                                    maxWidth: 'calc(100vw - 450px)', backgroundColor: '#F8F8F8', border: '1px solid #F8F8F8', paddingBottom: 10, marginBottom: 5, borderRadius: 10,
                                                                    width: 'calc(100vw - 450px)'
                                                                }}>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"><Label editorId={'sOMRFormNr'}>{mui(userRights, "common", "lblOMRFormNr", "OMRFormNr")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="sOMRFormNr" name="sOMRFormNr"
                                                                                        type='text' value={reqdata.sOMRFormNr} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> <Label editorId={'ReqExtAddress'}>{mui(userRights, "common", "lblReqExtAdd", "ReqExtAddress")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="ReqExtAddress" name="ReqExtAddress" onChange={handleChange}
                                                                                        type='text' value={reqdata.sReqExtAddress} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"><Label editorId={'sBillBatchNr'}>{mui(userRights, "common", "lblBillBatchNr", "BillBatchNr")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="sBillBatchNr" name="sBillBatchNr"
                                                                                        type='text' value={reqdata.sBillBatchNr} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> <Label editorId={'ExtCaseNr'}>{mui(userRights, "common", "lblExtCaseNr", "ExtCaseNr")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="ExtCaseNr" name="ExtCaseNr" onChange={handleChange}
                                                                                        type='text' value={reqdata.sExtCaseNr} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> <Label editorId={'ExtBillCheck'}>{mui(userRights, "common", "lblExtBillCheck", "ExtBillCheck")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="ExtBillCheck" name="ExtBillCheck" onChange={handleChange}
                                                                                        type='text' value={reqdata.sExtBillCheck} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> </div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sCopyTrtDocYN" name="sCopyTrtDocYN" value={reqdata.sCopyTrtDocYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblCopyTrtDocYN", "CopyTrtDocYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-4">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"><Label editorId={'sCopyToOrdProv'}>{mui(userRights, "common", "lblCopyToOrdProv", "CopyToOrdProv")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input width='100%' readOnly={true} autoComplete="off" id="sCopyToOrdProv" name="sCopyToOrdProv" type='text' onChange={handleChange} value={reqdata.sCopyToOrdProv} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="button-left">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <Button type={"button"} themeColor={'primary'} onClick={() => {

                                                                                            openMultiSelForm("OrderProvider")
                                                                                        }}>{mui(userRights, "common", "cmdMultiSelect", "M")}</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> </div>
                                                                                <div className="vertical-wrapper-child">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row pt5 pb5">
                                                            <div className="col">
                                                                <div className="col form-section-header">
                                                                    <LabelBold>{mui(userRights, "common", "headerMedInfo", "Med. Informationen")}</LabelBold>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="container" style={{
                                                                    maxWidth: 'calc(100vw - 450px)', backgroundColor: '#F8F8F8', border: '1px solid #F8F8F8', paddingBottom: 10, marginBottom: 5, borderRadius: 10,
                                                                    width: 'calc(100vw - 450px)'
                                                                }}>
                                                                    <div className="row">

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"><Label editorId={'sEmergencyTime'}>{mui(userRights, "common", "lblEmergencyTime", "EmergencyTime")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="sEmergencyTime" name="sEmergencyTime" onChange={handleChange}
                                                                                        type='text' value={reqdata.sEmergencyTime} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"> <Label editorId={'sHandProfCount'}>{mui(userRights, "common", "lblHandProfCount", "HandProfCount")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input autoComplete="off" id="sHandProfCount" name="sHandProfCount"
                                                                                        type='text' value={reqdata.sHandProfCount} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child"><Label editorId={'sDiagnoseCode'}>{mui(userRights, "common", "lblDiagnoseCode", "DiagnoseCode")}</Label></div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Input width='100%' readOnly={true} autoComplete="off" id="sDiagnoseCode" name="sDiagnoseCode" type='text' onChange={handleChange} value={reqdata.sDiagnoseCode} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="button-left">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <Button type={"button"} themeColor={'primary'} onClick={() => {
                                                                                            openMultiSelForm("DiagnoseCode")
                                                                                        }}>{mui(userRights, "common", "cmdMultiSelect", "M")}</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                            </div>
                                                                        </div>
                                                                      
                                                                    </div>

                                                                    <div className="row">

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sMachineYN" name="sMachineYN" value={reqdata.sMachineYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblMachineYN", "MachineYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Checkbox onChange={handleCheckboxChange} id="sExportSerothekYN" name="sExportSerothekYN" value={reqdata.sExportSerothekYN === "1" ? true : false}
                                                                                        label={mui(userRights, "common", "lblExportSerothekYN", "ExportSerothekYN")} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <Checkbox onChange={handleCheckboxChange} id="sExternYN" name="sExternYN" value={reqdata.sExternYN === "1" ? true : false}
                                                                                        label={mui(userRights, "common", "lblExternYN", "ExternYN")} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sAnticoagYN" name="sAnticoagYN" value={reqdata.sAnticoagYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblAnticoag", "AnticoagYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sChemotherapyYN" name="sChemotherapyYN" value={reqdata.sChemotherapyYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblChemotherapyYN", "ChemotherapyYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="vertical-wrapper">
                                                                                <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                <div className="vertical-wrapper-child">
                                                                                    <div style={{ display: 'inline-block' }}>
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sBillRequestYN" name="sBillRequestYN" value={reqdata.sBillRequestYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblBillRequestYN", "BillRequestYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                      
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row pt5 pb5">
                                                            <div className="col">
                                                                <div className="col form-section-header">
                                                                    <LabelBold>{mui(userRights, "common", "headerImmuno", "Immunhämatologie")}</LabelBold>
                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="container" style={{
                                                                        maxWidth: 'calc(100vw - 450px)', backgroundColor: '#F8F8F8', border: '1px solid #F8F8F8', paddingBottom: 10, marginBottom: 5, borderRadius: 10,
                                                                        width: 'calc(100vw - 450px)' 
                                                                    }}>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sBGMother'}>{mui(userRights, "common", "lblBGMother", "BGMother")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <Input autoComplete="off" id="sBGMother" name="sBGMother" onChange={handleChange}
                                                                                            type='text' value={reqdata.sBGMother} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col" >
                                                                                <CustomDate dateChanged={handleChangeDate} width='150px' className='input-large' defaulValue={RhesusProphylaxeDate} id={'txtRhesusProphylaxeDate'} source={'sRhesusProphylaxeDate'} label={mui(userRights, "common", "lblRhesusProphylaxeDate", "RhesusProphylaxeDate")} required={false} disabled={false}></CustomDate>

                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sOutBreakManagement'}>{mui(userRights, "common", "lblOutBreakManagement", "OutBreakManagement")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} id="sOutBreakManagement"
                                                                                            codeTableID={CT_NOTIFY_QUESTION} selCode={reqdata.sOutBreakManagement} mandantChanged={0} supplParam1="" supplParam2="" width="220px"></CodeTableSel>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col" >
                                                                                <CustomDate dateChanged={handleChangeDate} width='150px' className='input-large' defaulValue={ExternTransfusionDate} id={'txtExternTransfusionDate'} source={'sExternTransfusionDate'} label={mui(userRights, "common", "lblExternTransfusionDate", "ExternTransfusionDate")} required={false} disabled={false}></CustomDate>

                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sCompGroup'}>{mui(userRights, "common", "lblCompGroup", "CompGroup")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} id="sCompGroup"
                                                                                            codeTableID={CT_COMPONENT_GROUP} selCode={reqdata.sCompGroup} mandantChanged={0} supplParam1="" supplParam2="" width="220px"></CodeTableSel>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sTransfusedLatelyYN'}>{mui(userRights, "common", "lblTransfusedLatelyYN", "TransfusedLatelyYN")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} id="sTransfusedLatelyYN"
                                                                                            codeTableID={CT_YN_CODE} selCode={reqdata.sTransfusedLatelyYN} mandantChanged={0} supplParam1="" supplParam2="" width="220px"></CodeTableSel>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"> <Label editorId={'sRhesusProphylaxeYN'}>{mui(userRights, "common", "lblRhesusProphylaxeYN", "RhesusProphylaxeYN")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} id="sRhesusProphylaxeYN"
                                                                                            codeTableID={CT_YN_CODE} selCode={reqdata.sRhesusProphylaxeYN} mandantChanged={0} supplParam1="" supplParam2="" width="220px"></CodeTableSel>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <Checkbox onChange={handleCheckboxChange} id="sKnownAlloAntibodiesYN" name="sKnownAlloAntibodiesYN" value={reqdata.sKnownAlloAntibodiesYN === "1" ? true : false}
                                                                                            label={mui(userRights, "common", "lblKnownAlloAntibodiesYN", "KnownAlloAntibodiesYN")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <div style={{ display: 'inline-block' }}>
                                                                                            <Checkbox onChange={handleCheckboxChange} id="sNotTransfusedInLast48HrsYN" name="sNotTransfusedInLast48HrsYN" value={reqdata.sNotTransfusedInLast48HrsYN === "1" ? true : false}
                                                                                                label={mui(userRights, "common", "lblNotTransfusedInLast48HrsYN", "NotTransfusedInLast48HrsYN")} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="vertical-wrapper">
                                                                                    <div className="vertical-wrapper-child"><Label editorId={'sWorkpageInfo'}>{mui(userRights, "common", "lblWorkpageInfo", "WorkpageInfo")}</Label></div>
                                                                                    <div className="vertical-wrapper-child">
                                                                                        <Input width='100%' readOnly={true} autoComplete="off" id="sWorkpageInfo" name="sWorkpageInfo" type='text' onChange={handleChange} value={reqdata.sWorkpageInfo} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                            </div>
                                        </div>
                                </div>

                                <div className="col" style={{
                                    width: rightArea-20, maxWidth: rightArea-20,
                                    padding: 0, margin: 5, border: '1px solid lightblue'
                                }}>
                                    <div className="row" style={{ height: paneHeight - 60, overflowY: 'auto' }}>
                                        <div className="col" style={{
                                            width: rightArea - 20, maxWidth: rightArea - 20,
                                            border: '0px solid green', overflowX: 'auto'
                                        }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="col form-section-header">
                                                            <LabelBoldBigText>{mui(userRights, "common", "headerSelParams", "Selected Parameters")}</LabelBoldBigText>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        SelParamData !== null && SelParamData.map((tab: RequestData, index) => (
                                                            <tr>
                                                                <td style={{ backgroundColor: tab.sColor }}>
                                                                    &nbsp;&nbsp;&nbsp;{tab.sNameOnRep}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="col form-section-header">
                                                            <LabelBoldBigText>{mui(userRights, "common", "headerSelMaterials", "Selected Materials")}</LabelBoldBigText>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        selParamMatData !== null && selParamMatData.map((tab: CRequestMaterial, index) => (
                                                            <tr>
                                                                <td width="5%">
                                                                    <Button
                                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                                        icon="pencil"
                                                                        onClick={(event: any) => handleClickMatButton(event, tab)}
                                                                    >
                                                                    </Button>

                                                                </td>
                                                                <td>
                                                                    <Tooltip openDelay={100} position="auto" anchorElement="target" content={(props) =>
                                                                        <TooltipContentTemplate title={props.title} />}>

                                                                        <CustomCellMatForTT reqMat={tab} colIndex={index} />

                                                                    </Tooltip>
                                                                </td>

                                                            </tr>

                                                        ))
                                                    }
                                                </tbody>

                                            </table>
                                            <table style={{ width: '90%', overflowX: 'auto', overflowY: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="form-section-header">
                                                            <LabelBoldBigText>{mui(userRights, "common", "lblReqComment", "Comment")}</LabelBoldBigText>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                    <textarea
                                                        rows={7}
                                                        cols={37}
                                                        wrap="soft"
                                                        id="sComment" name="sComment" onChange={handleChange} value={reqdata.sComment}
                                                        />
                                                    </td>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <Suspense fallback={<Loader />}>
                                    <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                                        showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>
                                    <HintDialog data={hintDialogData} callbackhintDialogClosed={callbackhintDialogClosed}></HintDialog>
                                    <MaterialDialog data={matData} callbackmatDialogSubmit={callbackmatDialogSubmit} callbackmatDialogClosed={callbackmatDialogClosed}></MaterialDialog>
                            </Suspense>
                        </>
                        }

                    </Dialog>
                )}
            </div>
        </>
    );
}

export default RequestDialog;