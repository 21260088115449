import { useEffect, useRef, useState } from "react";
import { Dialog, DialogActionsBar, Window, WindowActionsEvent, WindowMoveEvent } from "@progress/kendo-react-dialogs";
import { pdfViewer } from '../../../types';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { Button } from "@progress/kendo-react-buttons";
import { mui } from "../../../Helper";

const ViewPDF = (props: pdfViewer) => {
    const ref_divPDFViewer = useRef<HTMLDivElement>(null);
    const ref_divButtons = useRef<HTMLDivElement>(null);
    const [pdfViewerHeight, setPDFViewerHeight] = useState<number | 760>(760);
    const [pdfViewerWidth, setPDFViewerWidth] = useState<number | 982>(982);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [base64, setBase64] = useState<string | undefined>(undefined);
    const [url, setUrl] = useState<string | undefined>(undefined);
    //const [windowStage, setWindowStage] = useState<
    //    "MINIMIZED" | "FULLSCREEN" | "DEFAULT" | undefined
    //    >("DEFAULT");
    const [position, setPosition] = useState({
        left: 300,
        top: 100,
        width: 200,
        height: 200,
    });

    useEffect(() => {
        // console.log('Mount: ViewPDF');

        return () => {
            // console.log('Unmount: ViewPDF');
        };

    }, [])

    const cancelDialog = () => {
        props.callbackPDFDialogClosed();
        setVisible(false);
    };

    const saveDialog = () => {
        props.callbackPDFDialogClosed();
        setVisible(false);
    };

    useEffect(() => {
        setUrl(props.url)
        setBase64(props.base64PDF);
        setVisible(props.showDialog);
    }, [props])

    const handleStageChange = (e: WindowActionsEvent) => {
        var ww: number=0;
        var wh: number=0;
        setTimeout(() => {
            ww=e.target.state.width;
            wh = e.target.state.height;            
            if (e.state === "FULLSCREEN") {
                setPDFViewerHeight(ww - 1030);
                setPDFViewerWidth(wh - 10);
            } else {
                setPDFViewerHeight(760);
                setPDFViewerWidth(982);
            };
        }, 100);       
    };

    const handleMove = (event: WindowMoveEvent) => {
        setPosition({
            ...position,
            left: event.left,
            top: event.top,
        });
    };

    const handleResize = (event: WindowMoveEvent) => {
        console.log(event.width, event.height);
        setPosition({
            left: event.left,
            top: event.top,
            width: event.width,
            height: event.height,
        });
    };

    var pdfProps;
    if (base64 === "") 
        pdfProps = {
            url: url,
        }
    else
        pdfProps = {
            data: base64,
    };
    return (
        <>
                {visible && (
                <Window title={"PDF Viewer"} onMove={handleMove} onResize={handleResize} onStageChange={handleStageChange} onClose={cancelDialog} height={900} width={1024}>
                    <div className="row" style={{ border: '2px solid lightblue', marginTop: 2, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                        <div ref={ref_divButtons} className="row" style={{ marginTop: 5 }}>
                            <div className="col" style={{ textAlign: 'right' }}>
                                <Button type={"button"} style={{ width: '120px', marginTop:2, marginBottom: 5 }} themeColor={'primary'}
                                    onClick={() => {
                                        cancelDialog();
                                    }}
                                >{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                {/*<button*/}
                                {/*    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"*/}
                                {/*    onClick={saveDialog}*/}
                                {/*>*/}
                                {/*    Yes*/}
                                {/*</button>*/}
                            </div>
                        </div>          
                        <div ref={ref_divPDFViewer} className="row" >
                            <div className="col" style={{ marginTop: 5 }}>
                                <PDFViewer defaultZoom={1} {...pdfProps} style={{ height: pdfViewerHeight, width: '100%' }} />                                
                            </div>                            
                        </div>                                   
                    </div>                        
                 </Window>
                )}
        </>
    );
}

export default ViewPDF;