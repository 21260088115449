interface errorProps {
    page: string;
}

const ErrorPage = (props: errorProps) => {
    return (
        <h1>External call invoke error {props.page}</h1>
    );
}

export default ErrorPage;