import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { useEffect, useRef } from "react";
import {
    IntlProvider,
    load,
    loadMessages,
    LocalizationProvider,
} from "@progress/kendo-react-intl";
import deMessages from "../../de.json";
import itMessages from "../../it.json";
import frMessages from "../../fr.json";
import UserSession from "../UserSession";

/* CLDR Data */
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import gbCaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import gbDateFields from "cldr-dates-full/main/en-GB/dateFields.json";

import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    deCaGregorian,
    deDateFields,
    gbCaGregorian,
    gbDateFields,
    itCaGregorian,
    itDateFields,
    frCaGregorian,
    frDateFields,
);

interface IMyDatePicker {
    pickerValue: Date | null;
    setPickerValue: React.Dispatch<React.SetStateAction<Date | null>>;
    id: string;
    parentOnChange: (value: Date|null, source: string, supplParam1: string, supplParam2: string, className: string, disabled:boolean) => void;
    source: string;
    label: string;
    required: boolean;
    supplParam1: string;
    supplParam2: string;
    className?: string;
    disabled?: boolean;
}

const MyDatePicker = (props: IMyDatePicker) => {
    const pickerRef = useRef<any>(null);

    const setValue = (value: Date | null) => {
        console.log("date", value);
        props.setPickerValue(value);
       /* props.parentOnChange(value, source, props.supplParam1, props.supplParam2, props.className !== undefined ? props.className : "", props.disabled !== undefined ? props.disabled : false);*/
    };
    
    const pickerOnChange = (ev: DatePickerChangeEvent) => {
        setValue(ev.value);        
    };

    const key_down = (ev: any) => {
        var someDate = new Date();
        var numberOfDaysToAdd = 1;
        var result: Date;

        if (ev.keyCode === '65') {
            props.setPickerValue(new Date()); //today
            setValue(new Date());
            props.parentOnChange(new Date(), props.source, props.supplParam1, props.supplParam2, props.className !== undefined ? props.className : "", props.disabled !== undefined ? props.disabled : false);
        }
        else if (ev.keyCode === '46') {
            props.setPickerValue(null); //clear 
            setValue(null);
            props.parentOnChange(null, props.source, props.supplParam1, props.supplParam2, props.className !== undefined ? props.className : "", props.disabled !== undefined ? props.disabled : false);
        }
        else if (ev.keyCode === '66') {
            numberOfDaysToAdd = 1;
            result = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
            props.setPickerValue(result); //tomorrow
            setValue(result);
            props.parentOnChange(result, props.source, props.supplParam1, props.supplParam2, props.className !== undefined ? props.className : "", props.disabled !== undefined ? props.disabled : false);
        }
        else if (ev.keyCode === '90') {
            numberOfDaysToAdd = -1;
            result = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
            props.setPickerValue(result); //yesteday
            setValue(result);
            props.parentOnChange(result, props.source, props.supplParam1, props.supplParam2, props.className !== undefined ? props.className : "", props.disabled !== undefined ? props.disabled : false);
        };
    };

    useEffect(() => {
        const lang: string = UserSession.getLanguage();
        if (lang === "de") {
            loadMessages(deMessages, "de-DE");
        }
        else if (lang === "it") {
            loadMessages(itMessages, "it-IT");
        }
        else if (lang === "fr") {
            loadMessages(frMessages, "fr-FR");
        }

        let element = document.getElementById(props.id);
        if (element) {
            element.addEventListener('keydown', key_down);
        }
        return () => {
            document.removeEventListener('keydown', key_down);
        };

    }, [])

    const onBlur = (ev: React.FocusEvent) => {
        console.log('pickerRef.current.value ', pickerRef.current.value); 
        setTimeout(() => {
            if (pickerRef.current.value == null) {
                pickerRef.current.dateInput.kendoDate.setValue(null);
                pickerRef.current.forceUpdate();                         
            };
        });
    };

    return (
        <>
            <LocalizationProvider language={globalThis.locale}>
                <IntlProvider locale={globalThis.locale}>
                    <DatePicker disabled={props.disabled} width='150px' required={props.required} id={props.id} label={props.label} className={props.className}
            formatPlaceholder={{ year: "    ", month: "    ", day: "        " }}
                        value={props.pickerValue}
                        onBlur={onBlur}
                        onChange={pickerOnChange} ref={pickerRef}
                    format="dd.MM.yyyy"></DatePicker>
            </IntlProvider>
        </LocalizationProvider>
        </>
    );
}

export default MyDatePicker;