import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Suspense, useEffect, useState } from "react";
import { CConfirmationMsg, CRequest, CResultEntry, CResultEntryOutput, CVerifyValidationData, confirmationDialog, emptyRequest, emptyVVD, resultEntryDialog, selCodeDataType } from "../../types";
import UserSession from "../UserSession";
import SerologyResultAndValidation from "./SerologyResultAndValidation";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import CustomResultEntry from "../Misc/CustomResultEntry";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import AddInProfileParams from "./AddInProfileParams";
import { mui } from "../../Helper";

interface IHProfileBlockProps {
    obj: CResultEntryOutput;    
    callbackhandleGroupCheckbox(e: CheckboxChangeEvent, sProfileID: string, sResultType: string): any; 
    callbackRefreshData(obj: CResultEntryOutput, action: string):any;
}

const IHProfileBlock = (props: IHProfileBlockProps) => {    
    const [isLoading, setIsLoading] = useState(false)
    const [reqData, setReqData] = useState<CRequest>(emptyRequest);

    const dlgEmptyConfirmationDialogState: confirmationDialog = {
        obj: emptyVVD, showDialog: false, SelectedOptions: () => { }
    };
    const [confirmationDialogData, setConfirmationDialogData] = useState<confirmationDialog>(dlgEmptyConfirmationDialogState);

    const [visibleWindow, setVisibleWindow] = useState<boolean>(false);

    const toggleWindow = () => {

        setVisibleWindow(!visibleWindow);
    };

    const OkClick = async () => {

        setVisibleWindow(false);
        props.callbackRefreshData(props.obj, '1');
    }

    let paramLen: number;
    let paramwithcomm: number;
    let adminLen: number;
    let admincommLen: number;
    let maxCols: number;
    let cellWidth: number = 100;
    if (props.obj.sProfileResultType.toUpperCase() === 'BC') {        
        paramLen = 3;
        paramwithcomm =  1//props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;
        adminLen = 0;
        admincommLen = 0;        
        if (paramwithcomm > 0) paramLen += 1;
        cellWidth = 150;
    } else {

        if (props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.bProfileValidated === false) {
            paramLen = props.obj.listResEntry.filter(objRE => objRE.sAdminYN !== "1").length;
            paramwithcomm = props.obj.listResEntry.filter(objRE => objRE.sAdminYN !== "1" && objRE.sResultComm !== "").length;

        }
        else {
            paramLen = props.obj.listResEntry.length;
            paramwithcomm = 1// props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;
        }
        
        adminLen = 0
        admincommLen = 1// props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;        
    };
    
    maxCols = paramLen > adminLen ? paramLen : adminLen;      
    if (maxCols === 1) {
        maxCols += 1.75
    }
    
    const EnableResultEdit = () => {
        props.obj.inEdit = true
        props.obj.disableEdit = false
        props.callbackRefreshData(props.obj, '2'); 
    };

    const DisableResultEdit = () => {
        props.obj.inEdit = false
        props.obj.disableEdit = false
        props.callbackRefreshData(props.obj, '1');
    };

    const SaveResult = () => {
        var obj: CResultEntry;
        var bok: boolean = true;
        for (obj of props.obj.listResEntry) {

            if (props.obj.inEdit === true) {
                console.log("SaveResult", obj.sRawResult, obj.sResultText, obj.sResSelTableName, obj.bDisableResultEntry)
                if ((obj.sRawResult === "" && obj.bDisableResultEntry === false && obj.sResSelTableName !== "Antibodies") &&
                    (obj.sResultText === "" && obj.bDisableResultEntry === false && obj.sResSelTableName === "Antibodies"))
                    {
                    
                    alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!") + obj.sParamDesc);
                    bok = false;
                    return;
                };
            }

        };
        if (bok === true) {
            VerifyData("S");

        }
       
    };

    const VerifyData = async (sMode: string) => {
        var bok: boolean = false;
        var objVVD: CVerifyValidationData = emptyVVD;
        objVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
        objVVD.objREO = props.obj;
        objVVD.sMode = "S"
        
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
            JSON.stringify({
                sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            bok = true;
            objVVD = response?.data;
            console.log("objVVD",objVVD);
            if (objVVD.ConfirmationMsg.length > 0 || objVVD.nGetReactionReason === 1) {
                const SelectedOpts = (obj: CVerifyValidationData) => {
                    if (obj !== undefined) {
                        console.log("1",obj);
                        var objCM: CConfirmationMsg;
                        for (objCM of obj.ConfirmationMsg) {
                            if (objCM.msgid === "MsgAntigenDoesNotMatch") {
                                obj.nMsgAntigenDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptBldGrpAndRhesus") {
                                objVVD.nMsgAcceptBldGrpAndRhesus = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptPhenoTypeYN") {
                                objVVD.nMsgAcceptPhenoTypeYN = Number(objCM.action);
                            };
                            if (objCM.action === "7") {
                                bok = false;
                            };
                        };
                    };
                    if (bok === true) {
                        console.log("2 SaveClick");
                        //setVVD(obj);
                        if (sMode === "V") {
                            /*ValidateClick(obj);*/
                        } else {
                            SaveClick(sMode, obj);
                        };
                    };
                    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
                };
                console.log("3", objVVD);
                setConfirmationDialogData({
                    obj: objVVD, showDialog: true, SelectedOptions: SelectedOpts
                });
            } else {
                if (bok === true) {
    
                    if (sMode === "V") {
                        //ValidateClick(objVVD);
                    } else {
                        SaveClick(sMode, objVVD);
                    };
                };
            };
        };
   
    };

    const SaveClick = async (sMode: string, objVVD: CVerifyValidationData) => {
  
        const binddata: CResultEntry[] = objVVD.objREO.listResEntry //[...resultData];
        var obj: CResultEntry;
        
        for (obj of binddata) {
            if ((obj.sRawResult === "" && obj.bDisableResultEntry === false && obj.sResSelTableName !== "Antibodies") &&
                (obj.sResultText === "" && obj.bDisableResultEntry === false && obj.sResSelTableName === "Antibodies")) {
                console.log("error", obj)
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
        };
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'serology/saverequestresultdata';
        } else {
            sAddnURL = 'serology/rerunparam';
        };
        setIsLoading(true);
        if (objVVD.objREO !== undefined) {
            //resultMainData.listResEntry = resultData;
            objVVD.objREO.sUserID = UserSession.getLoggedInUser().UserID;
        };
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                objREO: objVVD.objREO, objVVD: objVVD
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) {
            maindata = response?.data.objREO;
            //setVVD(response?.data.objVVD);
        }
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                setIsLoading(false);
                alert(maindata.sErrorMsg);
            } else {
                //setDisableSaveButton(true);
                //setDisableValidationButton(true);
                binddata.length = 0;
                maindata.inEdit = false
                maindata.disableEdit = false
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    //if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    //if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });
                //setResultData(binddata);
                //setResultSaved(true);
                props.obj.inEdit = false
                props.obj.disableEdit = false
                setIsLoading(false);
                props.callbackRefreshData(props.obj, '1');

            };
        };

    };
 
    const GetReqData = async () => {
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getResulyEntryDialogData',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: props.obj.listResEntry[0].sReqID },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        if (response?.data !== null) setReqData(response?.data);           
    };

    const handleShowCommentButtonClick = () => {
   
        console.log("handleShowCommentButtonClick")

        if (props.obj.sIcon === 'align-remove')
            props.obj.sIcon = 'align-justify';
        else
            props.obj.sIcon = "align-remove";

        props.callbackRefreshData(props.obj, '3');

    };

    useEffect(() => {
        setConfirmationDialogData(dlgEmptyConfirmationDialogState)
        GetReqData();
        console.log("IHProfileBlock" , props.obj)
    }, []);

    return (
        <>
            <table className="table-sm table-bordered" style={{ width: cellWidth * maxCols }}>
            <tbody>
                    <tr key={uuidv4()}>
                        <td colSpan={maxCols} title={props.obj.sProfileToolTip} style={{ fontWeight: 'bold', backgroundColor: props.obj.sProfileBackColor }}>
                            <div className="vertical-wrapper">
                                <div className="row">
                                    <div className="col-6">
                                        <Checkbox style={{ backgroundColor: props.obj.bProfileValidated === true ? 'green' : ''}} disabled={props.obj.bDisableProfileSel} onChange={(event: CheckboxChangeEvent) => props.callbackhandleGroupCheckbox(event, props.obj.sProfileID, props.obj.sProfileResultType)}
                                            value={props.obj.bProfileSel}
                                            >
                                            <label                                                                                               
                                                style={{ display: "inline-block", textWrap:'nowrap' }}
                                            >
                                                {props.obj.sProfileDesc}
                                            </label>
                                        </Checkbox>
                                    </div>
                                    <div className="col-6 button-right">
                                        
                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                            onClick={() => { handleShowCommentButtonClick(); }} icon={props.obj.sIcon}></Button>
                                        {props.obj.bProfileValidated === false ?
                                            (
                                            props.obj.inEdit === true ?
                                                <>
                                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                        onClick={() => { DisableResultEdit(); }} icon="reset"></Button>
                                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                        onClick={() => { SaveResult(); }} icon="floppy"></Button>
                                                    
                                            </>
                                                    : <>
                                                        {props.obj.collParamUnderProfile.length > 0 ?

                                                            <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                                onClick={toggleWindow} icon="plus"></Button>
                                                            : ""
                                                        }
                                                    <Button type={"button"} disabled={props.obj.disableEdit} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                        onClick={() => { EnableResultEdit(); }} icon="pencil"></Button>
                                               </>
                                           
                                        ):""
                                        }
                                    </div>
                                </div>
                                
                        </div>
                    </td>                             
                </tr>
                    {
                        props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() === 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                            <tr key={uuidv4()}>
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                                {
                                    props.obj.inEdit === true ?
                                        <>
                                            <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""}
                                                key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                <CustomResultEntry selType="RESULT" objRE={objRE} secondField={false } ></CustomResultEntry>
                                            </td>
                                            <td title={objRE.sCompType !== "" ? objRE.sCompType : ""} key={uuidv4()}
                                                style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                <CustomResultEntry selType="RESULT" objRE={objRE} secondField={true} ></CustomResultEntry>
                                            </td>

                                        </>
                                        :
                                        <>
                                            <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sInterpretationDesc}</td>
                                            <td title={objRE.sCompType !== "" ? objRE.sCompType : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sCompType}</td>

                                        </>
                                }
                                <>
                                    {props.obj.sIcon === 'align-remove' ?
                                        props.obj.inEdit === true ?

                                            <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                <CustomResultEntry selType="RESULTCOMM" objRE={objRE} secondField={false}></CustomResultEntry>
                                            </td>
                                            :
                                            <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sResultComm}</td>
                                        : ''
                                    }

                                </>
                            </tr>   
                        ))
                    }
                    <tr key={uuidv4()}>
                    {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.bProfileValidated === false && objRE.sAdminYN === "1" ?
                                    ""
                                    :
                                    <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                        ))
                    }
                    </tr>
                    <tr key={uuidv4()} >
                    {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                props.obj.inEdit === true ? 
                                    props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.bProfileValidated === false && objRE.sAdminYN === "1" ?
                                        ""
                                        :
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        <CustomResultEntry selType="RESULT" objRE={objRE} secondField={false} ></CustomResultEntry>
                                    </td>
                                    :
                                    props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.bProfileValidated === false && objRE.sAdminYN === "1" ?
                                        ""
                                        :
                                    <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                    </td>
                                
                        ))
                            }
                    
                        </tr>

                    {
                        props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && paramwithcomm > 0 && props.obj.listResEntry.length > 0 ?
                            <>
                                {props.obj.sIcon === 'align-remove' ?
                                    <tr key={uuidv4()}>
                                        {props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && paramwithcomm > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                            props.obj.inEdit === true ?

                                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                    <CustomResultEntry selType="RESULTCOMM" objRE={objRE} secondField={false}></CustomResultEntry>
                                                </td>
                                                :
                                                props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.bProfileValidated === false && objRE.sAdminYN === "1" ?
                                                    ""
                                                    :
                                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                    {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                                                </td>
                                        ))}
                                    </tr>
                                    : ''
                                }
                                
                            </>
                        :
                        ""
                    }
                  
                   
                {adminLen > 0 &&
                        <>
                        <tr style={{ lineHeight: '1px' }} key={uuidv4()}>
                        <td colSpan={maxCols}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr key={uuidv4()}>
                        {
                                props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                    <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                            ))
                        }
                    </tr>
                    <tr key={uuidv4()}>
                            {
                                props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                    <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                        </td>
                                ))
                            }
                        </tr>
                    <tr key={uuidv4()}>
                        {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && admincommLen > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                    {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                                </td>
                            ))
                        }
                    </tr>
                </>
                }
                </tbody>            
            </table>
            <Suspense fallback={<Loader />}>
              
                <ConfirmationDialog obj={confirmationDialogData.obj} showDialog={confirmationDialogData.showDialog} SelectedOptions={confirmationDialogData.SelectedOptions}></ConfirmationDialog>
                <AddInProfileParams obj={props.obj} showDialog={visibleWindow} callbackCancel={toggleWindow} selprofilecode={props.obj.sProfileID }
                    callbackSave={OkClick}></AddInProfileParams>
            </Suspense>
         
        </>
    );
}

export default IHProfileBlock;