import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useEffect, useRef, useState } from "react";
import { DialogTitleBar, mui } from "../../../Helper";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import axios from 'axios';
import { enumSocketCallback, fillDevicesSocket } from "../../../Twain";
import { doScanningSocket } from "../../../Twain";
import { Checkbox, CheckboxHandle } from "@progress/kendo-react-inputs";
import { CScanProperties, enmScanning, ListItemData, selCodeDataType } from "../../../types";
import { SelectCode } from "../../SelectCode/SelectCode";

export interface scannerUIProps {
    source: enmScanning,
    showDialog: boolean,
    multipleScans: boolean,
    selPDF: boolean,
    objScanProperties: CScanProperties,
    ScanningOver(source: string, success: boolean, data: string[], extlab: string, key2: string, objScanProperties: CScanProperties): any;
}

let source: any = null;

const ScannerUI = (props: scannerUIProps) => {
    const emptySelCode: selCodeDataType={
        code: "",
        mandantid: "",
        desc: "",
        DisplayString: "",
        addnfield: "",
        selected: false
    };
    const [extLab, setExtLab] = useState<selCodeDataType>(emptySelCode);
    const [scannedData, setScannedData] = useState<string[]>([]);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [scannerList, setScannerList] = useState<ListItemData[]>([]);
    const [selectedScanner, setSelectedScanner] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const ref = useRef<CheckboxHandle>(null);
    const selPDFRef = useRef<HTMLInputElement>(null);

    const CancelClick = async () => {
        console.log('CancelClick', scannedData);
        if (scannedData.length === 0)
            props.ScanningOver("", false, [],"","",props.objScanProperties);
        else
            props.ScanningOver("", true, scannedData, extLab.code, extLab.addnfield, props.objScanProperties);
        setScannedData([]);
    };

    const scanningSocketCallback = (source: string, status: enumSocketCallback, data: string) => {
        console.log('scanningSocketCallback', status);
        setIsLoading(false);
        if (status === "success") {
            setErrorMsg("Scanning successful.");
            var arrScanData: string[] = [...scannedData]
            arrScanData.push(data);
            setScannedData(arrScanData);
            if (!ref?.current?.element?.checked) {
                props.ScanningOver("", true, arrScanData, extLab.code, extLab.addnfield, props.objScanProperties); // tell the caller that we have successfully scanned a page
                setScannedData([]);
            }
        }      
        else if (status === "error" || status === "exception")
            setErrorMsg("Error scanning.");
    }

    const performScan = async () => {
        if ((props.source === enmScanning.Scan_ExternLabResults) && extLab.code === "") {
            alert("Select Ext Lab!");
            return;
        };
        if (props.selPDF === true) {
            const myElement = document.getElementById('selectpdf');
            myElement?.click();
        } else {
            if (selectedScanner === "") {
                setErrorMsg("Select scanner from the dropdown");
                return;
            };
            setIsLoading(true);
            setErrorMsg("");            
            doScanningSocket(props.selPDF === false? "" : "pdf" , selectedScanner, scanningSocketCallback);
        };             
    };

    const fillDeviceSocketCallback = (source: string, status: enumSocketCallback, data: string) => {
        console.log('fillDeviceSocketCallback', status);
        setIsLoading(false);
        if (status === "success") {
            var Scanners: string[] = data.split(",");
            var first: boolean = true;
            var newScannerList: ListItemData[] = [];
            Scanners.forEach((o: string) => {
                if (first)
                    setSelectedScanner(o);
                    newScannerList.push({
                        id: o,
                        name: o,
                        selected: first ? true: false
                    });
                first = false;
            });
            setScannerList(newScannerList);
        }
    }

    useEffect(() => {
        if (scannerList.length === 0)
            setErrorMsg("Unable to retrieve scanners or no scanners defined.");
        else
            setErrorMsg("");
    }, [scannerList])
    
    function getInfoAboutTwainDevices(): void {
        setIsLoading(true);
        setSelectedScanner("");
        setScannerList([]);
        fillDevicesSocket(fillDeviceSocketCallback);
     }

    useEffect(() => {
        setExtLab(emptySelCode);
        setErrorMsg("");
        setSelectedScanner("");
        setScannerList([]);
        setScannedData([]);
        source = axios.CancelToken.source();

        return () => {
            if (source) {
                source.cancel("Component got unmounted");
            }
        };

    }, [])

    useEffect(() => {
        if (props.showDialog) {
            setScannedData([]);
            getInfoAboutTwainDevices();
        }
        setVisible(props.showDialog);
    }, [props.showDialog])

    const ScannerSelectedFromDropDown = (event: DropDownListChangeEvent) => {
        if (event.value === null || event.value === undefined)
            setSelectedScanner("");
        else {
            setErrorMsg("");
            setSelectedScanner(event.value.id);
        }
    };

    const codeSelected = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        setExtLab(obj);
    };

    const getBase64 = (file: any) => {
        console.log('extLab.code', extLab.code);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {            
            var arrScanData: any[] = [];
            arrScanData.push(reader.result?.toString());
            setScannedData(arrScanData);
            props.ScanningOver("pdf", true, arrScanData, extLab.code, extLab.addnfield, props.objScanProperties);            
        };
        reader.onerror = function (error) {
            alert(error);
        };
    };
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null) getBase64(e.target.files[0]);
    };

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title="Scanning" />} onClose={CancelClick}
                        height={270} width={650} style={{ overflowY: 'hidden' }}>
                        <div className="container" style={{ overflow: 'hidden', width: 600 }}>
                            <div className="row pb10">
                                <div className="col">
                                    <Label>Ext Lab</Label>
                                </div>
                            </div>
                            <div className="row pb10">
                                <div className="col">
                                    <SelectCode autocomplete={false} disabled={props.source === enmScanning.Scan_ExternLabResults ? false : true} setSelectedTable={codeSelected} id="sExtLab"
                                        tablename="Address" code="PID" desc="LastName" selCode={extLab.code}
                                        mandantChanged={0} width={"100%"} supplParam1="AdrType" supplParam2="5"></SelectCode>
                                </div>
                            </div>
                           
                            <div className="row pb10">
                                <div className="col">
                                <Label>Scanner</Label>
                                </div>
                            </div>
                            <div className="row pb10">
                                <div className="col">
                                    <DropDownList disabled={props.selPDF} id="cboScanners" style={{ width: '100%' }} data={scannerList}
                                        textField="name"
                                        dataItemKey="id"
                                        defaultItem={scannerList.length > 0 ? scannerList[0] : {}}
                                        onChange={ScannerSelectedFromDropDown} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Checkbox disabled={props.selPDF} id="keepScanning" name="keepScanning" ref={ref}
                                        label="Scan multiple pages" style={{ display: "inline-block" }} defaultChecked={props.multipleScans} />
                                </div>
                                <div className="col">
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '100px', display: "inline-block" }} themeColor={'secondary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>&nbsp;
                                        <Button disabled={isLoading} type={"button"} style={{ width: '100px', display: "inline-block" }} themeColor={'primary'} onClick={() => {
                                            performScan()
                                        }}>{props.selPDF === true ? "Select PDF" : "Scan"}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt5">
                                <div className="col">
                                    <div className="indeterminate-progress-bar" style={{ display: isLoading ? "block" : "none"}}>
                                        <div className="indeterminate-progress-bar__progress"></div>
                                    </div>
                                    <Label style={{color: 'red'}}>{errorMsg}</Label>
                                </div>
                            </div>
                            <input ref={selPDFRef} style={{ display: "none" }} id="selectpdf" hidden type="file" accept=".pdf" onChange={handleFileChange} />
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    );
}

export default ScannerUI;
