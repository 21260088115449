
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect } from "react";
import { useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import {  CUserRightsAppl, resultEntryDialog } from "../../types";

import HamatResAndValComponent from "./HamatResAndValComponent";

const HamatResultAndValidation = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'HamatResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [visible, setVisible] = useState<boolean>(props.showDialog);

    const CancelClick = async () => {
        props.ResultSaved(false, '', '');
        setVisible(false);
    };

    useEffect(() => {        
        setVisible(props.showDialog);
    }, [props.showDialog])


    var dlgHeight: number = window.innerHeight - 175;
    var dlgWidth: number = window.innerWidth - 40;

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={mui(userRights, "HamatResultAndValidation", "textTitle", "Hamat Result")} />} onClose={CancelClick} height={dlgHeight} width={dlgWidth} >
                        <HamatResAndValComponent mandantid={props.mandantid} reqid={props.reqid} pid={props.pid} fid={props.fid}
                            paramid={props.paramid} reqdatetime={props.reqdatetime} reqprovider={props.reqprovider} valtype={props.valtype} addedparams={props.addedparams}
                            pregnancyweek={props.pregnancyweek} mbyn={props.mbyn} userid={props.userid}
                            showDialog={props.showDialog} ResultSaved={props.ResultSaved} selprofilecode={""} dayWorkSpace={false}></HamatResAndValComponent>
                    </Dialog>
                )}
            </div>
        </>
    );
}
export default HamatResultAndValidation;

