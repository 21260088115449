import { COneAddress, COneRequest, CUserRightsAppl, SystemUser } from './types'
import UserSession from './components/UserSession';
import axios from 'axios'
import { useState, useEffect } from 'react';
import logo from './assets/window.png';

// Actual login operation. Seperated out in external module as this will be called when doing login via UI
// and automatic login when calling a route from external application
export async function performLogin(username: string, password: string,userid:string): Promise<SystemUser> {
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'user/authenticate',
        JSON.stringify({ "username": username, "password": password, "badgenumber": "", "userid":userid }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    const user: SystemUser = response?.data;
    return user;
}

// During deployment and when debugging is over, this function should just return without logging to console
export function extendedLog(logString: string) {
    console.log('** ', logString);
}

// return true if socket related logging is needed
export function SocketLog(): boolean {
    return false;
}
export function UserLoggedIn(): boolean {
    var user: SystemUser = UserSession.getLoggedInUser();
    if (user === null || user === undefined)
        return false;
    else if (user.UserID === '')
        return false;
    return true;
}

// Date related functions
export function dateInternalToSystem(dateString: string) {
    if (dateString === null) return null;
    const [day, month, year] = dateString.split('.');
    return new Date(+year, +month - 1, +day);
}

export function datetimeInternalToSystem(dateString: string, timeString: string) {
    const [day, month, year] = dateString.split('.');
    const [h, m, s] = timeString.split(':');
    
    return new Date(+year, +month - 1, +day, Number(h), Number(m), Number(s));
}

export function formatDate(dt: string | undefined): string {
    if (dt === undefined)
        return '';
    var dtObj = new Date(Date.parse(dt));
    if (dtObj.toDateString() === new Date(Date.parse('0001-01-01T00:00:00')).toDateString())
        return '';
    else {
        const yyyy = dtObj.getFullYear();
        let mm: number = dtObj.getMonth() + 1; // Months start at 0!
        let dd: number = dtObj.getDate();
        var dds = dd.toString(), mms = mm.toString();
        if (dd < 10) dds = '0' + dds;
        if (mm < 10) mms = '0' + mms;
        return dds + '.' + mms + '.' + yyyy;
    }
}

export function formatTime(dt: string | undefined, allowSecond: boolean): string {
    if (dt === undefined)
        return '';
    var dtObj = new Date(Date.parse(dt));
    if (dtObj.toDateString() === new Date(Date.parse('0001-01-01T00:00:00')).toDateString())
        return '';
    else {
        let hh: number = dtObj.getHours(); 
        let mm: number = dtObj.getMinutes();
        let ss: number = dtObj.getSeconds();
        var hhs = hh.toString(), mms = mm.toString(), sss = ss.toString();
        if (hh < 10) hhs = '0' + hhs;
        if (mm < 10) mms = '0' + mms;
        if (ss < 10) sss = '0' + sss;
        if (!allowSecond)
            return hhs + ':' + mms;
        else
            return hhs + ':' + mms + ':' + sss;
    }
}

export function ReverseDateForSorting(date: string): string {
    if (date === '') return '';
    var sep: string = ".";
    if (date.indexOf('-') !== -1)
        sep = "-";
    if (date.length > 10) {
        var newDate = date.substring(0, 10);
        var timeValue = date.substring(11).trim();
        newDate = newDate.split(sep).reverse().join(".");
        return newDate + ' ' + timeValue;
    }
    else {
        return date.split(sep).reverse().join(".");
    }
}

export function DisplayDateInternalFormat(date: string): string {
    if (date === '') return '';
    var sep: string = ".";
    if (date.indexOf('-') !== -1)
        sep = "-";
    if (date.length > 10) {
        var newDate = date.substring(0, 10);
        var timeValue = date.substring(11).trim();
        newDate = newDate.split(sep).reverse().join(".");
        return newDate + ' ' + timeValue;
    }
    else {
        return date.split(sep).reverse().join(".");
    }
}

// Generic functions that may be needed in multiple components

export async function CacheOrderProvider() {
    try {
        const GETORDPROVIDER_URL = UserSession.getSystemSettings().API_URL + 'orderprovider/search';
        const response = await axios.post(GETORDPROVIDER_URL,
            JSON.stringify({ "mandantid": UserSession.getLoggedInUser().CurrentMandant, "ordprovcode": "" }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        await saveToStorage("orderProvider", JSON.stringify(response?.data));
    } catch (err: any) {
        console.log('CacheOrderProvider ', err);
    }
}

// Helpers to get data from localStorage
export async function saveToStorage(key: string, data: string): Promise<void> {
    try {
        await localStorage.setItem(key, data);
    }
    catch (ex: any) { };
}

export function loadFromStorage(key: string): string | null {
    try {
        return (localStorage.getItem(key));
    }
    catch (ex: any) { };
    return null;
}

export function removeFromStorage(key: string): void {
    try {
        localStorage.removeItem(key);
    }
    catch (ex: any) { };
}

export function sanitizeValue(value: string): string {
    if (value === null || value === undefined)
        return '';
    else
        return value;
}

export const useIntersection = (element: any, rootMargin: any) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        element.current && observer.observe(element.current);

        return () => { if (element.current) observer.unobserve(element.current) }
    }, []);

    return isVisible;
};

export function PatientInformation(patData: COneAddress | null | undefined): JSX.Element {
    if (patData === null || patData === undefined) return <span></span>;
    if (patData.objAddress.sPID === "") return <span></span>
    const displayData: string = patData.objAddress.sLastName + ', ' + patData.objAddress.sFirstName + ', ' + patData.objAddress.sBirthDate + ' ' + patData.Age +   ', '
        + patData.SexDesc + ', '
        + '<b>' + mui(userRights, "common", "lblPID", "PID") + ':</b> ' + patData.objAddress.sPID + ', '
        + '<b>' + mui(userRights, "address", "lblBG", "BG") + ':</b> ' + patData.BGDesc + ', '
        + '<b>' + mui(userRights, "address", "lblRH", "RH") + ':</b> ' + patData.RHDesc + ', '
        + '<b>' + mui(userRights, "address", "lblPT", "PT") + ':</b> ' + patData.PTDesc + ', '
        + '<b>' + mui(userRights, "address", "lblKell", "Kell") + ':</b> ' + patData.KellDesc + ', ';    
        const element = <span className="primary-color" dangerouslySetInnerHTML={{ __html: displayData }} />
    return element;
}
export function RequestInformation(ReqData: COneRequest | null | undefined): JSX.Element {
    if (ReqData === null || ReqData === undefined) return <span></span>;
    const displayData: string = '<b>' + ReqData.objRequest.sReqID + ', ' + ReqData.objRequest.sReqDate + ' ' + ReqData.objRequest.sReqTime + ', '
        + ReqData.StationDescr + ', ' + ReqData.objRequest.sFID + '</b> '     
    const element = <span className="primary-color" dangerouslySetInnerHTML={{ __html: displayData }} />
    return element;
}
export function PatientInformationMain(patData: COneAddress | null | undefined): string {
    if (patData === null || patData === undefined) return "";
    if (patData.objAddress.sPID === "") return "";
    const element: string = ': <b>' + mui(userRights, "common", "lblPID", "PID") + ':</b> ' + patData.objAddress.sPID + ', '
        + '<b>' + mui(userRights, "common", "lblLastname", "Lastname") + ':</b> ' + patData.objAddress.sLastName + ', '
        + '<b>' + mui(userRights, "common", "lblFirstname", "Firstname") + ':</b> ' + patData.objAddress.sFirstName + ', '
        + '<b>' + mui(userRights, "common", "lblBirthdate", "Birthdate") + ':</b> ' + patData.objAddress.sBirthDate + ', '
        + '<b>' + mui(userRights, "common", "lblGender", "Gender") + ':</b> ' + patData.SexDesc;
    return element;
}

export function mui(userRights: CUserRightsAppl[] | null, form: string, object: string, defaultval: string): string {
    const lang: string = UserSession.getLanguage();
    if (userRights === null || userRights === undefined) return defaultval;
    var found = userRights.filter(ura => {
        return form === ura.sFormName && object === ura.sObjectID;
    });
    if (found.length === 0) return defaultval;
    if (found.length === 1) {
        var text: string = "";
        if (lang === "en")
            text = found[0].sDescInternal;
        else if (lang === "de")
            text = found[0].sURTextGe;
        else if (lang === "fr")
            text = found[0].sURTextFr;
        else if (lang === "it")
            text = found[0].sURTextIt;
        return text;
    }
    else
        return defaultval;
}

export const DialogTitleBar = (props: any) => {
    return (
        <div style={{ fontSize: "16px" }}>
            <div style={{ verticalAlign: '' }}>
                {<img src={logo} alt="logo" />}&nbsp;<span dangerouslySetInnerHTML={{ __html: props.title }} />
            </div>
        </div>
    );
};


export const TabTitle = (props: any) => {
    return (
        <>
            {
                props.color !== "" && props.color !== undefined ?
                        props.selected ?
                            <span style={{
                                color:props.textwhite === "1" ? "white":"black",
                                backgroundColor: props.color, padding: 2,
                                borderRadius: 3
                            }} className="tab-selected">{props.title}</span>
                            :
                            <span style={{
                            color: props.textwhite === "1" ? "white" : "black",
                                backgroundColor: props.color, padding: 2,
                                borderRadius: 3
                            }} className="tab-not-selected">{props.title}</span>
                    :
                    props.selected ?
                        <span style={{
                        }} className="tab-selected">{props.title}</span>
                        :
                        <span style={{
                        }} className="tab-not-selected">{props.title}</span>
            }
            
            
        </>
    );
};

export const LabelBold = (props: any) => {
    return (
        <>
            <span style={{
                fontWeight: 'normal'
            }}>&nbsp;&nbsp;{props.children}</span>
        </>
    );
};


export const LabelBoldBigText = (props: any) => {
    return (
        <>
            <span style={{
                fontWeight: 'normal', fontSize: '16px' 
            }}>&nbsp;{props.children}</span>
        </>
    );
};

export const objectAlert = (obj: any) => {
    alert(JSON.stringify(obj));
}