import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { DialogTitleBar, mui } from "../../Helper";
import { CUserRightsAppl, resultEntryDialog } from "../../types";
import ChemieResAndValComponent from "./ChemieResAndValComponent";

const ChemieResultEntryDialog = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'ChemieResultEntry' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
            
    const CancelClick = async () => {
        props.ResultSaved(false, props.reqid, props.valtype);

    };
  
 
    var dlgHeight: number = window.innerHeight - 80;
    var dlgWidth: number = window.innerWidth - 40;
          
 
    return (
        <>
            {props.showDialog &&
                <Dialog style={{ overflowX: "hidden" }} title={<DialogTitleBar title={mui(userRights, "ChemieResultEntry", "textTitle", "Chemie Result")} />}
                    onClose={CancelClick} height={dlgHeight} width={dlgWidth} >
                    <ChemieResAndValComponent mandantid={props.mandantid} reqid={props.reqid} pid={props.pid} fid={props.fid}
                        paramid={props.paramid} reqdatetime={props.reqdatetime} reqprovider={props.reqprovider} valtype={props.valtype} addedparams={props.addedparams}
                        pregnancyweek={props.pregnancyweek} mbyn={props.mbyn} userid={props.userid}
                        showDialog={props.showDialog} ResultSaved={props.ResultSaved} selprofilecode={""} dayWorkSpace={false}></ChemieResAndValComponent>
                    </Dialog>
            }
        </>
    );
}
export default ChemieResultEntryDialog;