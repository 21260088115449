/* eslint-disable react/jsx-no-undef */

import { useEffect, useState } from "react";
import { formatDate, formatTime, mui } from "../../Helper";
import { CResultEntry, ctDataType, CUserRightsAppl, selCodeDataType, emptyREData, MULTI_SELECT_TABLES, CResultEntryOutput, emptyREO } from "../../types";
import axios from 'axios'
import { SelectCode } from "../SelectCode/SelectCode";

import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

import MultiSelectList, { multiSelectProps } from "../ModalForms/MultiSelect/MultiSelectList";
import UserSession from "../UserSession";
interface resSelDialog {
  
    objRE: CResultEntry;
    selType: string;
    secondField: boolean;
}
const CustomResultEntry = (props: resSelDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resseldialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [resultData, setResultData] = useState<CResultEntry>(emptyREData);

    const [resDate, setResDate] = useState(formatDate(new Date().toDateString()));
    const [resTime, setResTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [selCode, setSelCode] = useState<string | "">("");
    const [selDesc, setSelDesc] = useState<string | "">("");

    const [selDefCode, setSelDefCode] = useState<string | "">("");

    // new ms

    const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }

    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {
        
        if (cancel !== true) {
            console.log("callbackMultiSel", savedCodes)

            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }
            props.objRE.sRawResult = "AI"
            props.objRE.sResultText = savedCodes
            setSelCode(savedCodes);
            setSelDesc(savedCodes);
        }
      
        setMultiSelectData(dlgMSEmptyState);
    }

    const openMS = (multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        selectedValues = props.objRE.sResultText;
        console.log("openMS", props.objRE.sResultText)
        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved: MultiSelectSaved
        });
    };

    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);

    //new ms

    const handleResChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);
        if (props.secondField === false) {
            props.objRE.sRawResult = value
            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }

        }
        else {
            props.objRE.sCompType = value
            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }

            setResDate(value)
            setSelCode(value);
            setSelDesc("");
        }
    };

    const handleResChangeTime = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);

        if (props.secondField === true) {
            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }
            props.objRE.sCompType = value
            setResTime(value)
            setSelCode(value);
            setSelDesc("");

        }
        else {
          
            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }
            props.objRE.sRawResult = value
        }  
     
    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackCTSelected", objCT)
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        if (objCT.sCode !== undefined && objCT.sCode !== null) {
            setSelCode(objCT.sCode);
            setSelDesc(objCT.sDescDevelopment);
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackResultSel", obj)
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        if (obj.code !== undefined && obj.code !== null) {
            setSelCode(obj.code);
            setSelDesc(obj.desc);
           
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    useEffect(() => {
        setResultData(props.objRE);
        setSelCode("");
        setSelDesc("");
        
        if (props.secondField === false)
            setSelDefCode(props.objRE.sRawResult);
        else
            setSelDefCode(props.objRE.sCompType);

    }, [])

    const onBlur = async (e: any) => {
        console.log("onBlur CRE response - ", e.target.value)
        setSelDefCode(e.target.value);
       
        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var Msg: string = "";

        if (props.objRE !== undefined) {
            obj = props.objRE
            if (props.secondField === false)
                obj.sRawResult = e.target.value
            else
                obj.sCompType = e.target.value

            obj.sRawResult = e.target.value;
            if (obj.ExistingResult.length > 0) {
                obj.sRecStatus = "CHG"
            }
            else {
                obj.sRecStatus = "UNS"
            }
        }
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
            JSON.stringify({
                objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        if (response?.data !== null) {
            console.log("onBlur CRE response - ", response?.data)
            var objRO = response?.data

            Msg = objRO.objREO.sErrorMsg;
            if (Msg === undefined) Msg = '';
            if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

            if (objRO.objResultEntry.bInvalidCode === true) {
                alert(Msg);
                if (props.secondField === false)
                    props.objRE.sRawResult = e.target.value
                else
                    props.objRE.sCompType = e.target.value

            }
            else {
                if (props.secondField === false)
                    props.objRE.sRawResult = e.target.value
                else
                    props.objRE.sCompType = e.target.value

            }

        };

    };

    const handleChange = (e: any) => {
        setSelDefCode(e.target.value);
        if (props.secondField === false) 
            props.objRE.sRawResult = e.target.value;
        else
            props.objRE.sCompType = e.target.value
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: 'hidden', width: '100px' }}>
                <div className="row">

                    <div className="col-12">
                        {

                            props.selType === "RESULT" ?

                            props.objRE.sResSelTableName === "Date" ?
                                    
                                    props.secondField === false ? (<CustomDate objRE={props.objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={props.objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={props.objRE.sParamID} supplParam2=""
                                        required={false} disabled={false}></CustomDate>) :
                                        (<CustomTime objRE={props.objRE} allowSecond={true} supplParam1={props.objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width="100%" defaulValue={props.objRE.sCompType} id={'ResTime'} source={'selDefCodeSecond'} label="" required={false} disabled={false} secondField={true }></CustomTime>)
                                    
                            :
                            props.objRE.sResSelTableName === "Time" ?
                                        (<CustomTime objRE={props.objRE} allowSecond={true} supplParam1={props.objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width="100%" defaulValue={props.objRE.sRawResult} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={false} secondField={false}></CustomTime>)
                            :
                                props.objRE.sCodeTableID !== "" ?
                                    (<CodeTableSel objRE={props.objRE} autocomplete={true} setSelectedCT={callbackCTSelected} mandantChanged={parseInt(props.objRE.sMandantID)} id={props.objRE.sCodeTableID}
                                        codeTableID={props.objRE.sCodeTableID} selCode={props.objRE.sRawResult} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></CodeTableSel>)
                                    :
                                    (props.objRE.sResSelTableName === "ResultCode" || props.objRE.sResSelTableName === "PhenoType") ?
                                        (<SelectCode objRE={props.objRE} autocomplete={true} disabled={false} setSelectedTable={callbackResultSel} id="ResultCode"
                                            tablename={props.objRE.sResSelTableName} code={props.objRE.sResSelCodeFieldName} desc={props.objRE.sResSelDescrFieldName} selCode={props.objRE.sRawResult}
                                            mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectCode>)
                                                :
                                                (props.objRE.sResSelTableName === "Antibodies") ? <>
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-9">
                                                                <Input
                                                                    title={props.objRE.sResultText}
                                                                    disabled={true}
                                                                    defaultValue={props.objRE.sResultText} value={props.objRE.sResultText}
                                                                    width="100%"
                                                                />
                                                             
                                                            </div>
                                                            <div className="col-3" style={{ verticalAlign: "middle" }}>
                                                                <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px", verticalAlign: "middle" }} themeColor={'primary'}
                                                                    onClick={() => {
                                                                        openMS("Antibody")
                                                                    }} icon="more-vertical"></Button>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                   
                                                    <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                                                        showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>
                                                    
                                                </>
                                                    :
                                                    props.secondField === false ? 
                                        <Input
                                            onBlur={onBlur} disabled={false}
                                                onChange={handleChange} defaultValue={props.objRE.sRawResult} value={props.objRE.sRawResult}
                                            style={{ textAlign: 'right' }}
                                            width="100%"
                                                        />
                                                        :
                                                        <Input
                                                            onBlur={onBlur} disabled={false}
                                                            onChange={handleChange} defaultValue={props.objRE.sCompType} value={props.objRE.sCompType}
                                                            style={{ textAlign: 'right' }}
                                                            width="100%"
                                                        />
                                :
                                (<SelectCode objRE={props.objRE} autocomplete={true} disabled={false} setSelectedTable={callbackResultSel} id="ResultComm"
                                    tablename={"ResultComm"} code={"Code"} desc={"Conclusion"} selCode={props.objRE.sResultComm}
                                    mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectCode>)

                        }
                   
                    </div>

                </div>
            </div>
        </>
    );
}

export default CustomResultEntry;