import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect } from "react";
import { CAddressData } from "../../../types";

interface RemarksTabProps {
    data: CAddressData;
    callbackControlChange(e: any): any;
    contentContainerWidth: number
}

const RemarksTab = (props: RemarksTabProps) => {
    useEffect(() => {
        

        return () => {
           
        };

    }, [])

    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth, width: props.contentContainerWidth - 50 }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Remarks</Label></div>
                            <div className="vertical-wrapper-child">
                                <TextArea autoComplete="off" id="sRemark" name="sRemark" style={{ width: '100%' }}
                                    rows={11} onChange={props.callbackControlChange} value={props.data.sRemark} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Remarks</Label></div>
                            <div className="vertical-wrapper-child">
                                <TextArea autoComplete="off" id="sRemark2" name="sRemark2" style={{ width: '100%' }}
                                    rows={11} onChange={props.callbackControlChange} value={props.data.sRemark2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RemarksTab;