import { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import NavBar from '../NavBar/NavBar';
import { tParamData } from "../../types";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent } from "@progress/kendo-react-dropdowns";
import axios from 'axios'
import UserSession from '../UserSession';

const Leena = (props: any) => {


    // method 1
    // Default empty selected CT state
    var PData = {
        sMandantID: "",
        sParamId: "",
        sNameOnRep: ""
    };

    const [sel, setSel] = useState(PData);

    const [ParamData, setParamData] = useState<tParamData[]>([]);

    const [value, setValue] = useState("");

    const onChange = (event: AutoCompleteChangeEvent) => {
        // setValue(event.value);
        //console.log(event.value)
    };

    const onClose = (event: AutoCompleteCloseEvent) => {
        //setValue(event);
        // console.log(event.target.props.value)
    };

    async function GetParamData() {

        const GetParamData_URL = UserSession.getSystemSettings().API_URL + 'ParamSel/getparamdata';

        const response = await axios.post(GetParamData_URL,
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: tParamData[] = [];
        binddata.push({ DisplayString: "", sMandantID: "", sParamId: "", sNameOnRep: "", sShowOnNFMonitorYN: false, sShowOnNFMonitorOnlyIfEmergYN: false, sNFMonitorGroup: "", sNFMonitorGroupDisplayString: "" });
        if (response?.data !== null)
            response?.data.forEach((o: tParamData) => {
                binddata.push({ DisplayString: o.sParamId + ' : ' + o.sNameOnRep, sMandantID: o.sMandantID, sParamId: o.sParamId, sNameOnRep: o.sNameOnRep, sShowOnNFMonitorYN: false, sShowOnNFMonitorOnlyIfEmergYN: false, sNFMonitorGroup: "", sNFMonitorGroupDisplayString: o.sNFMonitorGroupDisplayString });
            });
        setParamData(binddata);
        binddata.forEach((o: tParamData) => {
            if (o.sParamId === props.selCode) {
                setSel(o)
            }
        });
    } 

    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        // console.log('CodeTableSel UE: props.mandantChanged');
        GetParamData();
    }, [props.mandantChanged])



    return (
<>
        <NavBar callbackMandantChanged={() => { } }>
            <div>
                <p>
                    <Button togglable={true} onClick={() => setSelected(!selected)}>
                        Togglable Button
                    </Button>
                </p>
                <p>
                    <Button togglable={true} onClick={() => setSelected(!selected)} disabled={true} >
                        Togglable Button
                    </Button>
                </p>
                <p>
                    <Button togglable={true} onClick={() => setSelected(!selected)}>
                        Togglable Button
                    </Button>
                </p>
                <p>The button is {selected ? "selected" : "not selected"}.</p>
               
                <div>
                    <AutoComplete
                        style={{ width: "300px" }}
                        data={ParamData}
                        textField="DisplayString"
                        value={value}
                        onClose={onClose }
                        onChange={onChange}
                        
                    />
                </div>
            </div>
            <div>&nbsp;
            </div>

        
            </NavBar>
        </>
    );
}

export default Leena;