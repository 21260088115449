import React, { Suspense, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn, GridRowProps, GridSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { CDayWorkSpaceDetailData, CDayWorkSpaceMasterData, CMainTimeline, CRequestParam, ctDataType, CTimelineData, CTimelineEvent, CUserRightsAppl, OrderProvider, resultEntryDialog, selCodeDataType } from "../../types";
import { SortDescriptor } from "@progress/kendo-data-query";
import { Loader } from '@progress/kendo-react-indicators';
import axios from 'axios';
import UserSession from "../UserSession";
import { TabTitle, dateInternalToSystem, loadFromStorage, mui } from "../../Helper";
import { useLocation } from "react-router-dom";

import {
    IntlService
} from "@progress/kendo-react-intl";
import { getter } from "@progress/kendo-react-common";

import CustomDate from "../Misc/CustomDate";
import { ComboBoxChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { RadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { SelectCode } from "../SelectCode/SelectCode";
import { Splitter, SplitterOnChangeEvent, Stepper, StepperChangeEvent, TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";

import IHValidationComponent from "../ResultAndValidation/IHValidationComponent";
import ChemieResAndValComponent from "../ResultAndValidation/ChemieResAndValComponent";
import UrineSticksResAndValComponent from "../ResultAndValidation/UrineSticksResAndValComponent";
import HamatResAndValComponent from "../ResultAndValidation/HamatResAndValComponent";
import TimelineComp from "../Timeline/TimelineComp";
import ProgressBar from "../Misc/ProgressBar";

const DATE_FORMAT = "dd.mm.yyyy";
const DATE_TIME_FORMAT = "dd.mm.yyyy hh:mm:ss";
const intl = new IntlService("en");

const initialSort: Array<SortDescriptor> = [

];

interface DayWorkspaceProps {
    loadfor: string;
    mandantChanged: number;
}

const DayWorkspace = (props: DayWorkspaceProps) => {
    const [selectedtab, setSelectedtab] = useState<number>(0);

    const handleSelect = (e: TabStripSelectEventArguments) => {
        //console.log(e)
        setSelectedtab(e.selected);
    };

    const emptyMainTimeline : CMainTimeline = {
        title: '',
        tl: []
    };

    const dlgEmptyStateDWSM: CDayWorkSpaceMasterData = {
        mandantID: "", reqID: "", pid: "", fid: "", reqDateTime: "", reqProvider: "", patLastName: "", patFirstName: "", pregnancyWeek: "", gender: "",
        birthDate: "", room: "", station: "", reqStatus: "", emergencyYN: "", valType: [], material: [], tlRequest: emptyMainTimeline, tlMaterial: [], tlParam: []
    }

    const [dayworkspacemasterdata, setDayWorkSpaceMasterData] = useState<CDayWorkSpaceMasterData>(dlgEmptyStateDWSM);

    async function ReqIsReadyForValidation(dwsmd: CDayWorkSpaceMasterData) {
/*        var bok: boolean = true;*/
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/reqisreadyforvalidation',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: '', valtype: '' }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setShowNewIHLayout(response?.data)
        //return bok;
    };

    const ResultSaved = async (save: boolean) => {
        setResultEntryDialogData(dlgEmptyState);
        setUrinSticksDialogData(dlgEmptyState);
        setSeroResultEntryDialogData(dlgEmptyState);
        setHamatDialogData(dlgEmptyState);
        setIHDialogData(dlgEmptyState);
    };

    const SetData = (dwsmd: CDayWorkSpaceMasterData, action: string) => {
        
        
        //console.log("SetData", dwsmd)

        if (dwsmd?.mandantID.trim() === '') {
            setResultEntryDialogData(dlgEmptyState);
            setUrinSticksDialogData(dlgEmptyState);
            setSeroResultEntryDialogData(dlgEmptyState);
            setHamatDialogData(dlgEmptyState);
            setIHDialogData(dlgEmptyState);
        }
        else {

            var suppStr: string = dwsmd.valType[0]?.suppStr
            var valType: string = dwsmd.valType[0]?.valType
            var obj: CDayWorkSpaceDetailData

            for (obj of dwsmd.valType) {

                suppStr = obj.suppStr
                valType = obj.valType

                //console.log("valtype", valType)
                if (suppStr?.trim() === 'C') {
                    setResultEntryDialogData({
                        mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: dwsmd?.reqID.toString(), pid: dwsmd?.pid.toString(), fid: dwsmd?.fid.toString(),
                        paramid: "", reqdatetime: dwsmd?.reqDateTime, reqprovider: dwsmd?.reqProvider, valtype: valType, addedparams: "", pregnancyweek: dwsmd?.pregnancyWeek, mbyn: "0",
                        userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: true
                    })
                } else if (suppStr?.trim() === 'US' || suppStr?.trim() === 'GF' || suppStr?.trim() === 'PU' || suppStr?.trim() === 'LI') {
                    setUrinSticksDialogData({
                        mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: dwsmd?.reqID.toString(), pid: dwsmd?.pid.toString(), fid: dwsmd?.fid.toString(),
                        paramid: "", reqdatetime: dwsmd?.reqDateTime, reqprovider: dwsmd?.reqProvider, valtype: valType, addedparams: "", pregnancyweek: dwsmd?.pregnancyWeek, mbyn: "0",
                        userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: true
                    });
                } else if (suppStr?.trim() === 'S') {
                    if (showNewIHLayout === true) {
                        setIHDialogData({
                            mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: dwsmd?.reqID.toString(), pid: dwsmd?.pid.toString(), fid: dwsmd?.fid.toString(),
                            paramid: "", reqdatetime: dwsmd?.reqDateTime, reqprovider: dwsmd?.reqProvider, valtype: valType, addedparams: "", pregnancyweek: dwsmd?.pregnancyWeek, mbyn: "0",
                            userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: true
                        });
                    } else {
                        setSeroResultEntryDialogData({
                            mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: dayworkspacemasterdata?.reqID.toString(), pid: dayworkspacemasterdata?.pid.toString(), fid: dayworkspacemasterdata?.fid.toString(),
                            paramid: "", reqdatetime: dayworkspacemasterdata?.reqDateTime, reqprovider: dayworkspacemasterdata?.reqProvider, valtype: valType, addedparams: "", pregnancyweek: dayworkspacemasterdata?.pregnancyWeek, mbyn: "0",
                            userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: true
                        });
                    };
                } else if (suppStr?.trim() === 'HM' || suppStr?.trim() === 'MD') {
                    setHamatDialogData({
                        mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: dwsmd?.reqID.toString(), pid: dwsmd?.pid.toString(), fid: dwsmd?.fid.toString(),
                        paramid: "", reqdatetime: dwsmd?.reqDateTime, reqprovider: dwsmd?.reqProvider, valtype: valType, addedparams: "", pregnancyweek: dwsmd?.pregnancyWeek, mbyn: "0",
                        userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: true
                    });
                };

            }

        }

    };

    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'DayWorkspace' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;


    const itemsStepper = [
        { label: mui(userRights, "dayworkspace", "lblReqResVal", "Request Result and Validation"), icon: "k-i-list-unordered" },
        { label: mui(userRights, "dayworkspace", "lblTimeline", "Timeline"), icon: "k-i-track-changes" }
       // { label: mui(userRights, "dayworkspace", "lblQCResVal", "QC Result and Validation"), icon: "k-i-track-changes" }
    ];

    const fidstate = [
        {
            label: mui(userRights, "AdditionalRequestParam", "optReqDate", "Open Cases "),
            value: "OC",
            width: "300px",
        },
        {
            label: mui(userRights, "AdditionalRequestParam", "optParamGroup", "All Cases"),
            value: "AC",
            width: "300px",
        },
        {
            label: mui(userRights, "AdditionalRequestParam", "optReqDate", "Closed Cases "),
            value: "CC",
            width: "300px",
        },
    ];

    const patkind = [
        {
            label: mui(userRights, "AdditionalRequestParam", "optAP", "Outpatient "),
            value: "AP",
            width: "300px",
        },
        {
            label: mui(userRights, "AdditionalRequestParam", "optSP", "Inpatient"),
            value: "SP",
            width: "300px",
        },
        {
            label: mui(userRights, "AdditionalRequestParam", "optOP", "Other patients"),
            value: "OP",
            width: "300px",
        },
    ];
    const [showNewIHLayout, setShowNewIHLayout] = React.useState<boolean>(false);
    const [opOptions, setOpOptions] = useState<OrderProvider[]>([]);
    const [reqprov, setReqProv] = React.useState<string | "">("");
    const [dateFrom, setDateFrom] = React.useState<string | "">("");
    const [sort, setSort] = useState(initialSort);
    const [selectedCode, setSelectedCode] = useState("");
    const [CodeTableData, setCodeTableData] = useState<ctDataType | null>(null);
    const [checkedExtMandant, setCheckedExtMandant] = useState(false);
    const [dayworkspaceData, setDayWorkSpaceData] = useState<CDayWorkSpaceMasterData[]>([]);
    const [dayworkspacedetailData, setDayWorkSpaceDetailData] = useState<CDayWorkSpaceDetailData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = React.useState("OC");
    const [selectedPatKindOption, setSelectedPatKindOption] = React.useState("SP");
    const [station, setStation] = useState<string | "">("");
    const [klinik, setKlinik] = useState<string | "">("");

    const [stepperValue, setStepperValue] = React.useState<number>(0);

    const dlgEmptyState: resultEntryDialog = {
        mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode: "", dayWorkSpace: true
    }

    const columnsOrderProvider = [
        { field: "OrdProvCode", header: mui(userRights, "common", "lblCode", "Code"), width: "100px" },
        { field: "DisplayName", header: mui(userRights, "common", "lblDescription", "Description"), width: "200px" },
    ];

    const [panes, setPanes] = React.useState<Array<any>>([
        { size: "15%", min: "15%", collapsible: true, resizable: true },
        { collapsible: false, resizable: false, keepMounted: true },
    ]);

    const onPaneChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    const [nestedPanes, setNestedPanes] = React.useState<Array<any>>([
        { size: "40%", min: "40%", collapsible: true, resizable: true },
        { resizable: false, keepMounted: true },
    ]);

    const onNestedChange = (event: SplitterOnChangeEvent) => {
        setNestedPanes(event.newState);
    };
    function refreshOrderProviders() {
        var opOptions = JSON.parse(loadFromStorage("orderProvider")!);
        var binddata: OrderProvider[] = [];
        opOptions.forEach((o: OrderProvider) => {
            binddata.push({ ...o, DisplayName: o.Name + (o.FirstName !== '' ? '(' + o.FirstName + ')' : '') });
        });
        setOpOptions(binddata);
    }

    const [resultEntryDialogData, setResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [urinsticksDialogData, setUrinSticksDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [seroresultEntryDialogData, setSeroResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [hamatDialogData, setHamatDialogData] = useState<resultEntryDialog>(dlgEmptyState);
    const [ihDialogData, setIHDialogData] = useState<resultEntryDialog>(dlgEmptyState);

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        //console.log("VT", objCT.sCode, objCT)
        setSelectedCode(objCT.sCode);
        setCodeTableData(objCT);
        setDayWorkSpaceData([]);
    };

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.emergencyreqcolor };
        const trProps: any = { style: rowColor };
        return React.cloneElement(
            trElement,
            { ...trProps }
        );
    };

    const handleFIDStatusChange = React.useCallback(
        (e: RadioGroupChangeEvent) => {
            setSelectedOption(e.value);
        },
        [setSelectedOption]
    );


    const handlePatKindChange = React.useCallback(
        (e: RadioGroupChangeEvent) => {
            setSelectedPatKindOption(e.value);
        },
        [setSelectedPatKindOption]
    );
    
    const SearchClick = async () => {
        setIsLoading(true);
        setDayWorkSpaceMasterData(dlgEmptyStateDWSM)
        setDayWorkSpaceData([])
        setDayWorkSpaceDetailData([])
        setResultEntryDialogData(dlgEmptyState);
        setUrinSticksDialogData(dlgEmptyState);
        setSeroResultEntryDialogData(dlgEmptyState);
        setHamatDialogData(dlgEmptyState);
        setIHDialogData(dlgEmptyState); 

        // for valtype selectedCode 
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'dayworkspace/getdayworklist',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "datefrom": dateFrom, "dateto": "", "valtype": selectedCode, "reqprovider": reqprov, "station": station, "klinik": klinik, "patkind": selectedPatKindOption, "fidstate": selectedOption }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        //console.log("SearchClick", response?.data);
        var binddata: CDayWorkSpaceMasterData[] = [];
        if (response?.data !== null)
            response?.data.forEach((odwsmd: CDayWorkSpaceMasterData) => {


                var arr: CTimelineEvent[] = [];
                var details: CTimelineData = { tlRequest: { title: '', tl: [] }, tlMaterial: [], tlParam: [] };
                //var objTLD: CTimelineData = response?.data
                odwsmd.tlRequest.tl.forEach((o: any) => {
                    var obj: CTimelineEvent = { description: '', date: new Date(), title: '' }
                    obj.description = o.description;
                    var dt: Date | null = dateInternalToSystem(o.tDate);
                    //console.log(dt)
                    if (dt !== null) obj.date = dt;
                    obj.title = o.title;
                    arr.push(obj);
                });

                odwsmd.tlRequest.tl = arr;

                var mainTL: CMainTimeline[] = [];
                odwsmd.tlMaterial.forEach((objMTL: CMainTimeline, index) => {
                    var objPTL: CMainTimeline = { title: '', tl: [] };
                    arr = [];
                    objMTL.tl.forEach((o: any) => {
                        var obj: CTimelineEvent = { description: '', date: new Date(), title: '' }
                        obj.description = o.description;
                        var dt: Date | null = dateInternalToSystem(o.tDate);
                        if (dt !== null) obj.date = dt;
                        obj.title = o.title;
                        arr.push(obj);
                    });

                    objPTL.title = objMTL.title;
                    objPTL.tl = arr;
                    mainTL.push(objPTL);

                });

                odwsmd.tlMaterial = mainTL;

                mainTL = [];
                odwsmd.tlParam.forEach((objMTL: CMainTimeline, index) => {
                    var objPTL: CMainTimeline = { title: '', tl: [] };
                    arr = [];
                    objMTL.tl.forEach((o: any) => {
                        var obj: CTimelineEvent = { description: '', date: new Date(), title: '' }
                        obj.description = o.description;
                        var dt: Date | null = dateInternalToSystem(o.tDate);
                        if (dt !== null) obj.date = dt;
                        obj.title = o.title;
                        arr.push(obj);
                    });

                    objPTL.title = objMTL.title;
                    objPTL.tl = arr;
                    mainTL.push(objPTL);

                });
                odwsmd.tlParam = mainTL;

                binddata.push(odwsmd)
            });

        //console.log("timeline", binddata)
        setDayWorkSpaceData(binddata);

        var dwsmd : CDayWorkSpaceMasterData = dlgEmptyStateDWSM

        SetData(dwsmd,'')
        panes[0].collapsed =false
        setIsLoading(false);
    };

    const callbackMandantChanged = async () => {
        refreshOrderProviders();
        setDayWorkSpaceData([])
        setDayWorkSpaceMasterData(dlgEmptyStateDWSM)
        setDayWorkSpaceData([])
    };

    const idGetter = getter('reqid');

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        //console.log("hi", event)
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'sReqID',
        });

        let selectedIds = []
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let product = dayworkspaceData.find(item => item.reqID.toString() === property.toString())
                selectedIds.push(product)
            }
        }
        //console.log("h0", selectedIds)
        setSelectedState(newSelectedState);
    };

    const handleRequestEditClick = (ReqID: string, PID: string, FID: string) => {
        //console.log("handleRequestEditClick")
        var tabElement: CDayWorkSpaceMasterData;

        const binddata: CDayWorkSpaceDetailData[] = [];
        for (tabElement of dayworkspaceData) {
            if (tabElement.reqID === ReqID) {
                //console.log("handleRequestEditClick 1", tabElement, tabElement.valType)
                setSelectedtab(0)
                setDayWorkSpaceMasterData(tabElement)
                binddata.push(...tabElement.valType);
                panes[0].collapsed = true
                nestedPanes[0].collapsed = true
                SetData(tabElement, 'open')
                break;
            }
        }
        setDayWorkSpaceDetailData(binddata);


        //console.log("handleRequestEditClick", binddata)
    };

    const CustomCellDisplay = (props: GridCellProps) => {
        //console.log("CustomCellDisplay", props.dataItem)
        const ReqID = props.dataItem.reqID;
        const PID = props.dataItem.pid;
        const FID = props.dataItem.fid;
        let item = props.dataItem;
        return (
            <td
                onClick={() => {
                    handleRequestEditClick(ReqID, PID, FID);
                }} >

                <p> {item.patLastName} {item.patFirstName}</p>
                <p> {item.birthDate} {item.gender}</p>
                <p> {item.reqID}</p>
            </td>
        );
    };

    //const ListViewItemRender = (props: ListViewItemProps) => {
    //    let item = props.dataItem;
    //    return (
    //        <div
    //            id={item.SettingID}
    //            className="k-listview-item"
    //            style={{ borderBottom: "1px solid lightgrey", backgroundColor: item.SettingBackColor, padding: 5, color: item.SettingForeColor }}
    //        >
    //            <p>{item.sPatLastName} {item.sPatFirstName}</p>
    //            <p>{item.sBirthDate} {item.sGender}</p>
    //            <p>{item.sReqID}</p>
    //        </div>
    //    );
    //};

    const handleChangeDate = (value: string | "", source: string) => {
        if (source === 'datefrom')
            setDateFrom(value);

        //clearForm();
    };
    const callbackCodeSelected = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        if (controlID === 'sStation') {
            setStation(obj.code);
        } else if (controlID === 'sDeptCode')
            setKlinik(obj.code);
    };

    const evComboChange = (event: ComboBoxChangeEvent) => {
        setReqProv(event.value)
    };

    const handleChangeStepper = (e: StepperChangeEvent) => {
        setStepperValue(e.value);
    };

    useEffect(() => {
        refreshOrderProviders();
        ReqIsReadyForValidation(dayworkspacemasterdata)
        setDateFrom(intl.formatDate(new Date(), "dd.MM.yyyy"))

    }, [])

    var dlgHeight: number = window.innerHeight - 80;
    var dlgWidth: number = window.innerWidth - 40;
    // If there is more content above the splitter, change this calculation
    var paneHeight: number = dlgHeight - 240;

    var patRequestAreaWidth: number = window.innerWidth - 100;
    var divWidth: number = (patRequestAreaWidth / 6) - (6 * 5);
    const location: string = useLocation().pathname;
    var gridHeight: number = window.innerHeight;
    gridHeight -= location.toLowerCase().indexOf("systemfunction") !== -1 ? 130 : 250;
    var minrightPaneWidth: number = window.innerWidth - 440;
    var rightPaneWidth: number = window.innerWidth - 480;
    if (panes[0].collapsed) {
        rightPaneWidth += 350;
    }
    var gridWidth: number = rightPaneWidth - 25;
    if (panes[0].collapsed) {
        gridWidth += 20;
    }


    return (
        <>
            <Splitter
                panes={nestedPanes}
                orientation={"vertical"}
                onChange={onNestedChange}
            >
                <div className="pane-content">
                   {/* <div style={{ overflowX: 'hidden' }}></div>*/}
                    <div className="container-fluid" style={{
                        maxWidth: patRequestAreaWidth, border: '1px solid lightblue', paddingBottom: 5, marginBottom: 5, borderRadius: 0, backgroundColor: "#e0f0ff",
                        width: patRequestAreaWidth - (patRequestAreaWidth * 3 / 100)
                    }}>
                        <div className="row"><div className="col">&nbsp;</div></div>
                        <div className="row">
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'reqdateto'}>{mui(userRights, "comporderlist", "lblReqDateTimeFrom", "Req. date/time from")}</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <CustomDate defaulValue={dateFrom} id={'txtdatefrom'} source={'datefrom'} label=""
                                            required={false} disabled={false} width={'150px'} supplParam1="" supplParam2="" className="k-input-header"
                                            dateChanged={handleChangeDate}></CustomDate>

                                    </div>
                                </div>

                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'valCriteria'}>{mui(userRights, "resultcriteria", "lblValType", "Val Type")}</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={props.mandantChanged} id="valType"
                                            codeTableID='202' selCode={selectedCode} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'orderprovider'}>{mui(userRights, "common", "lblOrderProvider", "Order provider")}</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <MultiColumnComboBox
                                            columns={columnsOrderProvider}
                                            id='orderprovider'
                                            data={opOptions}
                                            textField="DisplayName"
                                            dataItemKey="OrdProvCode"
                                            value={reqprov}
                                            onChange={evComboChange}
                                            clearButton={false}
                                            suggest={true}
                                            className='k-input-header'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'valCriteria'}>{mui(userRights, "resultcriteria", "lblStation", "Station")}</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <SelectCode autocomplete={false} disabled={false} setSelectedTable={callbackCodeSelected} id="sStation"
                                            tablename="Station" code="StationCode" desc="StationName" selCode={station}
                                            mandantChanged={0} width={"100%"} supplParam1="" supplParam2=""></SelectCode>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'valCriteria'}>{mui(userRights, "resultcriteria", "lblKlinik", "Klinik")}</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <SelectCode autocomplete={false} disabled={false} setSelectedTable={callbackCodeSelected} id="sDeptCode"
                                            tablename="Department" code="DeptCode" desc="DeptName" selCode={klinik}
                                            mandantChanged={0} width={"100%"} supplParam1="" supplParam2=""></SelectCode>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child">&nbsp;</div>
                                    <div className="vertical-wrapper-child">
                                        {/*<Input className='k-input-header' autoComplete="off" id="pid" name="pid"*/}
                                        {/*    type='text' onChange={evDataChange} value={data.pid} />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"></div>
                                    <div className="vertical-wrapper-child">
                                        <RadioGroup
                                            data={fidstate}
                                            value={selectedOption}
                                            onChange={handleFIDStatusChange}
                                            layout={"horizontal"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"></div>
                                    <div className="vertical-wrapper-child">
                                        <RadioGroup
                                            data={patkind}
                                            value={selectedPatKindOption}
                                            onChange={handlePatKindChange}
                                            layout={"horizontal"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div style={{ textAlign: "right", display: 'inline-block' }}>
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        SearchClick()
                                    }}>{mui(userRights, "common", "cmdSearch", "Search")}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"></div>
                                    <div className="vertical-wrapper-child"></div>
                                </div>
                            </div>

                        </div>

                        <div className="row"><div className="col">&nbsp;</div></div>

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Stepper value={stepperValue} onChange={handleChangeStepper} items={itemsStepper} style={{ fontSize: '12px' }} />
                    </div>
                </div>
               
                    <Splitter panes={panes} onChange={onPaneChange}>

                        <div className="pane-content-rm" style={{ height: paneHeight, padding: "15px", overflowX: 'hidden' }}>
                            <div className="row">
                                <div className="col">
                                {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                    <Grid
                                        style={{ height: gridHeight, overflowY: 'hidden' }}
                                        data={dayworkspaceData}
                                        dataItemKey={'sReqID'}
                                    >
                                        <GridColumn field="" title="" cell={CustomCellDisplay} />
                                    </Grid>
                                }
                                </div>
                            </div>
                            {/*<ListView*/}
                            {/*    data={dayworkspaceData}*/}
                            {/*    item={ListViewItemRender}*/}
                            {/*/>*/}
                        </div>

                        <div className="pane-content-rm" style={{
                            height: paneHeight, width: rightPaneWidth ,
                        overflowY: 'hidden', border: '0px solid green', overflowX: 'hidden' 
                        }}>
                             <div className="pane-content-rm" style={{ display: stepperValue === 0 ? 'block' : 'none', height: paneHeight, padding: "15px", overflowX: 'hidden' }}>
                                {
                                    <TabStrip id="tabValType" scrollable={true} selected={selectedtab} onSelect={handleSelect} style={{
                                        height: paneHeight, border: '0px solid green', padding: 0, overflowY: 'visible', userSelect: "none"
                                    }}
                                        keepTabsMounted={true}>
                                        {


                                            dayworkspacedetailData.map((tab: CDayWorkSpaceDetailData, index) => (

                                                <TabStripTab key={index} title={<TabTitle title={tab.valType + ' ' + tab.valTypeDesc} selected={index === selectedtab ? true : false} />}>
                                                    <>
                                                        {
                                                            tab.suppStr === 'S' ?
                                                                <Suspense fallback={<Loader />}>
                                                                    <IHValidationComponent mandantid={ihDialogData.mandantid} reqid={ihDialogData.reqid} pid={ihDialogData.pid} fid={ihDialogData.fid}
                                                                        paramid={ihDialogData.paramid} reqdatetime={ihDialogData.reqdatetime} reqprovider={ihDialogData.reqprovider} valtype={ihDialogData.valtype} addedparams={ihDialogData.addedparams}
                                                                        pregnancyweek={ihDialogData.pregnancyweek} mbyn={ihDialogData.mbyn} userid={ihDialogData.userid}
                                                                        showDialog={true} ResultSaved={ihDialogData.ResultSaved} selprofilecode={ihDialogData.selprofilecode} dayWorkSpace={true} ></IHValidationComponent></Suspense> :
                                                                (tab.suppStr === 'C') ?
                                                                    <Suspense fallback={<Loader />}>
                                                                        <ChemieResAndValComponent mandantid={resultEntryDialogData.mandantid} reqid={resultEntryDialogData.reqid} pid={resultEntryDialogData.pid} fid={resultEntryDialogData.fid}
                                                                            paramid={resultEntryDialogData.paramid} reqdatetime={resultEntryDialogData.reqdatetime} reqprovider={resultEntryDialogData.reqprovider} valtype={resultEntryDialogData.valtype} addedparams={resultEntryDialogData.addedparams}
                                                                            pregnancyweek={resultEntryDialogData.pregnancyweek} mbyn={resultEntryDialogData.mbyn} userid={resultEntryDialogData.userid}
                                                                            showDialog={resultEntryDialogData.showDialog} ResultSaved={resultEntryDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={true}></ChemieResAndValComponent></Suspense>
                                                                    : (tab.suppStr === 'US' || tab.suppStr === 'GF' || tab.suppStr === 'PU' || tab.suppStr === 'LI') ?
                                                                        <Suspense fallback={<Loader />}>
                                                                            <UrineSticksResAndValComponent mandantid={urinsticksDialogData.mandantid} reqid={urinsticksDialogData.reqid} pid={urinsticksDialogData.pid} fid={urinsticksDialogData.fid}
                                                                                paramid={urinsticksDialogData.paramid} reqdatetime={urinsticksDialogData.reqdatetime} reqprovider={urinsticksDialogData.reqprovider} valtype={urinsticksDialogData.valtype} addedparams={urinsticksDialogData.addedparams}
                                                                                pregnancyweek={urinsticksDialogData.pregnancyweek} mbyn={urinsticksDialogData.mbyn} userid={urinsticksDialogData.userid}
                                                                                showDialog={urinsticksDialogData.showDialog} ResultSaved={urinsticksDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={true}></UrineSticksResAndValComponent></Suspense>
                                                                        : (tab.suppStr === 'HM' || tab.suppStr === 'MD') ?
                                                                            <Suspense fallback={<Loader />}>
                                                                                <HamatResAndValComponent mandantid={hamatDialogData.mandantid} reqid={hamatDialogData.reqid} pid={hamatDialogData.pid} fid={hamatDialogData.fid}
                                                                                    paramid={hamatDialogData.paramid} reqdatetime={hamatDialogData.reqdatetime} reqprovider={hamatDialogData.reqprovider} valtype={hamatDialogData.valtype} addedparams={hamatDialogData.addedparams}
                                                                                    pregnancyweek={hamatDialogData.pregnancyweek} mbyn={hamatDialogData.mbyn} userid={hamatDialogData.userid}
                                                                                    showDialog={hamatDialogData.showDialog} ResultSaved={hamatDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={true}></HamatResAndValComponent></Suspense>
                                                                            : ''

                                                        }

                                                    </>
                                                </TabStripTab>

                                            ))
                                        }
                                    </TabStrip>
                                }
                              </div>
                        <div style={{ display: stepperValue === 1 ? 'block' : 'none', width: rightPaneWidth, height: paneHeight, padding: "0px", paddingBottom: '0', border: '0px solid blue', overflowY: 'scroll' }}>
                            {
                                dayworkspacemasterdata.mandantID !== '' ?
                                    <Suspense fallback={<Loader />}>
                                        <TimelineComp tlRequest={dayworkspacemasterdata.tlRequest} tlMaterial={dayworkspacemasterdata.tlMaterial} tlParam={dayworkspacemasterdata.tlParam} ></TimelineComp>
                                    </Suspense> :
                                    ''
                            }
                        </div>
                        <div style={{ display: stepperValue === 2 ? 'block' : 'none', width: rightPaneWidth, height: paneHeight, padding: "0px", paddingBottom: '0', border: '0px solid blue', overflowY: 'scroll' }}>
                            {
                                dayworkspacemasterdata.mandantID !== '' ?
                                   '' :
                                    ''
                            }
                        </div>
                        </div>


                    </Splitter>
           
               
                
            </Splitter>
        </>
    );
}
export default DayWorkspace;
