import { Collection } from "@progress/kendo-react-common";
import { scannerUIProps } from "./components/ModalForms/ScannerUI/ScannerUI";

declare global {
    var userRights: CUserRightsAppl[] | null;
    var defaultLanguage: string;
    var locale: string;
}

export const MULTI_SELECT_SEP: string = "·";

export type MULTI_SELECT_TABLES = "ReportPage" | "DiagnoseCode" | "OrderProvider" | "" | "Antibody";

//Constant for record status[RECSTATUS] in any grid
export const SAVED : string = "S"
export const UNSAVED : string = "UNS"
export const CHANGED : string = "CHG"
export const DELETED : string = "DEL"
export const VALIDATED : string = "VLD"
export const ENTERED : string = "ENT"
export const ENTERED_AUTO : string = "ENTA"

export type ListItemData = {
    id: string;
    name: string;
    selected: boolean;
}

export type ErrorInfo = {
    success: boolean;
    errnr: string
    errdesc: string
}

export type Mandant = {
    Code: string
    Description: string
    MBYN: string
    DisplayString: string
}

export type SystemSettings = {
    API_URL: string
    CMMode: number
    websiteLanguage: string
    hideInternalMenus: string
    socketPort: string;
}

export type SystemUser = {
    UserID: string
    Login: string
    Password: string
    GrpBelongedToID: string
    AdministratorYN: string
    UserLastName: string
    UserFirstName: string
    UserShortName: string
    UserInitials: string
    IdleTimeOutInMin: string
    DefMandant: string
    DefMandantDesc:string
    CurrentMandant: string
    CurrentMandantDesc: string
    MBYN: boolean
    errinfo: ErrorInfo
    MandantCat: Mandant[]
    ClientName: string
    Language: string
}

export interface CAddressData {
    state: string;
    sAddressTypes: string;
    sPID: string;
    sPIDNum: number;
    sMandantID: string;
    sSalutation: string;
    sInitials: string;
    sLastName: string;
    sFirstName: string;
    sBirthName: string;
    // sLastNameCaps: string;
    // sFirstNameCaps: string;
    // sBirthNameCaps: string;
    sLastNamePhonetic: string;
    sFirstNamePhonetic: string;
    sBirthNamePhonetic: string;
    sAddrLine3: string;
    sAddrLine4: string;
    sStreet: string;
    sPOBox: string;
    sBillYN: string;
    BillYN: boolean;
    sDomicileID: string;
    sDomicileCountry: string;
    sDomicilePLZ: string;
    sDomicileCity: string;
    // sDomicileCityCaps: string;
    sDomicileCityPhonetic: string;
    sDomicileEnding: string;
    sShortName: string;
    sSex: string;
    sWeight: string;
    sHeight: string;
    sBloodGroup: string;
    sRhesus: string;
    sPhenoType: string;
    sKell: string;
    sFirstUseDate: string;
    sBirthDate: any;
    sAhvNr: string;
    sTelPrivate: string;
    sTelOffice: string;
    sKey2: string;
    sKey3: string;
    sTelInternal: string;
    sFaxPrivate: string;
    sFaxOffice: string;
    sEMailAddr: string;
    sWebsite: string;
    sRepOutputTypeRefDoc: string;
    sRepOutputTypeTrtDoc: string;
    sRepOutputTypeAttDoc: string;
    sRepOutputTypeCopyToDoc: string;
    sRepToEmail: string;
    sExtBloodGrpYN: string;
    ExtBloodGrpYN: boolean;
    sMBIOPrevaluesYN: string;
    sLanguage: string;
    sStatus: string;
    sStatusDate: string;
    sConcordatNrAmb: string;
    sConcordatNrStat: string;
    sRemark: string;
    sRemark2: string;
    sEDTAPhenomenaYN: string;
    sNTParam: string;
    // notification template for param (NON MB) or profile (MB)
    sNTBacteria: string;
    // notification template for bacteria (MB)
    sNTAntibiotic: string;
    // notification template for antibiotic (MB)
    sNotifyFilePath: string;
    sNotifyPassMandatoryYN: string;
    NotifyPassMandatoryYN: boolean;
    sNotifyOnlyNegResultYN: string;
    NotifyOnlyNegResultYN: boolean;
    sDoNotPrintNotificationYN: string;
    DoNotPrintNotificationYN: boolean;
    sInActive: string;
    objRecUpd: CRecordUpdationGeneral;
    sAge: string;
    sDoNotUseForStatsYN: string;
    DoNotUseForStatsYN: boolean;
    sCompGroup: string;
    sDoNotUseEPDYN: string;
    DoNotUseEPDYN: boolean;
    sGeneralConsentYN: string;
    GeneralConsentYN: boolean;
    sWarmTransfusionYN: string;
    WarmTransfusionYN: boolean;
    sPassportID: string;
    sTransferCode: string;
    sDisplayString: string;
};

export const emptyAddress: CAddressData = {
    state: "EMPTY",
    sAddressTypes: "",
    sPID: "",
    sPIDNum: 0,
    sMandantID: "",

    sSalutation: "",

    sInitials: "",

    sLastName: "",

    sFirstName: "",

    sBirthName: "",

    // sLastNameCaps As String
    // sFirstNameCaps As String
    // sBirthNameCaps As String
    sLastNamePhonetic: "",

    sFirstNamePhonetic: "",

    sBirthNamePhonetic: "",

    sAddrLine3: "",

    sAddrLine4: "",

    sStreet: "",

    sPOBox: "",

    sBillYN: "",
    BillYN: false,

    sDomicileID: "",

    sDomicileCountry: "",

    sDomicilePLZ: "",

    sDomicileCity: "",

    // sDomicileCityCaps As String
    sDomicileCityPhonetic: "",

    sDomicileEnding: "",

    sShortName: "",

    sSex: "",

    sWeight: "",

    sHeight: "",

    sBloodGroup: "",

    sRhesus: "",

    sPhenoType: "",

    sKell: "",

    sFirstUseDate: "",

    sBirthDate: "",

    sAhvNr: "",

    sTelPrivate: "",

    sTelOffice: "",

    sKey2: "",

    sKey3: "",

    sTelInternal: "",

    sFaxPrivate: "",

    sFaxOffice: "",

    sEMailAddr: "",

    sWebsite: "",

    sRepOutputTypeRefDoc: "",

    sRepOutputTypeTrtDoc: "",

    sRepOutputTypeAttDoc: "",

    sRepOutputTypeCopyToDoc: "",

    sRepToEmail: "",

    sExtBloodGrpYN: "",

    sMBIOPrevaluesYN: "",

    sLanguage: "",

    sStatus: "",

    sStatusDate: "",

    sConcordatNrAmb: "",

    sConcordatNrStat: "",

    sRemark: "",

    sRemark2: "",

    sEDTAPhenomenaYN: "",

    sNTParam: "",

    // notification template for param (NON MB) or profile (MB)
    sNTBacteria: "",

    // notification template for bacteria (MB)
    sNTAntibiotic: "",

    // notification template for antibiotic (MB)
    sNotifyFilePath: "",

    sNotifyPassMandatoryYN: "",

    sNotifyOnlyNegResultYN: "",

    sDoNotPrintNotificationYN: "",

    sInActive: "",

    objRecUpd: {
        sModifiedBy: "",
        sModifiedDate: "",
        sModifiedTime: "",
        sRecordedBy: "",
        sRecordedDate: "",
        sRecordedTime: "",
        sDelete: "",
        sInActive: "",
    },

    sAge: "",

    //  Printing Blankreport
    sDoNotUseForStatsYN: "",

    sCompGroup: "",

    sDoNotUseEPDYN: "",

    sGeneralConsentYN: "",

    sWarmTransfusionYN: "",

    sPassportID: "",

    sTransferCode: "",
    DoNotUseForStatsYN: false,
    DoNotUseEPDYN: false,
    GeneralConsentYN: false,
    WarmTransfusionYN: false,
    ExtBloodGrpYN: false,
    NotifyPassMandatoryYN: false,
    NotifyOnlyNegResultYN: false,
    DoNotPrintNotificationYN: false,
    sDisplayString:""
};
export interface COneAddress {
    objAddress: CAddressData
    AdrBdAKSData: adrBdAKS[];
    AdrBdGrpData: adrBdGrp[]
    AdrBdTrfData: adrBdTrf[]
    AdrPatBldData: CAdrPatBld[];
    AdrExtPIDData: CAdrExtPID[];
    AdrExternTSData: CSearchAdrExtTS[];

    BGDesc: string
    RHDesc: string
    PTDesc: string
    KellDesc: string
    SexDesc: string
    ReqCount: string
    ActiveFID: string
    Age: string
    sMsg: string
    DisabledScanControls: boolean
    DisableScanPDF: boolean
    DisableShowDocButton: boolean
    DefReqSplComment1Color:string
    DefReqSplComment2Color: string
    ReqSplComment1Color: string
    ReqSplComment2Color : String
}

export interface CCaseData {
    sMandantID: string;
    sPID: string;
    sOldPID: string;
    sFID: string;
    sPatKind: string;
    sAdmDateDate: string;
    sAdmDateTime: string;
    sAdmDatePlanDate: string;
    sAdmDatePlanTime: string;
    sDischargeDateDate: string;
    sDischargeDateTime: string;
    sExternYN: string;
    ExternYN: boolean;
    sExternProvider: string;
    sExternCaseNumber: string;
    sEmergencyYN: string;
    EmergencyYN: boolean;
    sRemark: string;
    sInactiveYN: string;
    sAdmClassID: string;
    sKey2: string;
    sDeptCode: string;
    sStation: string;
    sRoom: string;
    sPatKindDesc: string;
    sBillingType: string;
    sBillingClass: string;
    sENANumber: string;
    sGarant: string;
    sDeptName: string;
    sInsurancePlan: string;
    sInsValidTo: string;
    sBAGNr: string;
    sBed: string;
    //TreatingDrID, ReferingDrID and AttendingDrID are just read and info is displayed on case data form.These fields are non - editable fields in LabMain.
    sTreatingDrID: string;
    sReferingDrID: string;
    sAttendingDrID: string;
    //COVID related info is just read and displayed on case data form. These fields are non-editable fields in LabMain.
    sEmployerName: string;
    sEmployerStreet: string;
    sEmployerCity: string;
    sEmployerPLZ: string; 
    sExternHospitalYN: string;
    ExternHospitalYN: boolean; 

    objRecUpd: CRecordUpdationGeneral;

    //following fields do not belong to the table
    bUseCheckDigit: boolean;
    sExternHospitalBackColor: string;
    sTreatingDrName: string; 
    sReferingDrName: string; 
    sAttendingDrName: string;
}

export const emptyCaseData: CCaseData = {
    sMandantID: "",
    sPID: "",
    sOldPID: "",
    sFID: "",
    sPatKind: "",
    sAdmDateDate: "",
    sAdmDateTime: "",
    sAdmDatePlanDate: "",
    sAdmDatePlanTime: "",
    sDischargeDateDate: "",
    sDischargeDateTime: "",
    sExternYN: "",
    ExternYN: false,
    sExternProvider: "",
    sExternCaseNumber: "",
    sEmergencyYN: "",
    EmergencyYN: false,
    sRemark: "",
    sInactiveYN: "",
    sAdmClassID: "",
    sKey2: "",
    sDeptCode: "",
    sStation: "",
    sRoom: "",
    sPatKindDesc: "",
    sBillingType: "",
    sBillingClass: "",
    sENANumber: "",
    sGarant: "",
    sDeptName: "",
    sInsurancePlan: "",
    sInsValidTo: "",
    sBAGNr: "",
    sBed: "",
    sTreatingDrID: "",
    sReferingDrID: "",
    sAttendingDrID: "",
    sEmployerName: "",
    sEmployerStreet: "",
    sEmployerCity: "",
    sEmployerPLZ: "",
    sExternHospitalYN: "",
    ExternHospitalYN: false,
    objRecUpd: {
        sModifiedBy: "",
        sModifiedDate: "",
        sModifiedTime: "",
        sRecordedBy: "",
        sRecordedDate: "",
        sRecordedTime: "",
        sDelete: "",
        sInActive: "",
    },
    bUseCheckDigit: false,
    sExternHospitalBackColor: "",
    sTreatingDrName: "",
    sReferingDrName: "",
    sAttendingDrName: "",
}

export interface CCaseDataOutput {
    MandantID: string;           
    Msg: string;
    objCaseData: CCaseData;
    CaseList: string[];
}

export interface CRequestSearchFilter {
    mandantid: string
    pid: string
    requestid: string
    requestfromdate: string
    requesttodate: string
    orderprovider: string
    fid: string
    workstationid:any
}

export type ctDataType = {
    sCodeTableID: string,
    sMandantID: string,
    sCode: string,
    sAbbrDevelopment: string,
    sDescDevelopment: string,
    sMemoDevelopment: string,
    sLanguage: string,
    sSuppl1: string,
    sSuppl2: string,
    sSuppl3: string,
    sUserGroup: string,
    DisplayString: string,
    selected: boolean
};

export type masterTableDataType = {
    sCode: string,
    sDesc: string,
    DisplayString: string
};

export type valcriteriaListEntry = {
    reqid: string,
    reqdatetime: string;
    reqdate: string;
    reqtime: string;
    pregnancyweek: string,
    pid: string,
    fid: string,
    birthdate: string
    gender: string
    name: string
    reqprovider: string
    valtype: string
    valtypeSuppl1:string
    diffnr: string
    valyn: string
    reqnr: string
    reqdatesort: string
    resultyn: string
    rowcolor: string
    valyncolcolor: string
    resultyncolcolor: string
};

export type resultCriteriaListEntry = {
    reqid: string,    
    reqdatetime: any,
    pid: number,    
    fid: number,    
    name: string
    reqprovider: string
    birthdate: any
    valtype: string
    valtypeSuppl1: string
    reqnr: number    
    pregnancyweek:string
    reqdatesort: any
    externmandantid: number
    emergencyreqcolor: string
};

export type requestProviderCombo = {
    MandantID: string;
    OrdProvCode: string;
    Name: string;
    FirstName: string;
}

export type addressSearchFilter = {
    emptySearch: boolean;
    mandantid: string;
    firstname: string;
    lastname: string;
    pid: string;
    birthdateinternal: string;
    reqid: string;
    casenr: string;
    datefrominternal: string;
    datetointernal: string;
    reqprov: requestProviderCombo;
    phonetic: boolean;
    patWithRequests: boolean;
    workstationid: any;
}

export type TabEntry = {
    key: string
    id: number
    header: string
}

export interface addressDialogData {
    loadPID: string;
    showDialog: boolean;
    inViewport: boolean;
}

export interface addressDialog {
    data: addressDialogData;
    callbackAddressDialogClosed(newPID:string): any;
}

export interface caseDataDialogData {
    mandantid: string;
    //pid: string;
    //fid: string;
    selpat: COneAddress | null;
    showDialog: boolean;
    inViewport: boolean;
}

export interface caseDataDialog {
    data: caseDataDialogData;
    callbackCaseDataDialogClosed(newFID: string): any;   
};

export interface requestDialogData {
    reqid: string;
    pid: string;
    fid: string;
    bloodProduct: boolean;
    showDialog: boolean;
    inViewport: boolean;
    extcall: boolean;
}

export interface requestDialog {
    data: requestDialogData;
    callbackRequestDialogClosed(objReturnRequest: COneRequest | null): any;
}

export interface pdfViewer {
    base64PDF: string;
    url: string;
    showDialog: boolean;
    callbackPDFDialogClosed(): any;
}

export type CRecordUpdationGeneral = {
    sModifiedBy: string;
    sModifiedDate: string;
    sModifiedTime: string;
    sRecordedBy: string;
    sRecordedDate: string;
    sRecordedTime: string;
    sDelete: string;
    sInActive: string;
}

export type CRequestMaterial = {
    sMandantID: string;
    sReqID: string;
    sMaterialID: string;
    sInLaborYN: string;
    sExtractionDate: string;
    sExtractionTime: string;
    sExtractionType: string;
    sCollectedDuration: string;
    sCollectedAmount: string;
    sMaterialQuality: string;
    sExtractionPlace: string;
    sInactiveYN: string;
    sExtractionPlaceText: string;
    sTransportMedium: string;
    objRecUpd: CRecordUpdationGeneral;

    sEditReason: string;
    sExtTimeEmptyYN: string;
    sMatInLabDate: string;
    sMatInLabTime: string;
    sCollectorID: string;
    sMatAcceptedBy: string;
    sMatPicture: string;

    //new?
    sSerothekPos: string;
    sBloodTakenInLabYN: string;
    sMatScannedOn: string;

    //these fields are not present in table.
    sMaterialDesc: string
    sReqDate: string;
    sReqTime: string;
    sReqProvider: string;
    sReqComment: string;
    sMacOrHand: string;
    sPregWeek: string;
    sCopyToAddr: string;
    sCopyToOrdProv: string;
    sReqProviderAddn: string;
    sReqExtAddress: string;
    sHandProfCount: string;
    sShortName: string;
    sShortNameMedVal: string;
    sFID: string;
    bSetDirtyFlag: Boolean;
    sReqNr: string;
    sAdrRemark2: string;
    sAnticoagYN: string;
    sHeparin: string;
    sDiagnose: string;
    sReqExtCaseNr: string;
    sDiagnoseCode: string;
    sReqMandantID: string;
    sDoctorName: string;
    sCollectedDurationDialysat: string;
    sCollectedAmountDialysat: string;

    bUpdate: boolean;
}

export type CRequestParam = {
    sMandantID: string;
    sReqID: string;
    sParamId: string;
    sParamTypeFlags: string;
    sParamEmergencyYN: string;
    sBelongsToRatios: string;
    sBelongsToProfiles: string;
    sBillParamYN: string;
    sStatus: string;
    sOrderedYN: string;
    sInactiveYN: string;
    sFormCode: string;
    objRecUpd: CRecordUpdationGeneral;
    sEditReason: string;
    // not actually part of the table
    sParamName: string;
    sResultEntryYN: string;
    sMaterial: string;
    sPercentYN: string;
    sCallerName: string;
    sColSelected: string;
    bSetDirtyFlag: Boolean;
    bExistsInDB: Boolean;
    sAddnParamYN: string;
    sMachineDiffYN: string;
    sInternComment: string;
    lRowNr: number;
    sExtractionType: string;
    sExternPostOrderYN: string;
    sAltParamID: string;
}
export type CRequestResult = {
    sMandantID : string;
    sReqID : string;
    sParamId : string;
    sSerID : string;
    sResult : string;
    sResultText : string;
    sResultUnit : string;
    sRawResult : string;
    sRawResultUnit : string;
    sWorkPlaceID : string;
    sResultComm : string;
    sSpComment : string;
    sCommText : string;
    sCompType : string;
    sCCompYN : string;
    sResultFlag : string;
    sResultStatus : string;
    sDilution : string;
    sCutOff : string;
    sCutResult : string;
    sResultRack : string;
    sResultCup : string;
    sTechName : string;
    sTechRemark : string;
    sResultDateTimeDate : string;
    sResultDateTimeTime : string;
    sTelephonedResultYN : string;
    sTelUser : string;
    sTelNum : string;
    sTelDate : string;
    sTelTime: string;
    sQualitativeResult: string;
    sInactiveYN : string;
    objRecUpd: CRecordUpdationGeneral;    
    sResultFile: string;
    sIncompatibleCompYN: string;
    sReagence: string;
    sCriticalValYN: string;
    sEmergencyCompResYN: string;
    sResultChangedManuallyYN: string;
    sAutoValidatedYN: string;    
}
export type CRequestResultView = {
    sMandantID: string;
    sReqID: string;
    sParamId: string;
    sParamName: string;
    sValType: string;
    sPreValue1: string;
    sPreValue2: string;
    sPreValue3: string;
    sPreValueDate1: string;
    sPreValueDate2: string;
    sPreValueDate3: string;
    sPreValue1TooltipText: string;
    sPreValue2TooltipText: string;
    sPreValue3TooltipText: string;
    sPreValueDesc1: string;
    sPreValueDesc2: string;
    sPreValueDesc3: string;
    sRawResult: string;
    sResult: string;
    sInterpretation: string;
    sCompType: string;
    sComment: string;
    sRefValue: string;
    sUnit: string;
    sResultText: string;
    sCommText: string;
}

export type CRequestMBParam = {
    sMandantID: string;
    sReqID: string;
    sProfileID: string;
    sParamId: string;
    sBillPositiveYN: string;
    sStatus: string;
    sInactiveYN: string;
    objRecUpd: CRecordUpdationGeneral;
    // not actually part of the table
    sParamName: string;
    bSetDirtyFlag: Boolean;
    lRowNr: number;
    lGridCol: number;
    sMaterial: string;
}

export type CRequestMBResult = {
    sMandantID: string;
    sReqID: string;
    sProfileID: string;
    sParamId: string;
    sResult: string;
    sResultText: string;
    sResultUnit: string;
    sRawResult: string;
    sRawResultUnit: string;
    sWorkPlaceID: string;
    sResultComm: string;
    sSpComment: string;
    sCommText: string;
    sCompType: string;
    sCCompYN: string;
    sResultFlag: string;
    sResultStatus: string;
    sDilution: string;
    sCutOff: string;
    sCutResult: string;
    sResultRack: string;
    sResultCup: string;
    sTechName: string;
    sTechRemark: string;
    sResultDateTimeDate: string;
    sResultDateTimeTime: string;
    sTelephonedResultYN: string;
    sTelUser: string;
    sTelNum: string;
    sTelDate: string;
    sTelTime: string;
    sResultFile: string;
    sQualitativeResult: string;
    sEditReason: string;
    sAutoValidatedYN: string;
    sTimeToPositive: string;
    sBottleNr: string;
    sGrowingDays: string;
    sStartDateTimeDate: string;
    sStartDateTimeTime: string;
    sEndDateTimeDate: string;
    sEndDateTimeTime: string;
    sInactiveYN: string;
    objRecUpd: CRecordUpdationGeneral;
    // fields not in actually table
    sParamSerothekYN: string;
    sOrderedYN: string;
}

export type CRequestData = {
    sParamID: string;
    sParamName: string;
    sProfileID: string;
    sMaterialID: string;
    sParamStatus: string;
    sParamStatusDescr: string;
    sResultStatus: string;
    sResultStatusDescr: string;
    sRawResult: string;
    sResult: string;
    sInterpretation: string;
    sResultText: string;
    sResultComment: string;
    sResultType: string;
    sRefVal: string;
    sUnit: string;
    sShowQualitativeYN: string;
};

export interface CTimelineEvent {
    description: string;
    date: Date;
    title: string;
};

export interface CTimeline {
    description: string;
    tDate: string;
    title: string;   
};

export type COneRequest = {

    objRequest: CRequest
    RequestMaterialData: CRequestMaterial[];
    RequestParamData: CRequestParam[];
    RequestResultData: CRequestResultView[];
    RequestMBParamData: CRequestMBParam[];
    RequestMBResultData: CRequestMBResult[];
    SelReqData: CRequestData[];

    // Extra fields
    sEmergency: string
    sdisplayReqDate: string
    ColorReq: string;
    ColorEmergencyReq: string;
    ValTypes: string;
    DoneInMandantShortName: string;
    ReqProviderName: string;
    StationDescr: string;
    ReportPageIDs: string;
    ReqStatusDescr: string;
    DisabledScanControls: boolean;
    DisableScanPDF: boolean;
    DisableShowDocButton: boolean;
    objTL: CTimeline[];
    DefReqSplComment1Color: string;
    DefReqSplComment2Color: string;
    ReqSplComment1Color: string;
    ReqSplComment2Color: string;
    details: any;
    expanded?: boolean
}

export type CRequest = {

    sMandantID: string

    sReqID: string

    sReqDate: string

    sReqTime: string

    sReqOrdDate: string

    sReqOrdTime: string

    sReqNr: string

    sTransferCode: string

    sReqProvider: string

    sExtCaseNr: string

    sEmergencyYN: string

    sCopyToAddr: string

    sCopyToOrdProv: string

    sPregnantYN: string

    sPregnancyWeek: string

    sTelReportYN: string

    sExtractionYN: string

    sPID: string

    sFID: string

    sComment: string

    sDiagnose: string

    sMedicine: string

    sReqProviderAddn: string

    sBillRequestYN: string

    sBillBatchNr: string

    sReqStatus: string

    sRepPrintDateDate: string

    sRepPrintDateTime: string

    sMachineYN: string

    sExportSerothekYN: string

    sExternYN: string

    sDoctorNum: string

    sReqExtAddress: string

    sOMRFormNr: string

    sDoctorName: string

    sInactiveYN: string

    objRecUpd: CRecordUpdationGeneral

    sEditReason: string

    sDiagnoseCode: string

    sReportComment: string

    sRepeat: string

    sEmergencyTime: string

    sWeight: string

    sHeight: string

    sHandProfCount: string

    bGetHandProfCount: boolean

    sMainGroup: string

    sCompGroup: string

    sAnticoagYN: string

    sHeparin: string

    sNBCheckYN: string

    sAddnParamYN: string

    sExtBillCheck: string

    nOMRFormNrOrder: number

    bAddAddnBP: boolean

    sNotTransfusedInLast48HrsYN: string

    sTransfusedLatelyYN: string

    sSymptomatic: string

    sOutBreakManagement: string

    bAddnBPNewGroup: boolean

    dblTotalTaxPoints: number

    sToDateDate: string

    sToDateTime: string

    sWorkpageInfo: string

    sLoggedInMandantID: string

    sSpecialComment1: string

    sSpecialComment2: string

    sANRESISDataSentYN: string

    sRhesusProphylaxeYN: string

    sRhesusProphylaxeDate: string

    sRhesusProphylaxeTime: string

    sExternTransfusionDate: string

    sExternTransfusionTime: string

    sBGMother: string

    sReqCommentLblPrintedYN: string

    sKnownAlloAntibodiesYN: string

    sChemotherapyYN: string

    sCopyTrtDocYN: string

    sOrdProvCostCenter: string

    reqprov: { MandantID: string, OrdProvCode: string, Name: string, FirstName: string };

    sBloodProdOrderYN: string
    sBPReason: string
    sBloodCollVoucherPrintedByUser: string
    sBloodCollVoucherPrintedDateTimeDate: string
    sBloodCollVoucherPrintedDateTimeTime: string

}

export type CNFMonitorSettings = {
    sKey?: number
    SettingID: string
    MandantID?: string
    SettingType?: string
    SettingTypeDesc?: string
    SettingValue?: string
    SettingForeColor?: string
    SettingBackColor?: string
    Remark?: string
    InactiveYN?: string
    UserName?: string
    bUpdate?: boolean
    TempSettingValue?: string
    Discontinued?: boolean;
    expanded?: boolean;
    inEdit?: boolean | string;
    locked?: boolean;
    txtSelNF?: string;
};

export type CFIDData = {
    mandantid: string;
    fid: string;
    admissiondatetime: string;
    dischargedatetime: string;
};

export type tParamData = {
    sMandantID: string,
    sParamId: string,
    sNameOnRep: string,
    DisplayString: string,
    sShowOnNFMonitorYN?: boolean,
    sShowOnNFMonitorOnlyIfEmergYN?: boolean,
    sNFMonitorGroup: string,
    sNFMonitorGroupDisplayString: string,
    inEdit?: boolean | string;
    bUpdate?: boolean,
    UserName?: string,
    disableEdit?: boolean
};

export type CCxCodeTableMaster = {
    sCodeTableID: string,
    sDescDevelopment: string,
    sLanguage: string,
    sMemoDevelop: string,
    sSortingNumericYN: string,
    sInMemoryYN: string,
    sScrollYN: string,
    sMandantSpecificYN: string,
    collCodeTableData: [],
    RecUpd: CRecordUpdationGeneral,
    sInactiveYN: string,
    bMandantSpecific: boolean,
    bScrollYN: boolean,
    bSortingNumeric: boolean,
    sSuppl1Header: string,
    sSuppl2Header: string,
    sSuppl3Header: string,
    bInMemory: boolean
};

export type CCxCodeTableData = {
    sCodeTableID: string,
    sMandantID: string,
    sCode: string,
    sAbbrDevelopment: string,
    sDescDevelopment: string,
    sMemoDevelopment: string,
    sLanguage: string,
    sSuppl1: string,
    sSuppl2: string,
    sSuppl3: string,
    sUserGroup: string,
    sCTTextGe: string,
    sCTTextFr: string,
    sCTTextIt: string,
    RecUpd: CRecordUpdationGeneral
};

export type CRequestFormCodeData = {
    sFormCode: string
    sFormName: string
    sTitleImage: string
    sTabOrder: string
    sValidFrom: string
    sValidTo: string
    sLaboratory: string
    nNoCols: number
    sTitleColorName: string
    sReqFormColor: string
    sMBYN: string
    col1: RequestData[]
    col2: RequestData[]
    col3: RequestData[]
    col4: RequestData[]
    col5: RequestData[]
}

export type RequestData = {
    bNew: boolean
    bNotSelectable: boolean
    sLTIURL: string
    bCheckBoxValue: boolean
    sIcon: string
    sParamID: string
    sNameOnRep: string
    sColor: string
    sTitleYN: string
    sTitleName: string
    nColNr: number
    nRowNr: number
    sFormCode: string
    sFormName: string
    sResult: string
    sAnalyt: string
    sMaterial: string
    sLinkedParams: string
    sAllowOnlyOneParamFromGroup: string
    sAACYN: string
    sAHWYN: string
    sSerID: string

    sSentYN: string

    sHintText: string
    sHintWebsite: string
    sHintDocument: string

    sEnterResultYN: string
    sHideDetailsYN: string
    sGroupHeaderYN: string

    sParamsInBlock: string
    sElementCat: string
    sLaboratory: string

    sParamMaterial: string
    sParamMaterialDesc: string
    sCheckForBGRHYN: string
    sCheckForPregYN: string
    sLinkedParamNotMandatoryYN: string
    sMaterialGrp: string
    sExtrTypeMandatoryYN: string
    sOneRequestForEachParam: string
    sMatPicture: string
    sNotSelectableWhenCrossmatchRequiredYN: string
    sAntiDParamYN: string
    sExtrPlaceCodeOrTextMandatoryYN: string
    sPrintBloodProductFormYN: string
    sReqOrderProv: string

    sTitleDocument: string
    sNumericResultYN: string

    sEmergencyYN: string

    nStartValue: number
    nIncrementBy: number
    nNrOfOccurences: number

    bProfileOrRatio : boolean
    bBold: boolean
    bValidated: boolean
    bNewParam: boolean
    nTabID: number
    sCheckBoxID: string
    sTextBoxID: string
    sParamResultText: string
    sRowID: string
    sTableID: string
    sImageID: string
    sTooltip: string

    sListOfParamsInBlock: string
    sListOfGroupMembers: string

    sRecordedDate: string
    sRecordedBy: string

    bDisabled: boolean
    sParamType: string
    sParamTypeFlags: string
    sBillYN: string
    sEnteredDateTime: string

    sNotDoneOnHolidayYN: string
    sNotDoneOnSaturdayYN: string
    sNotDoneOnSundayYN: string
    sParamNotDoneOnDaysWarningMsg: string
    collGroupMembers: RequestData[]

    //'RequestParam related fields

    objRP_RecUpd: CRecordUpdationGeneral
    sRP_ParamTypeFlags: string
    sRP_ParamEmergencyYN: string
    sRP_BelongsToRatios: string
    sRP_BelongsToProfiles: string
    sRP_BillParamYN: string
    sRP_Status: string
    sRP_OrderedYN: string
    sRP_InactiveYN: string
    sRP_FormCode: string
    sRP_EditReason: string

}

export interface masterCodeTableDialog {
    ctm: CCxCodeTableMaster;
    showDialog: boolean;
    CTMSaved(CTM: CCxCodeTableMaster | null, Insert: boolean): any;
}

export interface copyOrMoveCodeTableDataDialog {
    codeTableID: string;
    code: string;
    description: string;
    mandantID: string;
    isMove: boolean;
    userID: string;
    showDialog: boolean;
    CopyMoveSaved(cancel: boolean, isMove: boolean, newMandantCode: string): any;
}

export type CSaveStatus = {
    bRecordSaved: boolean;
    sMsg: string;
};

export type CUserRightsAppl = {
    sCustomer: string;
    sFormName: string;
    sObjectID: string;
    sDescInternal: string;
    sDescDevelopment: string;
    sLanguage: string;
    sDefaultValue: string;
    sFieldFormat: string;
    sInactiveYN: string;
    sDeleteYN: string;
    lNewState1: number
    lModifyState1: number;
    sTabIndex: string;
    sTabStop: string;
    bTabStop: boolean;
    sAutoComplete: string;
    sURTextGe: string,
    sURTextFr: string,
    sURTextIt: string,
    bInvisible: boolean,
    bLocked: boolean,
    RecUpd: CRecordUpdationGeneral;
};

export type OrderProvider = {
    FirstName: string;
    MandantID: string;
    Name: string;
    OrdProvCode: string;
    DisplayName: string;
};

export type ReqInfoBar = {
    mandantid: string;
    mandantdesc:string;
    reqid: string;
    reqdatetime: string;
    reqdate: string;
    reqtime: string;
    reqnr: string;
    reqprovider: string;
    reqproviderdesc: string;
    handprofcount: string;
    absneutrophile: string;
    pid: string;
    piddesc: string;
    lastname: string;
    firstname: string;
    fid: string;
    admdate: string;
    admtime: string;
    admdatetime: string;
    dischargedate: string;
    dischargetime: string;
    dischargedatetime: string;
    patkind: string;
    patkinddesc: string;
    deptcode: string;
    deptname: string;
    stationcode: string;
    stationname: string;
    room: string;
    roomdesc: string;
    bed: string;
    birthdate: string;
    gender: string;
    genderdesc: string;
    weight: string;
    height: string;
    edtaphenomena: string;
    latestaksinfo: string;
    latestantibody: string;
    antibodydescbackcolor: string;
    bginfo: string;
    telnr: string;
    pregweeks: string;
    anticoag: string;
    heparin: string;    
    reqcomment: string;
    reqcommentcolor: string;
    reqextadd: string;
    emergency: false,
    emergencycolor: string
    age: string;
    compgroupinfo: string;
    sc1color: string;
    sc2color: string;
    defsc1color: string;
    defsc2color: string;
    disableshowdocbutton: boolean;
    disabledscancontrols: boolean;
    disablescanresultpdf: boolean;
    listmatinfo: CRequestMaterial[];
};

export interface viewResultDialogCriteria {
    mandantid: string;
    mbyn: boolean;
    pid: string;
    fid: string;
    gendercode: string;
    birthdate: string;
    pregnancyweeks: string;
    toage: string;
    reqid: string;
    reqdate: string;
    reqtime: string;
    medvalidation: string;
    valtype: string;
};

export interface viewResultDialog {
    objVRC: viewResultDialogCriteria
    showDialog: boolean;
    cancleClicked(): any;
};

export type viewResult = {
    mandantid: string;
    paramid: string;
    paramname: string;
    orderedyn: string;
    valtype: string;
    paramtypeflag: string;
    prevaluedays: string;
    paramgroup: string;
    recstatus: string;
    paramnamecolor: string;
    paramstatus: string;
    chkrefvalyn: string;
    resulttype: string;
    workgroup: string;
    showQualitativeyn: string;
    unit: string;
    reaction: string;
    belongstoprofiles: string;
    preval1: string;
    preval2: string;
    preval3: string;
    prevaldate1: string;
    prevaldate2: string;
    prevaldate3: string;
    prevaldesc1: string;
    prevaldesc2: string;
    prevaldesc3: string;
    preval1tooltip: string;
    preval2tooltip: string;
    preval3tooltip: string;
    resultcode: string;
    interpretation: string;
    interpretationcolor: string;
    rawresult: string;
    resultflag: string;
    resulttext: string;
    resultchangedmanuallyyn: string;
    dilution: string;
    serid: string;
    refvalue: string;
    refflag: string;
    comment: string;
    commentfreetext: string;
    comptype: string;
    objParamRef: CParamRef
};

export type CParamRef = {
    sMandantID: string;
    sParamId: string;
    sGender: string;
    sToAge: string;
    sFromTime: string;
    sToTime: string;
    sFromDate: string;
    sToDate: string;
    sLowFlagYN: string;
    sHighFlagYN: string;
    sEquation: string;
    sRefValCode: string;
    sLowRefVal1: string;
    sHighRefVal1: string;
    sLowRefVal2: string;
    sHighRefVal2: string;
    sLowRefVal3: string;
    sHighRefVal3: string;
    sLowRefVal4: string;
    sHighRefVal4: string;
    sLowRefVal5: string;
    sHighRefVal5: string;
    sLLimTest: string;
    sHLimTest: string;
    sWarnCall1YN: string;
    sWarnCall2YN: string;
    sWarnCall3YN: string;
    sWarnCall4YN: string;
    sWarnCall5YN: string;
    sValLevel: string;
    sLowExtTest: string;
    sHighExtTest: string;
    sLowCheckYN: string;
    sHighCheckYN: string;
    sThRange1: string;
    sThRange2: string;
    sThRange3: string;
    sThRange4: string;
    sParamAdd1: string;
    sResultCode1: string;
    sParamAdd2: string;
    sResultCode2: string;
    sComment: string;
    sPathologyResult: string;
    sInactiveYN: string;
    objRecUpd: CRecordUpdationGeneral
    sRefValueString: string;
    sRefFlag: string;
};

export interface hintDialogData {
    NameOnRep: string;
    HintText: string;
    HintWebsite: string;
    HintDocument: string;
    showDialog: boolean;
}

export interface hintDialog {
    data: hintDialogData;
    callbackhintDialogClosed(): any;
}

export interface matDialogData {
    objMat: CRequestMaterial;
    showDialog: boolean;
}

export interface matDialog {
    data: matDialogData;
    callbackmatDialogClosed(): any;
    callbackmatDialogSubmit: (event: any) => void,
    //callbackmatDialogClosed(): any;
}

export type selCodeDataType = {
    code: string,
    mandantid: string,
    desc: string,
    DisplayString: string
    addnfield: string
    selected: boolean
};

export type adrBdGrp = {
    sMandantID: string;
    sPID: string;
    sReqID: string;
    sReqDate: string;
    sReqTime: string;
    ReqDateDisplay: string;
    sBldGrp: string;
    sRhesus: string;
    PhaenTypeDesc: string;
    sCtrlNr: string;
    sComment: string;
    sFullBGYN: string;
    KellDesc: string;
    DATDesc: string;
    ShowRedTick: boolean;
    ShowRedTickDisplay: string;
};

export type adrBdAKS = {
    sMandantID: string;
    sPID: string;
    sReqID: string;
    sReqDate: string;
    sReqTime: string;
    ReqDateDisplay: string;
    sAksResult: string;
    sAksComment: string;
    sAntibodyID: string;
    sAksEnzym: string;
    sAKSVALIDDate: string;
    sAksValidTime: string;
    AKSVALIDDateDisplay: string;
    sBGChangeSerID: string;
    sAntibodyNotToPrint: string;
};

export type adrBdTrf = {
    KeyField: string;
    sMandantID: string;
    sPID: string;
    sReqID: string;
    sReqDate: string;
    sReqTime: string;
    ReqDateDisplay: string;
    sBldBgNr: string;
    sCompType: string;
    BldBgBGrpDesc: string;
    BldBgRhsDesc: string;
    sTransDateDate: string;
    sTransDateTime: string
    TransDateDisplay: string;
    sReaction: string;
    sSwitch: string;
    sBillYN: string;
    sTransChargeYN: string;
    sDeliveryDateDate: string;
    sDeliveryDateTime: string;
    DeliveryDateDisplay: string;
    sBGChangeSerID: string;
    sIncompatibleCompReason: string;
    sBldBgType: string;
    sReservedDate: string;
    sReservedTime: string;
    ReservedDateDisplay: string;
    sRemark: string;
    CompPhenoTypeDesc: string;
    CompKellDesc: string;
};

export interface reqProvDataType {
    MandantID: string;
    OrdProvCode: string;
    Name: string;
    FirstName: string;

}

export type CAdrPatBld = {
    key: string;

    sMandantID: string;

    sPID: string;

    sOldPID: string;

    sIDFrgCard: string;

    sIssueBy: string;

    sIssueDate: string;

    sRemark: string;

    sBloodGroup: string;

    sRhesus: string;

    sPhenoType: string;

    sBGChangeSerID: string;

    sCWYN: string;

    sInactiveYN: string;

    objRecUpd: CRecordUpdationGeneral;

    // following fields do not belong to the table
    sBGDesc: string;

    sRHDesc: string;

    sPTDesc: string;

    sIssueByDesc: string;
    CWYN: boolean;
    sRecStatus: string;
}

export type CAdrExtPID = {
    key: string;
    sMandantID: string;
    sPID: string;
    sExtLab: string;
    sExtPID: string;
    sInactiveYN: string;
    sFirstName: string;
    sLastName: string;
    objRecUpd: CRecordUpdationGeneral;
    sRecStatus: string;
}

export type CSearchAdrExtTS = {
    key: string;
    sReqID: string;
    sBG: string;
    sBGDesc: string;
    sRH: string;
    sRHDesc: string;
    sPT: string;
    sPTDesc: string;
    sGrpCWYN: string;
    sKell: string;
    sKellDesc: string;
    sDat: string;
    sDatDesc: string;
    sTSIssueBy: string;
    sTSIssueByDesc: string;
    sTSIssueDate: string;
    sRemark: string;
    sEnteredBy: string;
    sEnteredByName: string;
    sEnteredDate: string;
    sAksResult: string;
    sAksResultDesc: string;
    sAksValidDate: string;
    sAksValidTime: string;
    sAntibodyID: string;
    sAksComment: string;
    sBGChangeSerID: string;
    sReqDate: string;
    sReqTime: string;
    sFullBGYN: string;
    // to enable binding
    FullBGYN: boolean;
    CWYN: boolean;
    sRecStatus: string;
}

export type CPLZCat ={
    sMandantID :string
    sCode :string
    sCountry :string
    sPLZ :string
    sCity :string
    sEnding :string
    sKantonID :string
    sRemark :string
    sInactiveYN :string
    RecUpd :CRecordUpdationGeneral
}

export type CResultView = {
    sTabName :string
    sTabColor :string
    sCol1 :string
    sCol2 :string
    sCol3 :string
    sCol4 :string
    sCol5 :string
    sCol6 :string
    sCol7 :string
    sCol8 :string
    sCol9 :string
    sCol10 :string
    sCol11 :string
    sCol12 :string
    sReportPDFFile :string
    sRowColor :string
    sBoldTitle :string
    sItalicsTitle :string
    sUnderlineTitle :string
}

export type CResultViewOutput = {
    sTabName: string
    sTabColor: string
    sTextInWhiteYN:string
    ResultViewData: CResultView[];
}

export type CAddnReqParam = {
    sReqID :string
    sReqNr :string
    sReqDate :string
    sPID :string
    sPatName :string
    sBirthdate :string
    sGender :string
    sFID :string
    sOrdProv :string
    sSerothekDetails :string
    sAddnParamYN :string
    sRecordedDate :string
    sParamID: string
    sTransportMedium: string
    sRowColor:string
}

export type CAddnReqParamOutput = {
    listAddnReqParam: CAddnReqParam[]
    listParamGroup: CGenType[]
    listWorkGroup: CGenType[]
}

export type CGenType = {
     sCode :string
     sDesc:string
}

export const emptyREData: CResultEntry = {
    sMandantID: "",
    sPID: "",
    sFID:"",
    sReqID: "",
    sReqDate: "",
    sReqTime: "",
    sReqProvider: "",
    sParamID: "",
    sParamDesc: "",
    sPreValueDays: "",
    sUnit: "",
    sParamType: "",
    sEntryUnit: "",
    sResultType: "",
    sChkRefValYN: "",
    sRawResult: "",
    sRawResult1: "",
    sRawResult2: "",
    sRefResult: "",
    sInterpretationCode: "",
    sInterpretationCode1: "",
    sInterpretationCode2: "",
    sInterpretationDesc: "",
    sInterpretationDesc1: "",
    sInterpretationDesc2: "",
    sInterpretationDesc3: "",
    sQualitativeResult: "",
    sDefResult: "",
    sResultComm: "",
    sResultComm1: "",
    sResultComm2: "",
    sDefResultComm: "",
    sResultCommDesc:"",
    sDilution: "",
    sCutOff: "",
    sCutResult: "",
    sResultDateTimeDate: "",
    sResultDateTimeTime: "",
    sValType: "",
    sResultStatus: "",
    sAutoValidatedYN: "",
    sWorkPlaceID: "",
    sTelephonedYN: "",
    sResultFlag: "",
    sResultFlag1: "",
    sResultFlag2: "",
    sParamTypeFlag: "",
    sBelongsToProfiles: "",
    sProbMaterial: "",
    sParamStatus: "",
    sBelongToRatio: "",
    sHighestVal: "",
    sLowestVal: "",
    sAllowResults: "",
    sZeroResultYN:"",
    sExtStatus: "",
    sAddnParamYN: "",
    sShowQualitativeYN: "",
    sPreVal1: "",
    sPreVal2: "",
    sPreVal3: "",
    sPreValDate1: "",
    sPreValDate2: "",
    sPreValDate3: "",
    sPreVal1Tooltip: "",
    sPreVal2Tooltip: "",
    sPreVal3Tooltip: "",
    sRefValue: "",
    sRefFlags: "",
    sValLevel: "",
    sLowRefVal: "",
    sHighRefVal: "",
    sValidate: "",
    sCodeTableID: "",
    sResSelTableName: "",
    sResSelCodeFieldName: "",
    sResSelDescrFieldName: "",
    sResultColColor: "",
    objParamRef: {
        sMandantID: "",
        sParamId: "",
        sGender: "",
        sToAge: "",
        sFromTime: "",
        sToTime: "",
        sFromDate: "",
        sToDate: "",
        sLowFlagYN: "",
        sHighFlagYN: "",
        sEquation: "",
        sRefValCode: "",
        sLowRefVal1: "",
        sHighRefVal1: "",
        sLowRefVal2: "",
        sHighRefVal2: "",
        sLowRefVal3: "",
        sHighRefVal3: "",
        sLowRefVal4: "",
        sHighRefVal4: "",
        sLowRefVal5: "",
        sHighRefVal5: "",
        sLLimTest: "",
        sHLimTest: "",
        sWarnCall1YN: "",
        sWarnCall2YN: "",
        sWarnCall3YN: "",
        sWarnCall4YN: "",
        sWarnCall5YN: "",
        sValLevel: "",
        sLowExtTest: "",
        sHighExtTest: "",
        sLowCheckYN: "",
        sHighCheckYN: "",
        sThRange1: "",
        sThRange2: "",
        sThRange3: "",
        sThRange4: "",
        sParamAdd1: "",
        sResultCode1: "",
        sParamAdd2: "",
        sResultCode2: "",
        sComment: "",
        sPathologyResult: "",
        sInactiveYN: "",
        objRecUpd: {
            sModifiedBy: "",
            sModifiedDate: "",
            sModifiedTime: "",
            sRecordedBy: "",
            sRecordedDate: "",
            sRecordedTime: "",
            sDelete: "",
            sInActive: ""
        },
        sRefValueString: "",
        sRefFlag: "",
    },
    bLockRow: false,
    bDisableResultEntry: false,
    bInvalidCode: false,
    sResultFreeText: "",
    sResultFreeText1: "",
    sResultFreeText2:"",
    sResultText: "",
    sResultText1: "",
    sResultText2: "",
    sCommText: "",
    sCommText1: "",
    sCommText2: "",
    sCompType: "",
    sRefCompBar: "",
    sRefCompType: "",
    sCompBloodGroup: "",
    sCompRhesus: "",
    sCompExpires: "",
    sCompPhenoType: "",
    sCompKell: "",
    sCompAntiCw: "",
    sIncompatibleCompYN: "",
    sCrossMatchNotCompatibleYN: "",
    sFormula: "",
    sResultPositiveYN: "",
    sAntibodyValidDate: "",
    sAntibodyIdentity: "",
    sReservationDate:"",
    sSerIDToValidate: "",
    sNotifyYN: "",
    sNotifyType: "",
    sNotifyPlace: "",
    sCommNotifyYN: "",
    sCommNotifyType: "",
    sCommNotifyPlace:"",
    sParamGroup: "",
    sOrderedYN: "", 
    sPercentYN: "",
    sMachineDiffYN:"",
    sReRunParamYN: "",
    sResultPosYN: "",
    sRecStatus: "",
    sEditReason:"",
    ExistingResult: [],    
    ExistingResult1: [],
    ExistingResult2: [],
    sGridKey: "",
    sAdminYN: "",
    sBackColor:"",
};
export type CResultEntry = {
    sMandantID: string
    sPID: string
    sFID: string
    sReqID: string
    sReqDate: string
    sReqTime: string
    sReqProvider: string
    sParamID: string
    sParamDesc: string
    sPreValueDays: string
    sUnit: string
    sParamType: string
    sEntryUnit: string
    sResultType: string
    sChkRefValYN: string
    sRawResult: string
    sRawResult1: string
    sRawResult2: string
    sRefResult: string
    sInterpretationCode: string
    sInterpretationCode1: string
    sInterpretationCode2: string
    sInterpretationDesc: string
    sInterpretationDesc1: string
    sInterpretationDesc2: string
    sInterpretationDesc3: string
    sQualitativeResult: string
    sDefResult: string
    sResultComm: string
    sResultComm1: string
    sResultComm2: string
    sDefResultComm: string
    sResultCommDesc: string
    sDilution: string
    sCutOff: string
    sCutResult: string
    sResultDateTimeDate: string
    sResultDateTimeTime: string
    sValType: string
    sResultStatus: string
    sAutoValidatedYN: string
    sWorkPlaceID: string
    sTelephonedYN: string
    sResultFlag: string
    sResultFlag1: string
    sResultFlag2: string
    sParamTypeFlag: string
    sBelongsToProfiles: string
    sProbMaterial: string
    sParamStatus: string
    sBelongToRatio: string
    sHighestVal: string
    sLowestVal: string
    sAllowResults: string
    sZeroResultYN: string
    sExtStatus: string
    sAddnParamYN: string
    sShowQualitativeYN: string
    sPreVal1: string
    sPreVal2: string
    sPreVal3: string
    sPreValDate1: string
    sPreValDate2: string
    sPreValDate3: string
    sPreVal1Tooltip: string
    sPreVal2Tooltip: string
    sPreVal3Tooltip: string
    sRefValue: string
    sRefFlags: string
    sValLevel: string
    sLowRefVal: string
    sHighRefVal: string
    sValidate: string
    sCodeTableID: string
    sResSelTableName: string
    sResSelCodeFieldName: string
    sResSelDescrFieldName: string
    sResultColColor: string
    objParamRef: CParamRef
    bLockRow: boolean
    bDisableResultEntry: boolean
    bInvalidCode: boolean
    sResultFreeText: string
    sResultFreeText1: string
    sResultFreeText2: string
    sResultText: string
    sResultText1: string
    sResultText2: string
    sCommText: string
    sCommText1: string
    sCommText2: string
    //sero related
    sCompType: string
    sRefCompBar: string
    sRefCompType: string
    sCompBloodGroup: string
    sCompRhesus: string
    sCompExpires: string
    sCompPhenoType: string
    sCompKell: string
    sCompAntiCw: string
    sIncompatibleCompYN: string
    sCrossMatchNotCompatibleYN: string
    sFormula: string
    sResultPositiveYN: string
    sAntibodyValidDate: string
    sAntibodyIdentity: string
    sReservationDate: string
    sSerIDToValidate: string
    sNotifyYN: string
    sNotifyType: string
    sNotifyPlace: string
    sCommNotifyYN: string
    sCommNotifyType: string
    sCommNotifyPlace: string
    sParamGroup: string
    sOrderedYN: string
    sPercentYN: string
    sMachineDiffYN: string
    sReRunParamYN: string
    sResultPosYN: string
    sRecStatus: string
    sEditReason: string
    ExistingResult: CRequestResult[]
    ExistingResult1: CRequestResult[]
    ExistingResult2: CRequestResult[]
    sGridKey: string
    sAdminYN: string
    sBackColor: string
};

export const emptyREO: CResultEntryOutput = {
    listResEntry: [],
    sQCMaxTimeWarningParams: "",
    sParamsInGrid: "",
    collPrevalResult: [],
    collResultCodeComments: [],
    collReaction: [],
    collRatioValLevel: [],
    collParamUnderProfile: [],
    bQualResultColVisible: false,
    collValidatedParams: [],
    sProfileID: "",
    sProfileDesc: "",
    bProfileSel: false,
    sProfileBackColor: "",
    sProfileResultType: "",
    bProfileValidated: false,
    bDisableProfileSel: false,
    sProfileToolTip:"",
    sUserID: "",
    sValType: "",
    bRefreshGrid: false,
    bMachineYN: false,
    sMachineProfile: "",
    bIncompatibleCompEntryExists: false,
    sErrorMsg: "",
    bInProfileParamNotAddedExist: false
};

export type CResultEntryOutput = {
    listResEntry: CResultEntry[];
    sQCMaxTimeWarningParams: string;
    sParamsInGrid: string;
    collPrevalResult:[];
    collResultCodeComments: [];
    collReaction: [];
    collRatioValLevel: [];
    collParamUnderProfile: CRequestParam[];
    bQualResultColVisible: boolean;
    collValidatedParams: [];
    sProfileID: string;
    sProfileDesc: string;
    bProfileSel: boolean;
    sProfileBackColor: string;
    sProfileResultType: string;
    bProfileValidated: boolean;
    bDisableProfileSel: boolean;
    sProfileToolTip:string
    sUserID: string;
    sValType: string;
    bRefreshGrid: boolean;
    bMachineYN: boolean;
    sMachineProfile: string;
    bIncompatibleCompEntryExists: boolean;
    sErrorMsg: string;
    inEdit?: boolean | string;
    disableEdit?: boolean;
    sIcon?: string;
    bInProfileParamNotAddedExist: boolean;
}

export interface resultEntryDialog {
    mandantid: string;
    reqid: string;
    pid: string;
    fid: string;
    paramid: string;
    reqdatetime: string;
    reqprovider:string
    valtype: string;
    addedparams: string;
    pregnancyweek: string;
    mbyn: string;
    userid: string;
    showDialog: boolean;
    ResultSaved(Save: boolean,reqID:string, valType:string): any; 
    selprofilecode: string;
    dayWorkSpace: boolean;
}

export type CMatInLabData = {
    sPID: number
    sFID: number
    sReqID: number
    sReqDate: any
    sMandantID: string
    sReqProvider: string
    sOMRFormNr: string
    sMatQuality: string
    sMatID: string
    sMatDescr: string
    sExtractionDateTime: string
    sOrdLastName: string
    sOrdFirstName: string
    sRoom: string
    sPatLastName: string
    sPatFirstName: string
    sGender: string
    sBirthDate: string
    sReqComment: string
    sReqExtAddress: string
    sWorkPageInfo: string
    sDiagnose: string
    sCaseRemark: string
    sPatRemark: string
    sPatRemark2: string
    sRequestExtractionYN: string
    sExtractionType: string
    sStation: string
    sTubeColor: string
    sEmergencyYN: string
    sCollectorID: string
    sBG: string
    sRH: string
    sPT: string
    sColor: string
    sPIDDetails: string
    sPIDDetailsAddn: string
    sOrdProvDetails: string
    sLatestAntibody: string
    sRemarks: string
    sParamResult: string
    collMatData: matInLabOutput[]
    expanded?: boolean;
 }

export type matInLabOutput = {
    mandantid: string;
    reqid: string;
    reqdate: string;
    pid: string;
    fid: string;
    piddetails: string;
    piddetailsaddn: string;
    ordprovdetails: string;
    room: string;
    latestantibody: string;
    remarks: string;
    reqextaddress: string;
    workpageinfo: string;
    diagnose: string;
    omrformnr: string;
    matid: string;
    matdetails: string;
    matdesc: string;
    tubecolor: string;
    extdatetime: string;
    extdatetimecolcolor: string;
    emergencycolor: string;
    
    collectorid: string;
    msg: string;
    msgtitle: string;
    tooltip: string;
    showRequestdatayn: string;

    //editable
    paramresult: string;
    extractiontype: string
    inlaboryn: string
    resultyn: string
    extrdate: string
    extrtime: string

    update : boolean
};

export type componentOrderListData = {
    pid: number
    fid: number
    lastname: string
    firstname: string
    birthdate: string
    gender: string
    reqid: number
    reqdatetime: any
    bg: string
    rh: string
    ph: string
    kell: string
    antibodies: string
    nrofcomps: string
    comptype: string
};

export interface resultSelDialog {
    selType: string;
    objRE: CResultEntry;
    selqualresult: boolean;
    showDialog: boolean;
    CodeSelected(Code: string | null, Desc: string | null): any;
    col?:number
}

export interface PrintData{
    sParamId: string
    sParamShortName: string
    sWorkGroup: string
    sWorkPlaceID: string
    sBarcode: string
    sBarCodeID: string
    sBarcodeLayout: string
    sMatCode: string
    sPrintLabelType: string
    sNumOfLabels: number
    sExtractionDateTime: string
    sWorkPlaces: string
    sExtTypeCode: string
    sExtTypeDesc: string
    sExtTimeEmptyYN: string
    sExtractionPlace: string
    sExtractionPlaceDesc: string
    sMedienID: string
    sParamResults: string
    sOnlyPatKind: string
    sPrint: string

    sWorkGroupName: string
    sLBLDesc: string
    sMatDesc: string
    sParamDesc: string

}       

export type PrintItemsData = {
    collPrintData: PrintData[]
    collPrintDataLabel: PrintData[]

}

export interface ItemsToPrintDialogData {
    reqData: CRequest
    matid: string;
    sformnr: string;
    enmwhattoprint: number;
    bprintdirectly: boolean;
    bprintwhenrequestplan: boolean;
    bprintreqlabel: boolean;
    bprintmedienlabelsonly: boolean;
    bprintomrbarcodenndlabel: boolean;
    bfrommatinlab: boolean;
    showDialog: boolean;
}

export interface patientReportsDialog {  
    mandantid: string;
    pid: string;   
    showDialog: boolean;
    FormClosed(Closed: boolean): any;
}

export const emptyRequest: CRequest = {
        sMandantID: "",
        sReqID: "",
        sReqDate: "",
        sReqTime: "",
        sReqOrdDate: "",
        sReqOrdTime: "",
        sReqNr: "",
        sTransferCode: "",
        sReqProvider: "",
        sExtCaseNr: "",
        sEmergencyYN: "",
        sCopyToAddr: "",
        sCopyToOrdProv: "",
        sPregnantYN: "",
        sPregnancyWeek: "",
        sTelReportYN: "",
        sExtractionYN: "",
        sPID: "",
        sFID: "",
        sComment: "",
        sDiagnose: "",
        sMedicine: "",
        sReqProviderAddn: "",
        sBillRequestYN: "",
        sBillBatchNr: "",
        sReqStatus: "",
        sRepPrintDateDate: "",
        sRepPrintDateTime: "",
        sMachineYN: "",
        sExportSerothekYN: "",
        sExternYN: "",
        sDoctorNum: "",
        sReqExtAddress: "",
        sOMRFormNr: "",
        sDoctorName: "",
        sInactiveYN: "",
        objRecUpd: {
            sModifiedBy: "",
            sModifiedDate: "",
            sModifiedTime: "",
            sRecordedBy: "",
            sRecordedDate: "",
            sRecordedTime: "",
            sDelete: "",
            sInActive: ""
        },
        sEditReason: "",
        sDiagnoseCode: "",
        sReportComment: "",
        sRepeat: "",
        sEmergencyTime: "",
        sWeight: "",
        sHeight: "",
        sHandProfCount: "",
        bGetHandProfCount: false,
        sMainGroup: "",
        sCompGroup: "",
        sAnticoagYN: "",
        sHeparin: "",
        sNBCheckYN: "",
        sAddnParamYN: "",
        sExtBillCheck: "",
        nOMRFormNrOrder: 0,
        bAddAddnBP: false,
        sNotTransfusedInLast48HrsYN: "",
        sTransfusedLatelyYN: "",
        sSymptomatic: "",
        sOutBreakManagement: "",
        bAddnBPNewGroup: false,
        dblTotalTaxPoints: 0,
        sToDateDate: "",
        sToDateTime: "",
        sWorkpageInfo: "",
        sLoggedInMandantID: "",
        sSpecialComment1: "",
        sSpecialComment2: "",
        sANRESISDataSentYN: "",
        sRhesusProphylaxeYN: "",
        sRhesusProphylaxeDate: "",
        sRhesusProphylaxeTime: "",
        sExternTransfusionDate: "",
        sExternTransfusionTime: "",
        sBGMother: "",
        sReqCommentLblPrintedYN: "",
        sKnownAlloAntibodiesYN: "",
        sChemotherapyYN: "",
        sCopyTrtDocYN: "",
    sOrdProvCostCenter: "",
    sBloodProdOrderYN: "",
    sBPReason: "",
    sBloodCollVoucherPrintedByUser: "",
    sBloodCollVoucherPrintedDateTimeDate: "",
    sBloodCollVoucherPrintedDateTimeTime: "",
        reqprov: {
            MandantID: "",
            OrdProvCode: "",
            Name: "",
            FirstName: ""
    }
         
    };

export type CPatientReports ={
    sMandantID: string 
    sMandantDesc:string
    sAddressMandant: string 
    sPID: string
    sCaseNr: string 
    sCheckDigit: string 
    sName: string 
    sFName: string 
    sBirthDate: string
    sSelectedCase: string 
    listCaseData: CPatientReportsCaseData[]
    listReportData: CPatientReportsReportData[]
    bIncludeInactiveCasesYN: boolean 
    bPhoneticYN: boolean 
    bIncludeInactivePatientsYN: boolean 
    bSetAllPagesPrintedYN: boolean 
    bShowAllFIDYN: boolean
    bPrintCopyToRep: boolean
    bMBYN: boolean
    sPDFBase64:string
    sMsg: string
    sMsgTitle: string
    sUserID:string
};

export type CPatientReportsCaseData = {
    sCaseNr: string
    sAdmissionDate: string
    sDischargeDate: string
    sDeptCode: string
    sStation: string
};

export type CPatientReportsReportData = {
    sReportPageID: string
    sPrintYN: string
    sPrintLastPageYN: string
    sPageNr:string
    sNrOfCopies: string
    sPrintNewestAddnDocYN: string
    sUpdatePageStatusYN: string
    bShowPageStatusCheckBox: boolean
};

export interface dashboardDialogData {
    dashboarditem: number;
    showDialog: boolean;
}
export interface dashboardDialog {
    data: dashboardDialogData;
    callbackDialogClosed(): any;
}

export type PLZObject = {
    sCode: string;
    sPLZ: string;
    sCity: string;
    sCountry: string;
    sDisplayString: string;
    selected: boolean;
}
export type ProfileDropdownData = {
    code: string;
    desc: string;
    paramtype: string;
    resultype: string;
    avgresultyn: string;
    selected: Boolean;
    color: any;
}
export enum enmScanning {
    Scan_DeliveryLabels = 0,
    Scan_BGCards = 1,
    Scan_ExternLabResults = 2,
    Scan_PatDocs = 3,
    Scan_AntibodyResults = 4,
    Scan_QCDocs = 5,
    Scan_RgtDocs = 6,
    Scan_CalDocs = 7,
    Scan_QCExtResults = 8,
    Scan_RgtMatDocs = 9,
    Scan_CalMatDocs = 10,
    Scan_QCMatDocs = 11,
    Scan_QCExtResultEvaluation = 12,
    Scan_TransReaction = 13,
    Scan_Components = 14
};
export const emptyScanProperties: CScanProperties = {
    pid: "",
    reqid: "",
    disableshowdocbutton: false
}
export const dlgScannerUIEmptyState: scannerUIProps = {
    source: enmScanning.Scan_ExternLabResults,
    showDialog: false,
    multipleScans: false,
    selPDF: false,
    objScanProperties: emptyScanProperties,
    ScanningOver: () => { }
}   
export type CScanProperties = {
    pid: string
    reqid: string    
    disableshowdocbutton: boolean
};
export const dlgPatRepEmptyState: patientReportsDialog = {
    mandantid: "", pid: "", showDialog: false, FormClosed: () => { }
}
export const emptyItemsToPrintData: ItemsToPrintDialogData = {

    reqData: emptyRequest,
    matid: "",
    sformnr: "",
    enmwhattoprint: 2,
    bprintdirectly: false,
    bprintwhenrequestplan: false,
    bprintreqlabel: false,
    bprintmedienlabelsonly: false,
    bprintomrbarcodenndlabel: false,
    bfrommatinlab: false,
    showDialog: false
}

export interface ReqSplCommentDialog {
    mandantid: string;
    mandantdesc: string;
    reqid: string;
    reqdate: string;
    reqtime: string;
    pid: string;
    splcommtype: string;
    showDialog: boolean;  
    callbackDataSaved(Save: boolean): any;
}
export const emptyReqSplCommentDialog: ReqSplCommentDialog = {
    mandantid: "",
    mandantdesc: "",
    reqid: "",
    reqdate: "",
    reqtime: "",
    pid: "",
    splcommtype: "1",
    showDialog: false,  
    callbackDataSaved:() => { }
}
export interface CReqSplComm {
    reqid: string;
    reqdate: string;
    reqcomment: string;
}
export interface CSplReqCommOutput {
    deftext: string;  
    list: CReqSplComm[];
}

export type BloodProductReqRelatedData = {
    sHbResult: string
    sHbResultFlag: string
    sHbWarning: string
    sTcResult: string
    sTcResultFlag: string
    sTcWarning: string
    sTestResult: string
    sTest2Result: string
    sSRKBefundReqID: string
    sSRKBefundParam: string
    sHkResult: string
    sHkResultFlag: string
    sHkWarning: string
    sTestBlutValue: string
}

export const emptyBloodProductReqRelatedData: BloodProductReqRelatedData = {
    sHbResult: "",
    sHbResultFlag: "",
    sHbWarning: "",
    sTcResult: "",
    sTcResultFlag: "",
    sTcWarning: "",
    sTestResult: "",
    sTest2Result: "",
    sSRKBefundReqID: "",
    sSRKBefundParam: "",
    sHkResult: "",
    sHkResultFlag: "",
    sHkWarning: "",
    sTestBlutValue: ""
}

export type CSysParameters = {
    sMandantID: string,
    sSettingSection: string,
    sSettingKey: string,
    sSettingValue: string,
    sRemark: string,
    sInactiveYN: string,
    objRecUpd: CRecordUpdationGeneral
};

export interface inputBoxDialog {    
    promptText: string;
    title: string;
    defText: string;
    maxLength: number;
    enm:enmInputBoxType
    setText(inputtext: string): any;
    showDialog: boolean;
}
export enum enmInputBoxType {
    RejectionReason = 0,
    FreeText = 1,   
};

export const dlgEmptyInputBoxState: inputBoxDialog = {
    promptText: "", title: "", defText: "", maxLength: 255, enm: enmInputBoxType.FreeText, setText: () => { }, showDialog: false
};

export interface confirmationDialog {
    obj: CVerifyValidationData; 
    showDialog: boolean;
    SelectedOptions(obj: CVerifyValidationData | null): any;
}

export interface CConfirmationMsg {
    msg: string
    msgid: string
    action: string
}

export type CVerifyValidationData = {
    sMandantID: string;
    objREO: CResultEntryOutput;
    sMode: string;
    bValidateIncompatibleComp: boolean;
    bAddBldGrpRhesusToPatient: boolean;
    bAddPhenoTypeToPatient: boolean;
    bSetCompFreeAndProceedWithValidation: boolean,
    bResultEnteredRDParam: boolean;
    bResultEnteredBCParam: boolean;
    bBloodComponentExists: boolean;
    bDataChanged: boolean;
    nMsgAntigenDoesNotMatch: number;
    nMsgBldGrpRhsOfPatAndCompDoesNotMatch: number;
    nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: number;
    nMsgBldGrpOfResultAndComponentDoesNotMatch: number;
    nMsgRhesusOfResultAndPatientDoesNotMatch: number;
    nMsgRhesusOfResultAndComponentDoesNotMatch: number;
    nMsgAcceptBldGrpAndRhesus: number;
    nMsgAcceptPhenoTypeYN: number;
    nGetReactionReason: number;
    sReactionReason: string;
    sConfirmationMsg: string;
    ConfirmationMsg: CConfirmationMsg[];
};

export const emptyVVD: CVerifyValidationData = {
    sMandantID: "",
    objREO: emptyREO,
    sMode: "",
    bValidateIncompatibleComp: false,
    bAddBldGrpRhesusToPatient: false,
    bAddPhenoTypeToPatient: false,
    bSetCompFreeAndProceedWithValidation: false,
    bResultEnteredRDParam: false,
    bResultEnteredBCParam: false,
    bBloodComponentExists: false,
    bDataChanged: false,
    nMsgAntigenDoesNotMatch: -1,
    nMsgBldGrpRhsOfPatAndCompDoesNotMatch: -1,
    nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: -1,
    nMsgBldGrpOfResultAndComponentDoesNotMatch: -1,
    nMsgRhesusOfResultAndPatientDoesNotMatch: -1,
    nMsgRhesusOfResultAndComponentDoesNotMatch: -1,
    nMsgAcceptBldGrpAndRhesus: -1,
    nMsgAcceptPhenoTypeYN: -1,
    nGetReactionReason: -1,
    sReactionReason: "",
    sConfirmationMsg: "-1",
    ConfirmationMsg: [],
};

export type qcResultList = {
    sBarcode: string,
    sQCMaterialID: string;
    sLotID: string;
    sValidFrom: string;
    sValidTo: string,
    sFromWorklist: string,
    sInUseFrom: string,
    sInUseTo: string
    sExpiryDate: string
    sQCMatDescr: string   
    sWorkplaceID: string 
    sWorkplaceDesc:string
};
export const emptyQCResultList: qcResultList = {
    sBarcode: "",
    sQCMaterialID: "",
    sLotID: "",
    sValidFrom: "",
    sValidTo: "",
    sFromWorklist: "",
    sInUseFrom: "",
    sInUseTo: "",
    sExpiryDate: "",
    sQCMatDescr: "",
    sWorkplaceID:"",
    sWorkplaceDesc:""
};
export type CDayWorkSpaceMasterData = {
    mandantID: string
    pid: string
    fid: string
    reqID: string
    reqDateTime: any
    patLastName: string
    patFirstName: string
    gender: string
    birthDate: string
    room: string
    station: string
    reqStatus: string
    emergencyYN: string
    reqProvider: string
    pregnancyWeek: string
    valType: CDayWorkSpaceDetailData[];
    material: CRequestMaterial[];
    tlRequest: CMainTimeline;
    tlMaterial: CMainTimeline[];
    tlParam: CMainTimeline[];
}

export type CDayWorkSpaceDetailData = {
    valType: string
    valTypeDesc: string
    suppStr: string
    reqParamData: CRequestParam[];
}

export type CScanData = {
    sMandantID : string
    sPID : string
    sReqID : string
    sBldBgNr : string
    sBldBgType : string
    sIDFrgCard : string
    sExtLabName : string
    sExtLabLastName : string
    sDocumentNr: string
    sPageNr:string
    sMaterialID : string
    sMaterialDesc : string
    sLotID : string
    sQCExtCenter : string
    sID : string
    sCompID : string
    sCompBar : string
    sFileType : string
    sRemark : string
    enm: enmScanning
    sKey:string
}

export type viewScanDialog = {
    sMandantID : string
    sPID : string
    sReqID : string
    sBldBgNr : string
    sBldBgType : string
    sMaterialID : string
    sLotID : string
    enm: enmScanning
    showDialog: boolean
    callbackDialogClosed(): any
}
export interface qcResultEntryDialog {
    mandantid: string;    
    userid: string;
    formstate: number;
    showDialog: boolean;
    obj: qcResultList
    ResultSaved(Save: boolean): any;    
}
export type CQCResultEntry = {
    sMandantID: string
    sRefParamID: string
    sParamDesc: string
    sParamTooltip:string
    sResult: string
    sRefResult: string
    sComment: string
    sRefComment: string
    sWorkplaceFlag: string
    sRefWorkplaceFlag: string    
    sAccept: string 
    sReject:string
    sRejectionReason: string
    sRefRejectionReason: string
    sResultDate: string
    sRefResultDate: string
    sResultTime: string
    sRefResultTime: string
    sResultStatus: string
    sStdDeviation: string
    sStdLow3S: string
    sStdHigh3S: string
    sResultType: string
    sKeyCode: string
    sSerIDToValidate: string
    sRecStatus: string
    sResultFlag: string
    sSumResults: string
    sNumResults: string
    sMinResult: string
    sMaxResult: string
    sFirstResult: string
    sAvgResults: string
    sStdVariantFlag: string
    sValidFrom: string
    //westgard rule columns
    s1x1s: string
    s1x2s: string
    s1x3s: string
    s2x2s: string
    s4s: string
    s4x1s: string
    s8x: string
    s10x: string
    s12x: string
    sResultRules: string
    sRefResultRules: string
    sStdLow1S: string
    sStdHigh1S: string
    sStdLow2S: string
    sStdHigh2S: string
    sStdValue: string
    sUnit: string
    sTextResult: string
    sBlockYN: string
    sParamDescColBackColor: string
    sParamDescColForeColor: string
    sResultColColor: string
    bDisableResultEntry: boolean
    ExistingResult: CQCResult[]    
    collPreviousResults :[]
    bLockAcceptReject: boolean
    sQCMaterialID: string
    sLotID: string
    sWorkplaceID: string
    bInvalidCode: boolean
}
export type CQCResult = {
    sMandantID: string
    sQCMaterialID: string
    sLotID: string
    sSerID: string
    sWorkPlaceID: string
    sKeyCode: string
    sParamId: string
    sValidFrom: string
    sResult: string
    sResultDate: string
    sResultTime: string
    sResultRules: string
    sRejectionReason: string
    sResultFlag: string
    sWorkPlaceFlag: string
    sComment: string
    sInactiveYN: string
    objRecUpd : CRecordUpdationGeneral
    //the following field(s) does not belong to table QCResults
    //if the param is marked as text result then the string will contain the possible text results
    sTextResult: string  
    sQCLotDetailsMeanValue: string
    sQCLotDetailsStdDeviate: string
    //used for showing graph
    sScaledResult: string 
}
export type CQCResultEntryOutput = {
    listResEntry: CQCResultEntry[]
    collBlockedParams: []
    bSaveValidation: boolean
    sColHeader: string
    sErrorMsg:string
}
export type CMainTimeline = {
    title: string;
    tl: CTimelineEvent[];
};
export type CTimelineData = {
    tlRequest: CMainTimeline;
    tlMaterial:  CMainTimeline[];
    tlParam :CMainTimeline[]
}
export type CSerothekPatients = {    
    sReqID : string
    sPID : string
    sPatName : string
    sBirthDate : string
    sGender : string
    sMaterialID : string
    sRackID : string
    sRackName : string
    sPosXY : string
    sExtDateTime: string
    sKey:string
}
export const emptySerothekPatients: CSerothekPatients = {
    sReqID: "",
    sPID: "",
    sPatName: "",
    sBirthDate: "",
    sGender: "",
    sMaterialID: "",
    sRackID: "",
    sRackName: "",
    sPosXY: "",
    sExtDateTime: "",
    sKey:"",
}
export type serothekPatientsDialog ={
    pid: string;
    pidDesc: string;
    showDialog: boolean;
    callbackDialogClosed(): any;
};

export const emptySeroRackListData = {
    sGroup: "",
    sRackID: "",
    sReqID: "",
    sMaterialID: "",   
}
export type CSeroRackListData = {
    sGroup: string; 
    sRackID: string;
    sReqID: string;
    sMaterialID: string;
}

export type CSeroRackGridCellInfo = {
    sRackID :string;
    sRackName :string;
    sRackLocation :string;
    sRackLocationName :string;
    sSeroParamLocation :string;
    sSeroParamLocationName :string;
    sReqID :string;
    sReqDate :string;
    sReqTime :string;
    sName :string;
    sFirstName :string;
    sBirthdate :string;
    sGender :string;
    sGroupID :string;
    sGroupName :string;
    sMaterial :string;
    sExtDate :string;
    sExtTime :string;
}
export type CSeroRackGridCell = {
    nRowNr: number;
    nColNr: number;
    sValue: string;
    bHeader: boolean;
    sBackColor: string;
    sForeColor: string;
    sComment: string;
    //info object on grid cell click
    objInfo: CSeroRackGridCellInfo;
}
export type CSeroRackDetails = {
    SeroRackDetailData: CSeroRackGridCell[],    
    selCellInfo: CSeroRackGridCellInfo;
}

export type CComponents = {
    sMandantID:string;
    sCompID:string;
    sCompType: string;
    sCompBar: string;
    sBGRHBar: string;
    sExpiryDate: string;
    sExpiryTime: string;
    sCompStatus: string;
    sEntryDate: string;
    sDonationDate: string;
    sDonationCentre: string;
    sReservedFor: string;
    sReservedForReqID: string;
    sBloodGroup: string;
    sRhesus: string;
    sPhenoType: string;
    sComment: string;
    sContA: string;
    sContB: string;
    sContD: string;
    sContBG: string;
    sContRH: string;
    sContPheno: string;
    sCardID: string;
    sContDate: string;
    sContTime: string;
    sTechName: string;
    sContComment: string;
    sMolekularGeneticPosYN: string;
    sPrintDate: string;
    sDiscardDate: string;
    sDiscardReason: string;
    sInactiveYN: string;
    sAntiuC: string;
    sAntiCw: string;
    sAntilc: string;
    sAntiuE: string;
    sAntile: string;
    sAntiKell: string;
    sNegCtrl: string;
    sPHCardID: string;
    sPHDate: string;
    sPHTime: string;
    sPHUser: string;
    sKell: string;
    sWeight: string;
    objRecUpd: CRecordUpdationGeneral;
    sExternControlledYN: string;
    sSpecialAnalysisBarCode: string;
    sSpecialAnalysisBarCodeDesc: string;
    sReservedForMandantID: string;
    sContPhenoDesc: string;
}

export type emergencyCompResDialog = {
    pid: string;
    fid: string;
    showDialog: boolean;
    callbackDialogClosed(): any;
};

export type CEmergencyCompResData = {
    sCompID: string;
    sCompBar: string;
    sCompBG: string;
    sCompRH: string;
    sProfileID: string;
    sBackColor: string;
};

export type CEmergencyCompResOutput = {
    mandantid: string;
    compid: string;
    compbar: string;
    listprofiles: CKeyCollectionParam [];
    listreqmat: CKeyCollectionMat[];
    listresultdata: CKeyCollectionResult [];
    collbcparamcountforprofile: []
    listbloodcomponents: CKeyCollectionBC[]
    pid: string;
    fid: string;
    reqdate: string;
    reqtime: string;
    reqprovider: string;
    emergency: boolean
    listcompEk: CEmergencyCompResData[];
    listcompFFP: CEmergencyCompResData[];
    listcompTK: CEmergencyCompResData[];
    objAddress:CAddressData
    msg: string;
    userid: string;
}
export type CKeyCollectionMat = {
    obj: CRequestMaterial;
    sKey: string;
}
export type CKeyCollectionParam = {
    list: CRequestParam[];
    sKey: string;
}
export type CKeyCollectionResult = {
    list: CRequestResult[];
    sKey: string;
}
export type CKeyCollectionBC = {
    obj: CComponents;
    sKey: string;
}
export type CDuplicatePatData = {
    sPATID : string;
    sFirstName : string;
    sLastName : string;
    sBirthName : string;
    sBirthDate : string;
    sDomicileCity : string;
    sDomicileCountry : string;
    sStreet : string;
    sAddrLine3 : string;
    sShortName : string;
    sInitials : string;
    sStatus : string;
    sMandantID : string;
    sSex : string;
    sTelInternal : string;
    sKey2 : string;
    sEmailID: string;
    sReqDate: string;
    sInactiveYN: string;
    sDisplayString: string;
}
export const emptyDuplicatePatData: CDuplicatePatData = {
    sPATID: "",
    sFirstName: "",
    sLastName: "",
    sBirthName: "",
    sBirthDate: "",
    sDomicileCity: "",
    sDomicileCountry: "",
    sStreet: "",
    sAddrLine3: "",
    sShortName: "",
    sInitials: "",
    sStatus: "",
    sMandantID: "",
    sSex: "",
    sTelInternal: "",
    sKey2: "",
    sEmailID: "",
    sReqDate: "",
    sInactiveYN: "",
    sDisplayString:"",
}
export type CDuplicateCaseData = {
    sMandantID: string; 
    sFID: string;
    sPID: string;
    sDisplayString: string;
}
export const emptyDuplicateCaseData: CDuplicateCaseData = {
    sMandantID: "",
    sFID: "",
    sPID: "",
    sDisplayString:"",
}
            
             