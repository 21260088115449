import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getSelectedState, Grid, GridColumn, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CReqSplComm, CSplReqCommOutput, ReqSplCommentDialog } from "../../types";
import axios from 'axios';
import UserSession from "../UserSession";
import { getter } from "@progress/kendo-data-query";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import ProgressBar from "../Misc/ProgressBar";

const initialSort: Array<SortDescriptor> = [
   

];
const RequestSpecialCommentDialog = (props: ReqSplCommentDialog) => {    
    const idGetter = getter('reqid');
    const [sort, setSort] = useState(initialSort);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [newComment, setNewComment] = useState("");
    const [commentSaved, setCommentSaved] = useState(false);

    const splReqCommOutputEmptyState: CSplReqCommOutput = {
        deftext:"",list: []
    }
    const [splReqCommData, setSplReqCommData] = useState<CSplReqCommOutput>(splReqCommOutputEmptyState);
    //const [gridData, setGridData] = useState<CReqSplComm[]>([]);  
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    const saveDialog = async () => {
        if (newComment === "") {
            alert(mui(userRights, "ReqSplComment", "msgEnterComment", "Please enter comment."));
            return;
        };
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'requestentry/saveRequestSplCommentData',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, splcommtype: props.splcommtype, comment:newComment
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objToAdd: CReqSplComm = response?.data;
            var obj: CSplReqCommOutput = splReqCommData;
            let reqsplcomm: CReqSplComm | undefined = obj.list.find((item: CReqSplComm) => item.reqid === objToAdd.reqid)
            if (reqsplcomm !== undefined) {
                reqsplcomm.reqcomment = objToAdd.reqcomment;
                setSplReqCommData(obj); 
            } else {
                obj.list.unshift({ ...objToAdd });  
                setSplReqCommData(obj); 
            };                      
            setComment(objToAdd.reqcomment);            
            setCommentSaved(true);
            let sel: any = {};
            sel[objToAdd.reqid] = true;
            setSelectedState(sel);
            //setVisible(false);
            //props.callbackDataSaved(true);
        };
        setIsLoading(false);
    };

    const cancelDialog = () => {
        setVisible(false);        
        props.callbackDataSaved(commentSaved);
    };

    const handleCommChange = (e: any) => {
        setNewComment(e.target.value);
    };

    async function GetReqSplCommData() {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'requestentry/getRequestSplCommentData',
            JSON.stringify({
                mandantid: props.mandantid, pid: props.pid, splcommtype: props.splcommtype, userid: UserSession.getLoggedInUser().UserID
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var binddata: CSplReqCommOutput = response?.data;
            setSplReqCommData(binddata);
            if (binddata.list.length > 0) {
                let reqsplcomm: CReqSplComm | undefined = binddata.list.find((item: CReqSplComm) => item.reqid === props.reqid)
                if (reqsplcomm !== undefined) {
                    setComment(reqsplcomm.reqcomment);
                    setNewComment(reqsplcomm.reqcomment);
                    let sel: any = {};
                    sel[reqsplcomm.reqid] = true;
                    setSelectedState(sel);
                } else {
                    setNewComment(binddata.deftext);
                    let sel: any = {};
                    sel[binddata.list[0].reqid] = true;
                    setSelectedState(sel);
                    setComment(binddata.list[0].reqcomment);
                };
            } else {                
                setNewComment(binddata.deftext);
            };            
        };
        setIsLoading(false);
    };

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'reqid',
        });
        // get the selected row from the grid and set state variable so that data is populated in the form
        let selectedIds: CReqSplComm[] = [];
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let reqsplcomm: CReqSplComm | undefined = splReqCommData.list.find((item: CReqSplComm) => item.reqid === property.toString())
                if (reqsplcomm !== undefined)
                    selectedIds.push(reqsplcomm)
            };
        }
        if (selectedIds.length > 0) {            
            setComment(selectedIds[0].reqcomment);            
        }
        else
            setComment(""); 
        setSelectedState(newSelectedState);
    };

    useEffect(() => {
        setCommentSaved(false);
        setSplReqCommData(splReqCommOutputEmptyState);
        setComment("");
        setNewComment("");
        GetReqSplCommData();
        setVisible(props.showDialog);
    }, [props.showDialog])

    var dataAreaWidth: number = window.innerWidth - 545;
    var dlgHeight: number = window.innerWidth - 500;

    return (
        <>
            <div>
                {visible && (
                    <Dialog contentStyle={{ overflow: 'hidden', padding: 5 }}
                        title={<DialogTitleBar title={mui(userRights, "common", "ReqSpecialComm", "Request Special Comment")} ></DialogTitleBar>}
                        onClose={cancelDialog} height='800px'
                        width={dlgHeight} >

                        {props.reqid !== "" && (<div className="container" style={{
                            maxWidth: dataAreaWidth, border: '1px solid lightblue', paddingBottom: 10, marginBottom: 5, borderRadius: 10,
                            width: dataAreaWidth
                        }}>

                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-2">
                                    <Label style={{ fontSize: 15 }} editorId={'lblmandantid'}>{mui(userRights, "common", "lblMandantID", "Mandant") + ' : '}</Label>
                                </div>
                                <div className="col-10">
                                    {props.mandantid + ' ' + props.mandantdesc}
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-2">
                                    <Label style={{ fontSize: 15 }} editorId={'lblreqdetails'}>{mui(userRights, "common", "lblReqDetails", "Request") + ' : '}</Label>
                                </div>
                                <div className="col-10">
                                    {props.reqid + ', ' + props.reqdate + ' ' + props.reqtime}
                                </div>

                            </div>
                            <div className="row" style={{ marginTop: '5px' }}>
                                <div className="col-2">
                                    <Label style={{ fontSize: 15 }} editorId={'lblsplcomment'}>{mui(userRights, "common", "lblSplComment", "Special Comment") + ' : '}</Label>
                                </div>
                                <div className="col-10">
                                    <TextArea autoComplete="off" id="comment" onChange={handleCommChange} name="comment"
                                        value={newComment} autoSize={false} style={{ width: '100%' }} maxLength={512} rows={4} />
                                </div>
                            </div>
                        </div>)}
                        {props.reqid !== "" && (<div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <div className="col">
                                <div className="button-right">
                                    <Button style={{ width: '120px' }} themeColor={'secondary'} onClick={cancelDialog}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    &nbsp;
                                    <Button style={{ width: '120px' }} themeColor={'primary'} onClick={saveDialog}>{mui(userRights, "common", "cmdSave", "Save")}</Button>
                                </div>
                            </div>
                        </div>)}
                        <div className="container" style={{
                            maxWidth: dataAreaWidth, border: '1px solid lightblue', paddingBottom: 5, marginBottom: 5, borderRadius: 10,
                            width: dataAreaWidth
                        }}>
                            <div className="row">
                                <div className="col" style={{ marginTop: '5px' }}>
                                    <TextArea autoComplete="off" id="reqcomment" name="reqcomment" value={comment}
                                        autoSize={false} style={{ width: '100%' }} disabled={true} rows={4} />
                                </div>
                            </div>
                        </div>          
                        <div className="container" style={{
                            maxWidth: dataAreaWidth, border: '1px solid lightblue', paddingBottom: 5, marginBottom: 5, borderRadius: 10,
                            width: dataAreaWidth
                        }}>
                            <div className="row">
                                <div className="col">
                                    {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                    <Grid
                                        style={{
                                            height: props.reqid !== ""?'400px':'600px', width: '100%', marginTop: '5px', marginBottom: '5px'
                                        }}                                        
                                        selectable={{
                                            enabled: true,
                                            drag: false,
                                            cell: false,
                                            mode: "single",
                                        }}
                                        onSelectionChange={onSelectionChange}
                                        selectedField={'selected'}
                                        data={orderBy(splReqCommData.list, sort).map((item) => ({
                                            ...item,
                                            selected: selectedState[idGetter(item)],
                                        }))}
                                        dataItemKey={"reqid"}                                        
                                    >
                                        <GridColumn field="reqid" title={mui(userRights, "ReqSplComment", "col_ReqID", "ReqID")} width="150px" />
                                        <GridColumn field="reqdate" title={mui(userRights, "ReqSplComment", "col_ReqDate", "ReqDate")} width="150px" />
                                        <GridColumn field="reqcomment" title={mui(userRights, "ReqSplComment", "col_ReqComment", "ReqComment")} />
                                        );
                                        </Grid>
                                    }
                                </div>
                            </div>
                        </div>                        
                    </Dialog>
                )}
            </div>            
        </>
    );
}
export default RequestSpecialCommentDialog;